import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

interface AllProps {
    verify: any;
    setVerify: any;
    saveUploadData: any;
    // completeEnable: any;
}
export class VerificationEOD extends Component<AllProps> {
    state = {

    }
    handleChange = (e, val) => {
        const { verify } = this.props;
        console.log(e.target.name, e.target.checked);
        verify[e.target.name] = val;
        this.props.setVerify(verify);
    }
    handleInputChange = (e) => {
        const { verify } = this.props;
        verify[e.target.name] = e.target.value;
        this.props.setVerify(verify);
    }
    handleKeyDownOtherSection = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const { verify } = this.props;
            verify.otherSection += ' ';
            this.props.setVerify(verify);
        }
    };
    handleKeyDownErrorDetails = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const { verify } = this.props;
            verify.errorDetails += ' ';
            this.props.setVerify(verify);
        }
    };
    handleBlurOtherSection = () => {
        const { verify } = this.props;
        verify.otherSection = verify.otherSection.trim();
        this.props.setVerify(verify);
    };

    handleBlurErrorDetails = () => {
        const { verify } = this.props;
        verify.errorDetails = verify.errorDetails.trim();
        this.props.setVerify(verify);
    };

    handleCheckBoxChange = (e) => {
        const { verify } = this.props;
        console.log(e.target.name, e.target.checked);
        if (e.target.checked) {
            if (!verify.errorSection.includes(e.target.name)) {
                verify.errorSection.push(e.target.name);
            }
        } else {
            verify.errorSection.splice(verify.errorSection.indexOf(e.target.name), 1);
        }
        console.log(verify);
        this.props.setVerify(verify);
    }
    handleErrTypeChange = (e) => {
        const { verify } = this.props;
        console.log(e.target.name, e.target.checked);
        if (e.target.checked) {
            if (!verify.errorTypes.includes(e.target.name)) {
                verify.errorTypes.push(e.target.name);
            }
        } else {
            verify.errorTypes.splice(verify.errorTypes.indexOf(e.target.name), 1);
        }
        console.log(verify);
        this.props.setVerify(verify);
    }
    saveData = () => {
        this.props.saveUploadData();
        // this.props.completeEnable(isComplete);
    }
    render() {
        const { verify } = this.props;
        // const{isComplete}=this.props;
        return (
            <div className="modal" id="verification-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Verification EOD</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-10">
                                <div className="col-md-3 mb-10">
                                    <label className="label">Highlight</label>
                                    <div className="row ml-10">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="highlight"
                                            type="radio"
                                            checked={verify.highlight}
                                            onChange={(e) => this.handleChange(e, true)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="highlight"
                                            type="radio"
                                            checked={!verify.highlight}
                                            onChange={(e) => this.handleChange(e, false)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-10">
                                    <label className="label">Data Entry</label>
                                    <div className="row ml-10">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="dataEntry"
                                            type="radio"
                                            checked={verify.dataEntry}
                                            onChange={(e) => this.handleChange(e, true)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="dataEntry"
                                            type="radio"
                                            checked={!verify.dataEntry}
                                            onChange={(e) => this.handleChange(e, false)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-10">
                                    <label className="label">Flow Chart</label>
                                    <div className="row ml-10">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="flowChart"
                                            type="radio"
                                            checked={verify.flowChart}
                                            onChange={(e) => this.handleChange(e, true)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="flowChart"
                                            type="radio"
                                            checked={!verify.flowChart}
                                            onChange={(e) => this.handleChange(e, false)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-10">
                                    <label className="label">Hint About the File</label>
                                    <div className="row ml-10">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="hintFile"
                                            type="radio"
                                            checked={verify.hintFile}
                                            onChange={(e) => this.handleChange(e, true)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="hintFile"
                                            type="radio"
                                            checked={!verify.hintFile}
                                            onChange={(e) => this.handleChange(e, false)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-10">
                                    <label className="label">Error Identified?</label>
                                    <div className="row ml-10">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="errorIdentified"
                                            type="radio"
                                            checked={verify.errorIdentified}
                                            onChange={(e) => this.handleChange(e, true)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="errorIdentified"
                                            type="radio"
                                            checked={!verify.errorIdentified}
                                            onChange={(e) => this.handleChange(e, false)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-10">
                                    <label className="label">Number Of LOD's</label>
                                    <div className="row ml-10">
                                        <input
                                            className="form-control"
                                            name="noOflod"
                                            type="text"
                                            value={verify.noOflod}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h6><b>Error Location</b></h6>
                                <div className="row mb-10">
                                    <label className="label">Select All Of The Section That Contain The Error</label>
                                    <div className="col-md-12">
                                        <div className="row ">
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="List of Document"
                                                    name="List Of Document"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('List Of Document')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Possession"
                                                    name="Possession"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('Possession')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Schedule of Property"
                                                    name="Schedule of Property"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('Schedule of Property')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Encumbrance Certificate"
                                                    name="Encumbrance Certificate"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('Encumbrance Certificate')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="History"
                                                    name="History"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('History')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Certificate"
                                                    name="Certificate"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('Certificate')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-4 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Other locations"
                                                    name="Other locations"
                                                    type="checkbox"
                                                    checked={verify.errorSection.includes('Other locations')}
                                                    onChange={this.handleCheckBoxChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-8">
                                        <textarea className="form-control"
                                            name="otherSection"
                                            value={verify.otherSection}
                                            onChange={this.handleInputChange}
                                            onKeyDown={this.handleKeyDownOtherSection}
                                            onBlur={this.handleBlurOtherSection}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h6><b>Error Type</b></h6>
                                <div className="row mb-10">
                                    <label className="label">Select All Type Of Errors</label>
                                    <div className="col-md-12">
                                        <div className="row ">
                                            <div className="col-md-3 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Typing"
                                                    name="Typing"
                                                    type="checkbox"
                                                    checked={verify.errorTypes.includes('Typing')}
                                                    onChange={this.handleErrTypeChange}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-10">
                                                <Form.Check
                                                    inline
                                                    label="Analyze"
                                                    name="Analyze"
                                                    type="checkbox"
                                                    checked={verify.errorTypes.includes('Analyze')}
                                                    onChange={this.handleErrTypeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="row mb-10">
                                    <label className="label">Describe The Errors In Detail</label>
                                    <div className="form-group col-md-12">
                                        <textarea className="form-control"
                                            name="errorDetails"
                                            value={verify.errorDetails}
                                            onChange={this.handleInputChange}
                                            onKeyDown={this.handleKeyDownErrorDetails}
                                            onBlur={this.handleBlurErrorDetails}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-md-6">
                                        <label className="label">Total Time To Complete The Verification (Mins)</label>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <input
                                            className="form-control"
                                            name="totalTime"
                                            type="text"
                                            value={verify.totalTime}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn "
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.saveData}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerificationEOD;
