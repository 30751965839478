import React from 'react';
import ReactFileReader from "react-file-reader";
import Select from 'react-select';

const particularList = [
  {
    label: 'Original',
    value: 'original'
  },
  {
    label: 'Xerox',
    value: 'xerox'
  }
];




const UploadDocument = (props) => {
  const validTypes = ['deed', 'mother_doc', 'title_deed'];
  const invalidDocuments = ['26', '27', '36', '35', '38', '103', '78', '5', 'Online Encumbrance Certificate'];
  const { index, baseDetails, handleDocumentDetailsChange, handleDocumentFilesChange, deleteFile, previewDoc, deleteDocumentDetails, categoriesList, newfile, path, isbtnload, deedList, doctype,bank_private } = props
  const { docNoYear, particular, type, base, isSavedFile, base_url, doc_path, doc_type, loc_doc_name } = baseDetails;
  let Ec_dropdown = [
    {
      "name": "SRO Encumbrance Certificate",
      "key": "SRO Encumbrance Certificate"
    },
    {
      "name": "Online Encumbrance Certificate",
      "key": "Online Encumbrance Certificate"
    },
  ];
  let Firstdoctitle = [
    {
      "name": "Title Deed",
      "id": "title_deed"
    }
  ];
  let Firstdocsaleaggre = [
    {
      "name": "Sale Agreement",
      "id": "sale_agreement"
    }
  ];
  const renderCategoriesSelect = () => renderSelect(
    categoriesList,
    type,
    (value) => handleDocumentDetailsChange(value, index, 'type'),
    'example: Title Deed'
  );

  const renderFirstDocTitleSelect = () => renderSelect(
    Firstdoctitle,
    type,
    (value) => handleDocumentDetailsChange(value, index, 'type'),
    'example: Title Deed'
  );

  const renderFirstDocSaleAggreSelect = () => renderSelect(
    Firstdocsaleaggre,
    type,
    (value) => handleDocumentDetailsChange(value, index, 'type'),
    'example: Title Deed'
  );

  const renderSelect = (options, value, onChange, placeholder) => {
    if (options && options.length > 0) {
      return (
        <Select
          options={options}
          name="category"
          value={options.find((o) => o.value === value)}
          onChange={(e) => onChange(e.value)}
          placeholder={placeholder}
        />
      );
    }
    return null;
  };

  return (
    <div className="upload-document-wrapper">
      <div className="row upload-document-folder">
        <div className="col-md-1">
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label>{index == 0 && 'Sl.No'}</label>
              </div>
            </div>
            <p style={{ 'padding': '20%' }}>{index + 1}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Category<span style={{ color: "#FF0000" }}>*</span></label>
            {/* <>
              {index !== 0 && doctype !== 3 ? (
                renderCategoriesSelect()
              ) : (
                <>
                  {doctype !== 3 && (index === 0 || index === 1) ? (
                    renderFirstDocTitleSelect()
                  ) : (
                    index === 0 && doctype === 3 && renderFirstDocSaleAggreSelect()
                  )}
                </>
              )}
            </> */}

            {index != 0 && !["10", "3"].includes(doctype) ? (<>
              {categoriesList && categoriesList.length > 0 && categoriesList.map((opt) => { opt.label = opt.name; opt.value = opt.id }) &&
                <>
                  <Select options={categoriesList} name="category"
                    value={categoriesList.find((o) => o.value === type)}
                    onChange={e => handleDocumentDetailsChange(e.value, index, 'type')}
                    placeholder="example: Title Deed"
                  />
                </>
              }
            </>) : (<>
              {!["10", "3"].includes(doctype) && (index == 0 || index == 1) && Firstdoctitle && Firstdoctitle.length > 0 && Firstdoctitle.map((opt) => { opt.label = opt.name; opt.value = opt.id }) ?
                <>
                  <Select options={Firstdoctitle} name="category"
                    value={Firstdoctitle.find((o) => o.value === type)}
                    onChange={e => handleDocumentDetailsChange(e.value, index, 'type')}
                    placeholder="example: 1Title Deed"
                  />
                </>
                : index == 0 && ["10", "3"].includes(doctype) && Firstdocsaleaggre && Firstdocsaleaggre.length > 0 && Firstdocsaleaggre.map((opt) => { opt.label = opt.name; opt.value = opt.id }) ?
                  <>
                    <Select options={Firstdocsaleaggre} name="category"
                      value={Firstdocsaleaggre.find((o) => o.value === type)}
                      onChange={e => handleDocumentDetailsChange(e.value, index, 'type')}
                      placeholder="example: Title Deed"
                    />
                  </> :
                  <>
                    {categoriesList && categoriesList.length > 0 && categoriesList.map((opt) => { opt.label = opt.name; opt.value = opt.id }) &&
                      <>
                        <Select options={categoriesList} name="category"
                          value={categoriesList.find((o) => o.value === type)}
                          onChange={e => handleDocumentDetailsChange(e.value, index, 'type')}
                          placeholder="example: Title Deed"
                        />
                      </>
                    }
                  </>
              }
            </>)}

            {!type && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
          </div>
        </div>

        {type == 'rev_rec_doc' || type == 'deed' || type == 'mother_doc' || type == 'title_deed' || type == 'ec_doc' || type == 'sale_agreement' ? (<>
          {/* {type == 'rev_rec_doc' ? (<> */}
          <div className="col-md-2">
            <div className="form-group">
              <label>Document Type<span style={{ color: "#FF0000" }}>*</span>
                {/* <span className="inputEgMsg">Eg. Sale Deed / Will Deed Etc...</span> */}
              </label>
              {type != "ec_doc" ? <>
                {deedList && deedList.length > 0 && deedList.map((opt) => {
                  opt.label = opt.name;
                  opt.value = opt.id;
                }) &&
                  <Select options={deedList} name="doc_type"
                    value={deedList.find((o) => o.value === doc_type)}
                    onChange={e => handleDocumentDetailsChange(e.value, index, 'doc_type')}
                  />
                }
              </> :
                <>
                  {Ec_dropdown && Ec_dropdown.length > 0 && Ec_dropdown.map((opt) => {
                    opt.label = opt.name;
                    opt.value = opt.name;
                  }) &&
                    <Select options={Ec_dropdown} name="doc_type"
                      value={Ec_dropdown.find((o) => o.value === doc_type)}
                      onChange={e => handleDocumentDetailsChange(e.value, index, 'doc_type')}
                    />
                  }
                </>
              }
              {!doc_type && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
            </div>
          </div>
        </>) : ""}

        {(type === "ec_doc" || validTypes.includes(type))
          && (doc_type === "SRO Encumbrance Certificate" || !invalidDocuments.includes(doc_type))
          &&
          <div className="col-md-2">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label>Doc.No/Year<span style={{ color: "#FF0000" }}>*</span><span className="inputEgMsg">Eg.2609/2023</span></label>
                </div>
              </div>
              <>
                <input
                  className="form-control"
                  name="docNoYear"
                  placeholder="example: 1501/1996"
                  type="text"
                  value={docNoYear}
                  onChange={e => handleDocumentDetailsChange(e.target.value, index, 'docNoYear', type)}
                />
                {!docNoYear && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
              </>
            </div>
          </div>
        }
        {/* style={(type == 'deed' || type == 'mother_doc' || type == 'title_deed' || type == 'ec_doc') ? { marginLeft: '8%' } : {}} */}
        <div className="col-md-2" >
          <div className="form-group">
            <label>Particular<span style={{ color: "#FF0000" }}>*</span></label>
            <>
              <Select options={particularList} name="particular"
                value={particularList.find((o) => o.value === particular)}
                onChange={(e) => handleDocumentDetailsChange(e.value, index, 'particular')}
                placeholder="example: Xerox"
              />
              {!particular && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
            </>
          </div>
        </div>

        <div className="col-md-2 upload-document-filereader">
          <div className="form-group">
            <ReactFileReader
              fileTypes={[".pdf"]}
              base64={true}
              multipleFiles={false}
              handleFiles={e =>
                handleDocumentFilesChange(e, index, baseDetails)
              }
            >

              {!newfile && (<>
                <button className="ml2 btn btn-primary btnupload float-right" style={{ marginTop: "-10px" }}>
                  {isbtnload && (
                    <i className="fa fa-spinner fa-spin"></i>
                  )}
                  Upload +
                </button><br />
                {!base && <p style={{ color: "red", fontSize: "14px" }}>Please upload.</p>}
              </>
              )}

            </ReactFileReader>
          </div>
        </div>
        {!isSavedFile &&(bank_private == "Bank" ?
          (!["10", "3"].includes(doctype) ? (
            [0].includes(index) ? (
              ""
            ) : (
              <div className="col-md-1">
                <div
                  style={{ padding: "8px", marginTop: "25px" }}
                  className="form-group"
                >
                  <a onClick={() => deleteDocumentDetails(index)}>
                    <i
                      style={{ fontSize: "30px" }}
                      className="feather icon-trash"
                    />
                  </a>
                </div>
              </div>
            )
          ) : [0, 1].includes(index) && ["10", "3"].includes(doctype) ? (
            ""
          ) : (
            <div className="col-md-1">
              <div
                style={{ padding: "8px", marginTop: "25px" }}
                className="form-group"
              >
                <a onClick={() => deleteDocumentDetails(index)}>
                  <i
                    style={{ fontSize: "30px" }}
                    className="feather icon-trash"
                  />
                </a>
              </div>
            </div>
          )):<div className="col-md-1">
              <div
                style={{ padding: "8px", marginTop: "25px" }}
                className="form-group"
              >
                <a onClick={() => deleteDocumentDetails(index)}>
                  <i
                    style={{ fontSize: "30px" }}
                    className="feather icon-trash"
                  />
                </a>
              </div>
            </div>)
        }
      </div>
      {
        base && <div className="col-md-4" key={index}>
          <div className="card card-border-c-red">
            <span
              onClick={() =>
                deleteFile(index)
              }
            >
              <i className="feather icon-x" />
            </span>
            <div className="card-body-uploaddoc">
              <h5 className="card-title">
                <span className="mb-1 text-c-green">
                  <i className="feather icon-file-text" />
                </span>
                <span>
                  {loc_doc_name ? loc_doc_name : base}
                </span>
                {path != "filedashboard" ? <>
                  <span style={{ float: 'right' }}
                    data-toggle="modal"
                    data-target="#preview-document"
                    onClick={() =>
                      previewDoc(doc_path)
                    }
                  >
                    <i className="fa fa-eye" />
                  </span>
                </> : ""}
              </h5>
            </div>
          </div>
        </div>
      }
    </div >
  )
}

export default UploadDocument