import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import "../file/ReactFlexyTable.css";
import { callApi } from "../../utils/api";
import VerifyFileModal from "../file/VerifyFileModal";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore,
} from "react-toasts";
import moment from "moment";

interface AllProps {
    history: any;
}
declare var $: any;
class RectificationQueue extends React.Component<AllProps> {
    state = {
        selectVal: false,
        outreason: "",
        files: [],
        rectificationemergentFile: [],
        isFileRefresh: true,
        autoAssign: [
            {
                id: "1",
                module_name: "Auto Assign",
                status: true,
            },
        ],
        isLoading: false,
    };
    componentDidMount() {
        this.getFiles();
        this.getOutTime();
        this.ouTime();
        // this.getAutoAssignStatus();

        const status: any = sessionStorage.getItem("dashboard_status");
        if (status == 2) {
            this.sendBalanceMin();
            console.log("status 2");
        } else {
            console.log("not status 2");
        }
    }

    sendBalanceMin() {
        const fileid1: any = sessionStorage.getItem("file_Id");
        let updatedRemainingTime: any = sessionStorage.getItem(
            "updatedRemainingTime"
        );
        let updatedtime = Math.round(updatedRemainingTime);
        const status: any = sessionStorage.getItem("dashboard_status");
        let obj = {
            file_id: JSON.parse(fileid1),
            balance_min: updatedtime,
            dashboard_status: status,
        };
        callApi("POST", "process_eod_insert.php", obj)
            .then((res) => res.data)
            .then((response) => {
                sessionStorage.removeItem("updatedRemainingTime");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser),
        };
        callApi("POST", "time_update.php", req)
            .then((res) => res.data)
            .then((response) => {
                sessionStorage.removeItem("currentfileId");
                localStorage.removeItem("query_raised_status");
                localStorage.removeItem("file_completed_status");
                // sessionStorage.removeItem("takenfile_id");
            });
    };

    getAutoAssignStatus = () => {
        callApi("GET", "module_option_select.php", "")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ autoAssign: result.responseJson.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    };
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    };
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime));
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        } else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
    };
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId));
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    };

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(
            moment(intime, "HH:mm"),
            "seconds"
        );
        console.log("Second Spn", secondsSpent);
        let updatedRemainingTime: any = sessionStorage.getItem(
            "updatedRemainingTime"
        );
        let updatedtime = Math.round(updatedRemainingTime);
        const status: any = sessionStorage.getItem("dashboard_status");
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        } else {
            let obj = {
                file_id: JSON.parse(fileid1),
                user_id: JSON.parse(userid1),
                in_reason: JSON.parse(inreason),
                out_reason: this.state.outreason,
                intime: JSON.parse(intime),
                outtime: JSON.parse(outtime),
                insert_date: JSON.parse(outdate),
                total_seconds: secondsSpent,
                balance_min: updatedtime,
                dashboard_status: status,
            };

            console.log("72", obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then((res) => res.data)
                .then((response) => {
                    this.setState({ isLoading: false });
                    sessionStorage.removeItem("updatedRemainingTime");
                    console.log("76", response);
                    sessionStorage.removeItem("updatedRemainingTime");
                    sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");

                    let process_officerId: any =
                        sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const query_status: any = localStorage.getItem("query_raised_status");
                    const file_completed = localStorage.getItem("file_completed_status");
                    if (
                        process_officer_id == user_id &&
                        take_file != "" &&
                        take_file === fileid1 &&
                        query_status == "false" &&
                        file_completed == "false"
                    ) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem("currentfileId");
                        localStorage.removeItem("query_raised_status");
                        localStorage.removeItem("file_completed_status");
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    console.log(err);
                    ToastsStore.error("Your Reason Not Stored");
                });
        }
    };
    onchangeReason = (e: any) => {
        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false });
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        } else if (e.target.value == "select") {
            this.setState({ setResponse: false });
        } else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
            selectVal: false,
        });
    };
    getFiles = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let req = {
            userId: userId
        }
        callApi("POST", "get_rectify_files.php", req)
            .then((res) => res.data)
            .then((response) => {
                const files = response.data;
                let EmergentFile: any[] = [];
                let NormalFile: any[] = [];
                files.forEach((file, index) => {
                    if (file.file_grade == "E") {
                        EmergentFile.push(file);
                    }
                });
                files.forEach((file, index) => {
                    if (file.file_grade != "E") {
                        NormalFile.push(file);
                    }
                });
                if (NormalFile && NormalFile.length > 0) {
                    NormalFile.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                if (EmergentFile && EmergentFile.length > 0) {
                    EmergentFile.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ files: NormalFile, rectificationemergentFile: EmergentFile, isFileRefresh: true });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    goToFileDashboard = (id) => {
        const url = "/deviation-queue";
        localStorage.setItem("url", url);
        let obj = { file_id: id };
        localStorage.setItem("thisPopup", JSON.stringify(obj));
        sessionStorage.setItem("currentfileId", JSON.stringify(id));
        this.props.history.push("/file-dashboard/" + id);
    };

    timeUpdate = (fileid, type) => {
        const loginuser: any = sessionStorage.getItem("user1");
        let req;
        if (type == "verification") {
            req = {
                id: fileid,
                reason: "Verification",
                created_by: JSON.parse(loginuser),
                color: "#faad4c",
            };
        } else if (type == "unhold") {
            req = {
                id: fileid,
                reason: "Unhold",
                created_by: JSON.parse(loginuser),
                color: "gray",
            };
        }
        callApi("POST", "time_update.php", req)
            .then((res) => res.data)
            .then((response) => { });
    };

    DEmergentFile = (modal: any) => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";

        const emergent_quota: any = sessionStorage.getItem("emergent_quota");
        const quota: any = emergent_quota - 1;

        sessionStorage.setItem("emergent_quota", quota);
        const obj = {
            fileid: modal.id,
            emergent_status: 1,
            userid: userId,
            current_count: quota
        };
        callApi("POST", "emergent_update.php", obj)
            .then((response) => response.data)
            .then((res) => {
                if (res.statusCode == 200) {
                    ToastsStore.success(res.message);
                    this.getFiles();
                } else {
                    ToastsStore.success(res.message);
                }
            });
    };

    render() {
        const user: any = sessionStorage.getItem("user");
        const roleType = user ? JSON.parse(user)["roleType"] : "";
        const role_label = user ? JSON.parse(user)["role_label"] : "";
        const emergent_quota: any = sessionStorage.getItem("emergent_quota");
        const columns = [
            {
                header: "S.No",
                key: "id",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>{file.index}</td>
                ),
            },
            {
                header: "File No",
                key: "file_id",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.file_id}
                    </td>
                ),
            },
            {
                header: "Bank Name",
                key: "bank_name",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.bank_name}{" "}
                        {file.product_name ? <>({file.product_name})</> : ""}
                    </td>
                ),
            },
            {
                header: "Bank Branch Name",
                key: "bank_branch",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.bank_branch}
                    </td>
                ),
            },
            {
                header: "Law Hands Branch",
                key: "lh_branch",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.lh_branch}
                    </td>
                ),
            },
            {
                header: "Property Owner Name",
                key: "owner_name",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.owner_name}
                    </td>
                ),
            },
            {
                header: "Process Officer",
                key: "po_name",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.po_name}
                    </td>
                ),
            },
            {
                header: "Created Date",
                key: "login_at",
                td: (file) => (
                    <td onClick={() => this.goToFileDashboard(file.id)}>
                        {file.login_at}
                    </td>
                ),
            },
        ];
        const data = this.state.files;
        const emergentFile = this.state.rectificationemergentFile;
        console.log("__________", data)
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        const downloadExcelProps = {
            type: 'filtered',
            title: `Subreport Report - ${formattedDate}`,
            showLabel: true
        }
        const { selectVal } = this.state;

        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="file-status-header">Rectification Queue</h5>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.isFileRefresh && (
                                                        <>
                                                            <ReactFlexyTable
                                                                data={data}
                                                                columns={columns}
                                                                sortable
                                                                globalSearch
                                                                // showExcelButton
                                                                className="Flexy-table"
                                                            // downloadExcelProps={downloadExcelProps}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                <div className="card-body">
                                                    {this.state.isFileRefresh && (
                                                        <>
                                                            <h5 className="file-status-header">Emergent Files</h5>
                                                            <ReactFlexyTable
                                                                data={emergentFile}
                                                                columns={columns}
                                                                sortable
                                                                globalSearch
                                                                // showExcelButton
                                                                className="Flexy-table"
                                                            // downloadExcelProps={downloadExcelProps}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade hide in"
                    id="out_reason"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog ">
                        <div
                            className="modal-content"
                            style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
                        >
                            <div
                                className="modal-body"
                                style={{ padding: "30px 23px 29px 24px" }}
                            >
                                <h3 style={{ margin: "auto", fontSize: "17px" }}>
                                    Please Select The Reason For To Close The File.No:
                                    {sessionStorage.getItem("currentfileId")}
                                </h3>
                            </div>
                            <div
                                className="form-group"
                                style={{ width: "74%", margin: "auto" }}
                            >
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: "8px" }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>
                                </select>
                                {selectVal ? (
                                    <p
                                        style={{
                                            color: "red",
                                            textAlign: "center",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Please Select Your Reason{" "}
                                    </p>
                                ) : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: "27px" }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: "76px", fontSize: "17px" }}
                                >
                                    {" "}
                                    {this.state.isLoading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                        "OK"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{
                                        width: "107px",
                                        fontSize: "14px",
                                        border: "1px solid black",
                                        height: "43px",
                                    }}
                                >
                                    {" "}
                                    Back To File
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    className="modal hide fade in"
                    id="another-reason"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog ">
                        <div
                            className="modal-content"
                            style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
                        >
                            <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                                Please Type a Reason To Close The File.No:{" "}
                                {sessionStorage.getItem("currentfileId")}{" "}
                            </h5>
                            <div
                                className="modal-body"
                                style={{ padding: "30px 23px 29px 24px" }}
                            >
                                {/* <div className="col-md-5"> */}
                                <div
                                    className="form-group"
                                    style={{ width: "98%", margin: "auto" }}
                                >
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason"
                                        onChange={this.onchange}
                                    />
                                    {selectVal ? (
                                        <p
                                            style={{
                                                color: "red",
                                                textAlign: "center",
                                                fontSize: "16px",
                                            }}
                                        >
                                            Please Select Your Reason{" "}
                                        </p>
                                    ) : null}
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: "76px", fontSize: "17px" }}
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                        "OK"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{
                                        width: "76px",
                                        fontSize: "17px",
                                        border: "1px solid black",
                                    }}
                                >
                                    {" "}
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default RectificationQueue;