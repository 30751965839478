import * as React from "react";
import ManageInprogressFile from "../file/ManageInprogessFile";
interface AllProps {
    history: any;
}
class ManagedashInprogressFile extends React.Component<AllProps> {
    render() {
        return (
            <ManageInprogressFile history={this.props.history} />
        );
    }
}
export default ManagedashInprogressFile;