import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import MeasurementItem from "./MeasurementItem";
export interface Survey {
  survey_no: string;
  old_survey_no: string;
  revenue_record: string;
  extent: string;
  plot_no: string;
  layout_name: string;
  sur_id?: string;
  collapseid?: boolean;
  boundary_details: [];
  unit: string;
  survey_type: string;
  sur_extent_unit: string;
  ts_no: string;
  old_ts_no: string;
  old_ts_s_no: string;
  ts_ward: string;
  ts_block: string;
  ts_street_name: string;
  natham_survey_no: string;
  ts_survey_type: string;
  old_natham_survey_no: string;
  ts_old_survey_no: boolean;
  ts_old_ts_no: boolean;
}
interface AllProps {
  stateToProps: any;
  surveyDetail: any;
  extentUnits: any;
  required: any;
}
const surveyTypeList = [
  { value: "ts_no", label: "TS No" },
  { value: "survey_no", label: "Survey No" },
  { value: "natham_survey_no", label: "Natham Survey No" },
];

const ts_survery_no_type = [
  { value: "ts_old_survey_no", label: "Old Survey No" },
  { value: "ts_old_ts_no", label: "Old TS No" },
];

export default class SurveyItem extends Component<AllProps> {
  state = {
    survey_details: this.props.surveyDetail,
    extentUnits: this.props.extentUnits,
    required: this.props.required,
    delete_items_survey: [],
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.surveyDetail && nextProps.surveyDetail.length) {
      this.setState({ survey_details: nextProps.surveyDetail });
    } else if (
      !this.state.survey_details ||
      !this.state.survey_details.length
    ) {
      this.setState({ survey_details: [] });
      this.addItem();
    }
  }
  componentDidMount() {
    if (this.props.surveyDetail && this.props.surveyDetail.length) {
      this.setState({ survey_details: this.props.surveyDetail });
    } else if (
      !this.state.survey_details ||
      !this.state.survey_details.length
    ) {
      this.setState({ survey_details: [] });
      this.addItem();
    }
  }
  addItem = (): void => {
    const survey_details: Survey[] = this.state.survey_details
      ? this.state.survey_details
      : [];
    survey_details.push({
      survey_no: "",
      revenue_record: "",
      extent: "",
      old_ts_s_no: "",
      plot_no: "",
      layout_name: "",
      boundary_details: [],
      unit: "",
      survey_type: "",
      sur_extent_unit: "",
      ts_no: "",
      ts_ward: "",
      ts_survey_type: "",
      ts_block: "",
      ts_street_name: "",
      old_survey_no: "",
      ts_old_survey_no: false,
      ts_old_ts_no: false,
      old_ts_no: "",
      natham_survey_no: "",
      old_natham_survey_no: "",
    });
    this.setState({ survey_details });
  };
  stateToProps = (event, index) => {
    const survey_details: any = this.state.survey_details;
    survey_details[index][event.name] = event.value;
    this.setState({ survey_details });
  };
  removeItem = (modal: Survey, index: number): void => {
    const survey_details: Survey[] = this.state.survey_details;
    survey_details.splice(index, 1);
    const delete_items_survey: any = this.state.delete_items_survey;
    delete_items_survey.push(modal.sur_id);
    this.setState({ survey_details, delete_items_survey });
    this.props.stateToProps({
      name: "delete_items_survey",
      value: this.state.delete_items_survey,
    });
  };
  collapsearray = (modal: Survey, index: number): void => {
    const survey_details: Survey[] = this.state.survey_details;
    if (survey_details[index] !== undefined) {
      if (survey_details[index].collapseid) {
        survey_details[index].collapseid = false;
      } else {
        survey_details[index].collapseid = true;
      }
      this.setState({ survey_details });
    }
  };
  handleChange = (e: any, index: number, propName?, fieldType?) => {

    const survey_details: Survey[] = this.state.survey_details;
    survey_details.forEach((itm: any, i) => {
      let fieldName = fieldType === "singleselect" ? propName : e.target.name;
      let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
      console.log(`344234${propName}----${fieldValue}`);

      if (i === index) {
        if (fieldName == "ts_survey_type") {
          if (fieldValue == "ts_old_survey_no") {
            itm.ts_old_survey_no = true;
            itm.ts_old_ts_no = false;
            itm.old_ts_no = "";
          }
          else if (fieldValue == "ts_old_ts_no") {
            itm.ts_old_ts_no = true;
            itm.ts_old_survey_no = false;
            itm.old_ts_s_no = "";
          } else {
            itm.old_ts_no = "";
            itm.old_ts_s_no = "";
            itm.ts_old_ts_no = false;
            itm.ts_old_survey_no = false;
            itm[fieldName] = fieldValue;
          }
        }
        else {
          itm[fieldName] = fieldValue;
        }
      }

    });
    this.setState({ survey_details });
    this.props.stateToProps({
      name: "survey_details",
      value: this.state.survey_details,
    });
    console.log("survey_details", survey_details);

  };
  render() {
    return this.state.survey_details && this.state.survey_details.length
      ? this.state.survey_details.map((surv: Survey, index) => {
        return (
          // <div className="sec_measurement">

          <div className="row" key={index}>
            <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
              <Card style={{ borderRadius: "10px" }}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => this.collapsearray(surv, index)}
                  className="card-border-c-green"
                >
                  <div className="row">
                    <div className="col-md-8" style={{ paddingTop: "6px" }}>
                      <h2 style={{ fontSize: "26px !important" }}>
                        {surv.collapseid ? (
                          <i className="fa fa-angle-down rotate-icon"></i>
                        ) : (
                          <i className="fa fa-angle-up rotate-icon"></i>
                        )}
                        &nbsp;&nbsp; Survey - {index + 1}
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <div style={{
                        float: "right"
                      }}>
                        <button
                          type="button"
                          style={{ marginTop: "4px" }}
                          className="btn btn-icon btn-rounded btn-primary"
                          onClick={this.addItem}
                        >
                          <i className="feather icon-plus" />
                        </button>
                        <button
                          type="button"
                          style={{ marginTop: "4px" }}
                          className="btn btn-icon btn-rounded btn-danger"
                          disabled={this.state.survey_details.length === 1}
                          onClick={() => this.removeItem(surv, index)}
                        >
                          <i className="feather icon-minus" />
                        </button>
                      </div>

                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="row" style={{ marginTop: "-9px" }}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Survey Type<span style={{ color: "#FF0000" }}>*</span><span className="inputEgMsg">Eg. T.S.No / S.No / Natham S.No</span></label>
                          <Select
                            options={surveyTypeList}
                            name="survey_type"
                            value={surveyTypeList.find(
                              (o: any) => o.value === surv.survey_type
                            )}
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                index,
                                "survey_type",
                                "singleselect"
                              )
                            }
                          />
                        </div>
                      </div>
                      {surv.survey_type === "survey_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Survey No.</label>
                            <input
                              className="form-control"
                              name="old_survey_no"
                              placeholder="Eg. 431/9"
                              type="text"
                              value={surv.old_survey_no}
                              onChange={(e) => this.handleChange(e, index)}
                              required
                            />
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "survey_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Survey No.<span style={{ color: "#FF0000" }}>*</span></label>
                            <input
                              className={this.state.required && surv.survey_no === '' ? 'form-control is-invalid' : 'form-control'}
                              name="survey_no"
                              placeholder="Eg. 541/10"
                              type="text"
                              value={surv.survey_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                            <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "natham_survey_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Natham Survey No.</label>
                            <input
                              className="form-control"
                              name="old_natham_survey_no"
                              type="text"
                              value={surv.old_natham_survey_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "natham_survey_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Natham Survey No.<span style={{ color: "#FF0000" }}>*</span></label>
                            <input
                              className={this.state.required && surv.natham_survey_no === '' ? 'form-control is-invalid' : 'form-control'}
                              name="natham_survey_no"
                              type="text"
                              value={surv.natham_survey_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                            <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Choose Old Survery No Type</label>
                            <Select
                              options={ts_survery_no_type}
                              name="ts_survey_type"
                              value={ts_survery_no_type.find(
                                (o: any) =>
                                  (surv.ts_old_ts_no === true && o.value === "ts_old_ts_no") ||
                                  (surv.ts_old_survey_no === true && o.value === "ts_old_survey_no")
                              )}
                              onChange={(e) =>
                                this.handleChange(e, index, "ts_survey_type", "singleselect")
                              }
                            />
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && surv.ts_old_survey_no == true && (
                        < div className="col-md-6">
                          <div className="form-group">
                            <label>Old Survey No.</label>
                            <input
                              className="form-control"
                              name="old_ts_s_no"
                              type="text"
                              value={surv.old_ts_s_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && surv.ts_old_ts_no == true && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old TS No.</label>
                            <input
                              className="form-control"
                              name="old_ts_no"
                              type="text"
                              value={surv.old_ts_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New TS No.<span style={{ color: "#FF0000" }}>*</span></label>
                            <input
                              className={this.state.required && surv.ts_no === '' ? 'form-control is-invalid' : 'form-control'}
                              name="ts_no"
                              type="text"
                              value={surv.ts_no}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                            <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Ward </label>
                            <input
                              className={this.state.required && surv.ts_ward === '' ? 'form-control is-invalid' : 'form-control'}
                              name="ts_ward"
                              type="text"
                              value={surv.ts_ward}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                            {/* <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div> */}
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Block</label>
                            <input
                              className={this.state.required && surv.ts_block === '' ? 'form-control is-invalid' : 'form-control'}
                              name="ts_block"
                              type="text"
                              value={surv.ts_block}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                            <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                          </div>
                        </div>
                      )}
                      {surv.survey_type === "ts_no" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Street Name</label>
                            <input
                              className="form-control"
                              name="ts_street_name"
                              type="text"
                              value={surv.ts_street_name}
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>As per revenue record <span className="inputEgMsg">Eg. S.No-26/1 / T.S.No-26, Ward-A, Block-5 / Natham S.No-266/2</span></label>
                          <input
                            className="form-control"
                            id="revenue_record"
                            placeholder="Eg. S.No-26/1 / T.S.No-26, Ward-A, Block-5 / Natham S.No-266/2"
                            name="revenue_record"
                            type="text"
                            value={surv.revenue_record}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Extent<span style={{ color: "#FF0000" }}>*</span></label>
                          <input
                            className={this.state.required && surv.extent === '' ? 'form-control is-invalid' : 'form-control'}
                            id="extent"
                            name="extent"
                            type="text"
                            value={surv.extent}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                          <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Unit<span style={{ color: "#FF0000" }}>*</span></label>
                          {this.state.extentUnits &&
                            this.state.extentUnits.length > 0 &&
                            this.state.extentUnits.map((opt: any) => {
                              opt.label = opt.name;
                              opt.value = opt.id;
                            }) && (
                              <Select
                                className={this.state.required && surv.sur_extent_unit === '' ? 'form-select is-invalid' : 'form-select'}
                                options={this.state.extentUnits}
                                name="sur_extent_unit"
                                value={this.state.extentUnits.find(
                                  (o: any) => o.value === surv.sur_extent_unit
                                )}
                                onChange={(e) =>
                                  this.handleChange(
                                    e,
                                    index,
                                    "sur_extent_unit",
                                    "singleselect"
                                  )
                                }
                              />
                            )}
                          <div className="invalid-feedback">This is a required field, kindly add Floor Number here.</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Plot No as per Document</label>
                          <input
                            className="form-control"
                            id="plot_no"
                            placeholder="Eg. Site No - 43 / Plot No - 123"
                            name="plot_no"
                            type="text"
                            value={surv.plot_no}
                            onChange={(e) => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Layout Name</label>
                          <input
                            className="form-control"
                            id="layout_name"
                            name="layout_name"
                            placeholder="Eg. Yuvaraj nagar extension"
                            type="text"
                            value={surv.layout_name}
                            onChange={(e) => this.handleChange(e, index)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <MeasurementItem
                        stateToProps={(e) => this.stateToProps(e, index)}
                        measurementDetail={surv.boundary_details}
                        extentUnits={this.state.extentUnits}
                        required={this.state.required}
                      />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div >
        );
      })
      : "";
  }
}
