import * as React from "react";
import { callApi } from "../../utils/api";
import { ADMIN_ROLE } from "../../utils/role";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import Select from 'react-select';
import moment from "moment";
declare var $: any;
export interface Bank {
    id: number;
    name: string;
}
interface AllProps {
    history: any;
}
class Bank_Productmanagement extends React.Component<AllProps> {
    state = {
        bankList: [],
        products: [],
        // branches: [],
        bankproducts: [],
        productToBeDeleted: "",
        isFileRefresh: false,
        selectedproduct: {
            id: 0,
            bank_id: 0,
            // branch_id: 0,
            product_id: 0,
            login_amount: "",
            complete_amount: "",
            vetting_amount: "",
        },
        outreason: "",
        selectVal: false,
        isLoading: false,
        // filteredBranches: []
    };
    componentDidMount() {
        this.listBank();
        this.getBankList();
        this.listproduct();
        this.getOutTime();
        this.ouTime();
        // this.listBranch();
    }

    getBankList = () => {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(response => {
                const bankList = response.data;
                this.setState({ bankList, isBankListLoaded: true });
            })
            .catch(err => {
                console.log(err);
            });
    };
    listproduct = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "product_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                this.setState({ products: files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };
    // listBranch = () => {
    //     callApi("GET", "bank_branch_get.php")
    //         .then(res => res.data)
    //         .then(response => {
    //             this.setState({ branches: response.data, filteredBranches: [] }, () => {
    //                 if (this.state.selectedproduct.bank_id) {
    //                     this.filterBranches(this.state.selectedproduct.bank_id);
    //                 }
    //             });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    //     console.log("surya---->", this.state);
    // };
    // filterBranches = (bankid) => {
    //     const filteredBranches = this.state.branches.filter(
    //         (branch: any) => branch.bank_id === bankid
    //     );
    //     const state = this.state;
    //     state.filteredBranches = filteredBranches;
    //     this.setState({ ...state });
    //     console.log("filterBranches", this.state);
    // };
    listBank = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "bank_product_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ bankproducts: files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };
    addBank = () => {
        this.setState({ selectedproduct: Object.assign({}) });
    };
    editBank = (bank: any) => {
        // this.filterBranches(bank.bank_id);
        this.setState({ selectedproduct: bank });
    };
    saveBank = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        if (this.state.selectedproduct.id) {
            this.state.selectedproduct.id = Number(this.state.selectedproduct.id);
            let req = {
                userid: userId,
                ...this.state.selectedproduct
            }
            callApi("POST", "bank_product_update.php", req)
                .then(res => res.data)
                .then(response => {
                    if (response) {
                        this.setState({ selectedproduct: {} })
                        ToastsStore.success("Updated Successfully");
                        this.listBank();
                    } else {
                        this.setState({ selectedproduct: {} })
                        ToastsStore.error("Not Updated");
                        this.listBank();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            let req = {
                userid: userId,
                ...this.state.selectedproduct
            }
            callApi("POST", "bank_product_insert.php", req)
                .then(res => res.data)
                .then(response => {
                    if (response) {
                        this.setState({ selectedproduct: {} });
                        ToastsStore.success("Saved Successfully");
                        this.listBank();
                    } else {
                        this.setState({ selectedproduct: {} });
                        ToastsStore.error("Not Inserted");
                        this.listBank();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    confirmDeleteBank = (modal: Bank) => {
        this.setState({ productToBeDeleted: modal.id });
    };

    deleteBank = () => {
        callApi("POST", "bank_product_delete.php", { id: this.state.productToBeDeleted })
            .then(res => res.data)
            .then(response => {
                this.listBank();
            })
            .catch(err => {
                console.log(err);
            });
    };
    handleChange = (e: any) => {
        const selectedproduct: any = this.state.selectedproduct;
        selectedproduct[e.target.name] = e.target.value;
        this.setState({ selectedproduct });
    };
    handleChangeselect = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        // if (fieldName === "bank_id") {
        //     this.listBranch();
        // }
        if (fieldName === "bank_id" || fieldName === "product_id") {
            const selectedproduct: any = this.state.selectedproduct;
            selectedproduct[fieldName] = fieldValue
            this.setState({ selectedproduct });
        }
    };
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };

    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    render() {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const columns = [
            {
                header: 'ID',
                key: 'index',
            },
            {
                header: 'Bank Name',
                key: 'bank_name',
            },
            {
                header: 'Product Name',
                key: 'product_name',
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <a
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#product-form"
                                onClick={() =>
                                    this.editBank(file)
                                }
                            >
                                Edit
                            </a>
                            {role_id === ADMIN_ROLE && (
                                <a
                                    className="dropdown-item"
                                    style={{ float: "right" }}
                                    data-toggle="modal"
                                    data-target="#delete-bank"
                                    onClick={() =>
                                        this.confirmDeleteBank(file)
                                    }
                                >
                                    Delete
                                </a>
                            )}
                        </div>
                    </div>
                }
            }
        ];
        const data = this.state.bankproducts;
        // const filteredBranchesoptions = [
        //     { value: "", label: "All" },
        //     ...this.state.filteredBranches,
        // ];
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Bank + Product Manage</h5>
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ float: "right" }}
                                                        onClick={() => this.props.history.goBack()}
                                                    >
                                                        Back
                                                    </button>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dt-responsive table-responsive">
                                                        <a
                                                            className="btn btn-primary m-b-20"
                                                            data-toggle="modal"
                                                            data-target="#product-form-insert"
                                                            onClick={this.addBank}
                                                        >
                                                            + Add Product
                                                        </a>

                                                        {this.state.isFileRefresh && data.length > 0 &&
                                                            <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="product-form"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title">Bank Form</h5> */}
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="template-select">
                                                    <label className="col-form-label">Bank:</label>{" "}
                                                    {this.state.bankList && this.state.bankList.length > 0 && this.state.bankList.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={this.state.bankList} name="bank_id"
                                                            value={this.state.bankList.find((o: any) => o.value == this.state.selectedproduct.bank_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'bank_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="template-select">
                                                    <label className="col-form-label">Product:</label>{" "}
                                                    {this.state.products && this.state.products.length > 0 && this.state.products.map((opt: any) => {
                                                        opt.label = opt.product; opt.value = opt.id
                                                    }) &&
                                                        <Select options={this.state.products} name="product_id"
                                                            value={this.state.products.find((o: any) => o.value == this.state.selectedproduct.product_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'product_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div>


                                            {/* <div className="col-md-6">
                                                <div className="template-select" style={{ zIndex: "auto" }}>
                                                    <label className="col-form-label">Branch:</label>{" "}
                                                    {this.state.branches && this.state.branches.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={filteredBranchesoptions} name="branch_id"
                                                            value={this.state.filteredBranches.find((o: any) => o.value === this.state.selectedproduct.branch_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'branch_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.saveBank}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="product-form-insert"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title">Bank Form</h5> */}
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="template-select">
                                                    <label className="col-form-label">Bank:</label>{" "}
                                                    {this.state.bankList && this.state.bankList.length > 0 && this.state.bankList.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={this.state.bankList} name="bank_id"
                                                            value={this.state.bankList.find((o: any) => o.value === this.state.selectedproduct.bank_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'bank_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="template-select">
                                                    <label className="col-form-label">Product:</label>{" "}
                                                    {this.state.products && this.state.products.map((opt: any) => {
                                                        opt.label = opt.product; opt.value = opt.id
                                                    }) &&
                                                        <Select options={this.state.products} name="product_id"
                                                            value={this.state.products.find((o: any) => o.value === this.state.selectedproduct.product_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'product_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <div className="template-select" style={{ zIndex: "auto" }}>
                                                    <label className="col-form-label">Branch:</label>{" "}
                                                    {this.state.branches && this.state.branches.map((opt: any) => {
                                                        opt.label = opt.name; opt.value = opt.id
                                                    }) &&
                                                        <Select options={filteredBranchesoptions} name="branch_id"
                                                            value={this.state.filteredBranches.find((o: any) => o.value === this.state.selectedproduct.branch_id)}
                                                            onChange={(e) => this.handleChangeselect(e, 'branch_id', 'singleselect')}
                                                        />
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.saveBank}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="delete-bank"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Bank & Product</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6>Are you sure you want to delete this Bank & Product?</h6>
                                <div className="form-group">
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.deleteBank}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                >{this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Bank_Productmanagement;
