import * as React from "react";
import MaskedInput from "react-text-mask";
import { callApi } from "../../utils/api";
import {
  ADMIN_ROLE,
  EXECITUVE_ROLE,
  FRONTOFFICE_ROLE,
  MANAGER_ROLE,
} from "../../utils/role";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import Select from "react-select";
import "../dashboard/Dashboard.css";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";

declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}
class vettingcompletereport extends React.Component<AllProps> {
  state = {
    lhBranchShow: false,
    bankBranch: false,
    branchName: false,
    lawBranchName: false,
    showBank: false,
    bankName: false,
    outreason: "",
    selectVal: false,
    overview: [],
    employees: [],
    branchInfo: [],
    branches: [],
    fromdate: "",
    eodDate: "",
    eodFromDate: "",
    eodToDate: "",
    todate: "",
    branch: "",
    eodReportTypes: [
      {
        id: 1,
        label: "Branch",
        value: "Branch",
      },
      {
        id: 2,
        label: "Employee",
        value: "Employee",
      },
    ],
    eodData: [],
    branchEodData: [],
    eodReportType: "Branch",
    eodEmployeeList: [],
    eodEmployee: "",
    lawhandsBranches: [],
    bank_private_name: "",
    banks: [],
    filteredBranches: [],
    lawHandsBranch: "",
    isDateSearchClicked: false,
    products: [],
    filteredProduct: [],
    product_id: "",
    isLoading: false
  };
  tableDetails: any;
  componentDidMount() {
    // this.getOverview("");
    // this.getEmployees();
    // this.getBranchInfo();
    // this.listBranches();
    // const script = document.createElement("script");
    // script.src = "http://app.elawhands.com/lawapp/assets/form-picker-custom.js";
    // script.async = true;
    // document.body.appendChild(script);
    $(".datepicker").on("change", this.handleChange);
    this.getEodTableData();
    this.getBranchEodData();

    this.getOutTime();
    this.ouTime();
    this.listBankproduct();
  }
  listBankproduct = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "bank_product_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ products: files, filteredProduct: files });
      })
      .catch(err => {
        console.log(err);
      });
  };
  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");
    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");

    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          console.log("76", response);
          this.setState({ isLoading: false });
          // sessionStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          const getfileId: any = sessionStorage.getItem("currentfileId");
          if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
            this.fileout(fileid1);
          } else {
            sessionStorage.removeItem('currentfileId');
          }
        }).catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('currentfileId');
        // sessionStorage.removeItem("takenfile_id");
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getBanks() {
    callApi("GET", "bank_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ banks: result.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then((res) => res.data)
      .then((response) => {
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        let lawhandsBranches = response.data;
        if (
          lawhandsBranches &&
          lawhandsBranches.length > 0 &&
          userDetails.role_id !== "1" &&
          userDetails.my_branchs
        ) {
          const myBranchList = userDetails.my_branchs.split(",");
          lawhandsBranches = lawhandsBranches.filter((value) =>
            myBranchList.includes(value.id)
          );
        }
        this.setState({ lawhandsBranches });
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  getBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ branches: result.data, filteredBranches: [] });
        if (this.state.bank_private_name) {
          this.filterBranches(this.state.bank_private_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterBranches = (bankid) => {
    const filteredBranches = this.state.branches.filter(
      (branch: any) => branch.bank_id === bankid
    );
    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
  };

  getEodTableData = () => {
    const {
      eodFromDate,
      eodToDate,
      lawHandsBranch,
      bank_private_name,
      branch,
      product_id
    } = this.state;

    let obj: any = {
      branch: lawHandsBranch,
      fromdate: eodFromDate,
      todate: eodToDate,
      bankname: bank_private_name,
      bankbranch: branch,
      product_id: product_id,
    };

    localStorage.setItem("report_filter", JSON.stringify(obj));

    const user: any = sessionStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    obj = {
      ...obj,
      roleId: userDetails ? userDetails.role_id : null,
      userId: userDetails ? userDetails.userId : null,
    };
    callApi("POST", "vetting_report.php", obj)
      .then((res) => res.data)
      .then((result) => {
        this.setState({ eodData: result.data, isDateSearchClicked: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getBranchEodData = () => {
    const user: any = sessionStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    callApi("POST", "main_report_branch.php", {
      branch: userDetails.my_branchs,
      role_id: userDetails.role_id
    })
      .then((res) => res.data)
      .then((result) => {
        console.log("result", result);
        this.setState({ branchEodData: result.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange1 = (e: any, propName?, fieldType?) => {



    const bank_private_name = this.state.bank_private_name;
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    if (fieldName === "bank_private_name") {
      this.filterBranches(bank_private_name);
      this.filterProduct(fieldValue);
    }
    this.setState({ bank_private_name });

    if (fieldName === "lawHandsBranch") {
      const lawHandsBranch = this.state.lawHandsBranch;
      const branch = fieldValue;
      this.setState({ lawHandsBranch });
    }
    this.setState({ [fieldName]: fieldValue });
    //  lawHandsBranch[fieldName] = fieldValue;
    if (fieldName === "product_id") {
      let newProductId = e.id;
      this.setState({ [fieldName]: newProductId }, () => {
        console.log("this.state.product_id", this.state.product_id);
      });
    }
  };
  filterProduct = (bankid: any) => {
    console.log("product id--", bankid,);
    if (bankid) {
      const filteredProduct = this.state.products.filter(
        (branch: any) => branch.bank_id === bankid
      );
      const state = this.state;
      state.filteredProduct = filteredProduct;
      this.setState({ ...state });
    } else {
      this.setState({ product_id: "" })
    }
  };
  handleChange = (e, stateName?, type?) => {
    if (e.target.name === "eodToDate") {
      this.setState({ todate: e.target.value });
    }
    if (e.target.name === "eodfromDate") {
      this.setState({
        fromdate: e.target.value,
      });
    }
    // if (e.target.name === "bank_private_name") {
    //   this.filterBranches(e.target.value);
    // }
    if (stateName && type === "dropdown") {
      this.setState({
        [stateName]: e.value,
      });
    } else this.setState({ [e.target.name]: e.target.value });
    if (["eodFromDate", "eodToDate"].includes(e.target.name)) {
      this.setState({ isDateSearchClicked: false });
    }
  };
  routeSubReport_vetting = (detail, type) => {
    const {
      eodFromDate,
      eodToDate,
      lawHandsBranch,
      bank_private_name,
      branch,
    } = this.state;
    const obj = {
      branch: lawHandsBranch,
      fromdate: eodFromDate,
      todate: eodToDate,
      bankname: bank_private_name,
      bankbranch: branch,
      isRaised: false,
    };
    if (detail.details === "Raised Query") {
      obj.isRaised = true;
    }
    this.props.history.push(`/sub-report-vetting/${type}/${detail.status}`, {
      filterData: { ...obj },
    });
  };
  handleDateChange = (date, dateStr, type) => {
    // console.log(date, dateStr);
    this.setState({ [type]: dateStr });
  };
  lawBranchName = (type: any) => {
    if (type == 2) {
      console.log("431", type)
      this.setState({ lhBranchShow: true })
      this.getLawHandsBranches();

    } else {
      console.log("436", type)
    }
  }
  bankName = (type: any) => {

    if (type == 2) {
      console.log("431", type)
      this.setState({ showBank: true })
      this.getBanks();
    } else {
      console.log("445", type)
    }
  }
  branchName = (type: any) => {
    if (type == 2) {
      console.log("450", type)
      this.setState({ bankBranch: true })
      this.getBankBranches();
    } else {
      console.log("454", type)
    }
  }
  disabledDate = (current) => {
    // Disable dates after today and dates before the last 3 months
    return current && (current > moment().endOf('day') || current < moment().subtract(3, 'months').startOf('day'));
  };
  render() {
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const {
      eodData,
      eodFromDate,
      eodToDate,
      isDateSearchClicked,
      branchEodData,
      selectVal,
      lawBranchName, bankBranch
      , branchName, bankName, showBank, lhBranchShow
    } = this.state;
    const userDetails = user ? JSON.parse(user) : "";
    const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
    // const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2" ;
    const lawhandsBranchesoptions = [
      { value: "", label: "All" },
      ...this.state.lawhandsBranches
    ];
    const banksoptions = [
      { value: "", label: "All" },
      ...this.state.banks
    ];
    const bankbranchoptions = [
      { value: "", label: "All" },
      ...this.state.filteredBranches
    ];
    const filteredProduct = [
      { value: "", label: "" },
      ...this.state.filteredProduct
    ];
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    {/* EOD BRANCH TABLE */}
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="mt-1 mb-1">Vetting Report</h5>
                        </div>

                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-xl-2 col-md-2">
                              <h6>From</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                disabledDate={this.disabledDate}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodFromDate"
                                  )
                                }
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-2 col-md-2">
                              <h6>To</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                disabledDate={this.disabledDate}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodToDate"
                                  )
                                }
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-2 col-md-2">
                              <h6>LH Branch</h6>
                              {lhBranchShow ? <>{this.state.lawhandsBranches &&
                                this.state.lawhandsBranches.length > 0 &&
                                this.state.lawhandsBranches.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select
                                    options={lawhandsBranchesoptions}
                                    name="lawHandsBranch"
                                    value={this.state.lawhandsBranches.find(
                                      (o: any) =>
                                        o.value === this.state.lawHandsBranch
                                    )}

                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "lawHandsBranch",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )}</> : <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={lawBranchName ? () => this.lawBranchName(1) : () => this.lawBranchName(2)}
                                  onChange={this.handleChange}
                                >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? lawhandsBranchesoptions.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>

                            <div className="col-xl-2 col-md-2" >
                              <h6>Bank Name</h6>
                              {showBank ? <> {this.state.banks &&
                                this.state.banks.length > 0 &&
                                this.state.banks.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select

                                    options={banksoptions}
                                    name="bank_private_name"
                                    value={this.state.banks.find(
                                      (o: any) =>
                                        o.value === this.state.bank_private_name
                                    )}
                                    onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "bank_private_name",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )}</> : <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                  onChange={this.handleChange}
                                >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? lawhandsBranchesoptions.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>

                            <div className="col-xl-2 col-md-2">
                              {/* <div className="form-group"> */}
                              <h6>Product</h6>
                              {this.state.products && this.state.products.map((opt: any) => {
                                opt.label = opt.product_name; opt.value = opt.id
                              }) &&
                                <Select options={filteredProduct} name="product_id"
                                  value={this.state.filteredProduct.find((o: any) => o.product_id === this.state.product_id ? this.state.product_id : "")}
                                  onChange={(e) => this.handleChange1(e, 'product_id', 'singleselect')}
                                />
                              }
                              {/* </div> */}
                            </div>

                            <div className="col-xl-2 col-md-2">
                              <h6>Bank Branch</h6>
                              {bankBranch ? <>{this.state.branches &&
                                this.state.branches.length > 0 &&
                                this.state.branches.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select
                                    options={bankbranchoptions}
                                    name="branch"
                                    value={this.state.filteredBranches.find(
                                      (o: any) => o.value === this.state.branch
                                    )}
                                    onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "branch",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )
                              } </> : <select
                                className="custom-select"
                                id="lawhands_branch"
                                name="lawhands_branch"
                                onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                onChange={this.handleChange}
                              >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? lawhandsBranchesoptions.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>
                            {/* <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ alignSelf: "end" }}
                              onClick={() => this.getEodTableData()}
                            >
                              Search
                            </button> */}
                          </div>
                          <div className="row">
                            <div className="col-xl-12 col-md-12 p-3">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ float: 'right' }}
                                onClick={() => this.getEodTableData()}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                          <div className="dt-responsive table-responsive">
                            <table
                              id="eodtable"
                              className="table table-striped table-bordered nowrap"
                            >
                              <thead className="text-center">
                                <tr style={{ padding: "0.50rem" }}>
                                  <th>Details</th>
                                  <th>FTD (For the Day)</th>
                                  <th>MTD (Month to Date)</th>
                                  <th>YTD (Year to Date)</th>
                                  {isDateSearchClicked &&
                                    eodFromDate &&
                                    eodToDate && (
                                      <th>{eodFromDate + " - " + eodToDate}</th>
                                    )}
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {eodData && eodData.length > 0 ? (
                                  eodData.map(
                                    (listValue: any, listIndex: number) => {
                                      return (
                                        <tr
                                          key={listIndex + listValue.details}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <td
                                            style={{
                                              padding: "0.60rem",
                                              borderColor: "inherit",
                                              fontWeight: "bold",
                                              background: listValue.color,
                                            }}
                                          >
                                            {listValue.details}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport_vetting(
                                                listValue,
                                                "ftd"
                                              )
                                            }
                                          >
                                            {listValue.ftd}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport_vetting(
                                                listValue,
                                                "mtd"
                                              )
                                            }
                                          >
                                            {listValue.mtd}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport_vetting(
                                                listValue,
                                                "ytd"
                                              )
                                            }
                                          >
                                            {listValue.ytd}
                                          </td>
                                          {/* {isDateSearchClicked &&
                                            eodFromDate &&
                                            eodToDate &&
                                            listValue.btd && (
                                              <td>{listValue.btd}</td>
                                            )} */}
                                          {isDateSearchClicked &&
                                            eodFromDate &&
                                            eodToDate &&
                                            listValue.btd && (
                                              <td onClick={() => this.routeSubReport_vetting(listValue, "btd")}>
                                                {listValue.btd}
                                              </td>
                                            )}

                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr
                                    style={{
                                      width: "100%",
                                      background: "grey",
                                    }}
                                  >
                                    <td colSpan={4}>No Details Found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* THIS FOR OUT REASON MODAL */}
                    <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                      <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                          <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                            <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                          </div>
                          <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                            <select
                              className="custom-select"
                              name="outreason"
                              value={this.state.outreason}
                              onChange={(e) => this.onchangeReason(e)}
                              required
                              id="select1"
                              style={{ marginTop: '8px' }}
                            >
                              <option value="select">Select Your Reason</option>
                              <option value="Query Raised">Query Raised</option>
                              <option value=" Re-Query Raised"> Re-Query Raised</option>
                              <option value="Hold ">Hold </option>
                              <option value="Completed">Completed</option>
                              <option value="Other">Others</option>

                            </select>
                            {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                          </div>
                          <div className="modal-footer" style={{ marginTop: '27px' }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={this.state.isLoading}
                              onClick={this.outimeGet}
                              style={{ width: '76px', fontSize: '17px' }}
                            > {this.state.isLoading ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : "OK"}</button>
                            <button
                              type="button"
                              className="btn btn-outline"
                              onClick={this.BacktoFile}
                              style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                            > Back To File</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* OTHER REASON MODAL */}
                    <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                      <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                          <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                          <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                            {/* <div className="col-md-5"> */}
                            <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                              <textarea
                                className="form-control"
                                placeholder="Type Your Reason"
                                name="outreason" onChange={this.onchange} />
                              {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            {/* </div> */}

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.outimeGet()}
                              style={{ width: '76px', fontSize: '17px' }}
                              disabled={this.state.isLoading}
                            > {this.state.isLoading ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : "OK"}</button>
                            <button
                              type="button"
                              className="btn btn-outline"

                              onClick={() => this.previousPop()}
                              // onClick={this.disableModalHandler}
                              style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                            > Back</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default vettingcompletereport;
