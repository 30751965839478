import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import DataModel from './sopDataModel';
import { MissingLod } from './sopDataTemplate';

interface AllProps {
    data: MissingLod;
    handleLodChange: any;
}
export class MissingLOD extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d'

    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (e) => {
        const { data } = this.props;
        data[e.target.name] = e.target.value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleLodChange(data);
    }

    handleDataChange = (name, value, index) => {
        const { data } = this.props;
        console.log(data["sheets"][index], value)
        data["sheets"][index][name] = value;
        data.bgColor = 'lightgreen';
        this.props.handleLodChange(data);
    }

    render() {
        const { data } = this.props;
        const { isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section missing-lod">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="4" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>Missing Sheets In LOD</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                {data && data.sheets && Array.isArray(data.sheets) &&
                                    data.sheets.map((sheet, sheetIndex) => {
                                        return (
                                            <>
                                                <div className="row mb-10" key={sheetIndex + sheet.fileName}>
                                                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                        <h6><b>{sheet.fileNo}</b></h6>
                                                        <h6><b>{sheet.fileName}</b></h6>
                                                    </div>
                                                    <div className='row col-md-12 no-left-padding'>
                                                        <div className="col-md-4 mb-10">
                                                            <label className="label" style={{ padding: 0 }}>Total Sheets</label>
                                                            <input
                                                                className="form-control"
                                                                name="totalSheets"
                                                                type="text"
                                                                value={sheet.totalSheets}
                                                                onChange={(e) => this.handleDataChange(e.target.name, e.target.value, sheetIndex)}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="label" style={{ padding: 0 }}>Available Sheets</label>
                                                            <input
                                                                className="form-control"
                                                                name="availableSheets"
                                                                type="text"
                                                                value={sheet.availableSheets}
                                                                onChange={(e) => this.handleDataChange(e.target.name, e.target.value, sheetIndex)}
                                                            />
                                                        </div>
                                                        {sheet.totalSheets !== sheet.availableSheets &&
                                                            <div className="col-md-4">
                                                                <label className="label" style={{ padding: 0 }}>Missing Sheets</label>
                                                                <input
                                                                    className="form-control"
                                                                    name="missingSheets"
                                                                    type="text"
                                                                    value={sheet.missingSheets}
                                                                    onChange={(e) => this.handleDataChange(e.target.name, e.target.value, sheetIndex)}
                                                                />
                                                            </div>}
                                                        <div className="col-md-8 no-right-padding">
                                                            <label className="label" style={{ padding: 0 }}>Is Certificate Copy Attached?</label>
                                                        </div>
                                                        <div className="col-md-4 no-left-padding">
                                                            <div className="row" style={{ marginLeft: 0 }}>
                                                                <Form.Check
                                                                    inline
                                                                    label="Yes"
                                                                    name={"isCertificateAttached" + sheetIndex}
                                                                    type="radio"
                                                                    checked={sheet.isCertificateAttached}
                                                                    onChange={(e) => this.handleDataChange("isCertificateAttached", true, sheetIndex)}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="No"
                                                                    name={"isCertificateAttached" + sheetIndex}
                                                                    type="radio"
                                                                    checked={!sheet.isCertificateAttached}
                                                                    onChange={(e) => this.handleDataChange("isCertificateAttached", false, sheetIndex)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </>
                                        )
                                    })
                                }
                                <div className="row">
                                    <div className="col-md-6 mb-10">
                                        <label className="label" style={{ padding: 0 }}>Name Change In Document?</label>
                                        <div className="row" style={{ marginLeft: 0 }}>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="isNameChanged"
                                                type="radio"
                                                checked={data.isNameChanged}
                                                onChange={() => this.handleChange({ target: { name: "isNameChanged", value: true } })}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="isNameChanged"
                                                type="radio"
                                                checked={!data.isNameChanged}
                                                onChange={() => this.handleChange({ target: { name: "isNameChanged", value: false } })}
                                            />
                                        </div>
                                    </div>
                                    {data.isNameChanged && <div className="col-md-6">
                                        <label className="label" style={{ padding: 0 }}>Name Changed Document(s) List</label>
                                        <input
                                            className="form-control"
                                            name="docList"
                                            type="text"
                                            value={data.docList}
                                            onChange={this.handleChange}
                                        />
                                    </div>}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default MissingLOD
