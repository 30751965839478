import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class SearchBox extends Component {
  state = {
    searchLabel: "file_name",
    searchValue: ""
  };
  handleChange = (e: any) => {
    this.setState({ searchLabel: e.target.value });
  };
  handleChangeInput = (e: any) => {
    this.setState({ searchValue: e.target.value });
  };
  render() {
    return (
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <div className="main-search open">
                  <div className="input-group">
                    <input
                      type="text"
                      name="searchValue"
                      className="form-control"
                      placeholder="Search . . ."
                      onChange={e => this.handleChangeInput(e)}
                      style={{ width: "150px" }}
                    />
                    <a
                      className="dropdown-toggle input-group-append"
                      href="#"
                      data-toggle="dropdown"
                    />

                    <div className="dropdown-menu dropdown-menu-right notification">
                      <ul className="pro-body list-unstyled">
                        <li>
                          <label>
                          <input
                            type="radio"
                            name="name"
                            value="phone"
                            onChange={e => this.handleChange(e)}
                          />
                          Phone Number
                          </label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="name"
                            value="email"
                            onChange={e => this.handleChange(e)}
                          />
                          Email
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="name"
                            value="file_name"
                            onChange={e => this.handleChange(e)}
                            checked
                          />
                          File Name
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="name"
                            value="borrower_name"
                            onChange={e => this.handleChange(e)}
                          />
                          Borrower Name
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="name"
                            value="property_owner_name"
                            onChange={e => this.handleChange(e)}
                          />
                          Property Owner Name
                        </li>
                      </ul>
                    </div>
                    {this.state.searchLabel && this.state.searchValue && (
                      <Link
                        to={
                          "/search-results/" +
                          this.state.searchLabel +
                          "/" +
                          this.state.searchValue
                        }
                        className="input-group-append search-btn btn btn-primary"
                      >
                        <i className="feather icon-search input-group-text" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
