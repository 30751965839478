import React from "react";
import { callApi } from "../../utils/api";
import { Bank } from "./role_management";
import { toast } from "react-toastify";
import { ToastsStore } from "react-toasts";
declare var $: any;
interface AllProps {
    selectedBank: Bank;
    listBank: any;
}
class AddRole extends React.Component<AllProps> {
    state = {
        bankdetail: { id: 0, bank_id: 0, name: "", role: "0" },
        isValid: false,
        existMessage: "",
    };
    componentWillReceiveProps(nextState) {
        this.setState({ bankdetail: nextState.selectedBank });
    }
    handleChange = (e: any) => {
        const bankdetail: any = this.state.bankdetail;
        bankdetail[e.target.name] = e.target.value;
        this.setState({ ...bankdetail, bankdetail });
    };
    validInput = () => {
        this.setState({ isValid: false, bankdetail: { id: 0, bank_id: 0, name: "", role: "0" } });
        $("#bank-form").modal("hide");
    }

    saveBank = () => {
        const { bankdetail, existMessage } = this.state;
        if (
            bankdetail.name != "" &&
            bankdetail.role != "0" && existMessage != "This Role Already Exist"
        ) {
            console.log(this.state);
            if (bankdetail.id) {
                bankdetail.id = Number(bankdetail.id);
                callApi("POST", "role_update.php", bankdetail)
                    .then((res) => res.data)
                    .then((response) => {
                        this.props.listBank();
                        this.setState({ bankdetail: {} });
                        $("#bank-form").modal("hide");
                        ToastsStore.success(response.message);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                callApi("POST", "role_create.php", this.state.bankdetail)
                    .then((res) => res.data)
                    .then((response) => {
                        this.props.listBank();
                        $("#bank-form").modal("hide");
                        ToastsStore.success(response.message);
                        this.setState({ bankdetail: {} });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            this.setState({ isValid: true });
            console.log(this.state);

            // ToastsStore.error("Please fill the fields to proceed");
        }
    };
    handleBlur = () => {
        // Call your API here
        // For example:
        callApi("POST", "role_check.php", this.state.bankdetail)
            .then((res) => res.data)
            .then((response) => {
                if (response.message == "This Role Already Exist") {
                    this.setState({ existMessage: response.message, isValid: true });
                } else {
                    this.setState({ existMessage: "", isValid: false });
                }
                // this.props.listBank();
                // ToastsStore.success(response.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    render() {
        return (
            <div
                className="modal fade" data-backdrop="static" data-keyboard="false"
                id="bank-form"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Role Form</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={this.validInput}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label">Role Name:</label>{" "}
                                    <input
                                        onBlur={this.handleBlur}
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Role Name"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        value={
                                            this.state.bankdetail.name
                                                ? this.state.bankdetail.name
                                                : ""
                                        }
                                    />
                                    {this.state.isValid && !this.state.bankdetail.name && (
                                        <p style={{ color: "red", fontSize: "14px" }}>
                                            Required field.
                                        </p>
                                    )}
                                    {this.state.isValid && this.state.existMessage && (
                                        <p style={{ color: "red", fontSize: "14px" }}>
                                            {this.state.existMessage}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <select
                                        className="custom-select"
                                        name="role"
                                        onChange={(e) => this.handleChange(e)}
                                        value={
                                            this.state.bankdetail.role
                                                ? this.state.bankdetail.role
                                                : ""
                                        }
                                    >
                                        <option value="0"> Select One</option>
                                        <option value="1">Admin</option>
                                        <option value="2">Manager</option>
                                        <option value="3">Executive</option>
                                        <option value="4">Front Office</option>
                                        {/* <option value="10">Trainer</option> */}
                                    </select>
                                    {this.state.isValid && this.state.bankdetail.role == "0" && (
                                        <p style={{ color: "red", fontSize: "14px" }}>
                                            Required field.
                                        </p>
                                    )}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.validInput}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                // data-dismiss="modal"
                                onClick={this.saveBank}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddRole;
