import * as React from "react";
import Loader from "react-loader-spinner";
import { callApi } from "../../utils/api";
import { History } from "history";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";

class Login extends React.Component<{ history: History }> {
  state = {
    // userid:"",
    email: "",
    password: ""
  };
  login = () => {
    const loginInput = {
      email: this.state.email,
      password: this.state.password
    };
    callApi("POST", "login.php", loginInput)
      .then(res => res.data)
      .then(response => {
        this.setState({ isloading: false });
        // console.log("25", response.data.admin_manager.length);

        // let userTel = response.data.admin_manager.split(",");
        // sessionStorage.setItem("managerId", JSON.stringify(response.data.admin_manager.split(",")));
        sessionStorage.setItem("branch_manager", JSON.stringify(response.data.branch_manager.split(",")));
        sessionStorage.setItem("senior_relational", JSON.stringify(response.data.senior_manager.split(",")));
        sessionStorage.setItem("reginal_relational", JSON.stringify(response.data.reginol_manager.split(",")));
        sessionStorage.setItem("emergency_team", JSON.stringify(response.data.admin_manager.split(",")));
        sessionStorage.setItem("process_manager", JSON.stringify(response.data.admin_manager.split(",")));
        sessionStorage.setItem("vetting_officer", JSON.stringify(response.data.vetting_officer.split(",")));
        sessionStorage.setItem("scan_officer", JSON.stringify(response.data.login_officer.split(",")));
        sessionStorage.setItem("Query_officer", JSON.stringify(response.data.query_officer.split(",")));
        sessionStorage.setItem("takenfile_id", JSON.stringify(response.data.taken_file));
        sessionStorage.setItem("emergent_quota", response.data.emergent_quota);
        sessionStorage.setItem("user_grade", response.data.user_grade);
        sessionStorage.setItem("e_grade", response.data.e_grade);
        sessionStorage.setItem("v_grade", response.data.v_grade);
        localStorage.setItem("verification_to_addon", JSON.stringify(false));
        sessionStorage.setItem("from_deviation", JSON.stringify(false));
        // sessionStorage.setItem("frontofficeid", JSON.stringify(response.data.frount_officer.split(",")));
        sessionStorage.setItem("user", JSON.stringify(response.data));
        sessionStorage.setItem("user1", JSON.stringify(response.data.userId));
        if (response && response.status === 200) {

          if (response.data && response.data.userId) {
            this.setStatus(response.data.userId);
            console.log("43", response.data.userId);
            const event = new CustomEvent('userIdAdded', { detail: { userId: response.data.userId } });
            document.dispatchEvent(event);
          }
          const role_id = response.data.role_id;
          const roleType = response.data.roleType;
          const r_grade = response.data.r_grade;
          if (roleType == '16') {
            this.props.history.push("/deviation-dashboard");
          } else if (r_grade == "R" && role_id != '1') {
            this.props.history.push("/rectify-dashboard");
          } else {
            if (role_id == 3) {
              this.props.history.push("/executive-dashboard");
            } else {
              this.props.history.push("/dashboard");
            }
          }
        } else {
          ToastsStore.error("Incorrect Username or Password!");
        }
      })
      .catch(err => {
        console.log(err);
        ToastsStore.error("Incorrect Username or Password!");
        this.setState({ isloading: false });
      });
  };
  setStatus = (userid1) => {
    const user: any = sessionStorage.getItem("user");
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    let obj = {
      user_id: userid1,
      status: 1,
      file_no: "Still not taken any file",
      in_time: curentTime,
      reason: "Just now LoggedIn",
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: "Just now Logged in",
      bank_name: "Just now Logged in",
    }
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {
        console.log("53", response);
      })
  }
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
        {/* <Loader type="Puff" color="#00BFFF" height="100" width="100" />{" "} */}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="row align-items-center w-100 align-items-stretch bg-white">
          <div className="d-none d-lg-flex col-md-8 aut-bg-img d-md-flex justify-content-center">
            <div className="col-md-8 d-flex">
              <div className="auth-content d-flex align-items-stretch">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators justify-content-start mx-0">
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="0"
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="1"
                    />
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="2"
                    />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                    <div className="carousel-item">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                    <div className="carousel-item">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 align-items-stret h-100 justify-content-center">
            <div className=" auth-content">
              <img
                src="../assets/images/logo-dark.png"
                alt=""
                className="img-fluid mb-4"
              />
              <h4 className="mb-3 f-w-400">Login into your account</h4>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="feather icon-mail" />
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  onChange={this.onchange}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="feather icon-lock" />
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  onChange={this.onchange}
                />
              </div>

              {/* <div className="form-group mt-2">
                <div className="checkbox checkbox-primary d-inline">
                  <input
                    type="checkbox"
                    name="checkbox-p-1"
                    id="checkbox-p-1"
                  />
                  <label className="cr">Save credentials</label>
                </div>
              </div> */}
              <button className="btn btn-primary mb-4" onClick={this.login}>
                Login
              </button>
              {/* <p className="mb-2 text-muted">
                Forgot password?{" "}
                <a href="auth-reset-password.html" className="f-w-400">
                  Reset
                </a>
              </p>
              <p className="mb-0 text-muted">
                Don’t have an account?{" "}
                <a href="sign.html" className="f-w-400">
                  Signup
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
