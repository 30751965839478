import React, { Component, RefObject } from "react";
import "./vetting_overview.css";
import { API_ENDPOINT, callApi } from "../../utils/api";
import Select from 'react-select';
import { Link } from "react-router-dom";
import PDFViewer from "mgr-pdf-viewer-react";
import { setTimeout } from "timers";
import { saveAs } from "file-saver";
import {
  ToastsStore, ToastsContainer,
  ToastsContainerPosition,
} from "react-toasts";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import moment from "moment";
import Spinner1 from "../work-file/loader";

declare var $: any;
interface AllProps {
  id: any;
  history: any,
  match: any,
  location: any
}

interface Scrutiny {
  fod_fk: string;
  verify: string;
  nature_doc: string;
  extent: string;
  old_surevey: string;
  particulars: string;
  doc_no: string;
  date: string;
  type: string;
  ec_id: any;
  scrutiny_id: any;
  color: string;
  status: string;
  obtainedstatus: string;
  vetting_nature_of_doc: string;
  // removestatus: boolean;
}

export default class VettingOverview extends Component<AllProps> {
  scrollToRef: RefObject<HTMLDivElement> = React.createRef();
  state = {
    bank_templates: [],
    bank_id: 0,
    realFileidfk: "",
    pdfPreview: "",
    report: "",
    enableRefresh: true,
    timer: 0,
    file_idfk: "",
    scrutinies: [{ scrutiny_id: "", date: "", color: "", doc_no: "", particulars: "", old_surevey: "", extent: "", vetting_nature_of_doc: "", remove_status: 0 }],
    ChangeObtained: [],
    originalPresents: [],
    required: false,
    obtined: "",
    obtainedstatus: "",
    ReasonVal: false,
    lastUploadedDocument: {
      pdf: null,
      docx: null,
    },
    info: {
      verify_officer: "",
      process_officer: "",
      app_id: "",
      doc_request: "",
      file_name: "",
      file_status: "",
      verify: "",
      report_status: "",
      pending: "",
      closed: "",
      total: "",
      bank_name: "",
      login_time: ""

    },
    outreason: "",
    obtained_id: "",
    obtained_type: "",
    obtained_doc_nature: "",
    rows: [],
    columns: [],
    lengthofRow: "",
    scrutinieLength: 0,
    showTitle: true,
    isloading: false,
  };
  removestatus: any;
  intervalId: NodeJS.Timeout | number | null = null;
  componentDidMount() {
    this.getFileDashboard();
    this.getTemplates();
    this.getScrutinizedDocs();
    this.getOriginalPresents();
    this.gettabledata();
    const file_id: any = sessionStorage.getItem("currentfileId");
    const str = file_id;
    const withoutQuotes = str.replace(/"/g, '');
    this.setState({ file_idfk: withoutQuotes });

    // rows:[['sdfsd', 'sdfd', 'sdfs'],['df', 'sdf', 'fds']]
    // let getrows = [['sdfsd', 'sdfd', 'sdfd', '1'], ['df', 'sdf', 'sdfd', '4'], ['df', 'sdf', 'sdfd', '6']];
    // let getcolumns = ['Date', 'Doc.no', 'PARTICULARS OF DEEDS', 'TO BE OBTAINED'];
    // this.setState({ rows: getrows })
    // this.setState({ columns: getcolumns })
  }

  fetchtablecolumn() {
    if (this.state.bank_id) {
      const user: any = sessionStorage.getItem("user1");
      callApi("POST", "vetting_table_template_get.php", {
        userid: JSON.parse(user),
        bank_id: this.state.bank_id,
      })
        .then(res => res.data)
        .then(response => {
          let res = response.data;
          if (res != "") {
            for (let i = 0; i < res.length; i++) {
              const element = res[i];
              let lengthofrow = element.tableHeadersData.length - 1;
              this.setState({ lengthofRow: lengthofrow });
              this.setState({ bank_id: element.bank_id });
              this.setState({ columns: element.tableHeadersData });
              this.setState({ rows: [] });
            }
            // if (this.state.columns != null) {
            //   this.gettabledata();
            // }
          } else {
            this.setState({ lengthofRow: "" });
            this.setState({ bank_id: "" });
            this.setState({ columns: [] });
            this.setState({ rows: [] });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      ToastsStore.error("Please Select the Bank");
    }

  }
  updatetabledata = () => {
    const user: any = sessionStorage.getItem("user1");
    console.log("saved", this.state);
    callApi("POST", "vetting_row_data.php", {
      userid: JSON.parse(user),
      id: this.props.match.params.id,
      bank_id: this.state.bank_id,
      tableRowData: this.state.rows,
    })
      .then(res => res.data)
      .then(response => {
        if (response.success == true) {
          ToastsStore.success("Updated Successfully");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  gettabledata = () => {
    const user: any = sessionStorage.getItem("user1");
    console.log("saved", this.state);
    callApi("POST", "vetting_row_data_get.php", {
      userid: JSON.parse(user),
      id: this.props.match.params.id,
      // bank_id: this.state.bank_id,
      // tableRowData: this.state.rows,
    })
      .then(res => res.data)
      .then(response => {
        let res = response.data;
        if (res != "") {
          for (let i = 0; i < res.length; i++) {
            const element = res[i];
            this.setState({ bank_id: element.bank_id });
            if (element.tableRowData != null) {
              this.setState({ rows: element.tableRowData });
            }
            else {
              this.setState({ rows: [] });
            }
          }
          this.showtablecolumn();
        }
        else { }
      })
      .catch(err => {
        console.log(err);
      });
  }
  showtablecolumn() {
    const user: any = sessionStorage.getItem("user1");
    callApi("POST", "vetting_table_template_get.php", {
      userid: JSON.parse(user),
      bank_id: this.state.bank_id,
    })
      .then(res => res.data)
      .then(response => {
        let res = response.data;
        if (res != "") {
          for (let i = 0; i < res.length; i++) {
            const element = res[i];
            let lengthofrow = element.tableHeadersData.length - 1;
            this.setState({ lengthofRow: lengthofrow });
            this.setState({ bank_id: element.bank_id });
            // this.setState({ columns: element.tableHeadersData });
            if (element.tableHeadersData != null) {
              this.setState({ columns: element.tableHeadersData });
            }
            else {
              this.setState({ columns: [] });
            }
          }
        } else { }
      })
      .catch(err => {
        console.log(err);
      });
  }

  incrementTimer = () => {
    const { timer } = this.state;
    this.setState({ timer: timer + 1 });
  };

  getTemplates = () => {
    callApi("POST", "bank_temp_get.php")
      .then(res => res.data)
      .then(response => {
        let banks = response.data;
        const vetting = banks.filter(item => item.name.split(" ").pop() === "Vetting");
        this.setState({ bank_templates: vetting });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getOriginalPresents = () => {
    callApi("POST", "original_get.php")
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          // const state = this.state;
          const originalPresents = response.data;
          this.setState({ originalPresents });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getFileDashboard = () => {
    callApi("POST", "file_dash.php", {
      branch_idfk: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        const state = response.data;

        if (response.info && response.info.length) {
          state.info = response.info[0];
          // state.bank_id = state.info.bank_id;
          if (state.bank_id == 0) {
            ToastsStore.success("Please Select Your Bank Format");
            this.setState({ overViewShow: true })
          }
        }
        this.setState({ ...state });
        let bankTemp: any = this.state.bank_templates.find((get: any) => {
          if (get.value === this.state.bank_id) {
            localStorage.setItem("bankTemplateName", get.name);
            // this.setState({overViewShow:false})
          }
        });
        $(document).ready(function () {
          $("#simpletable").DataTable();
        });
        // this.scrutinyReport();
      })
      .catch(err => {
        console.log(err);
      });
  };
  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    this.setState({ [fieldName]: fieldValue });
    this.setState({ bank_id: fieldValue })
  };
  handleChangeobtained = (e, index, modal, propName?, fieldType?) => {
    this.setState({ outreason: "" });
    const id = modal.type === "scrutiny" ? modal.scrutiny_id : modal.ec_id;
    this.setState({ obtained_id: id });
    this.setState({ obtained_type: modal.type });
    this.setState({ obtained_doc_nature: e.id })
    // if (this.state.outreason == "") {
    //   $("#modal-reason").modal("show");
    //   this.setState({ selectVal: true });
    // } else {
    //   $("#modal-reason").modal("hide");
    //   this.setState({ selectVal: false });
    // }
    callApi("POST", "vetting_doc_scrutinize_update.php", {
      id: id,
      vetting_type: modal.type,
      // verify: modal.verify === "UnVerified" ? 1 : 0
      vetting_nature_of_doc: e.id,
      vetting_reason: this.state.outreason,
    })
      .then(res => res.data)
      .then(response => {
        this.setState({ outreason: "" });
        this.getScrutinizedDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };

  closeModal(): void {
    $("#modal-reason").modal("hide");
    this.setState({ selectVal: false });
    this.setState({ outreason: "" });
    this.setState({ obtainedstatus: "" });
    this.getScrutinizedDocs();
  }
  ReasonUpdate(): void {
    if (this.state.outreason == "") {
      this.setState({ ReasonVal: true });
    } else {
      this.setState({ ReasonVal: false });
      $("#modal-reason").modal("hide");
      this.setState({ selectVal: false });
      callApi("POST", "vetting_doc_scrutinize_update.php", {
        id: this.state.obtained_id,
        vetting_type: this.state.obtained_type,
        // verify: modal.verify === "UnVerified" ? 1 : 0
        vetting_nature_of_doc: this.state.obtained_doc_nature,
        vetting_reason: this.state.outreason,
      })
        .then(res => res.data)
        .then(response => {
          this.setState({ outreason: "" });
          this.getScrutinizedDocs();
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), ReasonVal: false });
  };

  scrutinyReport = () => {
    if (this.state.bank_id) {
      this.setState({ isloading: true });
      this.setState({ report: "" });
      this.setState({ timer: 0 });
      if (this.intervalId) {
        clearInterval(this.intervalId as NodeJS.Timeout);
      }

      this.intervalId = setInterval(() => {
        this.incrementTimer();
      }, 1000);
      $('.loader').show();
      const user: any = sessionStorage.getItem("user");
      const userId = user ? JSON.parse(user)["userId"] : "";
      callApi("POST", "template/tests/scrutiny_report_vetting.php", {
        file_idfk: this.props.match.params.id,
        check: false,
        userId,
        bank_id: this.state.bank_id
      })
        .then((res) => res.data)
        .then((response) => {
          if (response && response.data) {
            this.setState({ isloading: false });
            // sessionStorage.setItem("overviewDocument", response.data);
            var currentFile = response.file_name.split("-");
            sessionStorage.setItem("currentFile", currentFile[1]);
            this.setState({ report: response.data, enableRefresh: true });
            // $("#root").unbind();

            if (this.scrollToRef.current !== null) {
              this.scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            console.log("this.state---->>>", this.state);
            this.setState({ timer: 0 });
            $('.loader').hide();
          } else {
            this.setState({ enableRefresh: false });
            // this.scrutinyReport();
          }
        });
    } else {
      ToastsStore.error("Please Select the Bank");
    }
  };

  modifyScrutinyVerification = modal => {
    callApi("POST", "scrutiny_verify.php", {
      id: modal.type === "scrutiny" ? modal.scrutiny_id : modal.ec_id,
      type: modal.type,
      verify: modal.verify === "UnVerified" ? 1 : 0
    })
      .then(res => res.data)
      .then(response => {
        this.getScrutinizedDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };

  goToFile = (file) => {
    this.props.history.push(
      "/" + file.type + "/" + this.props.match.params.id + "/" + file.fod_fk + '/3'
    );
  };

  getScrutinizedDocs = () => {
    callApi("POST", "vetting_doc_scrutinize_list.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        const scr = response.data.filter(itm => itm.type === "scrutiny");
        const ec = response.data.filter(itm => itm.type === "ec-form");
        if (scr && scr.length) {
          scr.forEach((doc, index) => {
            doc.index = index + 1;
          });
        }
        if (ec && ec.length) {
          ec.forEach((doc, index) => {
            doc.index = index + 1;
          });
        }
        const scrutinies = scr.concat(ec);
        let scrutinieLength = scrutinies.length;
        this.setState({ scrutinies });
        this.setState({ scrutinieLength });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChangeremovestatus = (event, scr) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.removestatus = 1;
    } else {
      this.removestatus = 0;
    }
    const scrutinies = this.state.scrutinies.map(scrutinies => {

      if (scrutinies.scrutiny_id === scr.scrutiny_id) {
        console.log("scrutinies", scrutinies);
        return { ...scrutinies, remove_status: JSON.stringify(this.removestatus) };
      }
      return scrutinies;
    });
    this.setState({ scrutinies });
  }

  handleSubmit = () => {
    console.log("saved", this.state);
    const checkedStatus = this.state.scrutinies.filter(scrutinie => scrutinie.remove_status);
    console.log(checkedStatus);
    callApi("POST", "vetting_doc_scrutinize_remove_update.php", {
      remove_status: checkedStatus,
    })
      .then(res => res.data)
      .then(response => {
        if (response.success == true) {
          this.updatetabledata();
          ToastsStore.success("Updated Successfully");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  vettingcompleteupdatetime() {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      vetting_completed_time: currenttime,
      vetting_completed_date: currentdate,
      vetting_completed_by: JSON.parse(loginuser),
      id: this.props.match.params.id
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  updateBillVettingComplete(status) {
    this.setState({ showTitle: true })
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "bill_vetting_complete.php", {
      file_id: this.props.match.params.id,
      vettingby: userId,
      status,
    })
      .then((res) => res.data)
      .then((result) => {
        this.setState({ showTitle: false })
        if (result.result == true) {
          if (status == 1) {
            $("#vetting-complete").modal('hide');
          }
          this.vettingcompleteupdatetime();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  close = () => {
    $("#processComplete").modal('hide');
  }

  //Download docs from external api
  downloadDocs = () => {
    callApi("POST", "template/tests/shell_vetting.php", {
      id: this.props.match.params.id,
      // responseType: "blob",
    })
      .then((res) => res.data)
      .then((response) => {
        //ToastsStore.success("Downloaded Successfully!");
        window.open(
          API_ENDPOINT + "template/tests/shell_vetting.php?id=" + this.props.match.params.id,
          "_self"
        );
      });
  };
  handleChangeheader(event: React.ChangeEvent<HTMLInputElement>, columnIndex: number): void {
    const { columns } = this.state;
    const newColumns = [...columns] as string[];
    newColumns[columnIndex] = event.target.value;
    this.setState({ columns: newColumns });
  }

  handleChangetable(event: React.ChangeEvent<HTMLInputElement>, rowIndex: number, columnIndex: number): void {
    const { rows } = this.state;
    const newRows = [...rows] as string[][];
    // Check if rowIndex is a valid index in newRows
    if (newRows[rowIndex] || newRows[columnIndex]) {
      newRows[rowIndex][columnIndex] = event.target.value;
      this.setState({ rows: newRows });
    }
    console.log("table rows", this.state.rows);
  }

  handleAddRow = () => {
    const { rows, columns } = this.state as unknown as { rows: never[][]; columns: string[] };
    const newRow = [] as string[];
    for (let i = 0; i < columns.length - 1; i++) {
      newRow.push("");
    }
    newRow.push(""); // add an empty value for the select box
    this.setState({ rows: [...rows, newRow] });
  };

  handleSelectboxChange = (selectedOption, rowIndex) => {
    const { rows } = this.state;
    const newRows = [...rows] as string[][];
    // Check if rowIndex is a valid index in newRows
    if (newRows[rowIndex]) {
      newRows[rowIndex][newRows[rowIndex].length - 1] = selectedOption.value;
      this.setState({ rows: newRows });
    }
    console.log("table rows", this.state.rows);
  };

  handleRemoveRow = (index) => {
    const { rows } = this.state;
    const newRows = [...rows];
    newRows.splice(index, 1);
    this.setState({ rows: newRows });
  };

  handleAddColumn = () => {
    const { rows, columns } = this.state;
    const newColumn = "";
    const newRows = rows.map((row) => [...row, newColumn]);
    this.setState({ rows: newRows, columns: [...columns, newColumn] });
  };

  handleRemoveColumn = (index) => {
    const { rows, columns } = this.state;
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    const newRows = rows.map((row) => {
      const newRow = [...row];
      newRow.splice(index, 1);
      return newRow;
    });
    this.setState({ rows: newRows, columns: newColumns });
  };

  render() {
    const { status: vettingStatus, showTitle, componentList, showElementList, documentComponentList, timer, ReasonVal, scrutinies, rows, columns }: any = this.state;
    const banktemplatesoption = [
      { value: "", label: "All" },
      ...this.state.bank_templates
    ];
    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="overview-body">
                      <div className="overview-main">
                        <div className="row">
                          <div className="col-sm-12">
                            {/* <h5>Vetting Overview</h5> */}
                            <div className="d-flex">
                              <h5 style={{ fontSize: "16px" }}>File No - {this.state.info.file_name}</h5><br />
                              <h5 style={{ marginLeft: "1%" }}>|</h5>
                              <h5 style={{ marginLeft: "1%", fontSize: "16px" }}>Login Date - {this.state.info.login_time}</h5>
                              <h5 style={{ marginLeft: "1%" }}>|</h5>
                              <h5 style={{ marginLeft: "1%", fontSize: "16px" }}>Bank Name - {this.state.info.bank_name}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 d-flex" style={{ marginTop: "1%" }}>
                            <h5 style={{ fontSize: "16px" }}>PO - {this.state.info.process_officer}</h5>
                            <h5 style={{ marginLeft: "1%" }}>|</h5>
                            <h5 style={{ marginLeft: "1%", fontSize: "16px" }}>VO - {this.state.info.verify_officer}</h5>
                          </div>
                          <div className="col-md-6 template-select">
                            <Link to={"/file-dashboard/" + this.props.match.params.id}
                              className="btn btn-square float-right"
                              style={{ color: 'black', border: '1px solid black', borderRadius: '5px', marginTop: "1%" }}
                            >
                              Back
                            </Link>
                            <button
                              onClick={() => { this.scrutinyReport() }}
                              className="ml2 btn btn-primary btnupload float-right"
                            >
                              Overview
                            </button>
                            <button
                              onClick={() => { this.fetchtablecolumn() }}
                              className="ml2 btn btn-primary btnupload float-right"
                            >
                              Get Extra Table
                            </button>
                            <div className="float-right" style={{ width: "30%", margin: "1% 2% 0 0" }}>
                              {this.state.bank_templates && this.state.bank_templates.length > 0 && this.state.bank_templates.map((opt: any) => {
                                opt.label = opt.name; opt.value = opt.id
                              }) &&
                                <Select options={banktemplatesoption} name="bank_id"
                                  value={this.state.bank_templates.find((o: any) => o.value === this.state.bank_id)}
                                  onChange={(e) => this.handleChange(e, 'bank_id', 'singleselect')}
                                />
                              }
                            </div>
                          </div>
                        </div>
                        <div className="text-center loader" style={{ display: 'none', border: 'none', marginTop: "2%" }}>
                          <h4>
                            <span>Loading... {timer} seconds</span>
                          </h4>
                        </div>
                        <div className="container" style={{ marginTop: "5%", maxWidth: "100%" }}>
                          <div className="row">
                            <div className="col-lg-12">
                              <form >
                                <table className="table table-hover" id="myTable">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="text-center">S.No</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Doc.No</th>
                                      <th scope="col">Particulars of deeds</th>
                                      <th scope="col">survey no</th>
                                      <th scope="col">Extent</th>
                                      <th scope="col">To Be Obtained</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {scrutinies.map((scr, index) => {
                                      return (
                                        <tr
                                          className="pointer"
                                          key={index}
                                          style={{ background: scr.color }}
                                        >
                                          <td className="indexInput" onClick={() => this.goToFile(scr)}>
                                            {index + 1}
                                          </td>
                                          <td
                                            className="indexInput"
                                            onClick={() => this.goToFile(scr)}
                                          >
                                            {scr.date}
                                          </td>
                                          <td onClick={() => this.goToFile(scr)}>
                                            <a>{scr.doc_no}</a>
                                          </td>
                                          <td onClick={() => this.goToFile(scr)}>
                                            {scr.particulars}
                                          </td>
                                          <td onClick={() => this.goToFile(scr)}>
                                            {scr.old_surevey}
                                          </td>
                                          <td onClick={() => this.goToFile(scr)}>
                                            {scr.extent}
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            {this.state.originalPresents && this.state.originalPresents.length > 0 && this.state.originalPresents.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                              <Select options={this.state.originalPresents} name="obtined"
                                                className={this.state.required && this.state.obtined === '' ? 'form-select is-invalid' : 'form-select'}
                                                value={this.state.originalPresents.find((o: any) => o.value === scr.vetting_nature_of_doc)}
                                                onChange={(e) => this.handleChangeobtained(e, index, scr, "obtainedstatus", 'singleselect')}
                                              />
                                            }
                                          </td>
                                          <td className="indexInput text-center" style={{ display: "flex", justifyContent: "center" }}>
                                            <input
                                              type="checkbox"
                                              className="form-control"
                                              value=""
                                              checked={scr.remove_status === "1"}
                                              onChange={(event) => this.handleChangeremovestatus(event, scr)}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                    )}
                                  </tbody>
                                </table>
                              </form>

                              <div className='table mt-4'>
                                <div className="card-body table">
                                  <div className="dt-responsive table-responsive">
                                    {this.state.columns && this.state.columns.length ?
                                      (
                                        <>
                                          <label className='bold'>Extra Table</label>
                                          <table className="table table-bordered table-hover">
                                            <thead>
                                              <tr>
                                                <th>S.No</th>
                                                {columns.map((column, columnIndex) => (
                                                  <th key={columnIndex}>
                                                    <p>{column}</p>
                                                  </th>
                                                ))}
                                                <th>
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rows.map((row: [], rowIndex = this.state.scrutinieLength) => (
                                                <tr key={rowIndex}>
                                                  <td style={{ width: "5%" }}>{rowIndex + this.state.scrutinieLength + 1}</td>
                                                  {row.map((cell, columnIndex: any) => (
                                                    <td key={columnIndex} style={{ width: "35%" }}>
                                                      {columnIndex != this.state.lengthofRow ? (

                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          value={cell}
                                                          onChange={(event) =>
                                                            this.handleChangetable(event, rowIndex, columnIndex)
                                                          }
                                                        />

                                                      ) : (
                                                        <Select
                                                          options={this.state.originalPresents.map((opt: any) => {
                                                            opt.label = opt.name;
                                                            opt.value = opt.name;
                                                            return opt;
                                                          })}
                                                          name="obtined"
                                                          className={
                                                            this.state.required && this.state.obtined === ""
                                                              ? "form-select is-invalid"
                                                              : "form-select"
                                                          }
                                                          value={this.state.originalPresents.find(
                                                            (o: any) => o.value === row[row.length - 1]
                                                          )}
                                                          onChange={(selectedOption) =>
                                                            this.handleSelectboxChange(selectedOption, rowIndex)
                                                          }
                                                        />)
                                                      }
                                                    </td>
                                                  ))}
                                                  <td style={{ width: "5%" }}>
                                                    <button
                                                      className="btn btn-danger btn-sm float-right"
                                                      onClick={() => this.handleRemoveRow(rowIndex)}
                                                    >
                                                      x
                                                    </button>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          <button
                                            onClick={this.handleAddRow}
                                            className="btn btn-primary pull-left"
                                          >
                                            Add Row
                                          </button>
                                        </>
                                      ) : ""}

                                  </div>
                                </div>
                              </div>
                              <div className="file-search-button formbtn d-flex float-right" style={{ marginTop: "0%" }}>
                                <button
                                  type="submit"
                                  className="btn btn-primary" onClick={this.handleSubmit}
                                >
                                  Save
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success" data-toggle="modal"
                                  data-target="#vetting-complete"
                                  style={{ height: "40px", marginTop: "3%" }}
                                >
                                  Vetting Complete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div>
                          <Documentviewer />
                        </div> */}


                        <div ref={this.scrollToRef} className="pdfcontainer" style={{ width: "100%" }}>
                          <div style={{ marginTop: "1%" }} />
                          {this.state.report &&
                            <div>
                              <button
                                type="button"
                                id="test"
                                className="btn btn-primary"
                                onClick={this.downloadDocs}
                              >
                                Download Doc <i className="fa fa-download" />
                              </button>
                              <iframe
                                width="100%"
                                height="600"
                                frameBorder="0"
                                src={this.state.report}
                              ></iframe>
                            </div>
                          }
                        </div>

                        {/* REASON MODAL */}
                        <div className="modal hide fade in" id="modal-reason" data-backdrop="static" data-keyboard="false">
                          <div className="modal-dialog ">
                            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Edit </h5>
                              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                  <textarea
                                    className="form-control"
                                    placeholder="Type Your Reason"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={this.onchange} />
                                  {ReasonVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Enter Your Reason </p> : null}
                                </div>

                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.ReasonUpdate()}
                                  style={{ width: '76px', fontSize: '17px' }}
                                > Ok</button>
                                <button
                                  type="button"
                                  className="btn btn-outline"
                                  onClick={() => this.closeModal()}
                                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Cancel</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="modal hide fade in" id="processComplete" data-backdrop="static" data-keyboard="false">
                          <div className="modal-dialog ">
                            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                              <div className="modal-body">
                                <h5 style={{ textAlign: 'center' }}> Thanks For Complete Your File:{this.props.match.params.id}</h5>
                              </div>
                              <div className="modal-footer">
                                {showTitle ? null : (<button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() => this.close()}
                                  // onClick={this.disableModalHandler}
                                  style={{ width: '50%', fontSize: '17px', margin: 'auto' }}
                                > Ok</button>)}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="modal fade"
                          id="vetting-complete"
                          role="dialog"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" style={{ textAlign: "center" }}>Vetting Completed</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {vettingStatus && (<h6>Your vetting report is completed please contact our support team</h6>)}

                                {!vettingStatus && (
                                  <>
                                    <h6>If you want to complete the vetting report ?</h6>
                                    <div className="form-group"></div>
                                  </>
                                )}
                              </div>
                              {!vettingStatus && (
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => this.updateBillVettingComplete(1)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                  >
                                    No
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}