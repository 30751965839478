import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import "react-flexy-table/dist/index.css";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "antd/lib/date-picker";
import Select from 'react-select';
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import { ComplaintTable } from './complaintTable';

export class ComplaintList extends Component {
    state = {
        complaints: [],
        user: {
            role_id: ""
        },
        statuses: [
            "pending", "completed", "newrequirement"
        ],
        filteredComplaints: [],
        selectedStatus: "pending",
        searchText: "",
        activekey: "pendingKey",
        complaintStatus: "",
        fromDate: "",
        toDate: "",
        isLoading: false
    }
    componentDidMount() {
        this.getComplaint();
        const user: any = sessionStorage.getItem("user");
        this.setState({ user: user ? JSON.parse(user)["userId"] : "" })
    }
    getComplaint = () => {
        callApi("POST", "complaint_list_all.php")
            .then(res => res.data)
            .then(response => {

                this.setState({
                    complaints: response.data,
                    filteredComplaints: response.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleStatusChange = (data, ticket, currentPath) => {
        const User: any = sessionStorage.getItem("user");
        const roleType = User ? JSON.parse(User)["roleType"] : "";
        const userId = User ? JSON.parse(User)["userId"] : "";
        const role_label = User ? JSON.parse(User)["role_label"] : "";
        let statusId;
        // if (userId == "1" || roleType == "1" || roleType == "2" || role_label == "ADMIN") {
        if (currentPath == "/all-complaints") {
            statusId = data === "Temporary Solution" ? "4" : data === "Completed" ? "5" : "6";
        } else {
            statusId = data === "Invalid" ? "1" : data === "Valid" ? "2" : "3";
        }
        callApi("POST", "complaint_status_change.php", { ticketId: ticket.ticketId, Status: statusId })
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint status changed successfully");
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleSearchChange = (e) => {
        const { complaints } = this.state;

        const list = complaints.filter((item: any) => {
            const id = (item.ticketId || '').toLowerCase();
            const alreadyExist = (item.alreadyExist || '').toLowerCase();
            const bankFormat = (item.bankFormat || '').toLowerCase();
            const createdDate = (item.createdDate || '').toLowerCase();
            const fileNo = (item.fileNo || '').toLowerCase();
            const location = (item.location || '').toLowerCase();
            const remark = (item.remark || '').toLowerCase();
            const status = (item.status || '').toLowerCase();
            const toBeChange = (item.toBeChange || '').toLowerCase();
            const updatedDate = (item.updatedDate || '').toLowerCase();
            const userName = (item.userName || '').toLowerCase();

            return (
                id.includes(e.target.value.toLowerCase()) ||
                alreadyExist.includes(e.target.value.toLowerCase()) ||
                bankFormat.includes(e.target.value.toLowerCase()) ||
                createdDate.includes(e.target.value.toLowerCase()) ||
                fileNo.includes(e.target.value.toLowerCase()) ||
                location.includes(e.target.value.toLowerCase()) ||
                remark.includes(e.target.value.toLowerCase()) ||
                status.includes(e.target.value.toLowerCase()) ||
                toBeChange.includes(e.target.value.toLowerCase()) ||
                updatedDate.includes(e.target.value.toLowerCase()) ||
                userName.includes(e.target.value.toLowerCase())
            );
        });

        console.log(e.target.value, list);
        this.setState({
            searchText: e.target.value,
            filteredComplaints: list,
        });
    };
    handleTabSelect = (key) => {
        const { complaints } = this.state;
        let list;
        if (key == "completeKey") {
            list = complaints.filter((item: any) => {
                return item.softwarestatus == "Completed";
            });
        } else if (key == "temporaryKey") {
            list = complaints.filter((item: any) => {
                return item.softwarestatus == "Temporary Solution";
            });
        }
        else if (key == "pendingKey") {
            list = complaints.filter((item: any) => {
                return item.softwarestatus == "Pending" || item.softwarestatus == "";
            });
        } else { }

        if (key == "allKey") {
            this.setState({
                searchText: '',
                filteredComplaints: complaints,
            });
        } else {
            this.setState({
                searchText: '',
                filteredComplaints: list,
            });
        }
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    handleTempChange = (data, currentPath) => {
        this.setState({ complaintStatus: data.id, currentPath });
    }
    getSearchComplaint = () => {
        if (this.state.fromDate != "" && this.state.toDate != "") {
            this.setState({ isLoading: true });
            let obj = {
                from_date: this.state.fromDate,
                to_date: this.state.toDate,
                complaint_Status: this.state.complaintStatus
            }
            console.log("date", obj);
            callApi("POST", "filter_complaint.php", obj)
                .then(res => res.data)
                .then(response => {
                    if (response.status == 200) {
                        const complaints = response.data;
                        if (complaints && complaints.length > 0) {
                            complaints.forEach((file, index) => {
                                Object.keys(file).forEach(fileKey => {
                                    file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                                })
                                file.index = index + 1;
                            })
                        }
                        this.setState({
                            complaints,
                            filteredComplaints: complaints
                        });
                        console.log("filter_complaint", response);
                        this.setState({ isLoading: false });
                    } else {
                        this.setState({ isLoading: false });
                        ToastsStore.error(response.message);
                        this.setState({
                            complaints: [],
                            filteredComplaints: []
                        });
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    console.log(err);
                });
        }
        else {
            ToastsStore.error("Please select the date.");
        }
    }
    render() {
        const { complaints, user, statuses, filteredComplaints, searchText } = this.state;
        const forSoftwareTeamStatuses = [
            { id: '', name: 'All' },
            { id: 'Completed', name: 'Completed' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Temporary Solution', name: 'Temporary Solution' },
        ];
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Complaint List</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <Accordion className="mainAccordion">
                                                        <Tabs defaultActiveKey="allKey" onSelect={this.handleTabSelect}
                                                            style={{ padding: 0 }}>
                                                            <Tab eventKey="allKey" title="All Complaints">
                                                                <div className="row m-3">
                                                                    <div className="col-md-3">
                                                                        <h6>From</h6>
                                                                        <DatePicker format={'DD-MM-YYYY'}
                                                                            onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h6>To</h6>
                                                                        <DatePicker format={'DD-MM-YYYY'}
                                                                            onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h6>S/W Status</h6>
                                                                        {forSoftwareTeamStatuses && forSoftwareTeamStatuses.length > 0 && forSoftwareTeamStatuses.map((opt: any) => {
                                                                            opt.label = opt.name; opt.value = opt.id
                                                                        }) &&
                                                                            <Select options={forSoftwareTeamStatuses} name="complaintStatus"
                                                                                value={forSoftwareTeamStatuses.find((o: any) => o.value == this.state.complaintStatus)}
                                                                                onChange={(e) => this.handleTempChange(e, "/all-complaints")}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group row justify-content-end">
                                                                            <div className="col-sm-3 mt-3 mr-5">
                                                                                <button type="button" className="btn btn-primary"
                                                                                    onClick={this.getSearchComplaint}
                                                                                    disabled={this.state.isLoading}
                                                                                >{this.state.isLoading ? (
                                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                                ) : "Search"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/all-complaints"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="pendingKey" title="Pending">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/all-complaints"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="completeKey" title="Completed">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/all-complaints"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="temporaryKey" title="Temporary Solution">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/all-complaints"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ComplaintList
