import moment from "moment";
import { callApi } from "./api";
import { notifyToUser } from "./firebase";

export const FIREBASE_PNotifyCountDown1 = 1000;

const getUpdateAPI = (type: string) => {
 
  const API_TYPES = {verify: "verify.php","assign-reason": "assign.php",
  };

  return API_TYPES[type];
};

const fileVerifyAPI = ( 
  inputValue: any,
  updateData: any,
  updateType: any,
  allValue: any,
  fileStatus: string) => {
  if(fileStatus=="Accepted"){
    callApi("POST", getUpdateAPI(updateType), JSON.parse(updateData))
    .then((res) => res.data)
    .then((response) => {
      const branchManger: any = sessionStorage.getItem("branch_manager");
        const branch_Manager = JSON.parse(branchManger);
        const queryOfficer: any = sessionStorage.getItem("Query_officer");
        const query_ooficer = JSON.parse(queryOfficer);
        const Scan_officer: any = sessionStorage.getItem("scan_officer");
        const sacanofficer = JSON.parse(Scan_officer);
        const process_mennager: any = sessionStorage.getItem("process_manager");
        const processManager = JSON.parse(process_mennager);
        const senior_manager: any = sessionStorage.getItem("senior_relational");
        const seniorManager = JSON.parse(senior_manager);
        const reginal_relational: any = sessionStorage.getItem("reginal_relational");
        const reginalRelational = JSON.parse(reginal_relational);
        if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, branch_Manager);
          const branchManager = branch_Manager.join(",");
          let obj = {
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: branchManager,
          title: `${allValue.fileId}File Verification Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
          notifyToUser("user", { notifyType: "notify", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, query_ooficer);
          const Query_officer = query_ooficer.join(",");
          let obj = {
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: Query_officer,
            title: `${allValue.fileId}File Verification Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
             
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(processManager) && processManager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, processManager);

          const ProcessManager = processManager.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: ProcessManager,
             title: `${allValue.fileId}File Verification Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(seniorManager) && seniorManager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, seniorManager);
          const SeniorManage = seniorManager.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
             reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: SeniorManage,
            title: `${allValue.fileId}File Verification Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(reginalRelational) && reginalRelational.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, reginalRelational);
          const ReginoalManager = reginalRelational.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
            title: `${allValue.fileId}File Verification Accepted `,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: ReginoalManager,
            reason: ` ${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
    })
    .catch((err) => {
      console.log(err);
    });
  }else {
    const branchManger: any = sessionStorage.getItem("branch_manager");
    const branch_Manager = JSON.parse(branchManger);
    const queryOfficer: any = sessionStorage.getItem("Query_officer");
    const query_ooficer = JSON.parse(queryOfficer);
    const Scan_officer: any = sessionStorage.getItem("scan_officer");
    const sacanofficer = JSON.parse(Scan_officer);
    const process_mennager: any = sessionStorage.getItem("process_manager");
    const processManager = JSON.parse(process_mennager);
    fileAssignApiphp(allValue.fileId,inputValue);
    if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
      notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, branch_Manager);
      const BranchManager = branch_Manager.join(",");
      let obj = {
        notification_type: "assigned",
        type: "FileAssigned",
        reason: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        user_id: BranchManager,
         title: `${allValue.fileId}File Verification Rejected`
      }
      callApi("POST", "pushnotification.php", obj)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        })
    }
    if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
      notifyToUser("user", { notifyType: "notify", title: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, query_ooficer);
      const QureyOfficer = query_ooficer.join(",");
      let obj = {
        notification_type: "assigned",
        type: "FileAssigned",
         reason: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        user_id: QureyOfficer,
        title: `${allValue.fileId}File Verification Rejected`
      }
      callApi("POST", "pushnotification.php", obj)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        })
    }
    // if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, frontofficeid);
    // }
    // if (Array.isArray(processManager) && processManager.length > 0) {
    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,text:`${allValue.inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, managerId);
    // }


    // const userId = user ? JSON.parse(user)["userId"] : "";
    // const userName = user ? JSON.parse(user)["firstName"] : "";

    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,text:`${allValue.inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, managerId);
  }
 
};

const submitReasonAPI = (value) => {
  callApi("POST", "reason_create.php", JSON.parse(value))
    .then((res) => res.data)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

const fileAssignAPI = (
  inputValue: any,
  updateData: any,
  updateType: any,
  allValue: any,
  fileStatus: string
) => {
  if (fileStatus == "Accepted") {
    callApi("POST", getUpdateAPI(updateType), JSON.parse(updateData))
      .then((res) => res.data)
      .then((response) => {
        const branchManger: any = sessionStorage.getItem("branch_manager");
        const branch_Manager = JSON.parse(branchManger);
        const queryOfficer: any = sessionStorage.getItem("Query_officer");
        const query_ooficer = JSON.parse(queryOfficer);
        const Scan_officer: any = sessionStorage.getItem("scan_officer");
        const sacanofficer = JSON.parse(Scan_officer);
        const process_mennager: any = sessionStorage.getItem("process_manager");
        const processManager = JSON.parse(process_mennager);
        const senior_manager: any = sessionStorage.getItem("senior_relational");
        const seniorManager = JSON.parse(senior_manager);
        const reginal_relational: any = sessionStorage.getItem("reginal_relational");
        const reginalRelational = JSON.parse(reginal_relational);
        if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, branch_Manager);
          const branchManager = branch_Manager.join(",");
          let obj = {
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: branchManager,
          title: `${allValue.fileId}File Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
          notifyToUser("user", { notifyType: "notify", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, query_ooficer);
          const Query_officer = query_ooficer.join(",");
          let obj = {
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: Query_officer,
            title: `${allValue.fileId}File Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
             
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(processManager) && processManager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, processManager);

          const ProcessManager = processManager.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
            reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: ProcessManager,
             title: `${allValue.fileId}File Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(seniorManager) && seniorManager.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, seniorManager);
          const SeniorManage = seniorManager.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
             reason: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: SeniorManage,
            title: `${allValue.fileId}File Accepted`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(reginalRelational) && reginalRelational.length > 0) {
          notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, reginalRelational);
          const ReginoalManager = reginalRelational.join(",");
          let obj = {
            notification_type: "assigned",
            type: "FileAssigned",
            title: `${allValue.fileId}File Accepted `,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: ReginoalManager,
            reason: ` ${allValue.fileId} File ${fileStatus} File Finish Date&Time:${inputValue}`
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    const branchManger: any = sessionStorage.getItem("branch_manager");
    const branch_Manager = JSON.parse(branchManger);
    const queryOfficer: any = sessionStorage.getItem("Query_officer");
    const query_ooficer = JSON.parse(queryOfficer);
    const Scan_officer: any = sessionStorage.getItem("scan_officer");
    const sacanofficer = JSON.parse(Scan_officer);
    const process_mennager: any = sessionStorage.getItem("process_manager");
    const processManager = JSON.parse(process_mennager);
    fileAssignApiphp(allValue.fileId,inputValue);
    if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
      notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, branch_Manager);
      const BranchManager = branch_Manager.join(",");
      let obj = {
        notification_type: "assigned",
        type: "FileAssigned",
        reason: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        user_id: BranchManager,
         title: `${allValue.fileId}File Rejected`
      }
      callApi("POST", "pushnotification.php", obj)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        })
    }
    if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
      notifyToUser("user", { notifyType: "notify", title: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`, updatedAt: moment().format("YYYY-MM-DD HH:mm:ss") }, query_ooficer);
      const QureyOfficer = query_ooficer.join(",");
      let obj = {
        notification_type: "assigned",
        type: "FileAssigned",
         reason: `${allValue.fileId} File ${fileStatus} Reject Reason:${inputValue}`,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        user_id: QureyOfficer,
        title: `${allValue.fileId}File Rejected`
      }
      callApi("POST", "pushnotification.php", obj)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        })
    }
    // if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, frontofficeid);
    // }
    // if (Array.isArray(processManager) && processManager.length > 0) {
    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,text:`${allValue.inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, managerId);
    // }


    // const userId = user ? JSON.parse(user)["userId"] : "";
    // const userName = user ? JSON.parse(user)["firstName"] : "";

    //   notifyToUser("user", { notifyType: "message", title: `${allValue.fileId} File ${fileStatus} Reason:${inputValue}`,text:`${allValue.inputValue}`,updatedAt:moment().format("YYYY-MM-DD HH:mm:ss") }, managerId);
  }
};
const fileAssignApiphp = (fileid: any,reason: any) => {
  const user: any = sessionStorage.getItem("user");
  const userId = 0;
  let obj = {
      id: fileid,
      assigned_to:userId,
  }
  callApi("POST", "assign.php", obj)
      .then((res) => res.data)
      .then((response) => {
      })
      .catch((err) => {
          console.log(err);
      });
}
const apiCallHelper = (
  inputValue: any,
  updateData: any,
  updateType: any,
  allValue: any,
  fileStatus: string
) => {
  if (updateType === "verify") {
    fileVerifyAPI(inputValue,updateData, updateType,allValue,fileStatus);
  } else if (updateType === "assign-reason") {
    fileAssignAPI(inputValue, updateData, updateType, allValue, fileStatus);
  }
};

export { fileVerifyAPI, apiCallHelper };
