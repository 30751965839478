import { useEffect, useState } from "react";
import { callApi } from "../../utils/api";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore,
} from "react-toasts";
const useRecorder = (props) => {
    const [audioURL, setAudioURL] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [audioList, setAudioList] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [timer, setTimer] = useState(0);
    let interval;
    const blobToBase64 = (blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            console.log(reader);
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    useEffect(() => {
        getAudioList();
    }, []);

    const getAudioList = () => {
        const getfileId = sessionStorage.getItem("currentfileId");
        let promise = new Promise((resolve, reject) => {
            callApi("POST", "audio_get.php", {
                file_id: JSON.parse(getfileId),
            })
                .then((res) => res.data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(true);
                });
        });
        promise.then((response) => {
            // setAudioList([...response.audioList]);
            if (Array.isArray(response.audioList)) {
                setAudioList([...response.audioList]);
            } else {
            }
        });
    };

    const uploadAudio = (params) => {
        const user = sessionStorage.getItem("user");
        const user_idfk = user ? JSON.parse(user)["userId"] : "";
        const getfileId = sessionStorage.getItem("currentfileId");
        let res = {
            file_id: JSON.parse(getfileId),
            user_id: user_idfk,
            audio: params.data,
        }
        let promise = new Promise((resolve, reject) => {
            callApi("POST", "audio_upload.php", res)
                .then((res) => res.data)
                .then((response) => {
                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(true);
                });
        });
        promise.then((response) => {
            ToastsStore.success("Audio saved successfully");
            console.log(response);
            getAudioList();
        });
    };

    useEffect(() => {
        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
            if (isRecording) {
                requestRecorder().then(setRecorder, console.error);
            }
            return;
        }
        // Manage recorder state.
        if (isRecording) {
            recorder.start();
        } else {
            recorder.stop();
        }
        // Obtain the audio when ready.
        const handleData = async (e) => {
            const response = await blobToBase64(e.data);
            // const url = URL.createObjectURL(blob);
            uploadAudio({
                data: response,
            });
            // setAudioURL(URL.createObjectURL(e.data));
            // let url = URL.createObjectURL(e.data);
            // console.log(url);
        };

        recorder.addEventListener("dataavailable", handleData);
        return () => recorder.removeEventListener("dataavailable", handleData);
    }, [recorder, isRecording]);

    const startRecording = () => {
        setIsRecording(true);
        setStartTime(performance.now());
        interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
    };

    const stopRecording = () => {
        setIsRecording(false);
        setStartTime('');
        clearInterval(interval);
        const elapsedTime = performance.now() - startTime;
        console.log('Elapsed time (ms):', elapsedTime);
    };

    return [audioURL, isRecording, startRecording, stopRecording, startTime, audioList, timer];
};

async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
}
export default useRecorder;
