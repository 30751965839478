import React, { Component } from "react";
import { callApi } from "../../utils/api";
import './ReactFlexyTable.css';
import { isEmpty, isNaN, isNumber } from 'lodash';
import { ToastsStore } from "react-toasts";
declare var $: any;

interface AllProps {
    match: any;
    history: any;
    newaddrequery: any;
    addrequery: any;
    Completequery: any;
    queryTableData?: Item[];
}

interface Item {
    id: string;
    name: string;
    parent_id: string;
    title: string;
    raice_by: string;
    to: string;
    status: string;
    reason: string;
    location: string;
    created_date: string;
}

interface OriginalObject {
    id: string;
    name: string;
    parent_id: string;
    title: string;
    raice_by: string;
    to: string;
    status: string;
    reason: string;
    d_request_reason: string;
    d_officer_reason: string;
    deviation_status: string;
    location: string;
    created_date: string;
    serial_no: string | number; // Update this line
    children: OriginalObject[];
}

interface FlattenedObject {
    id: string;
    name: string;
    parent_id: string;
    title: string;
    raice_by: string;
    to: string;
    status: string;
    reason: string;
    d_request_reason: string;
    d_officer_reason: string;
    deviation_status: string;
    location: string;
    created_date: string;
    serial_no: string | number;
}

export default class QueryTable extends Component<AllProps> {
    state = {
        deviationReason: "",
        deviationStatus: "",
        isLoading: false,
        deviationTakenQuerys: [],
        queryData: [],
        office_users: [],
        copyQueryData: [],
        selectedQueryData: [],
        queryLoading: false,
        quesSubmitted: false,
        queryQues: [],
        oldQueryQues: [],
        remote_users: [],
        remote_cc_users: [],
        base: [],
        deviationDetails: [],
        file_deviation_Status: ""
    };
    componentDidMount() {
        const { queryTableData }: any = this.props;
        const parseData = JSON.parse(JSON.stringify(queryTableData));
        if (queryTableData.length > 0) {
            this.setState({ queryData: [...queryTableData], copyQueryData: parseData });
        } else {
            this.setState({ queryData: [], copyQueryData: [] });
        }
        this.getFileDetail();
    }
    deviationTakenQuery = (data: { id: any }) => {
        this.setState((prevState: any) => ({
            deviationTakenQuerys: [...prevState.deviationTakenQuerys, data.id]
        }));
    }
    deviationTaken = (e: any, data: Item, index: number) => {
        const { queryData, selectedQueryData }: any = this.state;
        const isChecked = e.target.checked;
        const deviationTakenQuerys = this.state.deviationTakenQuerys as string[]; // Type assertion
        if (isChecked && !deviationTakenQuerys.includes(data.id)) {
            this.setState((prevState: any) => ({
                deviationTakenQuerys: [...prevState.deviationTakenQuerys, data.id],
                selectedQueryData: [...prevState.selectedQueryData, data]
            }), () => { });
            queryData[index].isChecked = true;
        } else if (!isChecked) {
            this.setState((prevState: any) => ({
                deviationTakenQuerys: prevState.deviationTakenQuerys.filter((id: string) => id !== data.id),
                selectedQueryData: prevState.selectedQueryData.filter((query: Item) => query.id !== data.id)
            }), () => { });
            queryData[index].isChecked = false;
        }
    }
    closeDeviationModal = () => {
        const { copyQueryData }: any = this.state;
        this.setState({ deviationTakenQuerys: [], queryData: copyQueryData, deviationReason: "", selectedQueryData: [] });
    }
    saveHint = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const { deviationTakenQuerys, queryData }: any = this.state;
        deviationTakenQuerys.forEach(element => {
            for (let i = 0; i < queryData.length; i++) {
                const query = queryData[i];
                if (query.id == element) {
                    const queryName = query.name;
                    callApi('POST', 'add_hints.php', { id: this.props.match.params.id, userid: userId, hints: `Query Name: ${queryName} <br/> Deviation Reason: ${this.state.deviationReason}` })
                        .then(res => res.data)
                        .then(result => { })
                        .catch(err => {
                            console.log(err);
                        });
                }
            }
        });
    }
    handleDeviationSubmit = (type) => {
        const { copyQueryData, queryData, deviationStatus, deviationReason }: any = this.state;
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        if (deviationReason != "" && deviationStatus != "") {
            let req = {
                "file_id": this.props.match.params.id,
                "query_ids": this.state.deviationTakenQuerys,
                "reason": this.state.deviationReason,
                "status": this.state.deviationStatus,
                "user_id": userId
            }
            callApi("POST", "deviation_taken.php", req)
                .then(res => res.data)
                .then(async response => {
                    if (response.statusCode == 200) {
                        this.getQueryTable();
                        if (response.deviation_count == 0) {
                            // setTimeout(() => {
                            //     this.props.history.push("/deviation-work-inprogress");
                            // }, 2000);
                            this.deviationFileLog(this.props.match.params.id, "completed");
                        } else {
                            this.deviationFileLog(this.props.match.params.id, "notcompleted");
                        }
                        let getQueryReason = await this.saveHint();
                        $("#deviationTakenModal").modal("hide");
                        this.setState({ deviationTakenQuerys: [], queryData: [], deviationReason: "", deviationStatus: "", selectedQueryData: [] });
                        this.getQueryTable();
                        ToastsStore.success(response.message);
                    } else {
                        ToastsStore.error(response.message);
                    }
                });
        } else {
            ToastsStore.error("Please type reason to proceed");
        }
    }
    deviationFileLog = (fileid, type) => {
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileid,
            reason: type == "notcompleted" ? "Deviation Taken" : "Deviation Completed",
            created_by: JSON.parse(loginuser),
            color: "#e9df9a"
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => { });
    }
    getFileDetail = () => {
        callApi("POST", "front_office_get.php", {
            id: this.props.match.params.fileid,
        })
            .then((res) => res.data)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.length &&
                    response.data[0].arr &&
                    response.data[0].arr.length
                ) {
                    const state = response.data[0].arr[0];
                    state.base = response.data[0].base
                        ? response.data[0].base.map((base) => {
                            return {
                                ...base,
                                isSavedFile: true,
                            };
                        })
                        : [];
                    this.setState({ documentBaseList: state.base });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    sendMailFromdeviaiton = () => {
        const { copyQueryData, selectedQueryData, deviationTakenQuerys, remote_cc_users, remote_users, base }: any = this.state;
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let req = {
            "fod_fk": this.props.match.params.id,
            "querydetail": selectedQueryData,
            "remote_cc_users": remote_cc_users,
            "remote_users": remote_users,
            "user_idfk": userId,
            "deviationTakenQuerys": deviationTakenQuerys,
            "base": base
        }
        callApi("POST", "deviationMailtoBank.php", req)
            .then(res => res.data)
            .then(response => {
                if (response.success == true) {
                    if (response.deviation_count == 0) {
                        // setTimeout(() => {
                        //     this.props.history.push("/deviation-work-inprogress");
                        // }, 2000);
                        this.deviationFileLog(this.props.match.params.id, "completed");
                    } else {
                        this.deviationFileLog(this.props.match.params.id, "notcompleted");
                    }
                    $("#deviation-mail").modal("hide");
                    this.setState({ deviationTakenQuerys: [], queryData: [], deviationReason: "", selectedQueryData: [] });
                    ToastsStore.success(response.message);
                    this.getQueryTable();
                } else {
                    ToastsStore.error(response.message);
                }
            });
    }
    getQueryTable = () => {
        let req = { fod_fk: this.props.match.params.id };
        callApi("POST", "query_table_test.php", req)
            .then(res => res.data)
            .then(async response => {
                if (response) {
                    const data = response.result;
                    const flatData: any = await this.flattenData(data);
                    const flattenedJson: FlattenedObject[] = await this.flattenObjects(flatData);
                    const parseData = JSON.parse(JSON.stringify(flattenedJson));
                    this.setState({ queryData: flattenedJson, copyQueryData: parseData }, () => { });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    flattenData = (data) => {
        const result: FlattenedObject[] = [];
        const flatten = (node) => {
            const { children, ...rest } = node;
            result.push({ ...rest, children: [] });
            if (children && children.length > 0) {
                children.forEach((child) => flatten(child));
            }
        };
        data.forEach((item) => flatten(item));
        return result;
    };
    flattenObjects(originalObjects: OriginalObject[]): FlattenedObject[] {
        const flattened: FlattenedObject[] = [];
        const flatten = (object: OriginalObject) => {
            if (object.status != "3" && object.deviation_status != "0") {
                flattened.push({
                    id: object.id,
                    name: object.name,
                    parent_id: object.parent_id,
                    title: object.title,
                    raice_by: object.raice_by,
                    to: object.to,
                    status: object.status,
                    reason: object.reason,
                    location: object.location,
                    created_date: object.created_date,
                    serial_no: object.serial_no,
                    d_request_reason: object.d_request_reason,
                    d_officer_reason: object.d_officer_reason,
                    deviation_status: object.deviation_status,
                });

                if (object.children && object.children.length > 0) {
                    object.children.forEach((child) => flatten(child));
                }
            }
        };
        originalObjects.forEach((obj) => flatten(obj));
        return flattened;
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleAnswerChange = (index, val) => {
        const queryQues: any = this.state.queryQues;
        queryQues[index].answer = val;
        this.setState({ queryQues });
    };
    saveQueryQuestion = () => {
        const { queryQues, oldQueryQues } = this.state;
        const user: any = sessionStorage.getItem("user");
        const obj = {
            fileNo: this.props.match.params.id,
            currentData: queryQues,
            oldData: oldQueryQues,
            loginId: user ? JSON.parse(user)["userId"] : "",
        };
        callApi("POST", "queryverification_checklist.php", obj)
            .then((res) => res.data)
            .then((response) => {
                this.setState({ quesSubmitted: true });
            })
            .catch((err) => { });
    };
    getUsers = () => {
        const remote_users: any = [];
        callApi("POST", "office_details.php", {
            id: this.props.match.params.id,
        })
            .then((res) => res.data)
            .then((response) => {
                response.data.map((user) => {
                    remote_users.push({ email: user.email, name: user.name });
                });
                this.setState({
                    office_users: response.data,
                    remote_users,
                    remote_cc_users: remote_users,
                });
            });
    };
    getQueryQuestion = () => {
        callApi("POST", "query_question_get.php", {
            id: this.props.match.params.id,
        })
            .then((res) => res.data)
            .then((response) => {
                this.setState({
                    queryQues: response.data,
                    oldQueryQues: JSON.parse(JSON.stringify(response.data)),
                });
            });
    };
    showQueryquestion = () => {
        const mailToBank: any = sessionStorage.getItem("mail-bank");
        const userDGrade: any = sessionStorage.getItem("user_d_grade");
        const fileDGrade: any = sessionStorage.getItem("file_d_grade");
        this.setState({ required: true });
        this.getUsers();
        this.getQueryQuestion();
    }
    selectUser = (e: any, user: any) => {
        let remote_users: any = this.state.remote_users;
        if (e.target.checked) {
            remote_users.push({ email: user.email, name: user.name });
        } else {
            remote_users = remote_users.filter((itm) => itm.email !== user.email);
        }
        this.setState({ remote_users });
    };
    selectCcUser = (e: any, user: any) => {
        let remote_users: any = this.state.remote_cc_users;
        if (e.target.checked) {
            remote_users.push({ email: user.email, name: user.name });
        } else {
            remote_users = remote_users.filter((itm) => itm.email !== user.email);
        }
        this.setState({ remote_cc_users: remote_users });
    };
    showDeviationHistory = () => {
        let obj = { file_id: this.props.match.params.id }
        callApi("POST", "get_deviation_history.php", obj)
            .then((res) => res.data)
            .then((response) => {
                if (response.responseJson?.data?.length > 0) {
                    this.setState({ deviationDetails: response.responseJson.data }, () => {
                        $("#deviation-view-history").modal("show");
                    });
                } else {
                    ToastsStore.error("No data found");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    noDeviation = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj = {
            user_id: userId,
            file_id: this.props.match.params.id,
        }
        callApi("POST", "deviation_no.php", obj)
            .then((res) => res.data)
            .then((response) => {
                if (response.result == true) {
                    $("#no-deviation").modal("hide");
                    ToastsStore.success(response.message);
                    setTimeout(() => {
                        this.props.history.push("/deviation-work-inprogress");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    deviationComplete = () => {
        const { file_deviation_Status } = this.state;
        let obj = {
            file_id: this.props.match.params.id,
            file_deviation_Status: file_deviation_Status
        }
        if (file_deviation_Status != '') {
            callApi("POST", "deviation_complete.php", obj)
                .then((res) => res.data)
                .then((response) => {
                    if (response.result == true) {
                        $("#deviation-confirm-complete").modal("hide");
                        ToastsStore.success(response.message);
                        setTimeout(() => {
                            this.props.history.push("/deviation-work-inprogress");
                        }, 1000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            ToastsStore.error("Please select status");
        }
    }

    render() {
        const { newaddrequery = () => { }, addrequery = () => { }, Completequery = () => { }, queryTableData = [] } = this.props;
        const { deviationStatus, deviationReason, isLoading, deviationTakenQuerys, queryData } = this.state;
        const user: any = sessionStorage.getItem("user");
        const roleType = user ? JSON.parse(user)["roleType"] : "";
        const currentfileId = sessionStorage.getItem("currentfileId");
        const takenfile_id = sessionStorage.getItem("takenfile_id");
        return (
            <div className="table-container query-table-container mt-4 mb-4">
                {currentfileId == takenfile_id && <>
                    {roleType == '16' && deviationTakenQuerys?.length > 0 && <>
                        <button data-toggle="modal"
                            onClick={() => this.showQueryquestion()}
                            data-target="#deviation-mail" className="ml2 btn btn-success btnupload float-right" style={{ margin: "auto" }}>Send Mail</button>
                    </>}
                    {roleType == '16' && deviationTakenQuerys?.length > 0 && <>
                        <button className="ml2 mr-2 btn btn-primary btnupload float-right" style={{ margin: "auto" }} data-toggle="modal"
                            data-target="#deviationTakenModal">Submit</button>
                    </>}

                    {roleType == '16' &&
                        <>
                            <button className="ml2 mr-2 btn btn-primary btnupload float-right" style={{ margin: "auto" }} data-toggle="modal"
                                data-target="#deviation-confirm-complete">Deviation Completed
                            </button>
                        </>
                    }
                    {roleType == '16' &&
                        <>
                            <button className="ml2 mr-2 btn btn-primary btnupload float-right" style={{ margin: "auto" }} data-toggle="modal"
                                data-target="#no-deviation">No Deviation
                            </button>
                        </>
                    }
                </>}
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">S.NO</th>
                            <th scope="col">Parent</th>
                            <th scope="col">Date</th>
                            <th scope="col">Query</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Location</th>
                            <th scope="col">Request Reason</th>
                            <th scope="col">DO Reason</th>
                            {/* <th scope="col" style={{ width: "150px" }}>Status</th> */}
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {queryData && queryData.length > 0 && queryData.map((queryData: any, index) => (
                            <tr className="pointer" style={queryData.deviation_status === "2" ? { background: "rgb(164, 217, 164)" } : (queryData.deviation_status === "1" ? { background: "#e09797" } : { background: "#e3e39b" })}>
                                <td className="indexInput text-center">
                                    {/* <input
                                        type="checkbox"
                                        value=""
                                        onChange={(event) => addrequery(event, queryData, index)}
                                    /> */}
                                    {roleType == '16' && !["2", "3"].includes(queryData.deviation_status) && <>
                                        <input
                                            type="checkbox"
                                            checked={queryData.isChecked === true}
                                            onChange={(event) => this.deviationTaken(event, queryData, index)}
                                        />
                                    </>}
                                </td>
                                <td className="indexInput text-center">{index + 1}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.serial_no}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.created_date}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.title}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.reason}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.location} </td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.d_request_reason}</td>
                                <td className="indexInput text-center" onClick={() => this.showDeviationHistory()}>{queryData.d_officer_reason}</td>
                                {/* <td className={`indexInput text-center`} >
                                    <span style={{ background: queryData.status == 3 ? "green" : "red", color: "white", padding: "5%", fontWeight: "bolder" }}>
                                        {queryData.status == "0" ? "Pending" : "Completed"}</span>
                                </td> */}
                                <td className="indexInput text-center">
                                    <div className="btn-group mb-2 mr-4 action-btn">
                                        {roleType == '16' && !["2", "3"].includes(queryData.deviation_status) && currentfileId == takenfile_id && <>
                                            <button
                                                className="btn btn-success"
                                                data-toggle="modal"
                                                data-target="#deviationTakenModal"
                                                onClick={() => this.deviationTakenQuery(queryData)}
                                            >
                                                Deviation Taken
                                            </button>
                                        </>}

                                        {/* <button type="button" className="btn btn-primary">
                                            Action
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <span className="sr-only">Toggle Dropdown</span>
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            x-placement="top-start"
                                            style={{
                                                position: "absolute",
                                                top: "0px",
                                                left: "0px",
                                                transform:
                                                    "translate3d(88px, -2px, 0px)"
                                            }}
                                        >
                                            {queryData.is_checked != true &&
                                                <button
                                                    style={{
                                                        border: "none",
                                                        background: "none",
                                                        display: "block",
                                                        margin: "auto",
                                                    }}
                                                    onClick={() => newaddrequery(queryData)}
                                                >
                                                    Requery
                                                </button>
                                            }
                                            <button
                                                style={{
                                                    border: "none",
                                                    background: "none",
                                                    display: "block",
                                                    margin: "auto",
                                                }}
                                                onClick={() => { queryData.status == 3 ? alert("Already completed") : Completequery(queryData) }}
                                            >
                                                Complete
                                            </button>
                                        </div> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div
                    className="modal fade"
                    id="deviationTakenModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="deviationLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5>
                                    Are you sure you want to take deviation?
                                </h5>
                            </div>
                            <div
                                className="modal-body"
                            >
                                <p style={{ textAlign: 'center', color: 'black' }}>Please Type a Reason To deviation taken for this query</p>
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <label>Status</label>
                                    <select
                                        className="custom-select"
                                        name="deviationStatus"
                                        onChange={this.handleChange}
                                        value={deviationStatus}
                                        required
                                        id="select1"
                                        style={{ marginTop: '5px' }}
                                    >
                                        <option value="select">Select</option>
                                        <option value="Positive">Positive</option>
                                        <option value="Negative">Negative</option>
                                        <option value="Unwanted">Unwanted</option>
                                    </select>
                                    {(isEmpty(deviationStatus)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please select status </p> : null}
                                    <label className="mt-3">Reason</label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        value={deviationReason}
                                        onChange={this.handleChange}
                                        name="deviationReason" />
                                    {(isEmpty(deviationReason)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please type your reason </p> : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => this.closeDeviationModal()}
                                >
                                    No
                                </button>
                                {deviationTakenQuerys?.length > 0 ? <>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.handleDeviationSubmit("multipleQuery")}
                                    >
                                        {isLoading ? (
                                            <i className="fa fa-spinner fa-spin"></i>
                                        ) : "Yes"}
                                    </button>
                                </> : <>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.handleDeviationSubmit("singleQuery")}
                                    >
                                        {isLoading ? (
                                            <i className="fa fa-spinner fa-spin"></i>
                                        ) : "Yes"}
                                    </button>
                                </>}

                            </div>
                        </div>
                    </div>
                </div>


                {/* send mail */}
                <div className="modal" id="deviation-mail">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Send Mail</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {!this.state.quesSubmitted && (
                                    <div className="query-question">
                                        <div className="row">
                                            {this.state.queryQues.map((ques: any, index) => (
                                                <div className="col-md-6" key={index}>
                                                    <div className="question">
                                                        <p>{ques.query_question}</p>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="radio"
                                                                        className=""
                                                                        name={`answer-${index}`}
                                                                        defaultChecked
                                                                        onChange={() =>
                                                                            this.handleAnswerChange(
                                                                                index,
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                    <label className="">Yes</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="radio"
                                                                        className=""
                                                                        name={`answer-${index}`}
                                                                        onChange={() =>
                                                                            this.handleAnswerChange(
                                                                                index,
                                                                                false
                                                                            )
                                                                        }
                                                                    />
                                                                    <label className="">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={this.saveQueryQuestion}
                                            >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {this.state.quesSubmitted && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>To:</label>
                                            <div className="form-group">
                                                {this.state.office_users &&
                                                    this.state.office_users.length ? (
                                                    this.state.office_users.map(
                                                        (user: any, index) => {
                                                            return (
                                                                <div
                                                                    className="custom-control custom-checkbox"
                                                                    key={index}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className=""
                                                                        onChange={(e) =>
                                                                            this.selectUser(e, user)
                                                                        }
                                                                        defaultChecked
                                                                    />
                                                                    <label className="">
                                                                        {user.name + " - " + user.email}
                                                                    </label>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <button
                                                        // onClick={this.addItem}
                                                        className="btn btn-primary m-b-20"
                                                    >
                                                        + Add User
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Cc:</label>
                                            <div className="form-group">
                                                {this.state.office_users &&
                                                    this.state.office_users.length ? (
                                                    this.state.office_users.map(
                                                        (user: any, index) => {
                                                            return (
                                                                <div
                                                                    className="custom-control custom-checkbox"
                                                                    key={index}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className=""
                                                                        onChange={(e) =>
                                                                            this.selectCcUser(e, user)
                                                                        }
                                                                        defaultChecked
                                                                    />
                                                                    <label className="">
                                                                        {user.name + " - " + user.email}
                                                                    </label>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <button
                                                        // onClick={this.addItem}
                                                        className="btn btn-primary m-b-20"
                                                    >
                                                        + Add User
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {this.state.quesSubmitted && (
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        // data-dismiss="modal"
                                        disabled={this.state.queryLoading}
                                        onClick={this.sendMailFromdeviaiton}
                                    >
                                        {this.state.queryLoading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <span>Send</span>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade bd-example-modal-lg"
                    id="deviation-view-history"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" style={{ maxWidth: "1400px", maxHeight: "660px", overflow: "hidden" }} role="document">
                        <div className="modal-content" style={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                            <div className="modal-header" style={{ backgroundColor: "#007bff", color: "white", borderBottom: "none", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                <h5 className="modal-title" style={{ textAlign: "center", color: "white" }}>Deviation Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ color: "white" }}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "8px", maxHeight: "670px", overflowY: "scroll" }}>
                                <div className="dt-responsive table-responsive">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead className="thead sticky-table-header" style={{ background: "#d9d9d9" }}>
                                            <tr style={{ fontSize: "14px" }}>
                                                <th scope="col" className="text-center th">S.no</th>
                                                <th scope="col" className="text-center th">Query</th>
                                                <th scope="col" className="text-center th">Location</th>
                                                <th scope="col" className="text-center th">Reason</th>
                                                <th scope="col" className="text-center th">Request Reason</th>
                                                <th scope="col" className="text-center th">Request By</th>
                                                <th scope="col" className="text-center th">Request Date</th>
                                                <th scope="col" className="text-center th">Deviation Reason</th>
                                                <th scope="col" className="text-center th">Deviation By</th>
                                                <th scope="col" className="text-center th">Deviation Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tbody">
                                            {this.state.deviationDetails && this.state.deviationDetails.map((data: any, index: any) => (
                                                <tr key={index}>
                                                    <td className="text-center td" style={{ width: "7%" }}>{index + 1}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.query}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.location}</td>
                                                    <td className="text-center td" style={{ width: "15%", padding: "1%" }}>{data.reason}</td>
                                                    <td className="text-center td" style={{ width: "15%", padding: "1%" }}>{data.req_reason}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.req_by}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.req_date}</td>
                                                    <td className="text-center td" style={{ width: "15%", padding: "1%" }}>{data.deviation_reason}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.deviation_by}</td>
                                                    <td className="text-center td" style={{ width: "10%" }}>{data.deviation_date}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ border: "none", backgroundColor: "#f8f9fa", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal"
                    id="deviation-confirm-complete"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body d-flex" style={{ justifyContent: "center" }}>
                                <h5 className="modal-title">Are You Sure You Want to Complete this File?</h5>
                            </div>
                            <div className="modal-footer">
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <label>Status</label>
                                    <select
                                        className="custom-select"
                                        name="file_deviation_Status"
                                        onChange={this.handleChange}
                                        required
                                        id="select1"
                                        style={{ marginTop: '5px' }}
                                    >
                                        <option value="">Select</option>
                                        <option value="Positive">Positive</option>
                                        <option value="Negative">Negative</option>
                                        <option value="Unwanted">Unwanted</option>
                                    </select>
                                    {(isEmpty(this.state.file_deviation_Status)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please select status </p> : null}
                                </div>
                                <div className="mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.deviationComplete()}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mt-1"
                                        data-dismiss="modal"
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal"
                    id="no-deviation"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body d-flex" style={{ justifyContent: "center" }}>
                                <h5 className="modal-title">Are You Sure There is No Deviation In this File?</h5>
                            </div>
                            <div className="modal-footer">
                                <div className="mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.noDeviation()}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mt-1"
                                        data-dismiss="modal"
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
