import moment from 'moment';
import * as React from "react";
import { callApi } from "../../utils/api";
interface AllProps {
    id: number;
    match: any;
    history: any
}
export default class VerifyHintAboutFile extends React.Component<AllProps> {
    state = {
        hints: [],
        userHintAboutFile: ''
    };
    file_id: any;
    componentDidMount() {
        this.getHints();
    }
    getHints = () => {
        callApi("POST", "hint_get.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(result => {
                this.setState({ hints: result.data })
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    saveHint = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi('POST', 'add_hints.php', { id: this.props.id, userid: userId, hints: this.state.userHintAboutFile })
            .then(res => res.data)
            .then(result => {
                this.getHints();
            })
            .catch(err => {
                console.log(err);
            });
    }
    previousPage() {
        this.props.history.goBack()
    }
    render() {
        return (
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="card latest-update-card">
                                        <div className="card-header row">
                                            <div className="col-md-11">
                                                <h5>Hints About Files</h5>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline"

                                                    onClick={() => this.previousPage()}
                                                    // onClick={this.disableModalHandler}
                                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                                > Back</button>
                                            </div>

                                        </div>
                                        <div className="" style={{ height: '320px', position: 'relative', overflow: 'auto' }}>
                                            <div className="card-body">
                                                <div className="latest-update-box">
                                                    {this.state.hints && this.state.hints.length > 0 && this.state.hints.map((hint: any, index: number) => (
                                                        <div className={index === 0 ? "row p-t-20 p-b-20" : "row p-b-20"}>
                                                            <div className="col-auto text-right update-meta">
                                                                <i className="b-primary update-icon ring"></i>
                                                            </div>
                                                            <div className="col p-l-5">
                                                                <h6 className="m-0">{hint.created_by}  <span style={{ fontSize: '12px' }}>{hint.created_date ? "(" + moment(hint.created_date).format('DD/MM/YYYY hh:mm:ss') + ")" : ''}</span></h6>
                                                                <p className="m-b-0" style={{ wordBreak: 'break-word' }}>{hint.hints}
                                                                    {/* <a href="#!" className="text-c-blue"> More</a> */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                                                <div className="ps__thumb-x" style={{ left: '0px', bottom: '0px' }}></div>
                                            </div><div className="ps__rail-y" style={{ top: '0px', height: '320px', right: '0px' }}>
                                                <div className="ps__thumb-y" style={{ top: '0px', height: '212px' }}></div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Add Hints</label>
                                                <textarea
                                                    placeholder=""
                                                    name="userHintAboutFile"
                                                    value={this.state.userHintAboutFile}
                                                    onChange={e => this.handleChange(e)}
                                                    className="form-control"
                                                    style={{ height: '25vh' }}
                                                    readOnly
                                                />
                                            </div>
                                            <button onClick={this.saveHint} className="btn btn-rounded btn-success">
                                                Save
                                            </button>
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}