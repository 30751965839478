import React from "react";
import { callApi } from "../../utils/api";
import { Measure } from "./ManageMeasurement";

interface AllProps {
  selectedMeasure: Measure;
  listMeasure: any;
}
class AddMeasurement extends React.Component<AllProps> {
  state = {
    measureDetail: { id: 0, name: "" }
  };
  componentWillReceiveProps(nextState) {
    this.setState({ measureDetail: nextState.selectedMeasure });
  }
  handleChange = (e: any) => {
    const measureDetail: any = this.state.measureDetail;
    measureDetail[e.target.name] = e.target.value;
    this.setState({ measureDetail });
  };
  saveMeasure = () => {
    const state = this.state;
    if (state.measureDetail.id) {
      state.measureDetail.id = Number(this.state.measureDetail.id);
    }
    callApi("POST", "measurement_master.php", state.measureDetail)
      .then(res => res.data)
      .then(response => {
        this.props.listMeasure();
      })
      .catch(err => {
        console.log(err);
      });
  };
  render() {
    return (
      <div
        className="modal fade"
        id="measure-form"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Measure Form</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="col-form-label">Measure Label:</label>{" "}
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Measure Label"
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    value={
                      this.state.measureDetail.name
                        ? this.state.measureDetail.name
                        : ""
                    }
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.saveMeasure}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddMeasurement;
