import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
interface ReportData {
    serial: number;
    id: number;
    file_name: string;
    bank: string,
    query: string,
    location: string,
    reason: string,
    req_by: string,
    req_reason: string,
    req_date: string,
    deviation_by: string,
    deviation_reason: string,
    deviation_date: string,
    lawhands_branch_name: string,
}
interface AllProps {
    reports: ReportData[];
    getBranch: any;
    redirectToDashboard?: any;
}
export default class DeviationReportTable extends Component<AllProps> {
    render() {
        const { reports = [], redirectToDashboard = () => { } } = this.props;
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return (
            <div className="">
                <div className="dt-responsive table-responsive" style={{ overflow: 'auto' }}>
                    <table style={{ overflowX: "scroll", width: "140%" }} id="processeodTable" className="table table-striped table-bordered nowrap">
                        <thead>
                            <tr>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Sl.No</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>File No</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>PO Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Bank Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>LH Branch Name</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>Total Pending Queries</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>2nd Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>3rd Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>4th Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>5th Status</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Deviation Taken By</th>
                                <th style={{ position: 'sticky', top: 0, background: 'white' }}>6th Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports && reports.length
                                ? reports.map(
                                    (query: any, index: number) => {
                                        return (
                                            <tr className="query-table-row cursor-pointer" key={index}>
                                                <td className="text-align-center">{index + 1}</td>
                                                <td className="click">
                                                    {query.file_name ? query.file_name : ""}
                                                </td>
                                                <td className="click">
                                                    {query.po_name}
                                                </td>
                                                <td className="click">
                                                    {query.bank}
                                                </td>
                                                <td className="click">
                                                    {query.lawhands_branch_name}
                                                </td>
                                                <td className="click" style={{ width: "25%" }}>
                                                    {query.pending_queries && query.pending_queries.length > 0 ? (query.pending_queries.map((list: any, index: any) => {
                                                        return (
                                                            <p key={index}>{index + 1 + "] "}{list.query}</p>
                                                        );
                                                    })) : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.first_deviation && query.first_deviation.length > 0 ? <>
                                                        <p>{query.first_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.first_deviation_status ? query.first_deviation_status : ""}
                                                </td>
                                                <td className="click">
                                                    {query.second_deviation && query.second_deviation.length > 0 ? <>
                                                        <p>{query.second_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.second_deviation_status ? query.second_deviation_status : ""}
                                                </td>
                                                <td className="click">
                                                    {query.third_deviation && query.third_deviation.length > 0 ? <>
                                                        <p>{query.third_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.third_deviation_status ? query.third_deviation_status : ""}
                                                </td>
                                                <td className="click">
                                                    {query.fourth_deviation && query.fourth_deviation.length > 0 ? <>
                                                        <p>{query.fourth_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.fourth_deviation_status ? query.fourth_deviation_status : ""}
                                                </td>
                                                <td className="click">
                                                    {query.fifth_deviation && query.fifth_deviation.length > 0 ? <>
                                                        <p>{query.fifth_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.fifth_deviation_status ? query.fifth_deviation_status : ""}
                                                </td>
                                                <td className="click">
                                                    {query.sixth_deviation && query.sixth_deviation.length > 0 ? <>
                                                        <p>{query.sixth_deviation[0].deviation_by}</p>
                                                    </> : (
                                                        <p>-</p>
                                                    )}
                                                </td>
                                                <td className="click">
                                                    {query.sixth_deviation_status ? query.sixth_deviation_status : ""}
                                                </td>
                                            </tr>
                                        );
                                    }
                                ) : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
