import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Verify } from './sopDataTemplate'

interface AllProps {
    data: Verify;
    handleVerifyChange: any;
}
export class Verification extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d'
    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (key, name, val) => {
        const { data } = this.props;
        console.log("KEY", key, name, val)
        data[key][name] = val;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleVerifyChange(data);
    }
    render() {
        const { data } = this.props;
        const {isOptionOpen, bgColor} = this.state;
        return (
            <div className="collapse-section verification-section">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="6" onClick={()=>{this.setState({isOptionOpen:!isOptionOpen})}}>
                            <h5>
                                <b>Verification</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus':'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold',float:'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body>
                                <div>
                                    <h6><b>Company</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Board Resolution</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="boardResolution"
                                                    type="radio"
                                                    checked={data.company.boardResolution}
                                                    onChange={() => this.handleChange("company", "boardResolution", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="boardResolution"
                                                    type="radio"
                                                    checked={!data.company.boardResolution}
                                                    onChange={() => this.handleChange("company", "boardResolution", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>All Directors Signed?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="allDirectorsSignedIn"
                                                    type="radio"
                                                    checked={data.company.allDirectorsSignedIn}
                                                    onChange={() => this.handleChange("company", "allDirectorsSignedIn", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="allDirectorsSignedIn"
                                                    type="radio"
                                                    checked={!data.company.allDirectorsSignedIn}
                                                    onChange={() => this.handleChange("company", "allDirectorsSignedIn", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>MOA &amp; AOA</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="MoaAndAoa"
                                                    type="radio"
                                                    checked={data.company.MoaAndAoa}
                                                    onChange={() => this.handleChange("company", "MoaAndAoa", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="MoaAndAoa"
                                                    type="radio"
                                                    checked={!data.company.MoaAndAoa}
                                                    onChange={() => this.handleChange("company", "MoaAndAoa", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Cert Of Incorporation</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="certOfInc"
                                                    type="radio"
                                                    checked={data.company.certOfInc}
                                                    onChange={() => this.handleChange("company", "certOfInc", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="certOfInc"
                                                    type="radio"
                                                    checked={!data.company.certOfInc}
                                                    onChange={() => this.handleChange("company", "certOfInc", true)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Trust</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Deed</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isDeed"
                                                    type="radio"
                                                    checked={data.trust.isDeed}
                                                    onChange={() => this.handleChange("trust", "isDeed", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isDeed"
                                                    type="radio"
                                                    checked={!data.trust.isDeed}
                                                    onChange={() => this.handleChange("trust", "isDeed", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Supplement</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isSupplement"
                                                    type="radio"
                                                    checked={data.trust.isSupplement}
                                                    onChange={() => this.handleChange("trust", "isSupplement", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isSupplement"
                                                    type="radio"
                                                    checked={!data.trust.isSupplement}
                                                    onChange={() => this.handleChange("trust", "isSupplement", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className='label' style={{ padding: 0,marginTop:'10px' }}>Resolution All Signed?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isResolutionSigned"
                                                    type="radio"
                                                    checked={data.trust.isResolutionSigned}
                                                    onChange={() => this.handleChange("trust", "isResolutionSigned", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isResolutionSigned"
                                                    type="radio"
                                                    checked={!data.trust.isResolutionSigned}
                                                    onChange={() => this.handleChange("trust", "isResolutionSigned", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Affidavit of Signed</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isAffidavitSigned"
                                                    type="radio"
                                                    checked={data.trust.isAffidavitSigned}
                                                    onChange={() => this.handleChange("trust", "isAffidavitSigned", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isAffidavitSigned"
                                                    type="radio"
                                                    checked={!data.trust.isAffidavitSigned}
                                                    onChange={() => this.handleChange("trust", "isAffidavitSigned", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Partnership</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-3 mb-10">
                                            <label className="label" style={{ padding: 0 }}>Deed</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isPartnershipDeed"
                                                    type="radio"
                                                    checked={data.partnership.isPartnershipDeed}
                                                    onChange={() => this.handleChange("partnership", "isPartnershipDeed", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isPartnershipDeed"
                                                    type="radio"
                                                    checked={!data.partnership.isPartnershipDeed}
                                                    onChange={() => this.handleChange("partnership", "isPartnershipDeed", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="label" style={{ padding: 0 }}>Form C</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isFormC"
                                                    type="radio"
                                                    checked={data.partnership.isFormC}
                                                    onChange={() => this.handleChange("partnership", "isFormC", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isFormC"
                                                    type="radio"
                                                    checked={!data.partnership.isFormC}
                                                    onChange={() => this.handleChange("partnership", "isFormC", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="label" style={{ padding: 0 }}>Retire Partner</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isRetirePartner"
                                                    type="radio"
                                                    checked={data.partnership.isRetirePartner}
                                                    onChange={() => this.handleChange("partnership", "isRetirePartner", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isRetirePartner"
                                                    type="radio"
                                                    checked={!data.partnership.isRetirePartner}
                                                    onChange={() => this.handleChange("partnership", "isRetirePartner", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="label" style={{ padding: 0 }}>Add of Partners</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="addOfPartner"
                                                    type="radio"
                                                    checked={data.partnership.addOfPartner}
                                                    onChange={() => this.handleChange("partnership", "addOfPartner", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="addOfPartner"
                                                    type="radio"
                                                    checked={!data.partnership.addOfPartner}
                                                    onChange={() => this.handleChange("partnership", "addOfPartner", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="label" style={{ padding: 0 }}>Authorisation Letter Obtain From All Partners to Mortage?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="letterObtained"
                                                    type="radio"
                                                    checked={data.partnership.letterObtained}
                                                    onChange={() => this.handleChange("partnership", "letterObtained", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="letterObtained"
                                                    type="radio"
                                                    checked={!data.partnership.letterObtained}
                                                    onChange={() => this.handleChange("partnership", "letterObtained", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Borrowing Power &amp; Execution By Single Partner. is it in Deed?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="singlePartnerInDeed"
                                                    type="radio"
                                                    checked={data.partnership.singlePartnerInDeed}
                                                    onChange={() => this.handleChange("partnership", "singlePartnerInDeed", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="singlePartnerInDeed"
                                                    type="radio"
                                                    checked={!data.partnership.singlePartnerInDeed}
                                                    onChange={() => this.handleChange("partnership", "singlePartnerInDeed", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Association</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Association By Law</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isAssociationLaw"
                                                    type="radio"
                                                    checked={data.association.isAssociationLaw}
                                                    onChange={() => this.handleChange("association", "isAssociationLaw", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isAssociationLaw"
                                                    type="radio"
                                                    checked={!data.association.isAssociationLaw}
                                                    onChange={() => this.handleChange("association", "isAssociationLaw", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Resolution</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isResolution"
                                                    type="radio"
                                                    checked={data.association.isResolution}
                                                    onChange={() => this.handleChange("association", "isResolution", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isResolution"
                                                    type="radio"
                                                    checked={!data.association.isResolution}
                                                    onChange={() => this.handleChange("association", "isResolution", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Power to Sign Of Execution</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="powerToSignExecution"
                                                    type="radio"
                                                    checked={data.association.powerToSignExecution}
                                                    onChange={() => this.handleChange("association", "powerToSignExecution", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="powerToSignExecution"
                                                    type="radio"
                                                    checked={!data.association.powerToSignExecution}
                                                    onChange={() => this.handleChange("association", "powerToSignExecution", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Society</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>MOA</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isMOA"
                                                    type="radio"
                                                    checked={data.society.isMOA}
                                                    onChange={() => this.handleChange("society", "isMOA", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isMOA"
                                                    type="radio"
                                                    checked={!data.society.isMOA}
                                                    onChange={() => this.handleChange("society", "isMOA", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Registration</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isSocietyRegistered"
                                                    type="radio"
                                                    checked={data.society.isSocietyRegistered}
                                                    onChange={() => this.handleChange("society", "isSocietyRegistered", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isSocietyRegistered"
                                                    type="radio"
                                                    checked={!data.society.isSocietyRegistered}
                                                    onChange={() => this.handleChange("society", "isSocietyRegistered", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Resolution All Signed?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isResolutionSocietySigned"
                                                    type="radio"
                                                    checked={data.society.isResolutionSocietySigned}
                                                    onChange={() => this.handleChange("society", "isResolutionSocietySigned", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isResolutionSocietySigned"
                                                    type="radio"
                                                    checked={!data.society.isResolutionSocietySigned}
                                                    onChange={() => this.handleChange("society", "isResolutionSocietySigned", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Appartment</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>UDS</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isUDS"
                                                    type="radio"
                                                    checked={data.appartment.isUDS}
                                                    onChange={() => this.handleChange("appartment", "isUDS", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isUDS"
                                                    type="radio"
                                                    checked={!data.appartment.isUDS}
                                                    onChange={() => this.handleChange("appartment", "isUDS", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Building Floor</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isBuildingFloor"
                                                    type="radio"
                                                    checked={data.appartment.isBuildingFloor}
                                                    onChange={() => this.handleChange("appartment", "isBuildingFloor", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isBuildingFloor"
                                                    type="radio"
                                                    checked={!data.appartment.isBuildingFloor}
                                                    onChange={() => this.handleChange("appartment", "isBuildingFloor", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Extent(Building)</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isExtent"
                                                    type="radio"
                                                    checked={data.appartment.isExtent}
                                                    onChange={() => this.handleChange("appartment", "isExtent", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isExtent"
                                                    type="radio"
                                                    checked={!data.appartment.isExtent}
                                                    onChange={() => this.handleChange("appartment", "isExtent", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default Verification
