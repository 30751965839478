const DataModel = {
    "generalVerification": {
        "isSpellingCorrect": false,
        "ownerShip": "Individual",
        "file": {
            "btTransfer": false,
            "modAvailable": false,
            "purchase": false,
            "saleAgreement": false,
            "selfConstruction": false,
            "proceedingApproval": false,
            "new": false
        },
        "bgColor": '#ffff8d'
    },
    "surveyNoVerification": {
        "register": [{
            "surveyNo": "",
            "tslrNo": "",
            "extent": "",
            "name": ""
        }],
        "titleDeed": [{
            "surveyNo": "",
            "tslrNo": "",
            "extent": "",
            "name": ""
        }],
        "currentPatta": [{
            "surveyNo": "",
            "tslrNo": "",
            "extent": "",
            "name": ""
        }],
        "totalTitleDeed": "",
        "totalEc": "",
        "difference": "",
        "missingEc": "",
        "type": false,
        "conditionPatta": false,
        "bgColor": '#ffff8d'
    },
    "deed": {
        "saleDeed": [{
            "saleDeedAmountTransfered": false,
        }],
        "giftDeed": [{
            "derivedBy": "",
            "registered": false,
            "condition": false,
            "conditionFulfilled": false,
            "mentionCondition": ""
        }],
        "settlementDeed": [{
            "derivedBy": "",
            "registered": false,
            "condition": false,
            "conditionFulfilled": false,
            "mentionCondition": ""
        }],
        "partitionDeed": [{
            "derivedBy": "",
            "registered": false,
            "condition": false,
            "conditionFulfilled": false,
            "mentionCondition": "",
            "docAllotedForMortgage": "",
            "originalCopyAvailable": ""
        }],
        "willDeed": [{
            "derivedBy": "",
            "registered": false,
            "condition": false,
            "conditionFulfilled": false,
            "mentionCondition": ""
        }],
        "leaseDeed": [{
            "leasePeriodFromDate": "",
            "leasePeriodToDate": "",
            "nocAvailable": false
        }],
        "attorneyDeed": [{
            "derivedBy": "",
            "registered": false,
            "isInForce": false,
            "isCertifiedCopyAvailable": false
        }],
        "releaseDeed": [{
            "derivedBy": "",
            "registered": false,
            "condition": false,
            "fulFilled": false,
            "propertyshare": "",
            "mentionCondition": "",
        }],
        "rectificationDeed": [{
            "derivedBy": "",
            "registered": false,
            "mentionDetails": "",
            "correctDetails": "",
        }],
        "consentDeed": [{
            "derivedBy": "",
            "registered": false,
            "reason": "",
        }],
        "plotRegularization": {
            "isDeedRegisteredAfter30_9_2016": false,
            "isPlotObtained": false
        },
        "foriegnPower": {
            "isForiegnPowerRegistered": false,
            "docNo": ""
        },
        "mortgageInPowerDoc": false,
        "bgColor": '#ffff8d'
    },
    "legalHeirsVerify": {
        "legalHeirs": [{
            "legalHeirsWithin13": false,
            "docNoAndYear": "",
            "nextDeedDocNoAndYear": "",
            "legalHeirsObtained": "",
            "geanology": "",
        }],
        "bgColor": '#ffff8d'
    },
    "HsdpattasVerify": {
        "Hsdpattas": [{
            "roadApproachs": false,
            "certificate": false,
            "nocforTahsildar": false,
            "conditionFullfilled": false,
            "mentionCondition": ""
        }],
        "bgColor": '#ffff8d'
    },
    "nathamPattaverify": {
        "nathamPatta": [{
            "specialTahsildar": false,
            "boundaryCertificate": false,
            "roadApproach": false,
        }],
        "bgColor": '#ffff8d'
    },
    "ecVerify": {
        "ecentry": [{
            "noOfEcEntries": "",
            "relatedEcEntries": "",
            "notRelatedVerified": false,
            "onlineEcVerified": false,
            "isCourtAttachment": false,
            "isAttachmentCanceled": false,
            "leaseInEc": false,
            "leaseCancellation": false,
            "isMortgageInEc": false,
            "isReceiptInEc": false,
            "isAgreementInEc": false,
            "isAgreementCanceled": false,
            "largerExtent": "",
            "nonRelatedSNo": "",
            "differentPlotNo": false,
        }],
        // "noOfEcEntries": "",
        // "relatedEcEntries": "",
        // "notRelatedVerified": false,
        // "onlineEcVerified": false,
        // "isCourtAttachment": false,
        // "isAttachmentCanceled": false,
        // "leaseInEc": false,
        // "leaseCancellation": false,
        // "isMortgageInEc": false,
        // "isReceiptInEc": false,
        // "isAgreementInEc": false,
        // "isAgreementCanceled": false,
        // "largerExtent": "",
        // "nonRelatedSNo": "",
        // "differentPlotNo": false,
        "bgColor": '#ffff8d'
    },
    "missingLOD": {
        "sheets": [{
            "fileNo": "",
            "fileName": "",
            "totalSheets": "",
            "availableSheets": "",
            "missingSheets": "",
            "isCertificateAttached": false
        }],
        "isNameChanged": false,
        "docList": "",
        "totalSheets": "",
        "availableSheets": "",
        "missingSheetDetails": "",
        "isCopyAttached": false,
        "bgColor": '#ffff8d'
    },
    "verification": {
        "company": {
            "boardResolution": false,
            "allDirectorsSignedIn": false,
            "MoaAndAoa": false,
            "certOfInc": false
        },
        "trust": {
            "isDeed": false,
            "isSupplement": false,
            "isResolutionSigned": false,
            "isAffidavitSigned": false
        },
        "partnership": {
            "isPartnershipDeed": false,
            "isFormC": false,
            "isRetirePartner": false,
            "addOfPartner": false,
            "letterObtained": false,
            "singlePartnerInDeed": false
        },
        "association": {
            "isAssociationLaw": false,
            "isResolution": false,
            "powerToSignExecution": false
        },
        "society": {
            "isMOA": false,
            "isSocietyRegistered": false,
            "isResolutionSocietySigned": false
        },
        "appartment": {
            "isUDS": false,
            "isBuildingFloor": false,
            "isExtent": false
        },
        "bgColor": '#ffff8d'
    },
    "courtDocVerification": {
        "partitionSuit": {
            "isFinalDecree": false,
            "isPreliminaryDecree": false,
            "isCompormiseDecree": false,
            "isExpartyDecree": false
        },
        "declarationSuit": {
            "isIAAvailable": false,
            "isContest": false,
            "isCompormiseDecree": false,
            "isExpartyDecree": false
        },
        "lokAdalat": {
            "oldCourtName": '',
            "caseNo": '',
            "lokAdalatCaseNo": ''
        },
        "specificSuit": {
            "agreementHolderName": "",
            "osNo": "",
            "epNo": ""
        },
        "appeal": {
            "lowerCourtName": "",
            "lowerCourtcaseNo": "",
            "appealCourtName": "",
            "appealCaseNo": "",
            "isLowerCourt": false,
            "lowerCourtReverse": "",
            "alter": ""
        },
        "courtAuctionPurchase": {
            "osNo": "",
            "epNo": "",
            "bidderName": "",
            "propertyHolderName": "",
            "isBankMortageInvolved": false,
            "isMotherAvailable": false,
            "isSalesAvailable": false
        },
        "sarfesiProceeding": {
            "possessionDate": "",
            "symbolicPossession": "",
            "borrowerNotice": "",
            "auctionDate": "",
            "isPaid15Percent": false,
            "isSalesCertificate": false,
            "isRegistered": false
        },
        "bgColor": '#ffff8d'
    },
    "remarks": "",
    "isVerified": false,
    "fileNo": ""
}



const DataModelKeyMapping = {
    "generalVerification": "General Verification",
    "isSpellingCorrect": "Is The Spelling for Mr./Mrs./Sri Mathi/Minor/Late Correct?",
    "ownerShip": "Ownership",
    "Individual": "",
    "file": "File",
    "btTransfer": "BT Transfer",
    "modAvailable": "MOD Available",
    "purchase": "Purchase",
    "saleAgreement": "Sale Agreement",
    "selfConstruction": "Self Construction",
    "proceedingApproval": "Proceeding of Building Approval & Approved Plan",
    "new": "Fresh/ Normal",
    "surveyNoVerification": "Survey No Verification",
    "register": "A Register",
    "surveyNo": "Survey No",
    "tslrNo": "TSLR No",
    "extent": "Extent",
    "name": "Name",
    "titleDeed": "Title Deed",
    "currentPatta": "Current Patta",
    "totalTitleDeed": "Total Survey No In Title Deed",
    "totalEc": "Total Survey No In EC",
    "difference": "Diffrence",
    "missingEc": "Missing Survey No In EC",
    "type": "Land Type",
    "conditionPatta": "Condition Patta",
    "deed": "Deed",
    "saleDeed": "Sale Deed",
    "saleDeedAmountTransfered": "Amount Fully Transfered?",
    "giftDeed": "Gift Deed",
    "legalHeirs": "LegalHeirs",
    "ecentry": "ecEntry",
    "derivedBy": "Executant Derived by",
    "registered": "Registered?",
    "condition": "condition?",
    "conditionFulfilled": "condition Fullfilled?",
    "mentionCondition": "Mention Condition",
    "settlementDeed": "Settlement Deed",
    "partitionDeed": "Partition Deed",
    "docAllotedForMortgage": "Document alloted for Mortage",
    "originalCopyAvailable": "Original/ simultaneous copy available?",
    "willDeed": "Will Deed",
    "leaseDeed": "Lease Deed",
    "leasePeriodFromDate": "Lease Period From Date",
    "leasePeriodToDate": "Lease Period To Date",
    "nocAvailable": "NOC Available?",
    "attorneyDeed": "General Power Of Attorney Deed",
    "releasedeed": "Partition Release Deed",
    "rectificationDeed": "Rectification Deed",
    "consentDeed": "Consent Deed",
    "isInForce": "Is in force?",
    "isCertifiedCopyAvailable": "Is certified copy available?",
    "plotRegularization": "Plot Regularization",
    "isDeedRegisteredAfter30_9_2016": "Is Title Deed Registered After 30-09-2016?",
    "isPlotObtained": "Is Any Of the Following Obtained?1. Plot Regularization, 2. DCTP Approval, 3. Building Approval",
    "foriegnPower": "Foriegn Power",
    "isForiegnPowerRegistered": "Is The Foriegn Power Registered?",
    "docNo": "Doc No",
    "mortgageInPowerDoc": "Power To Mortgage",
    "legalHeirsVerify": "Legal Heirs Verification",
    "legalHeirsWithin13": "Does Legal Hiers Come Within 13 Years Tracing Period From Till Date?",
    "docNoAndYear": "Legal Heirs Doc No & Year",
    "nextDeedDocNoAndYear": "Next Deed's(After Died) Doc No & Year",
    "legalHeirsObtained": "Legal Heirs Obtained From",
    "geanology": "Geanology",
    "ecVerify": "EC Verification",
    "noOfEcEntries": "No Of EC Entries",
    "relatedEcEntries": "No Of Not Related EC Entries",
    "notRelatedVerified": "Verified Not Related EC?",
    "onlineEcVerified": "Online EC Verified?",
    "isCourtAttachment": "Court Attachment",
    "isAttachmentCanceled": "Attachment Cancelled?",
    "leaseInEc": "Lease In EC",
    "leaseCancellation": "Lease Cancellation",
    "isMortgageInEc": "Mortgage In EC",
    "isReceiptInEc": "Receipt In EC",
    "isAgreementInEc": "Agreement In EC",
    "isAgreementCanceled": "Agreement Canceled?",
    "largerExtent": "Larger Extent",
    "nonRelatedSNo": "Not Related S.No",
    "differentPlotNo": "Different Plot No",
    "missingLOD": "Missing Sheets In LOD",
    "sheets": "Sheets",
    "fileNo": "File No",
    "fileName": "File Name",
    "totalSheets": "Total Sheets",
    "availableSheets": "Available Sheets",
    "missingSheets": "Details of Sheets",
    "isCertificateAttached": "Is Certificate Copy Attached?",
    "isNameChanged": "Name Change In Document?",
    "docList": "Name Changed Document(s) List",
    "missingSheetDetails": "Details of Sheets",
    "isCopyAttached": "Is Certificate Copy Attached?",
    "verification": "Verification",
    "company": "Company",
    "boardResolution": "Board Resolution",
    "allDirectorsSignedIn": "All Directors Signed?",
    "MoaAndAoa": "MOA & AOA",
    "certOfInc": "Cert Of Incorporation",
    "trust": "Trust",
    "isDeed": "Deed",
    "isSupplement": "Supplement",
    "isResolutionSigned": "Resolution All Signed?",
    "isAffidavitSigned": "Affidavit of Signed",
    "partnership": "Partnership",
    "isPartnershipDeed": "Deed",
    "isFormC": "Form C",
    "isRetirePartner": "Add of Partners",
    "addOfPartner": "Add of Partners",
    "letterObtained": "Authorisation Letter Obtain From All Partners to Mortage?",
    "singlePartnerInDeed": "Borrowing Power & Execution By Single Partner. is it in Deed?",
    "association": "Association",
    "isAssociationLaw": "Association By Law",
    "isResolution": "Resolution",
    "powerToSignExecution": "Power to Sign Of Execution",
    "society": "Society",
    "isMOA": "MOA",
    "isSocietyRegistered": "Registration",
    "isResolutionSocietySigned": "Resolution All Signed?",
    "appartment": "Appartment",
    "isUDS": "UDS",
    "isBuildingFloor": "Building Floor",
    "isExtent": "Extent(Building)",
    "courtDocVerification": "Court Document Verification",
    "partitionSuit": "Partition Suit",
    "isFinalDecree": "Final Decree",
    "isPreliminaryDecree": "Preliminary Decree",
    "isCompormiseDecree": "Compromise Decree",
    "isExpartyDecree": "Expert decree",
    "declarationSuit": "Declaration Suit/ Permanent (or) Temporary Injection",
    "isIAAvailable": "IA No Available?",
    "isContest": "Contest",
    "lokAdalat": "Lok Adalat",
    "oldCourtName": 'Old Court Name',
    "caseNo": 'Case No',
    "lokAdalatCaseNo": 'Lok Adalat Case No',
    "specificSuit": "Specific Performance/ Suit",
    "agreementHolderName": "Agreement Holder Name",
    "osNo": "OS No",
    "epNo": "EP No",
    "appeal": "Appeal",
    "lowerCourtName": "Lower Court Name",
    "lowerCourtcaseNo": "Case No",
    "appealCourtName": "Appeal Court Name",
    "appealCaseNo": "Case No",
    "isLowerCourt": "In Appeal Verdict, is It Lower Court Confirm?",
    "lowerCourtReverse": "Lower Court Reverse",
    "alter": "Alter",
    "courtAuctionPurchase": "Purchase From Court Auction",
    "bidderName": "Successfull Bidder Name",
    "propertyHolderName": "Property Holder Name",
    "isBankMortageInvolved": "Bank Mortage Involved?",
    "isMotherAvailable": "Mother/Title Available?",
    "isSalesAvailable": "Sales Certificate Available?",
    "sarfesiProceeding": "SARFESI Proceeding",
    "possessionDate": "Possession Notice Date",
    "symbolicPossession": "Symbolic Possession",
    "borrowerNotice": "Notice to the Borrower",
    "auctionDate": "Date Of Auction",
    "isPaid15Percent": "Bidder Paid 15%?",
    "isSalesCertificate": "Sales Certificate?",
    "isRegistered": "Registered",
    "remarks": "Remarks (if Any)/Any Error Found?",
    "isVerified": "Verified",
}
export default DataModel;
export {
    DataModelKeyMapping
}