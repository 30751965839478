import * as React from "react";
import { isEmpty } from 'lodash'
import { callApi } from "../../utils/api";
import { ADMIN_ROLE } from "../../utils/role";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import AddPolicy from './AddPolicy';

class ManagePolicy extends React.Component {
  state = {
    policyList: [],
    isAddEditPolicy: false,
    selectedPolicy: {},
    policyToDelete: {}
  };

  componentDidMount() {
    this.getPolicyList();
  }

  getPolicyList = () => {
    callApi("GET", "policy_get.php")
      .then(res => res.data)
      .then(response => {
        let policyList = response.data;
        policyList = policyList.map((list, index) => {
          const { policy } = list;
          return { policy, index: index + 1, policy_id: list.id }
        })
        this.setState({ policyList });
      })
      .catch(err => {
        console.log(err);
      });
  };

  addPolicy = () => {
    this.setState({ isAddEditPolicy: true });
  };

  editPolicy = (policy) => {
    this.setState({ selectedPolicy: policy, isAddEditPolicy: true });
  };

  confirmDeletePolicy = (policy) => {
    this.setState({ policyToDelete: policy.policy_id });
  };

  deletePolicy = () => {
    callApi("POST", "policy_delete.php", { id: this.state.policyToDelete })
      .then(res => res.data)
      .then(response => {
        this.getPolicyList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleBack = () => {
    this.getPolicyList();
    this.setState({ isAddEditPolicy: false, selectedPolicy: {} })
  }

  render() {
    if (this.state.isAddEditPolicy) {
      return <AddPolicy policy={this.state.selectedPolicy} onBack={this.handleBack} isEdit={!isEmpty(this.state.selectedPolicy)} />
    }
    const user = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const columns = [
      {
        header: 'S.No',
        key: 'index',
      },
      // {
      //   header: 'SRO ID',
      //   key: 'id',
      // },
      {
        header: 'Policy Name',
        key: 'policy',
      },
      {
        header: "Actions",
        td: (policy) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              <button
                className="dropdown-item"
                data-toggle="modal"
                data-target="#policy-form"
                onClick={() =>
                  this.editPolicy(policy)
                }
              >
                Edit
              </button>
              {role_id === ADMIN_ROLE && (
                <button
                  className="dropdown-item"
                  style={{ float: "right" }}
                  data-toggle="modal"
                  data-target="#delete-policy"
                  onClick={() =>
                    this.confirmDeletePolicy(policy)
                  }
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        }
      }
    ];
    const data = this.state.policyList;
    return (
      <section className="policymain-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-header policy-header">
                      <h5>Policy Manage</h5>
                      <button
                        className="btn btn-primary"
                        style={{ float: "right" }}
                        onClick={() => this.props.history.goBack()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="dt-responsive table-responsive">
                        <button
                          className="btn btn-primary m-b-20"
                          data-toggle="modal"
                          data-target="#policy-form"
                          onClick={this.addPolicy}
                        >
                          + Add Policy
                        </button>
                        <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete-policy"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Policy</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to delete this policy?</h6>
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  {/* <textarea
                    name="reason"
                    className="form-control"
                    onChange={e => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  /> */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deletePolicy}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ManagePolicy;
