import React, { Component } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import { DocList } from "./DocumentList";

interface AllProps {
  id: any;
  doclist: any;
}
export default class GeneralDetail extends Component<AllProps> {
  state = {
    docList: [],
    docStatus: 0
  };
  componentDidMount() {
    this.setState({ docList: this.props.doclist })
    this.getRevenueDocs();
  }

  getRevenueDocs = () => {
    callApi("POST", "rev_list_get.php", { id: this.props.id })
      .then(res => res.data)
      .then(response => {
        let docList: DocList[] = this.state.docList;
        if (response && response.data && response.data.length) {
          docList = response.data;
        }
        this.setState({ docList });
      })
      .catch(err => {
        console.log(err);
      });
  };
  render() {
    return (
      <div id="step-2">
        <h5>General Detail</h5>
        <hr />
        <div className="card-body">
          <div className="row">
            {this.state.docList.length
              ? this.state.docList.map((itm: DocList, index) => {
                return (
                  <a
                    data-toggle="tooltip"
                    title=""
                    className="col-md-4"
                    data-original-title="Sale deed is missing"
                  >
                    <div
                      className={
                        itm.inactive === 1 ? "card card-border-c-yellow" :
                          itm.submit === 2
                            ? "card card-border-c-green"
                            : "card card-border-c-red"
                      }
                    >
                      <i className="complete" />
                      <div className="card-body">
                        <Link
                          to={
                            "/general-detail/1/" +
                            this.props.id +
                            "/" +
                            itm.file_id
                          }
                        >
                          <h5 className="card-title">
                            <p>General Detail Form - {index + 1}</p>
                          </h5>
                          {itm.inactive === 1 && <span className="mb-1 text-c-yellow" style={{ fontSize: '32px', fontWeight: 900 }}>NOT RELATED</span>}
                          <span>{itm.remark}</span>
                        </Link>
                      </div>
                    </div>
                  </a>
                );
              })
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
