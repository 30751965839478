import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import Select from 'react-select';
import moment from 'moment';
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";

export class NotificationList extends Component {
    state = {
        notications: [],
        user: {
            role_id: ""
        },
        filteredNotifications: [],
        searchText: ""
    }
    componentDidMount() {
        this.getComplaint();
        const user: any = sessionStorage.getItem("user");
        this.setState({ user: user ? JSON.parse(user)["userId"] : "" })
    }
    getComplaint = () => {
        callApi("POST", "notice_board_list.php")
            .then((res: any) => res.data)
            .then((res: any) => res.responseJson)
            .then(response => {
                this.setState({
                    notications: response.data,
                    filteredNotifications: response.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleSearchChange = (e) => {
        const { notications } = this.state;
        const LowerCaseValue = e.target.value.toLowerCase();
        const toLC = (value) => value.toLowerCase();
        const list = notications.filter((item: any) => {
            return toLC(item.title).includes(LowerCaseValue) ||
                toLC(item.information).includes(LowerCaseValue);
        });
        this.setState({
            searchText: e.target.value,
            filteredNotifications: list
        });
    }
    render() {
        const { notications, user, filteredNotifications, searchText } = this.state;
        filteredNotifications.forEach((notification:any, index) => {notification.serial = index + 1})
        const columns = [
            {
                header: 'S.no',
                key: 'serial',
                td: (item) => <span>{item.serial}</span>
            },
            {
                header: 'Title',
                key: 'ticketId',
                td: (item) => <span>{item.title}</span>
            },
            {
                header: 'Notice',
                key: 'fileNo',
                td: (item) => <span >{item.information}</span>
            },
        ];
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Notice List</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row justify-content-end">
                                                        <div className="col-sm-3">
                                                            <input type="text" className="form-control mb-10"
                                                                name="search" placeholder="search"
                                                                value={searchText}
                                                                onChange={this.handleSearchChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="dt-responsive table-responsive">
                                                            {filteredNotifications && filteredNotifications.length === 0 &&
                                                                <p className="text-center">No Notifications Found</p>
                                                            }
                                                            {filteredNotifications && filteredNotifications.length > 0 && 
                                                                <ReactFlexyTable className="assigned-table" data={filteredNotifications} columns={columns} sortable pageSize={10} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default NotificationList
