import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { CourtDocVerification } from './sopDataTemplate';

interface AllProps {
    data: CourtDocVerification;
    handleCourtVerifyChange: any;
}
export class CourtDocVerify extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d'

    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleInputChange = (key, name, val) => {
        const { data } = this.props;
        data[key][name] = val;
        this.changeBgColor()
        data.bgColor = 'lightgreen';
        this.props.handleCourtVerifyChange(data);
    }
    render() {
        const { data } = this.props;
        const {isOptionOpen, bgColor} = this.state;
        return (
            <div className="collapse-section count-doc-verify">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="7" onClick={()=>{this.setState({isOptionOpen:!isOptionOpen})}}>
                            <h5>
                                <b>Court Document Verification</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus':'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold',float:'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                            <Card.Body>
                                <div>
                                    <h6><b>Partition Suit</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Final Decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isFinalDecree"
                                                    type="radio"
                                                    checked={data.partitionSuit.isFinalDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isFinalDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isFinalDecree"
                                                    type="radio"
                                                    checked={!data.partitionSuit.isFinalDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isFinalDecree", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Preliminary Decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isPreliminaryDecree"
                                                    type="radio"
                                                    checked={data.partitionSuit.isPreliminaryDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isPreliminaryDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isPreliminaryDecree"
                                                    type="radio"
                                                    checked={!data.partitionSuit.isPreliminaryDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isPreliminaryDecree", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Compromise Decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isCompormiseDecree"
                                                    type="radio"
                                                    checked={data.partitionSuit.isCompormiseDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isCompormiseDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isCompormiseDecree"
                                                    type="radio"
                                                    checked={!data.partitionSuit.isCompormiseDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isCompormiseDecree", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Expert decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isExpartyDecree_ds"
                                                    type="radio"
                                                    checked={data.partitionSuit.isExpartyDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isExpartyDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isExpartyDecree_ds"
                                                    type="radio"
                                                    checked={!data.partitionSuit.isExpartyDecree}
                                                    onChange={() => this.handleInputChange("partitionSuit", "isExpartyDecree", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Declaration Suit/ Permanent (or) Temporary Injection</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>IA No Available?</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isIAAvailable"
                                                    type="radio"
                                                    checked={data.declarationSuit.isIAAvailable}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isIAAvailable", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isIAAvailable"
                                                    type="radio"
                                                    checked={!data.declarationSuit.isIAAvailable}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isIAAvailable", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Contest</label>

                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isContest"
                                                    type="radio"
                                                    checked={data.declarationSuit.isContest}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isContest", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isContest"
                                                    type="radio"
                                                    checked={!data.declarationSuit.isContest}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isContest", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Compromise Decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isCompormiseDecree_ds"
                                                    type="radio"
                                                    checked={data.declarationSuit.isCompormiseDecree}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isCompormiseDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isCompormiseDecree_ds"
                                                    type="radio"
                                                    checked={!data.declarationSuit.isCompormiseDecree}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isCompormiseDecree", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Expert decree</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isExpartyDecree"
                                                    type="radio"
                                                    checked={data.declarationSuit.isExpartyDecree}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isExpartyDecree", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isExpartyDecree"
                                                    type="radio"
                                                    checked={!data.declarationSuit.isExpartyDecree}
                                                    onChange={() => this.handleInputChange("declarationSuit", "isExpartyDecree", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Lok Adalat</b></h6>
                                    <div className="row mb-10">
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Old Court Name</label>
                                            <input
                                                className="form-control"
                                                name="oldCourtName"
                                                type="text"
                                                value={data.lokAdalat.oldCourtName}
                                                onChange={(e) => this.handleInputChange("lokAdalat", "oldCourtName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Case No</label>
                                            <input
                                                className="form-control"
                                                name="caseNo"
                                                type="text"
                                                value={data.lokAdalat.caseNo}
                                                onChange={(e) => this.handleInputChange("lokAdalat", "caseNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Lok Adalat Case No</label>
                                            <input
                                                className="form-control"
                                                name="lokAdalatCaseNo"
                                                type="text"
                                                value={data.lokAdalat.lokAdalatCaseNo}
                                                onChange={(e) => this.handleInputChange("lokAdalat", "lokAdalatCaseNo", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Specific Performance/ Suit</b></h6>
                                    <div className="row mb-10">
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Agreement Holder Name</label>
                                            <input
                                                className="form-control"
                                                name="agreementHolderName"
                                                type="text"
                                                value={data.specificSuit.agreementHolderName}
                                                onChange={(e) => this.handleInputChange("specificSuit", "agreementHolderName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>OS No</label>
                                            <input
                                                className="form-control"
                                                name="osNo"
                                                type="text"
                                                value={data.specificSuit.osNo}
                                                onChange={(e) => this.handleInputChange("specificSuit", "osNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>EP No</label>
                                            <input
                                                className="form-control"
                                                name="epNo"
                                                type="text"
                                                value={data.specificSuit.epNo}
                                                onChange={(e) => this.handleInputChange("specificSuit", "epNo", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Appeal</b></h6>
                                    <div className="row mb-10">
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Lower Court Name</label>
                                            <input
                                                className="form-control"
                                                name="lowerCourtName"
                                                type="text"
                                                value={data.appeal.lowerCourtName}
                                                onChange={(e) => this.handleInputChange("appeal", "lowerCourtName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Case No</label>
                                            <input
                                                className="form-control"
                                                name="lowerCourtcaseNo"
                                                type="text"
                                                value={data.appeal.lowerCourtcaseNo}
                                                onChange={(e) => this.handleInputChange("appeal", "lowerCourtcaseNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Appeal Court Name</label>
                                            <input
                                                className="form-control"
                                                name="appealCourtName"
                                                type="text"
                                                value={data.appeal.appealCourtName}
                                                onChange={(e) => this.handleInputChange("appeal", "appealCourtName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Case No</label>
                                            <input
                                                className="form-control"
                                                name="appealCaseNo"
                                                type="text"
                                                value={data.appeal.appealCaseNo}
                                                onChange={(e) => this.handleInputChange("appeal", "appealCaseNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <label className="label" style={{ padding: 0 }}>In Appeal Verdict, is It Lower Court Confirm?</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name="isLowerCourt"
                                                            type="radio"
                                                            checked={data.appeal.isLowerCourt}
                                                            onChange={() => this.handleInputChange("appeal", "isLowerCourt", true)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name="isLowerCourt"
                                                            type="radio"
                                                            checked={!data.appeal.isLowerCourt}
                                                            onChange={() => this.handleInputChange("appeal", "isLowerCourt", false)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Lower Court Reverse</label>
                                            <input
                                                className="form-control"
                                                name="lowerCourtReverse"
                                                type="text"
                                                value={data.appeal.lowerCourtReverse}
                                                onChange={(e) => this.handleInputChange("appeal", "lowerCourtReverse", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Alter</label>
                                            <input
                                                className="form-control"
                                                name="alter"
                                                type="text"
                                                value={data.appeal.alter}
                                                onChange={(e) => this.handleInputChange("appeal", "alter", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Purchase From Court Auction</b></h6>
                                    <div className="row mb-10">
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>OS No</label>
                                            <input
                                                className="form-control"
                                                name="osNo"
                                                type="text"
                                                value={data.courtAuctionPurchase.osNo}
                                                onChange={(e) => this.handleInputChange("courtAuctionPurchase", "osNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>EP No</label>
                                            <input
                                                className="form-control"
                                                name="epNo"
                                                type="text"
                                                value={data.courtAuctionPurchase.epNo}
                                                onChange={(e) => this.handleInputChange("courtAuctionPurchase", "epNo", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Successfull Bidder Name</label>
                                            <input
                                                className="form-control"
                                                name="bidderName"
                                                type="text"
                                                value={data.courtAuctionPurchase.bidderName}
                                                onChange={(e) => this.handleInputChange("courtAuctionPurchase", "bidderName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0,marginTop:'10px' }}>Property Holder Name</label>
                                            <input
                                                className="form-control"
                                                name="propertyHolderName"
                                                type="text"
                                                value={data.courtAuctionPurchase.propertyHolderName}
                                                onChange={(e) => this.handleInputChange("courtAuctionPurchase", "propertyHolderName", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Bank Mortage Involved?</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isBankMortageInvolved"
                                                    type="radio"
                                                    checked={data.courtAuctionPurchase.isBankMortageInvolved}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isBankMortageInvolved", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isBankMortageInvolved"
                                                    type="radio"
                                                    checked={!data.courtAuctionPurchase.isBankMortageInvolved}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isBankMortageInvolved", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Mother/Title Available?</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isMotherAvailable"
                                                    type="radio"
                                                    checked={data.courtAuctionPurchase.isMotherAvailable}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isMotherAvailable", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isMotherAvailable"
                                                    type="radio"
                                                    checked={!data.courtAuctionPurchase.isMotherAvailable}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isMotherAvailable", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Sales Certificate Available?</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isSalesAvailable"
                                                    type="radio"
                                                    checked={data.courtAuctionPurchase.isSalesAvailable}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isSalesAvailable", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isSalesAvailable"
                                                    type="radio"
                                                    checked={!data.courtAuctionPurchase.isSalesAvailable}
                                                    onChange={() => this.handleInputChange("courtAuctionPurchase", "isSalesAvailable", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>SARFESI Proceeding</b></h6>
                                    <div className="row mb-10">
                                        {/* <div className="form-group col-md-3">
                                            <label className="label">Demand Notice Date</label>
                                            <input
                                                className="form-control"
                                                name="demandDate"
                                                type="text"
                                                onChange={(e) => this.handleInputChange("sarfesiProceeding", "demandDate", e.target.value)}
                                            />
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Possession Notice Date</label>
                                            <input
                                                className="form-control"
                                                name="possessionDate"
                                                type="text"
                                                value={data.sarfesiProceeding.possessionDate}
                                                onChange={(e) => this.handleInputChange("sarfesiProceeding", "possessionDate", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Symbolic Possession</label>
                                            <input
                                                className="form-control"
                                                name="symbolicPossession"
                                                type="text"
                                                value={data.sarfesiProceeding.symbolicPossession}
                                                onChange={(e) => this.handleInputChange("sarfesiProceeding", "symbolicPossession", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Notice to the Borrower</label>
                                            <input
                                                className="form-control"
                                                name="borrowerNotice"
                                                type="text"
                                                value={data.sarfesiProceeding.borrowerNotice}
                                                onChange={(e) => this.handleInputChange("sarfesiProceeding", "borrowerNotice", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Date Of Auction</label>
                                            <input
                                                className="form-control"
                                                name="auctionDate"
                                                type="text"
                                                value={data.sarfesiProceeding.auctionDate}
                                                onChange={(e) => this.handleInputChange("sarfesiProceeding", "auctionDate", e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Bidder Paid 15%?</label>
                                            <div className="row " style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isPaid15Percent"
                                                    type="radio"
                                                    checked={data.sarfesiProceeding.isPaid15Percent}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isPaid15Percent", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isPaid15Percent"
                                                    type="radio"
                                                    checked={!data.sarfesiProceeding.isPaid15Percent}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isPaid15Percent", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Sales Certificate?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isSalesCertificate"
                                                    type="radio"
                                                    checked={data.sarfesiProceeding.isSalesCertificate}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isSalesCertificate", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isSalesCertificate"
                                                    type="radio"
                                                    checked={!data.sarfesiProceeding.isSalesCertificate}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isSalesCertificate", false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Registered</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isRegistered"
                                                    type="radio"
                                                    checked={data.sarfesiProceeding.isRegistered}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isRegistered", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isRegistered"
                                                    type="radio"
                                                    checked={!data.sarfesiProceeding.isRegistered}
                                                    onChange={() => this.handleInputChange("sarfesiProceeding", "isRegistered", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default CourtDocVerify
