import React from "react";
import { callApi } from "../../utils/api";
import { Bank } from "./ManageBank";

interface AllProps {
  selectedBank: Bank;
  listBank: any;
}
class AddBank extends React.Component<AllProps> {
  state = {
    bankdetail: { id: 0, bank_id: 0, name: "" }
  };
  componentWillReceiveProps(nextState) {
    this.setState({ bankdetail: nextState.selectedBank });
  }
  handleChange = (e: any) => {
    const bankdetail: any = this.state.bankdetail;
    bankdetail[e.target.name] = e.target.value;
    this.setState({ bankdetail });
  };
  saveBank = () => {
    if (this.state.bankdetail.id) {
      this.state.bankdetail.id = Number(this.state.bankdetail.id);
      callApi("POST", "bank_update.php", this.state.bankdetail)
        .then(res => res.data)
        .then(response => {
          this.props.listBank();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      callApi("POST", "bank_create.php", this.state.bankdetail)
        .then(res => res.data)
        .then(response => {
          this.props.listBank();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  render() {
    return (
      <div
        className="modal fade"
        id="bank-form"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bank Form</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="col-form-label">Bank Name:</label>{" "}
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Bank Name"
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    value={
                      this.state.bankdetail.name
                        ? this.state.bankdetail.name
                        : ""
                    }
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.saveBank}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddBank;
