import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { GeneralVerify } from './sopDataTemplate';
import Select from 'react-select';
import { nathamPattaverify } from './sopDataTemplate';

interface AllProps {
    data: nathamPattaverify;
    handleNathampattaChange: any;
    isOnlyView?: boolean;
}
export class Nathampatta extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d',
        responseData: {},
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            responseData: JSON.parse(JSON.stringify(data))
        });
    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (name, val) => {
        const { data } = this.props;
        data[name] = val;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleNathampattaChange(data);
    }
    handleStateChange = (stateName, value) => {
        console.log("value", value)
        this.setState({
            [stateName]: value
        }, () => {
            this.changeBgColor()
        })
    }
    handleFileChange = (name, val) => {
        const { data } = this.props;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleNathampattaChange(data);
    }
    getOtherProps = (key) => {
        // const { data, isOnlyView } = this.props;
        // if (isOnlyView) return { [key]: true };
        return null
    }

    handleChildInputChange = (key, name, value, index?) => {
        const { data } = this.props;
        if (index !== undefined) data[key][index][name] = value;
        else data[key][name] = value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleNathampattaChange(data);
    }
    handleChildRadioChange = (key, name, val, index?) => {
        const { data } = this.props;
        if (index !== undefined) {
            data[key][index][name] = val;
        }
        else {
            data[key][name] = val;
        }
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleNathampattaChange(data);
    }

    addChild = (type) => {
        const { data } = this.props;
        data[type].push({
            "specialTahsildar": false,
            "boundaryCertificate": false,
            "roadApproach": false
        });
        this.props.handleNathampattaChange(data);
    }

    removeChild = (type, index) => {
        const { data } = this.props;
        delete data[type][index];
        const updatedLegalHeirs = data[type].filter((item) => item !== null);
        data[type] = updatedLegalHeirs;
        this.props.handleNathampattaChange(data);
    };

    getAddButton = (title, value) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6><b></b></h6>
                <div onClick={() => this.addChild(value)}>
                    <button className='btn btn-sm btn-primary' style={{ fontSize: 'smaller', marginBottom: "10%" }}>Add {title}</button>
                </div>
            </div>
        )
    }
    checkOperation = (type, value, index) => {
        let isEditable = false
        const { responseData } = this.state;
        if (responseData[type] && responseData[type].length < (index + 1)) {
            isEditable = true;
        };
        return isEditable;
    }

    render() {
        const { data, isOnlyView } = this.props;
        const { isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section count-doc-verify">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data?.bgColor ? data?.bgColor : bgColor }} as={Card.Header} eventKey="8" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>Natham/manaivari Patta</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="8">
                            <Card.Body>
                                <div>
                                    {this.getAddButton('Natham/manaivari Patta', 'nathamPatta')}
                                    {data?.nathamPatta && Array.isArray(data?.nathamPatta) && data?.nathamPatta.map((value, index) => {
                                        return (
                                            <>
                                                <div className="row mb-10" key={index}>
                                                    <div className="col-md-4 mb-10">
                                                        <label className="label mb-2" style={{ padding: 0 }}>Issued By Special Tahsildar</label>
                                                        {/* {data.file.btTransfer}/ */}
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={'specialTahsildar' + (value && value.specialTahsildar ? value.specialTahsildar : "") + index}
                                                                type="radio"
                                                                checked={value.specialTahsildar}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "specialTahsildar", true, index)}

                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={'specialTahsildar' + (value && !value.specialTahsildar ? !value.specialTahsildar : "") + index}
                                                                type="radio"
                                                                checked={!value.specialTahsildar}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "specialTahsildar", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-10">
                                                        <label className="label mb-2" style={{ padding: 0 }}>Boundary Certificate</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={'boundaryCertificate' + (value && value.boundaryCertificate ? value.boundaryCertificate : "") + index}
                                                                type="radio"
                                                                checked={value.boundaryCertificate}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "boundaryCertificate", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={'boundaryCertificate' + (value && !value.boundaryCertificate ? !value.boundaryCertificate : "") + index}
                                                                type="radio"
                                                                checked={!value.boundaryCertificate}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "boundaryCertificate", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-10">
                                                        <label className="label mb-2" style={{ padding: 0 }}>Road Approach</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={'roadApproach' + (value && value.roadApproach ? value.roadApproach : "") + index}
                                                                type="radio"
                                                                checked={value.roadApproach}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "roadApproach", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={'roadApproach' + (value && !value.roadApproach ? !value.roadApproach : "") + index}
                                                                type="radio"
                                                                checked={!value.roadApproach}
                                                                onChange={() => this.handleChildRadioChange("nathamPatta", "roadApproach", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('nathamPatta', value, index) &&
                                                        <div className='form-group col-md-2' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('nathamPatta', index)}
                                                        >
                                                            <i style={{ color: 'red'}} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default Nathampatta;