import React, { useEffect } from 'react';
import Select from 'react-select';

const ReplyQuery = (props) => {
    const { reply, queryreason, addnewQueryList, handleChange, removeReply } = props;
    useEffect(() => {
    }, [])
    let reasontemplatesoption = [
        { value: "other", label: "Other" }
    ];

    if (Array.isArray(queryreason)) {
        reasontemplatesoption = [
            { value: "other", label: "Other" },
            ...queryreason
        ];
    } else {
        // Handle the case when queryreason is not an array
        // You can assign a default value or handle it differently based on your requirements
    }
    return (
        <div className="main-body">
            <div className="page-wrapper">
                {addnewQueryList && addnewQueryList.length
                    ? addnewQueryList.map(
                        (reply: any, index: number) => {
                            return (
                                <>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Query</label>{" "}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="query"
                                                    placeholder=""
                                                    value={reply.query}
                                                    onChange={(e) => handleChange(e, index)}
                                                />
                                            </div>
                                            {!reply.query && <p style={{ color: "red", fontSize: "14px", marginTop: "-14px" }}>Required field.</p>}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Reason</label>
                                                {queryreason && queryreason.length > 0 && queryreason.map((opt: any) => {
                                                    opt.label = opt.reason; opt.value = opt.reason
                                                }) && (
                                                        <Select
                                                            options={reasontemplatesoption}
                                                            name="reason"
                                                            onChange={(selectedOption) =>
                                                                props.handleDetailsChange(selectedOption.value, 'review', index)
                                                            }
                                                            value={reasontemplatesoption.find(
                                                                (o: any) => o.value === reply.review
                                                            )}
                                                        />
                                                    )}
                                                {!reply.review && <p style={{ color: "red", fontSize: "14px", marginTop: "10px !important" }}>Required field.</p>}
                                                {reply.review === "other" && (
                                                    <div className="">
                                                        <label>Other Reason</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="other_review"
                                                            placeholder=""
                                                            value={reply.other_review}
                                                            onChange={(e) => handleChange(e, index)}
                                                        />
                                                        {!reply.other_review && <p style={{ color: "red", fontSize: "14px", marginTop: "10px !important" }}>Required field.</p>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    placeholder=""
                                                    value={reply.location}
                                                    onChange={(e) => handleChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1 display-flex" style={{ paddingTop: "40px" }}>
                                            <div onClick={props.addReply}>
                                                <i className="fa fa-plus-circle fa-icon" aria-hidden="true" ></i>
                                            </div>
                                            <div onClick={() => removeReply(index)}>
                                                <i className="fa fa-trash fa-icon" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    )
                    : ""}
            </div>
        </div>
    )
}

export default ReplyQuery;