import * as React from "react";
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import DataModel, { DataModelKeyMapping } from "../workFileVerificationList/sopDataModel";

declare var $: any;

interface AllProps {
    history: any;
    match: any;
}

class VerificationDetails extends React.Component<AllProps> {
    state = {
        verificationDetails: {}
    };
    tableDetails: any;
    columnOfDetails: any = {
        "generalVerification": 6,
        "ecVerify": 6,
        "surveyNoVerification": 12,
        "deed": 12,
        "verification": 6,
        "courtDocVerification": 6,
        "legalHeirsVerify": 6,
        "isVerified": 5,
        "missingLOD": 12,
        "fileNo": 12,
        "remarks": 12
    }
    componentDidMount() {
        this.getQueries();
    }


    renderHeader = (title: string) => {
        const titleValue = DataModelKeyMapping[title] || "NEED_CHECK"
        return <div className="row text-capitalize" style={{ fontWeight: 800, marginBottom: '5px', color: 'black', background: '#e3e3e3', padding: '5px' }}>{titleValue}</div>
    }

    renderKeyText = (key: string, value: string | boolean) => {
        const keyText = DataModelKeyMapping[key] || "NEED_CHECK"
        const keyValue = typeof value === 'boolean' ? value ? "Yes" : "No" : value
        return !!keyValue && <div className="row">
            <p className="col-md-6 font-weight-light">
                {keyText}
            </p>
            <p className="col-md-3 font-weight-bold">{keyValue}</p>
        </div>
    }

    renderTable = (list: Array<any>) => {
        const tableHeaders = Object.keys(list[0]);
        let comp = (
            <table className="table table-striped table-bordered nowrap" style={{ overflow: 'scroll', marginTop: '10px' }}>
                <thead>
                    <tr>
                        {tableHeaders.map((header, headerIndex) => (
                            <th key={header + headerIndex}>{DataModelKeyMapping[header] || "NEED_CHECK"}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {list.map((tableValue, tableIndex) => {
                        return (
                            <tr>
                                {Object.values(tableValue).map((tableChild: any, tableChildIndex) => {
                                    return <td>{tableChild}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
        return comp;
    }

    getQueries = () => {
        callApi("POST", "get_verification_checklist.php", {
            fileNo: this.props.match.params.id
        })
            .then(res => res.data)
            .then(response => {
                if (response.responseJson.data.currentJson) {
                    const currentJsonData = JSON.parse(response.responseJson.data.currentJson);

                    const JSONOrder = [
                        "generalVerification",
                        "ecVerify",
                        "surveyNoVerification",
                        "deed",
                        "verification",
                        "courtDocVerification",
                        "legalHeirsVerify",
                        "isVerified",
                        "missingLOD",
                        "fileNo",
                        "remarks"
                    ]
                    let currentOrderedJSON = {};
                    Object.keys(currentJsonData).sort(function (a, b) {
                        return JSONOrder.indexOf(a) > JSONOrder.indexOf(b) ? 1 : -1
                    }).forEach(key => {
                        if (!["remarks", "fileNo"].includes(key))
                            currentOrderedJSON[key] = { ...currentJsonData[key] };
                        else currentOrderedJSON[key] = currentJsonData[key];
                    })
                    this.setState({
                        verificationDetails: currentOrderedJSON
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    renderView = (detail: any, index: number) => {
        let comp: any = null;
        const valueType = Array.isArray(detail[1]) ? 'table' : typeof detail[1];
        if (!['bgColor'].includes(detail[0])) {
            if (['string', 'boolean'].includes(valueType)) {
                comp = (
                    <div>
                        {this.renderKeyText(detail[0], detail[1])}
                    </div>
                )
            }
            else if (valueType === 'object') {
                comp = (
                    <div style={{ margin: '10px' }}>
                        {this.renderHeader(detail[0])}
                        {
                            Object.entries(detail[1]).map((detailObj, detailIndex) => {
                                return this.renderView(detailObj, detailIndex)
                            })
                        }
                    </div>
                )
            }
            else if (valueType === 'table') {
                comp = (
                    <div style={{ margin: '10px' }}>
                        {this.renderHeader(detail[0])}
                        {this.renderTable(detail[1])}
                    </div>
                )
            }
        }
        return comp;
    }

    render() {
        const { verificationDetails } = this.state;
        return (
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="font-weight-bold text-uppercase">Verification Preview</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row" style={{ justifyContent: 'space-between' }}>
                                                        {Object.keys(verificationDetails).length > 0 ?
                                                            Object.entries(verificationDetails).map((detail, index) => {
                                                                return (
                                                                    <div className={`col-md-${detail[0] ? this.columnOfDetails[detail[0]] : '12'}`} style={{
                                                                        boxShadow: "0 3px 2px rgb(0 0 0 / 0.2)",
                                                                        marginTop: '5px',
                                                                        marginBottom: '15px',
                                                                        padding: '15px'
                                                                    }}>
                                                                        {this.renderView(detail, index)}
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="row font-weight-bolden" style={{ width: '100%', justifyContent: 'center' }}>No Details Found</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default VerificationDetails;
