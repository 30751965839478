import * as React from "react";
import Select from 'react-select';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import { PropertyOwnerItem } from "./PropertyOwnerForm";
type AllProps = {
  navigateToDocs: any;
  initialValue: any;
  required: any;
};
export interface BroItem {
  id?: string;
  bro_id?: string;
  designation: string;
  email: string;
  name: string;
  mobile: string;
}
export interface TitleItem {
  id?: string;
  doc_no: string;
  doc_type: string;
  schedule: string;
  name: string;
  isValid: Boolean;
  survey_no_extent: { survey_no: string, extent: string, sur_extent_unit: string, survey_position: number, title_survey_position: number }[];
}

class BankForm extends React.Component<AllProps> {
  state = {
    ...this.props.initialValue
  };
  componentDidMount() {
    if (!this.state.privateList || !this.state.privateList.length) {
      this.setState({ privateList: [] });
      this.addProperty();
    }
    if (!this.state.brolist || !this.state.brolist.length) {
      this.setState({ brolist: [] });
      this.addBro();
    }
    if (!this.state.titlelist || !this.state.titlelist.length) {
      this.setState({ titlelist: [] });
      this.addTitle();
    }
    // if (!this.state.surveylist || !this.state.surveylist.length) {
    //   this.setState({ surveylist: [] });
    //   let i;
    //   this.addSurveyExtent(i);
    // }
    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.brolist) {
      delete_items.brolist = [];
    }
    if (!delete_items || !delete_items.privateList) {
      delete_items.privateList = [];
    }
    if (!delete_items || !delete_items.titlelist) {
      delete_items.titlelist = [];
    }
    if (!delete_items || !delete_items.surveylist) {
      delete_items.surveylist = [];
    }
    this.setState({ delete_items });
    this.getBanks();
    this.getBankBranches();
    this.getLawHandsBranches();
    const isNewFile = Number(this.props.initialValue.file_idfk) === 0;
    if (!isNewFile) {
      this.ifIdBankBranches();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const state = this.props.initialValue;
      this.setState({ ...this.props.initialValue })
    }
  }

  getBanks() {
    callApi("GET", "bank_get.php")
      .then(res => res.data)
      .then(result => {
        this.setState({ banks: result.data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  getBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then(res => res.data)
      .then(result => {
        this.setState({ branches: result.data, filteredBranches: [] });
        if (this.state.bank_name) {
          this.filterBranches(this.state.bank_name);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ lawhandsBranches: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  ifIdfilterBranches = bankid => {
    const filteredBranches = this.state.branches.filter(branch => branch.bank_id === bankid);
    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
  };

  ifIdBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then(res => res.data)
      .then(result => {
        this.setState({ branches: result.data });
        if (this.state.bank_name) {
          this.ifIdfilterBranches(this.state.bank_name);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  filterBranches = bankid => {
    const filteredBranches = this.state.branches.filter(branch => branch.bank_id === bankid);
    const state = this.state;
    state.filteredBranchesproduct = filteredBranches;
    this.setState({ ...state });
  };

  filterBranchesproduct = productid => {
    const filteredBranches = this.state.filteredBranchesproduct.filter(branch => {
      return branch.product_id === productid && branch.bank_id === this.state.bank_name;
    });
    const state = this.state;
    state.filteredBranches = filteredBranches;
    if (filteredBranches.length == 0) {
      state.branch = "";
    }
    this.setState({ ...state });
  };

  filterProduct = bankid => {
    if (bankid) {
      const filteredProduct = this.state.products.filter(
        branch => branch.bank_id === bankid
      );
      const state = this.state;
      state.filteredProduct = filteredProduct;
      this.setState({ ...state });
    } else {
      this.setState({ product_id: "" });
    }
  };
  getBros = branchid => {
    callApi("POST", "bro_get.php", { branch_idfk: branchid })
      .then(res => res.data)
      .then(response => {
        if (!response.data.length) {
          this.addBro();
        } else {
          this.setState({ brolist: response.data });
          this.handleSubmit();
        }
      });
  };
  addBro = (): void => {
    const brolist: BroItem[] = this.state.brolist ? this.state.brolist : [];
    brolist.push({ name: "", mobile: "", email: "", designation: "" });
    this.setState({ brolist });
  };

  removeBro = (modal: BroItem, index: number): void => {
    const brolist: BroItem[] = this.state.brolist;
    brolist.splice(index, 1);

    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.brolist) {
      delete_items.brolist = [];
    }
    delete_items.brolist.push(modal.id);
    this.setState({ brolist, delete_items });
    this.handleSubmit();
  };
  addTitle = () => {
    const { titlelist } = this.state;
    const newIndex = titlelist.length;
    titlelist.push({
      doc_no: "",
      doc_type: "",
      schedule: "",
      name: "",
      isValid: true,
      survey_no_extent: [{ survey_no: "", extent: "", sur_extent_unit: "", title_survey_position: newIndex, survey_position: 0 }],
      title_position: newIndex,
    });
    this.setState({ titlelist });
  };
  removeTitle = (modal, index: number): void => {
    const titlelist: TitleItem[] = [...this.state.titlelist];
    titlelist.splice(index, 1);

    titlelist.forEach((title, i) => {
      title.survey_no_extent.forEach((surveyExtent) => {
        surveyExtent.title_survey_position = i;
      });
    });

    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.titlelist) {
      delete_items.titlelist = [];
    } else {
      delete_items.titlelist.push(modal.id);
    }
    this.setState({ titlelist, delete_items });
    setTimeout(() => {
      this.handleSubmit();
    }, 1000);
  };

  handleBroChange = (e: any, index: number) => {
    const brolist: BroItem[] = this.state.brolist;
    brolist.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ brolist });
    this.handleSubmit();
  };
  addSurveyExtent = (titleIndex: number) => {
    const titlelist: TitleItem[] = [...this.state.titlelist];
    const title: any = titlelist[titleIndex];
    const surveyPosition = title.survey_no_extent.length;
    title.survey_no_extent.push({
      survey_no: "",
      extent: "",
      sur_extent_unit: "",
      title_survey_position: title.title_position,
      survey_position: surveyPosition,
    });
    this.setState({ titlelist });
  };

  removeSurveyExtent = (index: number, subIndex: number, modal: any) => {
    const titlelist: TitleItem[] = [...this.state.titlelist];
    const removedSurveyExtent = titlelist[index].survey_no_extent.splice(subIndex, 1)[0];
    // Update survey_position for the remaining survey_no_extent objects within the same title
    titlelist[index].survey_no_extent.forEach((surveyExtent, i) => {
      surveyExtent.survey_position = i;
    });

    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.surveylist) {
      delete_items.surveylist = [];
    } else {
      delete_items.surveylist.push(modal.id);
    }
    this.setState({ titlelist, delete_items });
    this.handleSubmit();
  };

  handleSurveyNoChange = (e: any, index: number, subIndex: number) => {
    const titlelist: TitleItem[] = [...this.state.titlelist];
    const inputValue = e.target.value;

    // Check if the first letter is a space or hyphen
    if (/^[ -]/.test(inputValue)) {
      // Display an error message or handle the invalid input as needed
      console.error("Invalid input. The first letter cannot be a space or hyphen.");
      return;
    }

    titlelist[index].survey_no_extent[subIndex].survey_no = inputValue;
    this.setState({ titlelist });
  };
  // handleExtentChange = (e: any, index: number, subIndex: number) => {
  //   const titlelist: TitleItem[] = [...this.state.titlelist];
  //   titlelist[index].survey_no_extent[subIndex].extent = e.target.value;
  //   this.setState({ titlelist });
  // };

  handleExtentChange = (e: any, index: number, subIndex: number) => {
    const titlelist: TitleItem[] = [...this.state.titlelist];
    const inputValue = e.target.value;

    // Check if the first letter is a space or hyphen
    if (/^[ -]/.test(inputValue)) {
      // Display an error message or handle the invalid input as needed
      console.error("Invalid input. The first letter cannot be a space or hyphen.");
      return;
    }

    titlelist[index].survey_no_extent[subIndex].extent = inputValue;
    this.setState({ titlelist });
  };

  handleunitChange = (selectedOption: any, index: number, subIndex: number) => {
    console.log(`${selectedOption.value}----${index}---${subIndex}`);

    const titlelist: TitleItem[] = [...this.state.titlelist];
    titlelist[index].survey_no_extent[subIndex].sur_extent_unit =
      selectedOption.value;
    this.setState({ titlelist });
    console.log("extent", this.state);
  };

  handletitleChange = (e: any, index: number, key: any) => {
    const titlelist: TitleItem[] = this.state.titlelist;
    if (key == "doc_no") {
      const isValidInput = /^[0-9/-]*$/.test(e.target.value);

      if (!isValidInput) {
        // Display an error message or handle the invalid input as needed
        console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
        return;
      }

      titlelist.forEach((itm: any, i) => {
        if (i === index) {
          itm[e.target.name] = e.target.value;
        }
      });
    } else if (key !== "doc_type") {
      titlelist.forEach((itm: any, i) => {
        if (i === index) {
          const inputValue = e.target.value;

          // Check if the first letter is a space or hyphen
          if (/^[ -]/.test(inputValue)) {
            // Display an error message or handle the invalid input as needed
            console.error("Invalid input. The first letter cannot be a space or hyphen.");
            return;
          }

          itm[e.target.name] = inputValue;
        }
      });
    } else {
      titlelist.forEach((itm: any, i) => {
        if (i === index) {
          itm[key] = e.value;
        }
      });
    }
    this.setState({ titlelist });
    this.handleSubmit();
  };
  addProperty = (): void => {
    const privateList: PropertyOwnerItem[] = this.state.privateList
      ? this.state.privateList
      : [];
    privateList.push({ name: "", mobile: "", email: "" });
    this.setState({ privateList });
  };
  removeProperty = (modal: PropertyOwnerItem, index: number): void => {
    const privateList: PropertyOwnerItem[] = this.state.privateList;
    privateList.splice(index, 1);

    const delete_items: any = this.state.delete_items
      ? this.state.delete_items
      : Object.create({});
    if (!delete_items || !delete_items.privateList) {
      delete_items.privateList = [];
    }
    delete_items.privateList.push(modal.id);
    this.setState({ privateList, delete_items });
    this.handleSubmit();
  };
  handlePropertyChange = (e: any, index: number) => {
    const privateList: PropertyOwnerItem[] = this.state.privateList;
    privateList.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ privateList });
    this.handleSubmit();
  };
  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    if (fieldName === "lawhands_branch") {
      const branch = fieldValue;
      const filteredBranches = this.state.branches.filter(
        branch => branch.bank_id === branch
      );
      const state = this.state;
      state[fieldName] = fieldValue;
      console.log(`${fieldName} ---- ${fieldValue}`);
      this.setState({ filteredBranches });
    }
    if (fieldName === "product_id") {
      this.setState(
        {
          filteredBranches: [],
          branch: ""
        }, () => {
          // Directly pass the updated state to navigateToDocs
          this.props.navigateToDocs({
            ...this.state, // or you can manually pass the updated values if needed
            filteredBranches: [],
            branch: ""
          });
          setTimeout(() => {
            // Ensure the updated state is used inside these methods if needed
            this.filterBranchesproduct(fieldValue);
          }, 1000);
        }
      )
      let newProductId = e.product_id;
      const state = this.state;
      state.selected_product_id = newProductId;
      // console.log(`${fieldName} ---- ${newProductId}`);
    }
    console.log("this.state", this.state);
    if (fieldName === "bank_name") {
      this.setState(
        {
          bank_name: fieldValue,
          filteredProduct: [],
          filteredBranches: [],
          selected_product_id: "",
          branch: "",
          product_id: ""
        },
        () => {
          // Directly pass the updated state to navigateToDocs
          this.props.navigateToDocs({
            ...this.state, // or you can manually pass the updated values if needed
            filteredProduct: [],
            filteredBranches: [],
            selected_product_id: "",
            branch: "",
            product_id: ""
          });
          setTimeout(() => {
            // Ensure the updated state is used inside these methods if needed
            this.filterBranches(fieldValue);
            this.filterProduct(fieldValue);
          }, 1000);
        }
      );

    }
    if (fieldName === "branch") {
      if (this.state.branch != fieldValue) {
        this.getBros(fieldValue);
      }
      this.setState({ [fieldName]: fieldValue });
    }
    if (fieldName === "contact_number") {
      const isValidInput = /^[0-9/-]*$/.test(fieldValue);

      if (!isValidInput) {
        // Display an error message or handle the invalid input as needed
        console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
        return;
      } else {
        if (fieldValue.length <= 10) {
          this.setState({ [fieldName]: fieldValue });
        }
      }
    }
    if (fieldName === "loan_amount") {
      const inputValue = fieldValue;

      if (/^[ -]/.test(inputValue)) {
        console.error("Invalid input. The first letter cannot be a space or hyphen.");
        return;
      }
      this.setState({ [fieldName]: inputValue });
    }
    if (fieldName === "userHintAboutFile") {
      this.setState({ [fieldName]: fieldValue });
    }

    if (fieldName != "product_id" || fieldName != "contact_number") {
      const state = this.state;
      state[fieldName] = fieldValue;
      this.setState({ ...state });
    }
    // this.handleSubmit();
    const state = this.state;
    this.props.navigateToDocs(state);
  };
  handleSubmit = () => {
    const state = this.state;
    this.props.navigateToDocs(state);
    console.log("this.state", this.state);
  };
  saveHint = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const user = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    let req = {
      id: this.state.file_idfk,
      userid: userId,
      hints: this.state.userHintAboutFile
    }
    callApi('POST', 'add_hints.php', req)
      .then(res => res.data)
      .then(result => {
        // Handle the response here
        ToastsStore.success("Hint Saved");
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const titlelist = this.state.titlelist;
    const filteredProduct = [
      { value: "", label: "" },
      ...this.state.filteredProduct
    ];
    return (
      <>
        <div className="row">
          <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.TOP_CENTER}
          />
          <div className="col-md-6">
            <div className="form-group">
              <label>Bank Name<span style={{ color: "red" }}>*</span></label>
              {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.banks} name="bank_name" className={this.props.required && this.state.bank_name === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
                  onChange={(e) => this.handleChange(e, 'bank_name', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Product<span style={{ color: "red" }}>*</span></label>
              {this.state.products && this.state.products.map((opt: any) => {
                opt.label = opt.product_name; opt.value = opt.id
              }) &&
                <Select options={filteredProduct} name="product_id" className={this.props.required && this.state.product_id === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.filteredProduct.find((o: any) => o.product_id === this.state.selected_product_id ? this.state.selected_product_id : "")}
                  onChange={(e) => this.handleChange(e, 'product_id', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Bank Branch<span style={{ color: "red" }}>*</span></label>
                </div>
              </div>

              {this.state.filteredBranches && this.state.filteredBranches.length > 0 && this.state.filteredBranches.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.filteredBranches} name="branch" className={this.props.required && this.state.branch === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.filteredBranches && this.state.filteredBranches.find((o: any) => o.value === this.state.branch)}
                  onChange={(e) => this.handleChange(e, 'branch', 'singleselect')}
                />
              }
              {this.state.branch == "" && this.state.required == true && <div className="pt-1" style={{ fontSize: "11px", color: "#dc3545" }}>*Required Field</div>}
            </div>
          </div>
          {/* </div>
        <div className="row"> */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Lawhands Branch<span style={{ color: "red" }}>*</span></label>
              {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                opt.label = opt.name; opt.value = opt.id
              }) &&
                <Select options={this.state.lawhandsBranches} name="lawhands_branch" className={this.props.required && this.state.lawhands_branch === '' ? 'form-select is-invalid' : 'form-select'}
                  value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                  onChange={(e) => this.handleChange(e, 'lawhands_branch', 'singleselect')}
                />
              }
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          {/* <div className="col-md-6"></div> */}
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label>Borrower Name<span style={{ color: "red" }}>*</span><span className="inputEgMsg">Eg. Mr.Vijayakumar / Smt.Subbulakshmi / Miss.Devi / Jayasri Traders</span></label>
                </div>
              </div>
              <input
                className={this.props.required && this.state.borrower_name === '' ? 'form-control is-invalid' : 'form-control'}
                placeholder="Ex - Chennai Silks / Mr. Ramasamy"
                name="borrower_name"
                type="text"
                value={this.state.borrower_name}
                onChange={e => this.handleChange(e)}
              />
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label>App Id<span style={{ color: "red" }}>*</span><span className="inputEgMsg">If App ID Not Available / There is No App ID, Use 'NIL'</span></label>
                </div>
              </div>
              <input
                className={this.props.required && this.state.app_id === '' ? 'form-control is-invalid' : 'form-control'}
                name="app_id"
                placeholder="Example : Application No / LS No / Leaf ID / Proposal No"
                type="text"
                value={this.state.app_id}
                onChange={e => this.handleChange(e)}
              />
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>LH Branch Contact Number<span style={{ color: "red" }}>*</span></label>
                </div>
              </div>
              <input
                className={this.props.required && this.state.contact_number === '' ? 'form-control is-invalid' : 'form-control'}
                placeholder="Ex - 9876543210"
                name="contact_number"
                type="text"
                value={this.state.contact_number}
                onChange={e => this.handleChange(e)}
              />
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>Loan Amount<span style={{ color: "red" }}>*</span></label>
                </div>
              </div>
              <input
                className={this.props.required && this.state.loan_amount === '' ? 'form-control is-invalid' : 'form-control'}
                placeholder="Eg. Rs.6,00,000"
                name="loan_amount"
                type="text"
                value={this.state.loan_amount}
                onChange={e => this.handleChange(e)}
              />
              <div className="invalid-feedback">*Required Field</div>
            </div>
          </div>

        </div>
        <div className=" text-right">
          <button
            type="button"
            className="btn btn-primary btn-sm "
            onClick={() => this.addBro()}
          >
            Add BRO +
          </button>
        </div>
        <div>
          {this.state.brolist && this.state.brolist.length
            ? this.state.brolist.map((broItem: BroItem, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label style={{ width: "300px" }}>
                          <a onClick={() => this.removeBro(broItem, index)}>
                            <i className="feather icon-trash deleteicon-trash" />
                          </a>
                          Branch / Credit / Sales - Manager Name
                        </label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      value={broItem.name}
                      onChange={e => this.handleBroChange(e, index)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Designation</label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Ex - Credit / Sales Managers"
                      name="designation"
                      type="text"
                      value={broItem.designation}
                      onChange={e => this.handleBroChange(e, index)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile</label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="mobile"
                      type="number"
                      maxLength={10}
                      value={broItem.mobile}
                      onChange={e => this.handleBroChange(e, index)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Email</label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={broItem.email}
                      onChange={e => this.handleBroChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            ))
            : ""}
        </div>

        <div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => this.addTitle()}
            >
              Add Title Deed Info +
            </button>
          </div>

          {titlelist ? titlelist.map((titleitem: TitleItem, index: number) => (
            <div key={index}>
              <span className="indexNo">Title Info - {index + 1}</span>
              <button style={{ outline: "none", border: "none", cursor: titlelist.length === 1 ? "not-allowed" : "pointer" }} type="button" onClick={() => this.removeTitle(titleitem, index)} disabled={titlelist.length === 1}>
                <i className="feather icon-trash deleteicon-trash" />
              </button>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Property Owner Name<span style={{ color: "red" }}>*</span><span className="inputEgMsg">Eg. Mr.Vijayakumar / Smt.Subbulakshmi / Miss.Devi / Miss.Devi</span>
                        </label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Ex - Title Owner / Current Owner / Mortgagor"
                      value={titleitem.name ? titleitem.name : ""}
                      onChange={(e) => this.handletitleChange(e, index, "name")}
                    />
                  </div>
                  {this.props.required && !titleitem.name && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label style={{ width: "300px" }}>
                          Schedule / Item No / Plot No / NA<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="schedule"
                      placeholder="Eg. A-Schedule / Item No-3 / Plot No-3A"
                      value={titleitem.schedule ? titleitem.schedule : ""}
                      onChange={(e) => this.handletitleChange(e, index, "schedule")}
                    />
                  </div>
                  {this.props.required && !titleitem.schedule && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label style={{ width: "300px" }}>
                          Document No<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="doc_no"
                      type="text"
                      placeholder="Eg.Doc.No-2609/2000"
                      value={titleitem.doc_no ? titleitem.doc_no : ""}
                      onChange={(e) => this.handletitleChange(e, index, "doc_no")}
                    />
                  </div>
                  {this.props.required && !titleitem.doc_no && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Document Type:<span style={{ color: "red" }}>*</span><span className="inputEgMsg">Eg. Partition Deed</span></label>
                      </div>
                    </div>
                    {this.state.deeds && this.state.deeds.length > 0 && this.state.deeds.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) &&
                      <Select options={this.state.deeds} name="doc_type"
                        className={this.state.required && titleitem.doc_type === '' ? 'form-select is-invalid' : 'form-select'}
                        value={this.state.deeds.find((o) => o.value == titleitem.doc_type ? titleitem.doc_type : "")}
                        onChange={(e) => this.handletitleChange(e, index, "doc_type")}
                      />
                    }
                  </div>
                  {this.props.required && !titleitem.doc_type && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                </div>
              </div>
              {titleitem.survey_no_extent && titleitem.survey_no_extent.map((surveyExtent, subIndex) => (
                <div key={subIndex}>
                  <span className="indexNo">Survey - {index + 1}<span>.</span>{subIndex + 1}</span>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label style={{ width: "300px" }}>
                              Survey Number<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <input
                          className="form-control"
                          name={`survey_no[${subIndex}]`}
                          placeholder="Eg.T.S.No-26, Ward-B, Block-33 / S.No-291/31"
                          value={surveyExtent.survey_no ? surveyExtent.survey_no : ""}
                          onChange={(e) => this.handleSurveyNoChange(e, index, subIndex)}
                        />
                      </div>
                      {this.props.required && !surveyExtent.survey_no && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label style={{ width: "300px" }}>
                              Extent<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <input
                          className="form-control"
                          name={`extent[${subIndex}]`}
                          placeholder="Eg.1300 Sq.Ft / 1.00 Acres / 100 Sq.Meter"
                          value={surveyExtent.extent ? surveyExtent.extent : ""}
                          onChange={(e) => this.handleExtentChange(e, index, subIndex)}
                        />
                      </div>
                      {this.props.required && !surveyExtent.extent && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                    </div>

                    <div className="col-md-2">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label style={{ width: "300px" }}>
                              Unit<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        {this.state.extentUnits &&
                          this.state.extentUnits.length > 0 &&
                          this.state.extentUnits.map((opt: any) => {
                            opt.label = opt.name;
                            opt.value = opt.id;
                            return opt; // Make sure to return the modified object
                          }) && (
                            <Select
                              className="form-select"
                              options={this.state.extentUnits}
                              name="sur_extent_unit"
                              value={this.state.extentUnits.find(
                                (o: any) => o.value === surveyExtent.sur_extent_unit
                              )}
                              onChange={(selectedOption) =>
                                this.handleunitChange(selectedOption, index, subIndex)
                              }
                            />
                          )}
                      </div>
                      {this.props.required && !surveyExtent.sur_extent_unit && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                    </div>

                    <div className="col-md-2" style={{ margin: "2% 0% 2% 0%" }}>
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-danger mt-2"
                        data-toggle="modal"
                        style={{ width: "30px", height: "30px", padding: "4px 6px" }}
                        onClick={() => this.removeSurveyExtent(index, subIndex, surveyExtent)}
                        disabled={titleitem.survey_no_extent.length === 1}
                      >
                        <i className="feather icon-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-primary"
                        data-toggle="modal"
                        style={{ width: "30px", height: "30px", padding: "3px 6px" }}
                        onClick={() => this.addSurveyExtent(index)}
                      >
                        <i className="feather icon-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )) : ""}
        </div>

        {this.state.file_idfk != 0 && (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Add Hints About File</label>
                <textarea
                  placeholder=""
                  name="userHintAboutFile"
                  value={this.state.userHintAboutFile}
                  onChange={e => this.handleChange(e)}
                  className="form-control"
                  style={{ height: '10vh' }}
                />
              </div>
            </div>
            <button onClick={this.saveHint} type="button" className="btn btn-rounded btn-success" style={{ height: "40px", marginTop: "5%" }}>
              Save
            </button>
          </div>
        )}


        {/* <div className=" text-right">
          <button
            type="button"
            className="btn btn-primary btn-sm "
            onClick={() => this.addProperty()}
          >
            Add Property Owner +
          </button>
        </div>
        {this.state.privateList && this.state.privateList.length
          ? this.state.privateList.map(
            (PropertyOwnerItem: PropertyOwnerItem, index: number) => (
              <div className="row" key={index}>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <a
                            onClick={() =>
                              this.removeProperty(PropertyOwnerItem, index)
                            }
                          >
                            <i className="feather icon-trash" />
                          </a>
                          Property Owner Name
                        </label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Ex - Title Owner / Current Owner / Mortgagor"
                      type="text"
                      value={PropertyOwnerItem.name}
                      onChange={e => this.handlePropertyChange(e, index)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile</label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="mobile"
                      type="number"
                      minLength={10}
                      maxLength={10}
                      value={PropertyOwnerItem.mobile}
                      onChange={e => this.handlePropertyChange(e, index)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Email</label>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={PropertyOwnerItem.email}
                      onChange={e => this.handlePropertyChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            )
          )
          : ""} */}




        {/* <div className="text-right">
          <button
            type="button"
            className="btn btn-primary btn-sm "
            onClick={this.handleSubmit}
          >
            Next
          </button>
        </div> */}
      </>
    );
  }
}
export default BankForm;
