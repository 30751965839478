import React from 'react';
import { CSVLink } from 'react-csv';
import { getFileStatus } from '../../utils/fileStatus';
const RectificationExcelComponent = ({ data }) => {
    const csvData = data.map((item, index) => {
        const reason = item['rectify_data']?.length > 0 ? item['rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "";
        const status = item['rectify_data']?.length > 0 ? item['rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "";
        const received_dt = item['rectify_data']?.length > 0 ? item['rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "";
        const completed_dt = item['rectify_data']?.length > 0 ? item['rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "";
        return {
            "Sl.No": index + 1,
            "File No": item.file_name,
            "Property Owner Name": item.owner_name,
            "Borrower Name": item.borrower_name,
            "File Status": getFileStatus(item.file_status),
            "Bank Name": item.bank_name,
            "Bank Branch Name": item.bank_branch_name,
            "Law Hands Branch": item.lawhands_branch_name,
            // first rectification
            "1st Rectification Reason": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "1st Rectification Status": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "1st Rectification Received Date": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "1st Rectification Picked Date": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "1st Rectification Querie Raised Date": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "1st Rectification Completed Date": item['first_rectify_data']?.length > 0 ? item['first_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
            // second rectification
            "2nd Rectification Reason": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "2nd Rectification Status": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "2nd Rectification Received Date": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "2nd Rectification Picked Date": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "2nd Rectification Querie Raised Date": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "2nd Rectification Completed Date": item['second_rectify_data']?.length > 0 ? item['second_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
            // third rectification
            "3rd Rectification Reason": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "3rd Rectification Status": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "3rd Rectification Received Date": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "3rd Rectification Picked Date": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "3rd Rectification Querie Raised Date": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "3rd Rectification Completed Date": item['third_rectify_data']?.length > 0 ? item['third_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
            // fourth rectification
            "4th Rectification Reason": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "4th Rectification Status": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "4th Rectification Received Date": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "4th Rectification Picked Date": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "4th Rectification Querie Raised Date": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "4th Rectification Completed Date": item['fourth_rectify_data']?.length > 0 ? item['fourth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
            // fifth rectification
            "5th Rectification Reason": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "5th Rectification Status": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "5th Rectification Received Date": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "5th Rectification Picked Date": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "5th Rectification Querie Raised Date": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "5th Rectification Completed Date": item['fifth_rectify_data']?.length > 0 ? item['fifth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
            // sixth rectification
            "6th Rectification Reason": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.reason}`).join('\n') : "",
            "6th Rectification Status": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectification_status}`).join('\n') : "",
            "6th Rectification Received Date": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_received_dt}`).join('\n') : "",
            "6th Rectification Picked Date": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_picked_dt}`).join('\n') : "",
            "6th Rectification Querie Raised Date": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_querie_raised_dt}`).join('\n') : "",
            "6th Rectification Completed Date": item['sixth_rectify_data']?.length > 0 ? item['sixth_rectify_data'].map((rectify, index) => `${index + 1}] ${rectify.rectify_completed_dt}`).join('\n') : "",
        };
    });
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    return (
        <CSVLink data={csvData} className="btn btn-outline-success" filename={`Rectification Report - ${formattedDate}.csv`}>
            Download Excel
        </CSVLink>
    );
};
export default RectificationExcelComponent;