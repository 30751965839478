import React, { useState } from 'react';
import "./NotifyModal.css"


const NotifyModal = ({ notifyData, updateReadStatus = () => { } }) => {

    const [readStatus, setReadStatus] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const updateReadStatusHandle = () => {
        setReadStatus(false);
        setCurrentIndex(0);
        updateReadStatus();
    }
    return (
        <div
            className="modal fade notify"
            id="NotifyModal"
            role="dialog"
        >
            <div
                className="modal-dialog"
                role="document"
                style={{ maxWidth: '900px' }}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title notify-head">
                            Notice Board
                        </h5>
                    </div>
                    <div
                        className="modal-body"
                        style={{ maxHeight: '500px' }}
                    >
                        {notifyData && notifyData[currentIndex] && (
                            <div style={{ padding: '0px 30px' }}>
                                <div style={{ marginTop: '20px' }}>
                                    <p
                                        className='notify-title'>
                                        {notifyData[currentIndex].title}
                                    </p>
                                </div>
                                <div>
                                    <p className='notify-desc'>
                                        {notifyData[currentIndex].information}
                                    </p>
                                </div>
                            </div>
                        )}
                        {console.log("notifyData.length -> ", notifyData.length)}
                        {notifyData && notifyData.length > 1 &&
                            <div
                                style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 30px', marginTop: '5%' }} >
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        if (currentIndex !== 0) {
                                            setCurrentIndex(currentIndex - 1)
                                        }
                                    }}
                                    style={currentIndex > 0 ?
                                        { userSelect: 'none', color: 'black' } : { userSelect: 'none', color: 'lightgrey' }}>
                                    <i className="feather icon-chevron-left" /> Prev
                                </div>
                                <div className='cursor-pointer'
                                    style={currentIndex + 1 < notifyData.length ? { marginLeft: '2%', userSelect: 'none', color: 'black' } : { marginLeft: '2%', userSelect: 'none', color: 'lightgrey' }}
                                    onClick={() => {
                                        console.log("currentIndex",notifyData.length )
                                        if (currentIndex + 1 < notifyData.length) {
                                            setCurrentIndex(currentIndex + 1)
                                        }
                                    }}>Next <i className="feather icon-chevron-right" /></div>
                                    
                            </div>}
                    </div>
                    <div
                        className="modal-footer"
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, padding: '0px 10px' }}>
                            <div
                                className="custom-control custom-checkbox"
                                style={{ paddingLeft: 0 }}
                            >
                                <input
                                    type="checkbox"
                                    className=""
                                    id="notifyCheckbox"
                                    disabled={!(notifyData.length === currentIndex + 1)}
                                    checked={readStatus}
                                    onChange={e => {
                                        setReadStatus(e.target.checked);
                                    }
                                    }
                                />
                                <label className="" htmlFor='notifyCheckbox' style={{ marginLeft: '1%', userSelect: 'none' }}>
                                    I have read all notices
                                </label>
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                disabled={!readStatus}
                                onClick={updateReadStatusHandle}
                            >
                                {"Ok & Close"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default NotifyModal;