import React, { useState, useEffect } from 'react'
import { isEmpty, get } from 'lodash'
import { callApi } from '../../utils/api';

const AddPolicy = (props) => {
  const { policy, onBack, isEdit } = props
  const [ tempPolicyList, setTempPolicyList ] = useState([ policy ]);

  const handleAddNewPolicy = () => {
    let newPolicyList = JSON.parse(JSON.stringify(tempPolicyList))
    newPolicyList = [ ...newPolicyList, { policy: '' } ]
    setTempPolicyList(newPolicyList)
  }

  const handlePolicyChange = (value, index) => {
    let newPolicyList = JSON.parse(JSON.stringify(tempPolicyList))
    newPolicyList[index] = {
      ...newPolicyList[index],
      policy: value
    };
    setTempPolicyList(newPolicyList)
  }

  const handleDeletePolicy = (index) => {
    let newPolicyList = JSON.parse(JSON.stringify(tempPolicyList))
    newPolicyList.splice(index, 1)
    if (newPolicyList.length <= 0) {
      newPolicyList = [ ...newPolicyList, { policy: '' } ]
    }
    setTempPolicyList(newPolicyList)
  }

  const handleSavePolicy = () => {
    const policyList = tempPolicyList.filter(tempPolicy => tempPolicy.policy)
    callApi("POST", "policy_update.php", { data: [ ...policyList ] })
      .then(res => res.data)
      .then(response => {
        onBack()
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <div className="policymain-container">
      <div className="pcoded-content">
        <div className="card">
          <div className="card-header display-flex align-items-center policy-header">
            <button className="btn policy-back-button" onClick={onBack}><i className="feather icon-arrow-left"></i></button>
            <h5>{isEdit ? 'Edit Policy' : 'Add Policy'}</h5>
          </div>
          <div className="card-body">
            {tempPolicyList.map((temppolicy, index) => {
              return (
                <div className="display-flex flex-align-center policy-input-wrapper" key={index}>
                  <textarea type="text" name={`policy-${index}`} className="form-control policy-input" value={temppolicy.policy} onChange={event => handlePolicyChange(event.target.value, index)} />
                  {!isEdit && <div className="col-md-1 policy-button">
                    <button disabled={isEmpty(temppolicy.policy)} onClick={() => handleDeletePolicy(index)} className="btn btn-icon btn-rounded btn-danger">
                      <i className="feather icon-minus" />
                    </button>
                  </div>}
                </div>
              )
            })}
            {!isEdit && <div className="col-md-1 add-policy-button policy-button">
              <button className="btn btn-icon btn-rounded btn-primary" onClick={handleAddNewPolicy}><i className="feather icon-plus"></i></button>
            </div>}
            <div className="float-right">
              <button className="btn btn-success" onClick={handleSavePolicy} disabled={isEmpty(tempPolicyList)}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPolicy