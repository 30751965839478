import * as React from "react";
import { callApi } from "../../utils/api";
interface AllProps {
  history: any;
  match: any;
}
class AddBranch extends React.Component<AllProps> {
  state = {
    branchdetail: {
      id: 0,
      name: "",
      email: "",
      mob_no: "",
      branch: "",
      password:"",
      address: ""
    }
  };
  componentDidMount() {
    callApi("POST", "get_branch_single.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response && response.data && response.data.length) {
          this.setState({ branchdetail: response.data[0] });
        }
      });
  }
  handleChange = (e: any) => {
    const branchdetail: any = this.state.branchdetail;
    branchdetail[e.target.name] = e.target.value;
    this.setState({ branchdetail });
  };
  saveBranch = () => {
    if (Number(this.props.match.params.id)) {
      this.state.branchdetail.id = this.props.match.params.id;
      callApi("POST", "branch_update.php", this.state.branchdetail)
        .then(res => res.data)
        .then(response => {
          this.props.history.push("/branch");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      callApi("POST", "branch_create.php", this.state.branchdetail)
        .then(res => res.data)
        .then(response => {
          this.props.history.push("/branch");
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  render() {
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Branch Form</h5>
                          <button
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            onClick={() => this.props.history.goBack()}
                          >
                            Back
                          </button>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.name}
                                  placeholder="Full Name"
                                />
                              </div>
                              <div className="form-group">
                                <label>Phone</label>
                                <input
                                  type="number"
                                  name="mob_no"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.mob_no}
                                  placeholder="Mobile"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.email}
                                  placeholder="Email"
                                />
                              </div>
                              <div className="form-group">
                                <label>Address</label>
                                <textarea
                                  name="address"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.address}
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>App-Password</label>
                                <input
                                  type="text"
                                  name="password"
                                  onChange={e => this.handleChange(e)}
                                  className="form-control"
                                  value={this.state.branchdetail.password}
                                  placeholder="App Password"
                                />
                              </div>
                            </div>
                            <div className="col-md">
                            <button
                              type="submit"
                              style={{position:"absolute",top:"22px"}}
                              className="btn btn-primary"
                              onClick={this.saveBranch}
                            >
                              Submit
                            </button>
                            </div>

                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddBranch;
