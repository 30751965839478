import React, { Component } from "react";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import _ from 'lodash';
import moment from "moment";
import DeviationExcelComponent from "./DeviationExcelComponent";
import RectificationReportTable from "./RectificationReportTable";
import RectificationExcelComponent from "./RectificationExcelComponent";
declare var $: any;
interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
}

export default class RectificationReport extends Component<AllProps> {
    state = {
        outreason: "",
        selectVal: false,
        searchText: "",
        reports: [],
        filteredReports: [],
        lawhandsBranches: [],
        status_id: "",
        type: "",
        isLoading: false,
        users: [],

    }

    componentDidMount() {
        this.getLawHandsBranches();
        this.getOutTime();
        this.ouTime();
    //    this.getUsers();

    }

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    this.setState({ isLoading: false });
                    console.log("76", response);
                    sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    localStorage.removeItem("url");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }


    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data }, () => {
                    const id = this.props.match.params.id;
                    const type = this.props.match.params.name;
                    this.getSubReport(id, type);
                    console.log(id);
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    redirectToDashboard = (id) => {
        if (id !== undefined) {
            let obj = { "id": id }
            let status = this.state.status_id;
            let type = this.state.type;
            const url = `/deviation-report/${type}/ ${status}`;
            localStorage.setItem("url", url);
            localStorage.setItem("thisPopup", JSON.stringify(obj))
            sessionStorage.setItem("currentfileId", JSON.stringify(id));
            this.props.history.push("/file-dashboard/" + id);
        }
    }
    getSubReport(id, name) {
        const { state } = this.props.history.location;
        const filterData = state && state.filterData;
        this.setState({ status_id: id, type: name })
        let payload: any = {
            status: id,
            type: name
        }
        if (filterData !== null && filterData !== undefined) {
            if (filterData.isRaised) {
                filterData.reportType = "Raised";
            }
            delete filterData.isRaised
            payload = { ...payload, ...filterData }
        }
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        payload = {
            ...payload,
            roleId: userDetails ? userDetails.role_id : null,
            userId: userDetails ? userDetails.userId : null,
            roleType: userDetails ? userDetails.roleType : null,
        }
        callApi("POST", "rectify_subreport.php", payload)
            .then(res => res.data)
            .then(result => {
              
                if (result.result == true) {
                    let data = result.data;
                    this.setState({ reports: data, filteredReports: data }, () => {
                        console.log("filteredReports--->set---", this.state.filteredReports);
                    });
                }
        
                
            })
            .catch(err => {
                console.log(err);
            });
    }
    getBankBrancheById = (id) => {
        const branches = this.state.lawhandsBranches;
        console.log(branches, id);
        const branch = _.filter(branches, ['id', id])[0];
        return branch === undefined ? '' : branch.name;
    }
    handleSearchChange = (e) => {
        const { reports } = this.state;

        const list = reports.filter((item: any) => {
            const id = item.fileId.toLowerCase();
            return id.includes(e.target.value.toLowerCase());
        });
        console.log("listlist--->send data",list);
        
        console.log(e.target.value, list);
        this.setState({
            searchText: e.target.value,
            filteredReports: list
        });
    }

    // getUsers = () => {
    //     callApi("GET", "user_get.php")
    //       .then((res) => res.data)
    //       .then((response) => {
    //         this.setState({ users: response.data });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   };
    
    //   getNameById(id) {
    //     const { users }: any = this.state;
    //     for (let i = 0; i < users.length; i++) {
    //       if (users[i].id === id) {
    //         return users[i].name;
    //       }
    //     }
    //     return null;
    //   }


    render() {
        const { searchText, filteredReports, selectVal } = this.state;
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Rectification Report</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="dt-responsive table-responsive">
                                                            <RectificationExcelComponent data={filteredReports} />
                                                            <RectificationReportTable redirectToDashboard={this.redirectToDashboard} reports={filteredReports} getBranch={this.getBankBrancheById} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={this.BacktoFile}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}