import * as React from "react";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import { Branch } from "../branch/ManageBranch";
interface AllProps {
  history: any;
  match: any;
}
class VisitorAdd extends React.Component<AllProps> {
  state = {
    errorteamType: false,
    errorFind: false,
    isDocPreviewLoading: false,
    visitorId: "",
    userdetail: {
      visitorName: "",
      phoneNo: "",
      bankName: "",
      purpose: "",
    },
    required: false,
    bankList: [],
    purposeList: [],
    reporters: []
  };
  componentDidMount() {
    this.listBanks();
    this.listPurpose();
  }

  listBanks = () => {
    callApi("POST", "bank_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ bankList: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  listPurpose = () => {
    callApi("POST", "pupose_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ purposeList: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  handleChange = (e: any) => {
    const userdetail: any = this.state.userdetail;

    userdetail[e.target.name] = e.target.value;


    this.setState({});
  };
  saveUser = (e) => {
    e.preventDefault();

    this.props.match.params.id = Number(this.props.match.params.id);
    if (this.state.userdetail.visitorName && this.state.userdetail.phoneNo && this.state.userdetail.bankName && this.state.userdetail.purpose) {
      callApi("POST", "visitor_insert.php", this.state.userdetail)
        .then(res => res.data)
        .then(response => {
          console.log("visitor_insert", response.id);
          this.setState({ visitorId: response.id });
          this.setState({ isDocPreviewLoading: true });
          this.setState({
            userdetail: {
              visitorName: "",
              phoneNo: "",
              bankName: "",
              purpose: "",
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({ required: true });
      this.setState({ isDocPreviewLoading: false })
      ToastsStore.error("Required fields are Empty");
    }

  };
  render() {
    return (


      <><div className="card" style={{
        width: "90%",
        margin: "auto",
        borderRadius: "15px",
        marginTop: "23%",
        boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px"
      }}>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT} />
        <div className="card-header" style={{
          padding: "10px",
          textAlign: "center",
          fontSize: "16px"
        }}>
          <h5>Visitor's Entry</h5>
        </div>
        <div className="card-body">
          <form className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Visitor's Name<span style={{ color: "#FF0000" }}>*</span></label>
                <input
                  type="text"
                  style={{ border: "none", boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px" }}
                  className={this.state.required && this.state.userdetail.visitorName === '' ? 'form-control is-invalid' : 'form-control'}
                  placeholder="Enter Full Name"
                  onChange={e => this.handleChange(e)}
                  name="visitorName"
                  value={this.state.userdetail.visitorName} />
                <div className="invalid-feedback">This is a required field, kindly add Visitor's Name here.</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Visitor's Mobile Number<span style={{ color: "#FF0000" }}>*</span></label>
                <input
                  type="tel"
                  style={{ border: "none", boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px" }}
                  className={this.state.required && this.state.userdetail.phoneNo === '' ? 'form-control is-invalid' : 'form-control'}
                  placeholder="Enter Mobile Number"
                  name="phoneNo"
                  onChange={e => this.handleChange(e)}
                  value={this.state.userdetail.phoneNo} />
                <div className="invalid-feedback">This is a required field, kindly add Visitor's Mobile Number here.</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Bank Name<span style={{ color: "#FF0000" }}>*</span></label>
                <select
                  style={{ border: "none", boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px" }}
                  className={this.state.required && this.state.userdetail.bankName === '' ? 'custom-select is-invalid' : 'custom-select'}
                  name="bankName"
                  value={this.state.userdetail.bankName}
                  onChange={e => this.handleChange(e)}
                >
                  <option value="">Select the Bank</option>
                  {this.state.bankList &&
                    this.state.bankList.length
                    ? this.state.bankList.map((itm: any) => {
                      return (
                        <option value={itm.id}>
                          {itm.name}
                        </option>
                      );
                    })
                    : ""}
                </select>
                <div className="invalid-feedback">This is a required field, kindly add Bank here.</div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Purpose<span style={{ color: "#FF0000" }}>*</span></label>
                <select
                  style={{ border: "none", boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px" }}
                  className={this.state.required && this.state.userdetail.purpose === '' ? 'custom-select is-invalid' : 'custom-select'}
                  name="purpose"
                  value={this.state.userdetail.purpose}
                  onChange={e => this.handleChange(e)}
                >
                  <option value="">Select Purpose</option>
                  {this.state.purposeList &&
                    this.state.purposeList.length
                    ? this.state.purposeList.map((itm: any) => {
                      return (
                        <option value={itm.name}>
                          {itm.name}
                        </option>
                      );
                    })
                    : ""}
                </select>
                <div className="invalid-feedback">This is a required field, kindly add Purpose here.</div>
              </div>
            </div>
            <div className="col-md-6">
              <button
                type="submit"
                className="btn mt-3 btn-primary"
                data-toggle="modal"
                data-target="#doc-preview"
                style={{
                  width: "50%",
                  margin: "auto",
                  display: "block",
                  boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px"
                }}
                onClick={(e) => this.saveUser(e)}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

        <div className="modal fade" id="doc-preview" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              {this.state.isDocPreviewLoading == false && (
                <div style={{ border: "none" }} className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle" style={{ margin: "auto" }}>OOPS....!!!!</h5>
                </div>
              )}
              {this.state.isDocPreviewLoading == true && (
                <div style={{ border: "none" }} className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle" style={{ margin: "auto" }}>Congratulations</h5>
                </div>
              )}
              {this.state.isDocPreviewLoading == true && (
                <div className="modal-body">
                  <h6 style={{ fontSize: "16px !important" }}>It has been Successfully recorded that you visited Law Hands.</h6>
                  <h6 style={{
                    fontSize: "15px !important",
                  }}>You Visitor Id is <span style={{
                    fontWeight: "bolder",
                    color: "#0d8b0d"
                  }}>{this.state.visitorId}</span> </h6>
                  <h5>Thank you.</h5>

                </div>
              )}
              {this.state.isDocPreviewLoading == false && (
                <div className="modal-body">
                  <h6 style={{ fontSize: "16px !important", textAlign: "center", color: "#fe0000" }}>Please Enter all the details which are required</h6>
                </div>
              )}
              <div style={{ border: "none" }} className="modal-footer">
                <button type="button" style={{
                  margin: "auto",
                  background: "#0169d9",
                  width: "35%"
                }} className="btn btn-secondary" data-dismiss="modal">Okay</button>
              </div>
            </div>
          </div>
        </div>

      </>

    );
  }
}
export default VisitorAdd;
