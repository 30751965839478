import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import Select from 'react-select';
import moment from 'moment';
import './Notification.css';
// import './process_eod.css'
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
// import { initializeApp } from "firebase/app";
// import { getDatabase, ref, onValue, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, query } from "firebase/database";
import { FIREBASE_CONFIG, FIREBASE_PNotifyCountDown } from "../../utils/constant";
import { apiCallHelper } from '../../utils/commonAPI';
import { notifyToUser, updatePNFirebaseReadStatus } from '../../utils/firebase';
import DatePicker from 'antd/lib/date-picker';

declare var $: any;
// interface AllProps {
//     history: any;
//   }
export class PushNotification extends Component {
    state = {
        inputDisable: true,
        rejectReason: "",
        acceptDate: "",
        fileid: "",
        assignedBy1: "",
        durationtime: "",
        TypingComplete: [],
        FileRejected: [],
        FileAssigned: [],
        NewLogin: [],
        NewFile: [],
        ReassignSuccessfully: [],
        VerificationAssigned: [],
        QueryUploaded: [],
        QueryRaised: [],
        VerificationCompletd: [],
        VerificationRejected: [],
        Newfilecreate: [],
        NotificationData: [],
        notications: [],
        user: {
            role_id: ""
        },
        filteredNotifications: [],
        searchText: ""
    }
    pushNotifyData: any;
    componentDidMount() {
        initializeApp(FIREBASE_CONFIG);
        const user: any = sessionStorage.getItem("user");
        this.setState({ user: user ? JSON.parse(user)["userId"] : "" });
        // setInterval(() => {
        this.getpushNotificationList();
        // }, 3000);

    }
    selfAssignPickFile = (modal: any, assignby: any) => {
        this.setState({ fileid: modal, assignedBy1: assignby })
        $("#doc-preview").modal("show");
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    verificationApi=(modal: any, assignby: any,type:any)=>{
        if(type=="Accept"){
            this.setState({ fileid: modal, assignedBy1: assignby,inputDisable:true})
            $("#verficationModal").modal("show");
        }else{
            this.setState({ fileid: modal, assignedBy1: assignby,inputDisable:false})
            $("#verficationModal").modal("show");
        }

    }
    VerifyPicked=(acceptType:any)=>{
        if(acceptType=="Accepted"){
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            const file_Id = this.state.fileid;
            let obj = {
                reason: `Date:${this.state.acceptDate} and Time:${this.state.durationtime}`,
                type:"VerificationAssigned",
                notification_type:"verificationassign",
                title: `${file_Id} Verification File accepted `,
                fileId: file_Id,
                updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                user_id: userId,
                status: 1
            }
            callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {

                    const user: any = sessionStorage.getItem("user");
                    const userId = user ? JSON.parse(user)["userId"] : "";
                    // const userId = user ? JSON.parse(user)["userId"] : "";
                    const file_Id = this.state.fileid;
                    this.FileVerificationApi(file_Id,"accepted")
                    const notifyData = {
                        fileId: file_Id,
                        title: `${file_Id} Verivication File accepted Date:${this.state.acceptDate} and Time:${this.state.durationtime}`,
                        assignedTo: this.state.assignedBy1,
                        assignedBy: userId,
                        notifyType: "message",
                    };
                    notifyToUser("user", { ...notifyData, notifyType: 'message' }, [this.state.assignedBy1]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }else{
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            const file_Id = this.state.fileid;
            let obj = {
                reason: `${file_Id} File Rejected Reason:${this.state.rejectReason}`,
                type:"VerificationAssigned",
                notification_type:"verificationassign",
                title: `${file_Id} File Verification Rejected `,
                fileId: file_Id,
                updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                user_id: userId,
                status: 1
            }
            callApi("POST", "pushnotification.php", obj)
                .then((res) => res.data)
                .then((response) => {
                    const user: any = sessionStorage.getItem("user");
                    const userId = user ? JSON.parse(user)["userId"] : "";
                    // const userId = user ? JSON.parse(user)["userId"] : "";
                    const file_Id = this.state.fileid;
                    this.FileVerificationApi(file_Id,"Reject");
                    const notifyData = {
                        fileId: file_Id,
                        title: `${file_Id} Verification File Rejected Reason:${this.state.rejectReason}`,
                        assignedTo: this.state.assignedBy1,
                        assignedBy: userId,
                        notifyType: "message",
                    };
                    notifyToUser("user", { ...notifyData, notifyType: 'message' }, [this.state.assignedBy1]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }

    assignPickFile = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const file_Id = this.state.fileid;
        let obj = {
            reason: `Date:${this.state.acceptDate} and Time:${this.state.durationtime}`,
            notification_type: "assigned",
            type: "FileAssigned",
            title: `${file_Id} File accepted `,
            fileId: file_Id,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: userId,
            status: 1
        }
        callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {

                const user: any = sessionStorage.getItem("user");
                const userId = user ? JSON.parse(user)["userId"] : "";
                // const userId = user ? JSON.parse(user)["userId"] : "";
                const file_Id = this.state.fileid;
                this.fileAssignApi(file_Id,"fileaccept")
                const notifyData = {
                    fileId: file_Id,
                    title: `${file_Id} File accepted Date:${this.state.acceptDate} and Time:${this.state.durationtime}`,
                    assignedTo: this.state.assignedBy1,
                    assignedBy: userId,
                    notifyType: "message",
                };
                notifyToUser("user", { ...notifyData, notifyType: 'message' }, [this.state.assignedBy1]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
 
    RejectTheFile = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const file_Id = this.state.fileid;
        let obj = {
            reason: `${file_Id} File Rejected Reason:${this.state.rejectReason}`,
            notification_type: "assigned",
            type: "FileAssigned",
            title: `${file_Id} File Rejected `,
            fileId: file_Id,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            user_id: userId,
            status: 1
        }
        callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
                const user: any = sessionStorage.getItem("user");
                const userId = user ? JSON.parse(user)["userId"] : "";
                // const userId = user ? JSON.parse(user)["userId"] : "";
                const file_Id = this.state.fileid;
                this.fileAssignApi(file_Id,"rejecte");
                const notifyData = {
                    fileId: file_Id,
                    title: `${file_Id} File Rejected Reason:${this.state.rejectReason}`,
                    assignedTo: this.state.assignedBy1,
                    assignedBy: userId,
                    notifyType: "message",
                };
                notifyToUser("user", { ...notifyData, notifyType: 'message' }, [this.state.assignedBy1]);
            })
            .catch((err) => {
                console.log(err);
            });

    }
    fileAssignApi = (fileid: any,type:any) => {
        if(type=="fileaccept"){
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            let obj = {
                id: fileid,
                assigned_to:userId,
            }
            callApi("POST", "assign.php", obj)
                .then((res) => res.data)
                .then((response) => {
                })
                .catch((err) => {
                    console.log(err);
                });
        }else{
            const user: any = sessionStorage.getItem("user");
        const userId = 0;
        let obj = {
            id: fileid,
            assigned_to:userId,
        }
        callApi("POST", "assign.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
        }
        
    }
    getpushNotificationList = () => {
        const user: any = sessionStorage.getItem("user");
        let userId: any = JSON.parse(user)["userId"] || null
        if (user) userId = JSON.parse(user)["userId"] || null;
        let obj = {
            // user_id: userId,
            user_id: userId,
        }
        callApi("POST", "pushnotification_get.php", obj)
            .then((res) => res.data)
            .then((response) => {
                const pushNotifyData: any = response.responseJson.data;
                console.log("187", pushNotifyData);

                this.setState({ NotificationData: response.responseJson.data })
            })
            .catch((err) => {
                console.log(err);
            });
    }
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };

    fileReject = (fileid: any, assignedBy1: any) => {
        this.setState({ fileid: fileid, assignedBy1: assignedBy1 })
        $("#fileReject").modal("show");
    }

    updateNotifyDetails = (
        notifyData: any,
        text: string,
        type: string,
        userId: string
    ) => {
        if (!!notifyData) {
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            callApi("POST", "notification.php", {
                file_id: notifyData.fileId,
                user_id: userId,
                reason: text,
                status: "1",
                picked_time: moment().format("HH:mm"),
            })
                .then((response) => {

                    updatePNFirebaseReadStatus(notifyData.id, userId, text, type, notifyData.updatedAt);
                })
                .catch((error) => {
                    console.log("Error");
                });
        }
    };
    FileVerificationApi=(fileid:any,reason:any)=>{
        if(reason=="accepted"){
console.log("fileaccepted" );
        }else{
            console.log("FileRejected" );
        }

    }
    render() {
        const user1: any = sessionStorage.getItem("user");
        const role_id = user1 ? JSON.parse(user1)["role_id"] : "";
        const userDetails: any = user1 ? JSON.parse(user1) : "";
        const isProcessTeam = userDetails.role_id === "3";
        const { notications, user, filteredNotifications, searchText, NotificationData, Newfilecreate,inputDisable} = this.state;
        Newfilecreate.forEach((notification: any, index) => { notification.serial = index + 1 })

        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper notifyBackgroundCard">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">

                                            <div className="card">


                                            </div>
                                        </div>
                                    </div>  <table
                                        className=" _1OmBB assigned-table"
                                    >
                                        <thead>
                                            <tr >
                                                <th>ID</th>
                                                {isProcessTeam
                                                    ? (<th>Actions</th>) : null}
                                                <th>Notification Title</th>
                                                <th>Notification Date</th>
                                                <th>Notification Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.NotificationData && this.state.NotificationData.length
                                                ? this.state.NotificationData.map(
                                                    (query: any, index: number) => {
                                                        return (
                                                            <tr className="TableRow query-table-row cursor-pointer" key={index}>
                                                                <td className='tableCentre'>{index + 1}</td>

                                                                {query.title.includes("File Assigned") && isProcessTeam ? (<td className='tableCentre'>
                                                                    <button
                                                                        className="btn btn-success"
                                                                        onClick={() => this.selfAssignPickFile(query.file_id, query.assignedBy)}
                                                                    >
                                                                        Accept
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={() => this.fileReject(query.file_id, query.assignedBy)}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                </td>) : null
                                                                }
                                                                {query.title.includes("Verification Assigned") && isProcessTeam ? (<td className='tableCentre'>
                                                                    <button
                                                                        className="btn btn-success"
                                                                        onClick={()=>this.verificationApi(query.file_id, query.assignedBy,"Accept")}
                                                                    >
                                                                        Accept
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={()=>this.verificationApi(query.file_id, query.assignedBy,"Reject")}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                </td>) : null

                                                                }
                                                                {!query.title.includes("File Assigned") && !query.title.includes("File Verification Assigned") && isProcessTeam ? (<td className='tableCentre'></td>) : null}
                                                                <td className="tableCentre">
                                                                    {query.title}
                                                                </td>
                                                                <td className="tableCentre">
                                                                    {query.notify_date}
                                                                </td>
                                                                <td className="tableCentre">
                                                                    {query.reason}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                                : ""}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="doc-preview" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ height: '202px' }}>

                            <div className="modal-body">
                                <h4 style={{ textAlign: 'center' }}>Enter Your File Finishing Date & Time</h4>
                                <div>
                                    <input
                                        className='acceptName'
                                        placeholder='Eg.12/12/2022(DD/MM/YYYY )'
                                        name="acceptDate"
                                        onChange={this.onchange}
                                    />
                                    <input type="text"
                                        style={{ marginTop: '10px' }}
                                        className='acceptName'
                                        placeholder='Eg.23Hours Format(24Hours)'
                                        name="durationtime"
                                        onChange={this.onchange}
                                    />
                                </div>
                            </div>
                            <button type="button" style={{
                                margin: "auto",
                                background: "#0169d9",
                                width: "35%"
                            }} className="btn btn-secondary" data-dismiss="modal" onClick={() => this.assignPickFile()} >Okay</button>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="fileReject" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ height: '202px' }}>

                            <div className="modal-body">
                                <h4 style={{ textAlign: 'center' }}>Enter Your File Finishing Date & Time</h4>
                                <div>
                                    <input
                                        className='acceptName'
                                        placeholder='Eg.Valid Reason For File Reject'
                                        name="rejectReason"
                                        onChange={this.onchange}
                                    />
                                </div>
                            </div>
                            <button type="button" style={{
                                margin: "auto",
                                background: "#0169d9",
                                width: "35%"
                            }} className="btn btn-secondary" data-dismiss="modal" onClick={() => this.RejectTheFile()} >Okay</button>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="verficationModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ height: '202px',padding: "10px",boxShadow: "0 0 15px #010101" }}>

                            <div className="modal-body" style={{padding: "30px"}}>
                               {inputDisable ? (<> <h4 style={{ textAlign: 'center' }}>Enter Your Verification File Finishing Date & Time</h4></>):(<> <h4 style={{ textAlign: 'center' }}>Enter Your Reason Verification Reject</h4></>)}
                                <div>
                                    {inputDisable ? (<><input
                                        className='acceptName'
                                        placeholder='Eg.12/12/2022(DD/MM/YYYY )'
                                        name="acceptDate"
                                        onChange={this.onchange}
                                    />
                                    <input type="text"
                                        style={{ marginTop: '10px' }}
                                        className='acceptName'
                                        placeholder='Eg.23Hours Format(24Hours)'
                                        name="durationtime"
                                        onChange={this.onchange}
                                    /></>):(<input
                                        className='acceptName'
                                        placeholder='Eg.Valid Reason For File Reject'
                                        name="rejectReason"
                                        onChange={this.onchange}
                                    />)}
                                </div>
                            </div>
                            {inputDisable ? (<>
                                <button type="button" style={{
                                margin: "auto", width: "35%"
                            }} className="btn btn-success" data-dismiss="modal" onClick={() => this.VerifyPicked("Accepted")} >Okay</button></>):(<>
                            <button type="button" style={{
                                margin: "auto",width: "35%"
                            }} className="btn btn-danger" data-dismiss="modal" onClick={() => this.VerifyPicked("Rejected")} >Okay</button></>)}
                
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default PushNotification
