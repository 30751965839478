import React from 'react';
import ReactFileReader from "react-file-reader";
import Select from 'react-select';

const particularList = [
  {
    label: 'Original',
    value: 'original'
  },
  {
    label: 'Xerox',
    value: 'xerox'
  }
];
let Ec_dropdown = [
  {
    "name": "SRO Encumbrance Certificate",
    "key": "SRO Encumbrance Certificate"
  },
  {
    "name": "Online Encumbrance Certificate",
    "key": "Online Encumbrance Certificate"
  },
];

const DashUploadDocument = (props) => {
  const { index, baseDetails, handleDocumentDetailsChange, handleDocumentFilesChange, deleteFile, previewDoc, deleteDocumentDetails, categoriesList, newfile, path, deedList } = props
  const { docNoYear, particular, type, base, isValid, isSavedFile, base_url, doc_type } = baseDetails;
  const validTypes = ['deed', 'mother_doc', 'title_deed'];
  const invalidDocuments = ['26', '27', '36', '35', '38', '103', '78', '5', 'Online Encumbrance Certificate'];
  return (
    <div className="upload-document-wrapper">
      <div className="row upload-document-folder">
        <div className="col-md-1">
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label>{index == 0 && 'Sl.No'}</label>
              </div>
            </div>
            <p style={{ 'padding': '20%' }}>{index + 1}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Category</label>
            {categoriesList && categoriesList.length > 0 && categoriesList.map((opt) => { opt.label = opt.name; opt.value = opt.id }) &&
              <>
                <Select options={categoriesList} name="category"
                  value={categoriesList.find((o) => o.value === type)}
                  onChange={e => handleDocumentDetailsChange(e.value, index, 'type')}
                  placeholder="example: Title Deed"
                />
              </>
            }
            {!type && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
          </div>
        </div>

        {type == 'rev_rec_doc' || type == 'deed' || type == 'mother_doc' || type == 'title_deed' || type == 'ec_doc' || type == 'sale_agreement' ? (<>
          {/* {type == 'rev_rec_doc' ? (<> */}
          <div className="col-md-2">
            <div className="form-group">
              <label>Document Type<span style={{ color: "#FF0000" }}>*</span>
              </label>
              {type != "ec_doc" ? <>
                {deedList && deedList.length > 0 && deedList.map((opt) => {
                  opt.label = opt.name;
                  opt.value = opt.id;
                }) &&
                  <Select options={deedList} name="doc_type"
                    value={deedList.find((o) => o.value === doc_type)}
                    onChange={e => handleDocumentDetailsChange(e.value, index, 'doc_type')}
                  />
                }
              </> :
                <>
                  {Ec_dropdown && Ec_dropdown.length > 0 && Ec_dropdown.map((opt) => {
                    opt.label = opt.name;
                    opt.value = opt.name;
                  }) &&
                    <Select options={Ec_dropdown} name="doc_type"
                      value={Ec_dropdown.find((o) => o.value === doc_type)}
                      onChange={e => handleDocumentDetailsChange(e.value, index, 'doc_type')}
                    />
                  }
                </>
              }
              {!doc_type && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
            </div>
          </div>
        </>) : ""}

        {(type === "ec_doc" || validTypes.includes(type)) && (document === "SRO Encumbrance Certificate" || !invalidDocuments.includes(doc_type)) &&
          <>
            <div className="col-md-2">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label>Doc.No/Year</label>
                  </div>
                </div>
                <>
                  <input
                    className="form-control"
                    name="docNoYear"
                    placeholder="example: 1501/1996"
                    type="text"
                    value={docNoYear}
                    onChange={e => handleDocumentDetailsChange(e.target.value, index, 'docNoYear')}
                  />
                  {!docNoYear && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
                </>
              </div>
            </div>
          </>
        }

        <div className="col-md-2">
          <div className="form-group">
            <label>Particular</label>
            <>
              <Select options={particularList} name="particular"
                value={particularList.find((o) => o.value === particular)}
                onChange={(e) => handleDocumentDetailsChange(e.value, index, 'particular')}
                placeholder="example: Xerox"
              />
              {!particular && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
            </>
          </div>
        </div>
        <div className="col-md-2 upload-document-filereader" >
          <div className="form-group">
            <ReactFileReader
              fileTypes={[".pdf"]}
              base64={true}
              multipleFiles={false}
              handleFiles={e =>
                handleDocumentFilesChange(e, index, baseDetails)
              }
            >

              {!newfile && (<button className="ml2 btn btn-primary btnupload float-right" style={{ marginTop: "30px" }}>
                Upload +
              </button>)}

            </ReactFileReader>
            {!base && <p style={{ color: "red", fontSize: "14px" }}>Please upload.</p>}
          </div>
        </div>
        {!isSavedFile && <div className="col-md-1" style={{ marginTop: "20px" }}>
          <div style={{ padding: "8px" }} className="form-group">
            <a onClick={() => deleteDocumentDetails(index)}>
              <i style={{ fontSize: "25px" }} className="feather icon-trash" />
            </a>
          </div>
        </div>}
      </div>
      {base && <div className="col-md-4" key={index}>
        <div className="card card-border-c-red">
          <span
            onClick={() =>
              deleteFile(index)
            }
          >
            <i className="feather icon-x" />
          </span>
          <div className="card-body">
            <h5 className="card-title">
              <span className="mb-1 text-c-green">
                <i className="feather icon-file-text" />
              </span>
              <span>
                {base}
              </span>
              {path != "filedashboard" ? <>
                <span style={{ float: 'right' }}
                  data-toggle="modal"
                  data-target="#preview-document"
                  onClick={() =>
                    previewDoc(base_url)
                  }
                >
                  <i className="fa fa-eye" />
                </span>
              </> : ""}
            </h5>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default DashUploadDocument