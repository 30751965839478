import { CircularProgress } from "@material-ui/core";
import PDFViewer from "mgr-pdf-viewer-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore,
} from "react-toasts";
import { setTimeout } from "timers";
import { API_ENDPOINT, callApi } from "../../utils/api";
import { ADMIN_ROLE, FRONTOFFICE_ROLE, MANAGER_ROLE } from "../../utils/role";
import ReactFileReader from "react-file-reader";
import { saveAs } from "file-saver";
import { notifyToUser } from "../../utils/firebase";
import moment from "moment";
import { pushnotificationApi } from "../../utils/pushNotify";
import Spinner from "../PDF-Highlighter/Spinner";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
declare var $: any;
interface AllProps {
    id: any;
    isFinal: boolean;
    checkOverviewApproval: any;
    history: any;
    fileId: any;
    fileName: any
}
interface AllProps {
    match: any;
    history: any;
}
export default class SignatureFile extends Component<AllProps> {
    state = {
        showTitle: true,
        isComplete: true,
        report: "",
        signreport: "",
        rep_id: "",
        remote_users: [],
        isapproved: 0,
        office_users: [],
        responseURL: "",
        pdfPreview: "",
        realFileidfk: "",
        file: "",
        pdfurl: "",
        enableRefresh: true,
        isApprove: false,
        isVerify: false,
        action: "",
        reason: "",
        uploadFile: {
            base64: "",
            fileList: [{ name: "" }],
        },
        updateDownloadData: {
            reasonForDownload: [],
            updatedDetails: [],
        },
        lastUploadedDocument: {
            pdf: null,
            docx: null,
        },
        timer: 0,
        blockdownload: "",
        activetab: "wordkey"
    };
    rendered: any;
    componentDidMount() {
        // this.getFileOverview();
        this.scrutinyReport();
        // this.blockdownload();
        // if (this.props.isFinal) {
        //   this.getUsers();
        // }
        // this.checkApproveVerify();
        // this.getUpdateReasonData();
        setInterval(() => {
            this.incrementTimer();
        }, 1000);
        this.signatureReport();
    }
    // downloadPDF(pdf) {
    //     const linkSource = `data:application/pdf;base64,${pdf}`;
    //     const downloadLink = document.createElement("a");
    //     const fileName = "file.pdf";

    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    // }
    incrementTimer = () => {
        const { timer } = this.state;
        this.setState({ timer: timer + 1 });
    };
    blockdownload = () => {
        callApi("POST", "block_download_status.php", {
            file_idfk: this.props.match.params.id,
        })
            .then(res => res.data)
            .then(response => {
                this.setState({ blockdownload: response['responseJson'].data[0].block_download_status })
                console.log('blockdownload___>>>', this.state.blockdownload);
            })
            .catch(err => {
                console.log(err);
            });
    };
    getFileOverview = () => {
        callApi("POST", "final_report_get.php", {
            file_idfk: this.props.match.params.id,
            check: false,
        })
            .then((res) => res.data)
            .then((response) => {
                let realFileidfk = response.data[0].file_idfk;

                this.setState({
                    realFileidfk: 0,
                });
                this.delaylaoding(realFileidfk);
                if (response.data[0].file_idfk !== null) {
                    callApi(
                        "POST",
                        "print_preview.php?id=" + response.data[0].file_idfk,
                        0
                    )
                        .then((res) => res.data)
                        .then((response) => {
                            // console.log(response.data);
                            let previewURL = response.data;
                            let previewEcho = API_ENDPOINT + "api/" + previewURL;

                            let pdfPreview = response.data[0].file_idfk;
                            this.setState({
                                pdfPreview,
                            });
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    scrutinyReport = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const timeoutDuration = 100000;

        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                // window.location.reload();
                this.setState({ timer: 0 });
                this.incrementTimer();
                reject(new Error('Timeout'));
            }, timeoutDuration);
        });

        Promise.race([
            callApi("POST", "template/tests/scrutiny_report.php", {
                file_idfk: this.props.match.params.id,
                check: true,
                userId,
            }).then((res) => res.data),
            timeoutPromise
        ]).then((response: any) => {
            if (response && response.data) {
                console.log("response.data------>>>>>", response);
                sessionStorage.setItem("overviewDocument", response.data);
                var currentFile = response.file_name.split("-");
                sessionStorage.setItem("currentFile", currentFile[1]);
                this.setState({ report: response.data, enableRefresh: true });
                // $("#root").unbind();
            } else {
                this.setState({ enableRefresh: false });
                // this.scrutinyReport();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({ enableRefresh: false });
            // this.scrutinyReport();
        });
    };

    signatureReport = () => {
        callApi("POST", "sign_upload_pdf.php", { id: this.props.match.params.id })
            .then((res) => res.data)
            .then((response) => {
                if (response && response.responseJson.data) {
                    this.setState({ signreport: response.responseJson.data[0].filepath, enableRefresh: true });
                } else {
                    this.setState({ enableRefresh: false });
                }
            });
    };

    delaylaoding = (realFileidfk) => {
        setTimeout(() => {
            console.log(realFileidfk);
            this.setState({
                realFileidfk,
            });
        }, 10000);
    };
    resetOverview = () => {
        callApi("POST", "report_delete.php", { id: this.props.match.params.id })
            .then((res) => res.data)
            .then((response) => {
                ToastsStore.success("Reset success!");
                this.getFileOverview();
            });
    };
    downloadPDF = () => {
        const input: any = document.getElementById("report-content");
        let mywindow: any = window.open(
            "",
            "PRINT",
            "height=650,width=900,top=100,left=150"
        );
        mywindow.document.write(input.innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();

        ToastsStore.success("Downloaded Successfully!");
        return true;
    };
    saveReport = (status) => {
        // const report: any = document.getElementById("report-content");
        const reportInput: any = this.state;
        const user: any = sessionStorage.getItem("user");
        reportInput.userId = user ? JSON.parse(user)["userId"] : "";
        // reportInput.report = report.innerHTML;
        reportInput.file_idfk = this.props.match.params.id;
        reportInput.status = status;
        delete reportInput.isapproved;
        delete reportInput.office_users;
        delete reportInput.remote_users;
        callApi("POST", "final_report.php", reportInput)
            .then((res) => res.data)
            .then((response) => {
                if (status === 0)
                    ToastsStore.success("Saved & Submitted Successfully!");
                else ToastsStore.success("Saved Successfully!");
                this.getFileOverview();
                this.props.checkOverviewApproval();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getUsers = () => {
        callApi("POST", "office_details.php", {
            id: this.props.match.params.id,
        })
            .then((res) => res.data)
            .then((response) => {
                this.setState({ office_users: response.data });
            });
    };

    getUpdateReasonData = () => {
        const reasonData: any = this.state.updateDownloadData;
        callApi("POST", "dashboard_log.php", { id: this.props.match.params.id })
            .then((res) => res.data)
            .then((result) => {
                let { lastUploadedDocument } = this.state;
                reasonData.reasonForDownload = result.data.reasonForDownload;
                // Object.keys(result.data[0][1]).map(key => result.data[0][1][key]);
                reasonData.updatedDetails = result.data.updatedDetails;
                if (reasonData.updatedDetails && reasonData.updatedDetails.length > 0) {
                    lastUploadedDocument = reasonData.updatedDetails[0].url[0];
                }
                // result.data[0][0];
                console.log("reasonData", reasonData);
                this.setState({ updateDownloadData: reasonData, lastUploadedDocument });
            })
            .catch((err) => {
                console.log("Errororor", err);
                // ToastsStore.error("Failed to get ");
            });
    };

    //Download docs from external api
    downloadDocs = () => {
        const { lastUploadedDocument } = this.state;

        if (lastUploadedDocument.docx) {
            callApi("POST", "get_branch_code.php", {
                id: this.props.match.params.id,
            })
                .then((res) => res.data)
                .then((response) => {
                    console.log(response);
                    const name = `${response.data[0].lawhands_branch}-${this.props.match.params.id}.docx`;
                    lastUploadedDocument.docx && saveAs(lastUploadedDocument.docx, name);
                });
        } else {
            callApi("POST", "template/tests/shell.php", {
                id: this.props.match.params.id,
                responseType: "blob",
            })
                .then((res) => res.data)
                .then((response) => {
                    //ToastsStore.success("Downloaded Successfully!");
                    window.open(
                        API_ENDPOINT + "template/tests/shell.php?id=" + this.props.match.params.id,
                        "_self"
                    );
                });
        }
        // ToastsStore.success("Downloaded Successful!");
    };

    selectUser = (e: any, user: any) => {
        const remote_users: any = this.state.remote_users;
        if (e.target.checked) {
            remote_users.push({ email: user.email, name: user.name });
        } else {
            remote_users.filter((itm) => itm.email !== user.email);
        }
        this.setState({ remote_users });
    };
    sendMail = () => {
        const mailInput: any = Object.create({});
        mailInput.fod_fk = this.props.match.params.id;
        mailInput.remote_users = this.state.remote_users;
        const user: any = sessionStorage.getItem("user");
        mailInput.user_idfk = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "report_mail.php", mailInput)
            .then((res) => res.data)
            .then((response) => { })
            .catch((err) => {
                console.log(err);
            });
    };
    approveFile = () => {
        const mailInput: any = Object.create({});
        mailInput.fod_fk = this.props.match.params.id;
        mailInput.status = this.state.isapproved ? 0 : 1;
        const user: any = sessionStorage.getItem("user");
        mailInput.user_idfk = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "report_approve.php", mailInput)
            .then((res) => res.data)
            .then((response) => {
                if (this.state.isapproved) ToastsStore.success("Reworked");
                else ToastsStore.success("Approved");
                this.getFileOverview();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    onEditorChange(evt) {
        this.setState({
            report: evt.editor.getData(),
        });
    }
    checkApproveVerify = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "get_approved_status.php", {
            id: this.props.match.params.id,
            userId,
        })
            .then((res) => res.data)
            .then((response) => {
                if (response && response.data && response.data.length > 0) {
                    this.setState({
                        isApprove: Number(response.data[0].is_approved),
                        isVerify: Number(response.data[0].is_verified),
                    });
                }
            });
    };
    fileVerification = () => {
        callApi("POST", "set_verify.php", {
            id: this.props.match.params.id,
            status: this.state.isVerify ? 0 : 1,
        })
            .then((res) => res.data)
            .then((response) => {
                this.checkApproveVerify();
            });
    };
    fileApproval = () => {
        callApi("POST", "set_approve.php", {
            id: this.props.match.params.id,
            status: this.state.isApprove ? 0 : 1,
        })
            .then((res) => res.data)
            .then((response) => {
                this.checkApproveVerify();
            });
    };
    actionHandle = (action) => {
        this.setState({ action });
    };
    handleReasonChange = (e) => {
        this.setState({ reason: e.target.value });
    };
    validateReason = () => {
        if (this.state.reason === "") {
            ToastsStore.error("Please Enter Reason");
        } else {
            if (this.state.action === "download") {
                this.downloadDoc();
            } else {
                this.uploadDoc();
            }
        }
    };
    downloadDoc = () => {
        $("#reason-modal").modal("hide");
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj = {
            fileid: this.props.match.params.id,
            reason: this.state.reason,
            createdBy: userId,
        };
        callApi("POST", "download_log.php", obj)
            .then((res) => res)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Reason saved successfully");
                    this.downloadDocs();
                    this.clearModal();
                } else {
                    ToastsStore.error("Error in storing, Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    uploadFile = (file: any) => {
        console.log(file);
        this.setState({ uploadFile: file });
    };
    uploadDoc = () => {
        $("#reason-modal").modal("hide");
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj = {
            fileid: this.props.match.params.id,
            reason: this.state.reason,
            createdBy: userId,
            file: this.state.uploadFile.base64.split(",")[1],
            errorTypes: ["1", "2"],
            errorSection: ["1", "2"],
        };
        callApi("POST", "/upload_log.php", obj)
            .then((res) => res)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    this.getUpdateReasonData();
                    this.setState({ isComplete: false });
                    this.setState({ activetab: "pdfkey" });
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Reason saved successfully");
                    this.clearModal();
                    this.scrutinyReport();
                    this.signatureReport();
                    console.log("activetab", this.state.activetab);
                } else {
                    ToastsStore.error("Error in storing, Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    clearModal = () => {
        this.setState({
            reason: "",
        });
    };
    enableModalHandler = () => {
        // if (this.state.blockdownload == "true") {
        //     this.completeFlowHandler();
        // } else {
        $("#complete-process").modal("show");
        // }
    };
    close = () => {
        $("#processComplete").modal('hide');
        // this.props.history.push("/inprogress-file");
    }

    completeFlowHandler = () => {
        this.setState({ showTitle: true })
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const report_to = user ? JSON.parse(user)["report_to"] : "";
        // file status 6 => moved to "files to be verfied"
        callApi("POST", "file_status.php", {
            id: this.props.match.params.id,
            file_status: 11,
        })
            .then((res) => res)
            .then((response) => {
                console.log(response);
                this.setState({ showTitle: false })
                if (response.status === 200) {
                    $("#processComplete").modal('show');
                    const user: any = sessionStorage.getItem("user");
                    const userId = user ? JSON.parse(user)["userId"] : "";
                    const userName = user ? JSON.parse(user)["firstName"] : "";
                    const notifyData = {
                        title: `${this.props.match.params.id}Typing Completed By ${userName}`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
                    };
                    pushnotificationApi(notifyData, userId);
                    const branchManger: any = sessionStorage.getItem("branch_manager");
                    const branch_Manager = JSON.parse(branchManger);
                    const queryOfficer: any = sessionStorage.getItem("Query_officer");
                    const query_ooficer = JSON.parse(queryOfficer);
                    const Scan_officer: any = sessionStorage.getItem("scan_officer");
                    const sacanofficer = JSON.parse(Scan_officer);
                    const process_mennager: any = sessionStorage.getItem("process_manager");
                    const processManager = JSON.parse(process_mennager);
                    if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
                        const branchManager = branch_Manager.join(",");
                        let obj = {
                            notification_type: "typingcomplete",
                            user_id: branchManager,
                            ...notifyData,
                            reason: `${this.props.match.params.id}Typing Completed`
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
                        const OfficerQuery = query_ooficer.join(",");
                        let obj = {
                            notification_type: "typingcomplete",
                            user_id: OfficerQuery,
                            ...notifyData,
                            reason: `${this.props.match.params.id}Typing Completed`
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    // if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
                    //   notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
                    // }
                    if (Array.isArray(processManager) && processManager.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
                        const ProcessManager = processManager.join(",");
                        let obj = {
                            notification_type: "typingcomplete",
                            user_id: ProcessManager,
                            ...notifyData,
                            reason: `${this.props.match.params.id}Typing Completed`
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    this.signverificationtime();
                } else {
                    ToastsStore.error("Error in completing the file , Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    signverificationtime() {
        const props: any = this.props;
        const loginuser: any = sessionStorage.getItem("user1");
        var now = new Date();
        var date = now.toLocaleDateString();
        var time = now.toLocaleTimeString();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const currenttime = `${hours}:${minutes}:${seconds}`;
        const currentdate = moment(date).format("YYYY-MM-DD");
        const obj = {
            id: props.match.params.id,
            sign_verification_completed_time: currenttime,
            sign_verification_completed_date: currentdate,
            sign_verification_completed_by: JSON.parse(loginuser)
        };
        callApi("POST", "time_update.php", obj)
            .then(res => res.data)
            .then(response => {

            });
    }

    previousPage = () => {
        const fileurl: any = localStorage.getItem("url");
        $("#in_time").modal("hide");
        sessionStorage.removeItem("currentfileId")
        localStorage.removeItem("thisPopup");
        this.props.history.push(`${fileurl}`);
        localStorage.removeItem("url");
    }
    render() {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const {
            action,
            reason,
            uploadFile,
            lastUploadedDocument,
            timer,
            isComplete,
            showTitle
        } = this.state;
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="d-fles">
                                                        <h5 style={{ fontSize: "20px " }}>Signature File</h5>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline"

                                                            onClick={() => this.previousPage()}
                                                            style={{ width: '76px', fontSize: '17px', border: '1px solid black', float: "right" }}
                                                        > Back</button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <Accordion className="mainAccordion">
                                                        <Tabs
                                                            defaultActiveKey={this.state.activetab}
                                                            style={{ padding: 0 }}
                                                            onSelect={(name) => {
                                                                this.setState({ activeMainTab: name });
                                                            }}
                                                        >
                                                            <Tab eventKey="wordkey" title="Word">
                                                                <div className="">
                                                                    <div className="buttons" style={{ margin: "2%" }}>
                                                                        <button
                                                                            className="btn btn-outline-dark"
                                                                            onClick={() => this.actionHandle("download")}
                                                                            data-toggle="modal"
                                                                            data-target="#reason-modal"
                                                                            style={{ fontWeight: "bold", borderWidth: "2px" }}
                                                                        >
                                                                            Download Doc <i className="fa fa-download" />
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-outline-dark"
                                                                            onClick={() => this.actionHandle("upload")}
                                                                            data-toggle="modal"
                                                                            data-target="#reason-modal"
                                                                            style={{ fontWeight: "bold", borderWidth: "2px" }}
                                                                        >
                                                                            Upload Doc <i className="fa fa-upload" />
                                                                        </button>
                                                                        {/* <button type="button" className="btn btn-primary"
                                                                            style={{ marginTop: '0px' }}>Complete
                                                                        </button> */}
                                                                        {isComplete && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                style={{ marginTop: "0px", float: "right" }}
                                                                                onClick={this.enableModalHandler}
                                                                            >
                                                                                Complete
                                                                            </button>
                                                                        )}

                                                                        {!isComplete && (
                                                                            <>
                                                                                <button
                                                                                    onClick={() => this.completeFlowHandler()}
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    style={{ marginTop: "0px", float: "right" }}
                                                                                >
                                                                                    Complete
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div style={{ marginTop: "10px" }} />
                                                                    {this.state.report ? (
                                                                        <iframe
                                                                            width="100%"
                                                                            height="600"
                                                                            frameBorder="0"
                                                                            src={this.state.report}
                                                                        ></iframe>
                                                                    ) : (
                                                                        <div className="text-center">
                                                                            <h4>
                                                                                <span>Loading... {timer} seconds</span>
                                                                            </h4>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="pdfkey" title="Pdf with Signature">
                                                                <div>
                                                                    <div className="buttons" style={{ margin: "2%" }}>
                                                                        <button
                                                                            className="btn btn-outline-dark"
                                                                            onClick={() => this.actionHandle("download")}
                                                                            data-toggle="modal"
                                                                            data-target="#reason-modal"
                                                                            style={{ fontWeight: "bold", borderWidth: "2px" }}
                                                                        >
                                                                            Download Doc <i className="fa fa-download" />
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-outline-dark"
                                                                            onClick={() => this.actionHandle("upload")}
                                                                            data-toggle="modal"
                                                                            data-target="#reason-modal"
                                                                            style={{ fontWeight: "bold", borderWidth: "2px" }}
                                                                        >
                                                                            Upload Doc <i className="fa fa-upload" />
                                                                        </button>
                                                                        {/* <button type="button" className="btn btn-primary"
                                                                            style={{ marginTop: '0px' }}>Complete
                                                                        </button> */}
                                                                        {/* {isComplete && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                style={{ marginTop: "0px", float: "right" }}
                                                                                onClick={this.enableModalHandler}
                                                                            >
                                                                                Complete
                                                                            </button>
                                                                        )} */}

                                                                        {/* {!isComplete && (
                                                                            <> */}
                                                                        <button
                                                                            onClick={() => this.completeFlowHandler()}
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            style={{ marginTop: "0px", float: "right" }}
                                                                        >
                                                                            Complete
                                                                        </button>
                                                                        {/* </>
                                                                        )} */}
                                                                    </div>
                                                                    <div style={{ marginTop: "10px" }} />
                                                                    {this.state.signreport ? (
                                                                        <iframe
                                                                            width="100%"
                                                                            height="600"
                                                                            frameBorder="0"
                                                                            src={this.state.signreport}
                                                                        ></iframe>
                                                                    ) : (
                                                                        <div className="text-center">
                                                                            <h4>
                                                                                <span>Loading... {timer} seconds</span>
                                                                            </h4>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </Accordion>

                                                    <div className="modal" id="reason-modal">
                                                        <div className="modal-dialog ">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    {action === "download" ? (
                                                                        <h5 className="modal-title">Reason For Download</h5>
                                                                    ) : (
                                                                        <h5 className="modal-title">Reason For Upload</h5>
                                                                    )}
                                                                    <button type="button" className="close" data-dismiss="modal">
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div>
                                                                        {action === "download" ? (
                                                                            <label>Enter Reason*</label>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <label>Enter Updated Informations*</label>
                                                                                <ReactFileReader
                                                                                    fileTypes={[".doc", ".docx"]}
                                                                                    base64={true}
                                                                                    // multipleFiles={true}
                                                                                    handleFiles={(e) => this.uploadFile(e)}
                                                                                >
                                                                                    <button
                                                                                        className="ml2 btn btn-success btnupload"
                                                                                    // disabled={this.state.isFlowChartUploading}
                                                                                    >
                                                                                        {/* {this.state.isFlowChartUploading ? (
                                                                                            <i className="fa fa-spinner fa-spin"></i>
                                                                                        ) : <i className="icon feather icon-upload" />} */}
                                                                                        Select File
                                                                                    </button>
                                                                                </ReactFileReader>
                                                                                <span>{uploadFile.fileList[0].name}</span>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <textarea
                                                                            className="form-control mt-10"
                                                                            name="reason"
                                                                            value={reason}
                                                                            onChange={this.handleReasonChange}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn " data-dismiss="modal">
                                                                        Cancel
                                                                    </button>
                                                                    {action === "download" ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={this.validateReason}
                                                                        >
                                                                            Download
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={this.validateReason}
                                                                        >
                                                                            Upload
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal hide fade in" id="processComplete" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog ">
                                                            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                                                                <div className="modal-body">
                                                                    <h5 style={{ textAlign: 'center' }}> Thanks For Complete Your File:{this.props.match.params.id}</h5>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    {showTitle ? null : (<button
                                                                        type="button"
                                                                        className="btn btn-success"
                                                                        onClick={() => this.close()}
                                                                        // onClick={this.disableModalHandler}
                                                                        style={{ width: '50%', fontSize: '17px', margin: 'auto' }}
                                                                    > Ok</button>)}

                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* This is for modal */}
                                                    <div className="modal" id="complete-process">
                                                        <div className="modal-dialog ">
                                                            <div className="modal-content">
                                                                <div className="modal-body">
                                                                    <h3>Without upload document you can't complete the file</h3>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        data-dismiss="modal"
                                                                    // onClick={this.disableModalHandler}
                                                                    >
                                                                        {" "}
                                                                        Ok
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
