import * as React from "react";
import { callApi } from "../../utils/api";
import Select from "react-select";
import DatePicker from "antd/lib/date-picker";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import Pagination from "@material-ui/lab/Pagination";
import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import moment from "moment";
import { getFileStatus } from "../../utils/fileStatus";
import { FileLogCalculation } from "./FileLogCalculations";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}
class FileLog extends React.Component<AllProps> {
  limitList = [20, 40, 60];
  state = {
    lhBranchShow: false,
    bankBranch: false,
    branchName: false,
    lawBranchName: false,
    showBank: false,
    bankName: false,
    selectVal: false,
    overview: [],
    employees: [],
    branchInfo: [],
    branches: [],
    fromdate: "",
    eodDate: "",
    eodFromDate: "",
    eodToDate: "",
    todate: "",
    branch: "",
    assigned_to: "",
    users: [],
    eodData: [],
    branchEodData: [],
    eodReportType: "Branch",
    eodEmployeeList: [],
    eodEmployee: "",
    lawhandsBranches: [],
    bank_private_name: "",
    file_id: "",
    banks: [],
    filteredBranches: [],
    lawHandsBranch: "",
    isDateSearchClicked: false,
    total_file: {
      total_time_sum: "",
      front_office_sum: "",
      work_in_progress_sum: "",
      query_waiting_time_sum: "",
      verification_wip_sum: "",
      waiting_time_sum: "",
      extended_hours_sum: "",
    },
    files: [],
    pageNumber: 1,
    rowCount: this.limitList[0],
    totalCount: 0,
    excelData: [],
    loadingexcel: false,
    outreason: "",
    isLoading: false,
    timeDifferences: [],
    groupedFiles: [],
    total_wip: "",
    total_qr: "",
    total_vc: "",
    total_ex: "",
    total_fc: "",
    total_queue: "",
    total_time: "",
  };
  componentDidMount() {
    $(".datepicker").on("change", this.handleChange);
    this.getUsers();
    this.getOutTime();
    this.ouTime();
    const currentDate = moment();
    this.setState(
      {
        eodFromDate: moment(currentDate, "DD.MM.YYYY"),
        eodToDate: moment(currentDate, "DD.MM.YYYY"),
      },
      () => {
        const fileLogObj = localStorage.getItem("file-log");
        const filelog_history: any = localStorage.getItem("filelog_history");
        if (fileLogObj && filelog_history == "true") {
          let extractFileObj = JSON.parse(fileLogObj);
          let momentextractFileObj = moment(
            extractFileObj.from_date,
            "DD.MM.YYYY"
          );
          this.setState({
            eodFromDate: moment(extractFileObj.from_date, "DD.MM.YYYY"),
            eodToDate: moment(extractFileObj.to_date, "DD.MM.YYYY"),
          });
          this.getEodTableData();
        }
      }
    );
  }
  componentWillUnmount() {
    localStorage.setItem("filelog_history", JSON.stringify(false));
  }

  onchange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
      selectVal: false,
    });
  };
  getBanks() {
    callApi("GET", "bank_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ banks: result.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then((res) => res.data)
      .then((response) => {
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        let lawhandsBranches = response.data;
        if (
          lawhandsBranches &&
          lawhandsBranches.length > 0 &&
          userDetails.role_id !== "1" &&
          userDetails.my_branchs
        ) {
          const myBranchList = userDetails.my_branchs.split(",");
          lawhandsBranches = lawhandsBranches.filter((value) =>
            myBranchList.includes(value.id)
          );
        }
        this.setState({ lawhandsBranches });
      })
      .catch((err) => {
        console.log({ err });
      });
  }
  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  };
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  };
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime));
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    } else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
  };
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  };
  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    const outtime: any = localStorage.getItem("outtimeGet");
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(
      moment(intime, "HH:mm"),
      "seconds"
    );
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    } else {
      let obj = {
        file_id: JSON.parse(fileid1),
        user_id: JSON.parse(userid1),
        in_reason: JSON.parse(inreason),
        out_reason: this.state.outreason,
        intime: JSON.parse(intime),
        outtime: JSON.parse(outtime),
        insert_date: JSON.parse(outdate),
        total_seconds: secondsSpent,
      };
      callApi("POST", "process_eod_insert.php", obj)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ isLoading: false });
          // sessionStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any =
            sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          const getfileId: any = sessionStorage.getItem("currentfileId");
          if (
            process_officer_id == user_id &&
            take_file != "" &&
            take_file == getfileId
          ) {
            this.fileout(fileid1);
          } else {
            sessionStorage.removeItem("currentfileId");
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        });
    }
  };
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser),
    };
    callApi("POST", "time_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        sessionStorage.removeItem("currentfileId");
        // sessionStorage.removeItem("takenfile_id");
      });
  };
  onchangeReason = (e: any) => {
    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false });
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    } else if (e.target.value == "select") {
      this.setState({ setResponse: false });
    } else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  getBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ branches: result.data, filteredBranches: [] });
        if (this.state.bank_private_name) {
          this.filterBranches(this.state.bank_private_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterBranches = (bankid) => {
    const filteredBranches = this.state.branches.filter(
      (branch: any) => branch.bank_id === bankid
    );
    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
  };
  // Function to parse a duration string in "HH:mm:ss" format and return the total number of seconds
  parseDuration(duration: string): number {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  // Function to format a duration given the total number of seconds into "HH:mm:ss" format
  formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(
      seconds
    )}`;
  }
  // Function to pad a single digit number with a leading zero
  padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }
  getEodTableData = () => {
    this.setState({ isLoading: true });
    const {
      eodFromDate,
      eodToDate,
      lawHandsBranch,
      bank_private_name,
      branch,
      assigned_to,
      file_id,
    } = this.state;
    let obj: any = {
      lawhands_branch: lawHandsBranch,
      from_date: eodFromDate,
      to_date: eodToDate,
      bank_private_name: bank_private_name,
      branch: branch,
      // page_id: page_id,
      // row_count: row_count,
      assigned_to: assigned_to,
      id: file_id,
    };

    localStorage.setItem("file-log", JSON.stringify(obj));
    callApi("POST", "file_log_new.php", obj)
      .then((res) => res.data)
      .then(async (result) => {
        const files = result.responseJson.data;
        let groupedFiles: any = files.reduce((acc, file) => {
          if (!acc[file.file_id]) {
            acc[file.file_id] = [file];
          } else {
            acc[file.file_id].push(file);
          }
          return acc;
        }, {});
        for (const fileId in groupedFiles) {
          if (groupedFiles.hasOwnProperty(fileId)) {
            groupedFiles[fileId].sort(
              (a: any, b: any) => a.created_dt - b.created_dt
            );
          }
        }
        const groupedFilesArray = Object.values(groupedFiles);
        this.setState({ groupedFiles: groupedFilesArray });
        const timeDifferences: any = await FileLogCalculation(groupedFiles, this.state.users);
        console.log("timeDifferences_+_+______++____", timeDifferences);
        this.setState({
          timeDifferences: timeDifferences,
          isLoading: false,
          loadingexcel: true,
        });
        let total_wip = "00:00:00";
        let total_qr = "00:00:00";
        let total_vc = "00:00:00";
        let total_ex = "00:00:00";
        let total_fc = "00:00:00";
        let total_queue = "00:00:00";
        let total_time = "00:00:00";
        for (let i = 0; i < timeDifferences.length; i++) {
          total_wip = this.getTotalTimeDiff(
            total_wip,
            timeDifferences[i].total_wip_file
          );
          total_fc = this.getTotalTimeDiff(
            total_fc,
            timeDifferences[i].front_office
          );
          total_qr = this.getTotalTimeDiff(
            total_qr,
            timeDifferences[i].total_qr_file
          );
          total_vc = this.getTotalTimeDiff(
            total_vc,
            timeDifferences[i].total_vc_file
          );
          total_ex = this.getTotalTimeDiff(
            total_ex,
            timeDifferences[i].total_ex_file
          );
          total_time = this.getTotalTimeDiff(
            total_time,
            timeDifferences[i].total_time
          );
          for (
            let j = 0;
            j < timeDifferences[i].queueTime[0].total_waiting_time.length;
            j++
          ) {
            total_queue = this.getTotalTimeDiff(
              total_queue,
              timeDifferences[i].queueTime[0].total_waiting_time[j]
            );
          }
        }
        this.setState({ total_wip: total_wip });
        this.setState({ total_qr: total_qr });
        this.setState({ total_vc: total_vc });
        this.setState({ total_ex: total_ex });
        this.setState({ total_fc: total_fc });
        this.setState({ total_queue: total_queue });
        this.setState({ total_time: total_time });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTimeDiff = (date1, date2) => {
    const diff = moment(date2).diff(moment(date1));
    const duration = moment.duration(diff);
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  // getTimeDiff function
  getqueryTimeDiff = (date1: any, date2: any) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    const diff = momentDate2.diff(momentDate1);
    const duration = moment.duration(diff);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  addTime = (time1: string, time2: string) => {
    const momentTime1 = moment.duration(time1);
    const momentTime2 = moment.duration(time2);
    const totalDuration = momentTime1.add(momentTime2);
    const hours = Math.floor(totalDuration.asHours());
    const minutes = Math.floor(totalDuration.asMinutes()) % 60;
    const seconds = Math.floor(totalDuration.asSeconds()) % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  getTotalTimeDiff = (time1, time2) => {
    const moment1 = moment.duration(time1);
    const moment2 = moment.duration(time2);
    const totalDuration = moment1.add(moment2);
    // Get the hours, minutes, and seconds from the total duration
    const hours = Math.floor(totalDuration.asHours());
    const minutes = totalDuration.minutes();
    const seconds = totalDuration.seconds();
    // Format the result as "HH:mm:ss"
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  };

  handleChange1 = (e: any, propName?, fieldType?) => {
    const bank_private_name = this.state.bank_private_name;
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    if (fieldName === "bank_private_name") {
      this.branchName(2);
      this.filterBranches(bank_private_name);
    }
    this.setState({ bank_private_name });

    if (fieldName === "lawHandsBranch") {
      const lawHandsBranch = this.state.lawHandsBranch;
      const branch = fieldValue;
      this.setState({ lawHandsBranch });
    }
    this.setState({ [fieldName]: fieldValue });
  };
  handleChange = (e, stateName?, type?) => {
    if (e.target.name === "eodToDate") {
      this.setState({ todate: e.target.value });
    }
    if (e.target.name === "eodfromDate") {
      this.setState({
        fromdate: e.target.value,
      });
    }
    if (stateName && type === "dropdown") {
      this.setState({
        [stateName]: e.value,
      });
    } else this.setState({ [e.target.name]: e.target.value });
    if (["eodFromDate", "eodToDate"].includes(e.target.name)) {
      this.setState({ isDateSearchClicked: false });
    }
  };
  handleDateChange = (date, dateStr, type) => {
    this.setState({ [type]: dateStr });
  };
  getUsers = () => {
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const user_id = user ? JSON.parse(user)["userId"] : null;
    const userDetails = user ? JSON.parse(user) : "";
    const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2";
    const isFront = userDetails.role_id === "3" || userDetails.role_id === "4";
    if (isAdmin) {
      callApi("GET", "user_get.php")
        .then((res) => res.data)
        .then((response) => {
          this.setState({ users: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      callApi("POST", "team_leader.php", { user_id: user_id })
        .then((res) => res.data)
        .then((response) => {
          this.setState({ users: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  getNameById(id) {
    const { users }: any = this.state;
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        return users[i].name;
      }
    }
    return null; // Return null if ID is not found
  }
  lawBranchName = (type: any) => {
    if (type == 2) {
      console.log("431", type);
      this.setState({ lhBranchShow: true });
      this.getLawHandsBranches();
    } else {
      console.log("436", type);
    }
  };
  bankName = (type: any) => {
    if (type == 2) {
      console.log("431", type);
      this.setState({ showBank: true });
      this.getBanks();
    } else {
      console.log("445", type);
    }
  };
  branchName = (type: any) => {
    if (type == 2) {
      console.log("450", type);
      this.setState({ bankBranch: true });
      this.getBankBranches();
    } else {
      console.log("454", type);
    }
  };
  filteredData = (fileId) => {
    return this.state.groupedFiles.filter((innerArray: any) =>
      innerArray.some((obj) => obj.file_id === fileId)
    );
  };
  goToFileHistory = (id) => {
    if (id) {
      // const url = `/inprogress-file`;
      // localStorage.setItem("url", url);
      // let obj = { "file_id": id }
      // localStorage.setItem("thisPopup", JSON.stringify(obj))
      // sessionStorage.setItem("currentfileId", JSON.stringify(id));
      const selectedFile = this.state.timeDifferences.filter(
        (item: any) => item.file_id === id
      );
      const groupedFiles = this.filteredData(id);
      const selectedGroupedFile = groupedFiles[0];
      const fileData = selectedFile[0];
      this.props.history.push("/file-history/" + id, {
        fileData: fileData,
        groupedFiles: selectedGroupedFile,
      });
    } else {
      console.log("no fileId");
    }
  };
  handlePageLimit = (event) => {
    const rowCount = event.target.value;
    const pageNumber = 1;
    this.setState({ pageNumber, rowCount });
    // this.getEodTableData(pageNumber, rowCount);
  };
  handlePagination = (event, value) => {
    this.setState({ pageNumber: value });
    // this.getEodTableData(value, this.state.rowCount);
  };
  disabledDate = (current) => {
    // Disable dates after today and dates before the last 3 months
    return (
      current &&
      (current > moment().endOf("day") ||
        current < moment().subtract(3, "months").startOf("day"))
    );
  };
  render() {
    const columns = [
      {
        header: "S.No",
        key: "serial",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.id)}>{file.serial}</td>
        ),
      },
      {
        header: "File ID",
        key: "file_id",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.file_id}
          </td>
        ),
      },
      {
        header: "File Status",
        key: "file_status",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {getFileStatus(file.file_status)}
          </td>
        ),
      },
      {
        header: "Total Time",
        key: "total_time",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.total_time}
          </td>
        ),
      },
      {
        header: "Front Office",
        key: "front_office",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.front_office}
          </td>
        ),
      },
      {
        header: "Process WIP",
        key: "total_wip_file",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.total_wip_file}
          </td>
        ),
      },
      {
        header: "Query Waiting Time",
        key: "query_diff",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.query_diff}
          </td>
        ),
      },
      {
        header: "verification WIP",
        key: "total_vc_file",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.total_vc_file}
          </td>
        ),
      },
      {
        header: "Queue Time",
        key: "total_queue_file",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.total_queue_file}
          </td>
        ),
      },
      {
        header: "Extended Hours",
        key: "total_ex_file",
        td: (file) => (
          <td onClick={() => this.goToFileHistory(file.file_id)}>
            {file.total_ex_file}
          </td>
        ),
      },
    ];
    const data = this.state.timeDifferences;
    const excelData = this.state.timeDifferences;
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const {
      lawBranchName,
      bankBranch,
      branchName,
      bankName,
      showBank,
      lhBranchShow,
    } = this.state;
    const userDetails = user ? JSON.parse(user) : "";
    const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2";
    const lawhandsBranchesoptions = [
      { value: "", label: "All" },
      ...this.state.lawhandsBranches,
    ];
    const banksoptions = [{ value: "", label: "All" }, ...this.state.banks];
    const filteredBranchesoptions = [
      { value: "", label: "All" },
      ...this.state.filteredBranches,
    ];
    const usersoptions = [{ value: "", label: "All" }, ...this.state.users];
    const noOfPages = Math.ceil(this.state.totalCount / this.state.rowCount);

    const processedData = data.map((report: any, index) => ({
      serial: index + 1,
      file_id: report.file_id,
      file_status: report.file_status,
      front_office: report.front_office,
      total_wip_file: report.total_wip_file,
      total_qr_file: report.total_qr_file,
      total_vc_file: report.total_vc_file,
      total_queue_file: report.total_queue_file,
      total_time: report.total_time,
      login_time: report.login_time ? report.login_time : "",
      login_by: report.login_by ? report.login_by : "",
      scan_queue_time: report.scan_queue_time ? report.scan_queue_time : "",
      scan_completed_time: report.scan_completed_time
        ? report.scan_completed_time
        : "",
      file_taken_queue_time: report.file_taken_queue_time
        ? report.file_taken_queue_time
        : "",
      assigned_to: report.assigned_to ? report.assigned_to : "",
      file_taken_time: report.file_taken_time ? report.file_taken_time : "",
      query_raised_time: report.query_raised_time
        ? report.query_raised_time
        : "",
      query_waiting_time: report.query_waiting_time
        ? report.query_waiting_time
        : "",
      query_received_time: report.query_received_time
        ? report.query_received_time
        : "",
      // query_queue_time: report.query_queue_time ? report.query_queue_time : "",
      query_taken_by: report.query_taken_by ? report.query_taken_by : "",
      typing_completed_time: report.typing_completed_time
        ? report.typing_completed_time
        : "",
      verification_queue_time: report.verification_queue_time
        ? report.verification_queue_time
        : "",
      verification_by: report.verification_by ? report.verification_by : "",
      verification_file_taken_time: report.verification_file_taken_time
        ? report.verification_file_taken_time
        : "",
      verification_completed_time: report.verification_completed_time
        ? report.verification_completed_time
        : "",

      total_time_sum: report.total_time_sum ? report.total_time_sum : "",
      front_office_sum: report.front_office_sum ? report.front_office_sum : "",
      work_in_progress_sum: report.work_in_progress_sum
        ? report.work_in_progress_sum
        : "",
      query_waiting_time_sum: report.query_waiting_time_sum
        ? report.query_waiting_time_sum
        : "",
      verification_wip_sum: report.verification_wip_sum
        ? report.verification_wip_sum
        : "",
      waiting_time_sum: report.waiting_time_sum ? report.waiting_time_sum : "",
      total_ex_file: report.total_ex_file ? report.total_ex_file : "",
      wrong_upload_query_by: report.wrong_upload_query_by
        ? report.wrong_upload_query_by
        : "",
      wrong_upload_query_dt: report.wrong_upload_query_dt
        ? report.wrong_upload_query_dt
        : "",
      total_dwip_file: report.total_dwip_file ? report.total_dwip_file : "",
      query_diff: report.query_diff ? report.query_diff : "",
    }));

    const excelColumn = [
      { label: "S.No", key: "serial" },
      { label: "File Id", key: "file_id" },
      { label: "File Status", key: "file_status" },
      { label: "APP ID", key: "app_id" },
      { label: "Borrower Name", key: "borrower_name" },
      { label: "Property Owner Name", key: "property_ownername" },
      { label: "Bank Name", key: "bank_name" },
      { label: "Product", key: "product" },
      { label: "Bank Branch", key: "bank_branch" },
      { label: "LH Branch", key: "lh_branch" },
      { label: "Login Time", key: "login_date" },
      { label: "Login By", key: "login_by" },
      { label: "Scan Completed Time", key: "scan_date" },
      { label: "Scan Queue Time", key: "front_office" },

      { label: "File Queue Time", key: "file_taken_time" },
      { label: "Process Officer", key: "assigned_to" },
      { label: "File Taken Time", key: "taken_date" },

      { label: "File Picked By - 1", key: "first_workInprogress_By" },
      { label: "File Picked By - 2", key: "second_workInprogress_By" },
      { label: "File Picked By - 3", key: "third_workInprogress_By" },
      { label: "File Picked By - 4", key: "fourth_workInprogress_By" },
      { label: "File Picked By - 5", key: "fifth_workInprogress_By" },

      { label: "Query Raised Time - 1", key: "first_Query_Raise" },
      { label: "Query Received Time - 1", key: "first_Query_Received" },
      {
        label: "Query Received Pick Name - 1",
        key: "first_Query_Received_WIP_By",
      },
      {
        label: "Query Received Pick Time - 1",
        key: "first_Query_Received_WIP_Time",
      },
      { label: "Query Raised Time - 2", key: "second_Query_Raise" },
      { label: "Query Received Time - 2", key: "second_Query_Received" },
      {
        label: "Query Received Pick Name - 2",
        key: "second_Query_Received_WIP_By",
      },
      {
        label: "Query Received Pick Time - 2",
        key: "second_Query_Received_WIP_Time",
      },
      { label: "Query Raised Time - 3", key: "third_Query_Raise" },
      { label: "Query Received Time - 3", key: "third_Query_Received" },
      {
        label: "Query Received Pick Name - 3",
        key: "third_Query_Received_WIP_By",
      },
      {
        label: "Query Received Pick Time - 3",
        key: "third_Query_Received_WIP_Time",
      },
      { label: "Query Raised Time - 4", key: "fourthQueryRaise" },
      { label: "Query Received Time - 4", key: "fourthqueryReceived" },
      {
        label: "Query Received Pick Name - 4",
        key: "fourth_Query_Received_WIP_By",
      },
      {
        label: "Query Received Pick Time - 4",
        key: "fourth_Query_Received_WIP_Time",
      },
      { label: "Query Raised Time - 5", key: "fifthQueryRaise" },
      { label: "Query Received Time - 5", key: "fifthqueryReceived" },
      {
        label: "Query Received Pick Name - 5",
        key: "fifth_Query_Received_WIP_By",
      },
      {
        label: "Query Received Pick Time - 5",
        key: "fifth_Query_Received_WIP_Time",
      },

      { label: "Last Query Raised Time", key: "query_raised_dt" },
      { label: "Query Waiting Time", key: "query_diff" },
      { label: "Last Query Received Time", key: "query_reiceved_date" },
      { label: "Query Taken By", key: "assigned_to" },

      { label: "Typing Completed Time - 1", key: "first_Typing_completed_Time" },
      { label: "Typing Completed Time - 2", key: "second_Typing_completed_Time" },
      { label: "Typing Completed Time - 3", key: "third_Typing_completed_Time" },
      { label: "Typing Completed Time - 4", key: "fourth_Typing_completed_Time" },
      { label: "Typing Completed Time - 5", key: "fifth_Typing_completed_Time" },

      { label: "Verification Picked Time - 1", key: "first_Verification_Picked_Time" },
      { label: "Verification Picked Time - 2", key: "second_Verification_Picked_Time" },
      { label: "Verification Picked Time - 3", key: "third_Verification_Picked_Time" },
      { label: "Verification Picked Time - 4", key: "fourth_Verification_Picked_Time" },
      { label: "Verification Picked Time - 5", key: "fifth_Verification_Picked_Time" },

      { label: "Verification Completed Time - 1", key: "first_Verification_Completed_Time" },
      { label: "Verification Completed Time - 2", key: "second_Verification_Completed_Time" },
      { label: "Verification Completed Time - 3", key: "third_Verification_Completed_Time" },
      { label: "Verification Completed Time - 4", key: "fourth_Verification_Completed_Time" },
      { label: "Verification Completed Time - 5", key: "fifth_Verification_Completed_Time" },

      { label: "Typing Completed Time", key: "typing_complited_dt" },
      { label: "Verification Queue Time", key: "total_Vfilequeue_time" },
      { label: "Verification By", key: "verified_by" },
      {
        label: "Verification File Taken Time",
        key: "verification_picked_dt",
      },
      {
        label: "Verification Completed Time",
        key: "verification_completed_dt",
      },
      {
        label: "Hold Time",
        key: "total_hold_time",
      },
      {
        label: "UnHold Time",
        key: "total_unhold_time",
      },
      { label: "Hold By - 1", key: "first_hold_By" },
      { label: "Hold Time - 1", key: "first_hold_Time" },
      { label: "UnHold By - 1", key: "first_unhold_By" },
      { label: "UnHold Time - 1", key: "first_unhold_Time" },
      { label: "Hold By - 2", key: "second_hold_By" },
      { label: "Hold Time - 2", key: "second_hold_Time" },
      { label: "UnHold By - 2", key: "second_unhold_By" },
      { label: "UnHold Time - 2", key: "second_unhold_Time" },
      { label: "Hold By - 3", key: "third_hold_By" },
      { label: "Hold Time - 3", key: "third_hold_Time" },
      { label: "UnHold By - 3", key: "third_unhold_By" },
      { label: "UnHold Time - 3", key: "third_unhold_Time" },
      { label: "Hold By - 4", key: "fourth_hold_By" },
      { label: "Hold Time - 4", key: "fourth_hold_Time" },
      { label: "UnHold By - 4", key: "fourth_unhold_By" },
      { label: "UnHold Time - 4", key: "fourth_unhold_Time" },
      { label: "Hold By - 5", key: "fifth_hold_By" },
      { label: "Hold Time - 5", key: "fifth_hold_Time" },
      { label: "UnHold By - 5", key: "fifth_unhold_By" },
      { label: "UnHold Time - 5", key: "fifth_unhold_Time" },

      { label: "Deviation Received Time - 1", key: "first_deviation_Received" },
      {
        label: "Deviation Completed Time - 1",
        key: "first_deviation_Completed",
      },
      { label: "Deviation By - 1", key: "first_deviation_By" },
      {
        label: "Deviation Received Time - 2",
        key: "second_deviation_Received",
      },
      {
        label: "Deviation Completed Time - 2",
        key: "second_deviation_Completed",
      },
      { label: "Deviation By - 2", key: "second_deviation_By" },
      { label: "Deviation Received Time - 3", key: "third_deviation_Received" },
      {
        label: "Deviation Completed Time - 3",
        key: "third_deviation_Completed",
      },
      { label: "Deviation By - 3", key: "third_deviation_By" },
      {
        label: "Deviation Received Time - 4",
        key: "fourth_deviation_Received",
      },
      {
        label: "Deviation Completed Time - 4",
        key: "fourth_deviation_Completed",
      },
      { label: "Deviation By - 4", key: "fourth_deviation_By" },
      { label: "Deviation Received Time - 5", key: "fifth_deviation_Received" },
      {
        label: "Deviation Completed Time - 5",
        key: "fifth_deviation_Completed",
      },
      { label: "Deviation By - 5", key: "fifth_deviation_By" },
      {
        label: "Total Deviation Queue Time",
        key: "total_deviation_queue_time",
      },
      { label: "Total Deviation WIP", key: "total_deviation_wip_file" },

      {
        label: "Rectification Received Time - 1",
        key: "first_Rectify_Received",
      },
      {
        label: "Rectification Completed Time - 1",
        key: "first_Rectify_Completed",
      },
      {
        label: "Rectification Received Time - 2",
        key: "second_Rectify_Received",
      },
      {
        label: "Rectification Completed Time - 2",
        key: "second_Rectify_Completed",
      },
      {
        label: "Rectification Received Time - 3",
        key: "third_Rectify_Received",
      },
      {
        label: "Rectification Completed Time - 3",
        key: "third_Rectify_Completed",
      },
      {
        label: "Rectification Received Time - 4",
        key: "fourth_Rectify_Received",
      },
      {
        label: "Rectification Completed Time - 4",
        key: "fourth_Rectify_Completed",
      },
      {
        label: "Rectification Received Time - 5",
        key: "fifth_Rectify_Received",
      },
      {
        label: "Rectification Completed Time - 5",
        key: "fifth_Rectify_Completed",
      },
      {
        label: "Total Rectification Queue Time",
        key: "total_rectify_queue_time",
      },
      { label: "Total Rectification WIP", key: "total_rectify_wip_file" },

      { label: "Total Time Sum", key: "total_time" },
      { label: "Front Office Sum", key: "front_office_sum" },
      { label: "Work In Progress Sum", key: "work_in_progress_sum" },
      { label: "Query Waiting Time Sum", key: "query_waiting_time_sum" },
      { label: "Verification wip Sum", key: "verification_wip_sum" },
      { label: "Waiting Time Sum", key: "waiting_time_sum" },
      { label: "Scanning Extended Hours Sum", key: "s_extendHours" },
      { label: "Process Extended Hours Sum", key: "p_extendHours" },
      { label: "Verification Extended Hours Sum", key: "v_extendHours" },
      { label: "Total Extended Hours Sum", key: "extended_hours_sum" },
      { label: "Deviation WIP", key: "total_dwip_file" },
      // { label: "Wrong Query Upload Date", key: "wrong_upload_query_dt" },
    ];

    const excelRowData = excelData.map((report: any, index) => ({
      serial: index + 1,
      file_id: report.file_id,
      app_id: report.app_id,
      borrower_name: report.borrower_name,
      property_ownername: report.property_ownername,
      bank_name: report.bank_name,
      product: report.product,
      bank_branch: report.bank_branch,
      lh_branch: report.lh_branch,
      file_status: getFileStatus(report.file_status),
      login_date: report.login_date ? report.login_date : "",
      login_by: report.login_by ? this.getNameById(report.login_by) : "",
      scan_date: report.scan_date ? report.scan_date : "",
      front_office: report.front_office ? report.front_office : "",

      total_filequeue_time: report.total_filequeue_time
        ? report.total_filequeue_time
        : "",
      file_taken_time: report.file_taken_time ? report.file_taken_time : "",
      assigned_to: report.assigned_to
        ? this.getNameById(report.assigned_to)
        : "",
      taken_date: report.taken_date ? report.taken_date : "",
      query_raised_dt: report.query_raised_dt ? report.query_raised_dt : "",
      first_Query_Raise: report.first_Query_Raise
        ? report.first_Query_Raise
        : "",
      second_Query_Raise: report.second_Query_Raise
        ? report.second_Query_Raise
        : "",
      third_Query_Raise: report.third_Query_Raise
        ? report.third_Query_Raise
        : "",
      fourthQueryRaise: report.fourthQueryRaise ? report.fourthQueryRaise : "",
      fifthQueryRaise: report.fifthQueryRaise ? report.fifthQueryRaise : "",
      first_Query_Received: report.first_Query_Received
        ? report.first_Query_Received
        : "",
      second_Query_Received: report.second_Query_Received
        ? report.second_Query_Received
        : "",
      third_Query_Received: report.third_Query_Received
        ? report.third_Query_Received
        : "",
      fourthqueryReceived: report.fourthqueryReceived
        ? report.fourthqueryReceived
        : "",
      fifthqueryReceived: report.fifthqueryReceived
        ? report.fifthqueryReceived
        : "",
      first_Query_Received_WIP_By: report.first_Query_Received_WIP_By
        ? report.first_Query_Received_WIP_By
        : "",
      second_Query_Received_WIP_By: report.second_Query_Received_WIP_By
        ? report.second_Query_Received_WIP_By
        : "",
      third_Query_Received_WIP_By: report.third_Query_Received_WIP_By
        ? report.third_Query_Received_WIP_By
        : "",
      fourth_Query_Received_WIP_By: report.fourth_Query_Received_WIP_By
        ? report.fourth_Query_Received_WIP_By
        : "",
      fifth_Query_Received_WIP_By: report.fifth_Query_Received_WIP_By
        ? report.fifth_Query_Received_WIP_By
        : "",

      first_Query_Received_WIP_Time: report.first_Query_Received_WIP_Time
        ? report.first_Query_Received_WIP_Time
        : "",
      second_Query_Received_WIP_Time: report.second_Query_Received_WIP_Time
        ? report.second_Query_Received_WIP_Time
        : "",
      third_Query_Received_WIP_Time: report.third_Query_Received_WIP_Time
        ? report.third_Query_Received_WIP_Time
        : "",
      fourth_Query_Received_WIP_Time: report.fourth_Query_Received_WIP_Time
        ? report.fourth_Query_Received_WIP_Time
        : "",
      fifth_Query_Received_WIP_Time: report.fifth_Query_Received_WIP_Time
        ? report.fifth_Query_Received_WIP_Time
        : "",
      //typing completed
      first_Typing_completed_Time: report.first_Typing_completed_Time
        ? report.first_Typing_completed_Time
        : "",
      second_Typing_completed_Time: report.second_Typing_completed_Time
        ? report.second_Typing_completed_Time
        : "",
      third_Typing_completed_Time: report.third_Typing_completed_Time
        ? report.third_Typing_completed_Time
        : "",
      fourth_Typing_completed_Time: report.fourth_Typing_completed_Time
        ? report.fourth_Typing_completed_Time
        : "",
      fifth_Typing_completed_Time: report.fifth_Typing_completed_Time
        ? report.fifth_Typing_completed_Time
        : "",
      //verification picked
      first_Verification_Picked_Time: report.first_Verification_Picked_Time
        ? report.first_Verification_Picked_Time
        : "",
      second_Verification_Picked_Time: report.second_Verification_Picked_Time
        ? report.second_Verification_Picked_Time
        : "",
      third_Verification_Picked_Time: report.third_Verification_Picked_Time
        ? report.third_Verification_Picked_Time
        : "",
      fourth_Verification_Picked_Time: report.fourth_Verification_Picked_Time
        ? report.fourth_Verification_Picked_Time
        : "",
      fifth_Verification_Picked_Time: report.fifth_Verification_Picked_Time
        ? report.fifth_Verification_Picked_Time
        : "",
      //verification Completed
      first_Verification_Completed_Time: report.first_Verification_Completed_Time
        ? report.first_Verification_Completed_Time
        : "",
      second_Verification_Completed_Time: report.second_Verification_Completed_Time
        ? report.second_Verification_Completed_Time
        : "",
      third_Verification_Completed_Time: report.third_Verification_Completed_Time
        ? report.third_Verification_Completed_Time
        : "",
      fourth_Verification_Completed_Time: report.fourth_Verification_Completed_Time
        ? report.fourth_Verification_Completed_Time
        : "",
      fifth_Verification_Completed_Time: report.fifth_Verification_Completed_Time
        ? report.fifth_Verification_Completed_Time
        : "",

      total_qr_file: report.total_qr_file ? report.total_qr_file : "",

      query_reiceved_date: report.query_reiceved_date
        ? report.query_reiceved_date
        : "",
      // query_queue_time: report.query_queue_time ? report.query_queue_time : "",
      query_taken_by: report.query_taken_by
        ? this.getNameById(report.query_taken_by)
        : "",
      typing_complited_dt: report.typing_complited_dt
        ? report.typing_complited_dt
        : "",
      total_Vfilequeue_time: report.total_Vfilequeue_time
        ? report.total_Vfilequeue_time
        : "",
      verified_by: report.verified_by
        ? this.getNameById(report.verified_by)
        : "",
      verification_picked_dt: report.verification_picked_dt
        ? report.verification_picked_dt
        : "",
      verification_completed_dt: report.verification_completed_dt
        ? report.verification_completed_dt
        : "",

      total_time: report.total_time ? report.total_time : "",
      front_office_sum: report.front_office ? report.front_office : "",
      work_in_progress_sum: report.total_wip_file ? report.total_wip_file : "",
      query_waiting_time_sum: report.total_qr_file ? report.total_qr_file : "",
      verification_wip_sum: report.total_vc_file ? report.total_vc_file : "",
      waiting_time_sum: report.total_queue_file ? report.total_queue_file : "",
      extended_hours_sum: report.total_ex_file ? report.total_ex_file : "",
      "": "",
      s_extendHours: report.s_extendHours ? report.s_extendHours : "",
      p_extendHours: report.p_extendHours ? report.p_extendHours : "",
      v_extendHours: report.v_extendHours ? report.v_extendHours : "",
      total_deviation_wip_file: report.total_deviation_wip_file
        ? report.total_deviation_wip_file
        : "",
      total_deviation_queue_time: report.total_deviation_queue_time
        ? report.total_deviation_queue_time
        : "",
      total_rectify_queue_time: report.total_rectify_queue_time
        ? report.total_rectify_queue_time
        : "",
      total_rectify_wip_file: report.total_rectify_wip_file
        ? report.total_rectify_wip_file
        : "",
      query_diff: report.query_diff ? report.query_diff : "",
      total_hold_time: report.total_hold_time ? report.total_hold_time : "",
      total_unhold_time: report.total_unhold_time ? report.total_unhold_time : "",
      // hold by
      first_hold_By: report.first_hold_By ? report.first_hold_By : "",
      second_hold_By: report.second_hold_By ? report.second_hold_By : "",
      third_hold_By: report.third_hold_By ? report.third_hold_By : "",
      fourth_hold_By: report.fourth_hold_By ? report.fourth_hold_By : "",
      fifth_hold_By: report.fifth_hold_By ? report.fifth_hold_By : "",
      // hold time
      first_hold_Time: report.first_hold_Time ? report.first_hold_Time : "",
      second_hold_Time: report.second_hold_Time ? report.second_hold_Time : "",
      third_hold_Time: report.third_hold_Time ? report.third_hold_Time : "",
      fourth_hold_Time: report.fourth_hold_Time ? report.fourth_hold_Time : "",
      fifth_hold_Time: report.fifth_hold_Time ? report.fifth_hold_Time : "",
      // unhold by
      first_unhold_By: report.first_unhold_By ? report.first_unhold_By : "",
      second_unhold_By: report.second_unhold_By ? report.second_unhold_By : "",
      third_unhold_By: report.third_unhold_By ? report.third_unhold_By : "",
      fourth_unhold_By: report.fourth_unhold_By ? report.fourth_unhold_By : "",
      fifth_unhold_By: report.fifth_unhold_By ? report.fifth_unhold_By : "",
      // unhold time
      first_unhold_Time: report.first_unhold_Time
        ? report.first_unhold_Time
        : "",
      second_unhold_Time: report.second_unhold_Time
        ? report.second_unhold_Time
        : "",
      third_unhold_Time: report.third_unhold_Time
        ? report.third_unhold_Time
        : "",
      fourth_unhold_Time: report.fourth_unhold_Time
        ? report.fourth_unhold_Time
        : "",
      fifth_unhold_Time: report.fifth_unhold_Time
        ? report.fifth_unhold_Time
        : "",
      // workInprogress by
      first_workInprogress_By: report.first_workInprogress_By
        ? report.first_workInprogress_By
        : "",
      second_workInprogress_By: report.second_workInprogress_By
        ? report.second_workInprogress_By
        : "",
      third_workInprogress_By: report.third_workInprogress_By
        ? report.third_workInprogress_By
        : "",
      fourth_workInprogress_By: report.fourth_workInprogress_By
        ? report.fourth_workInprogress_By
        : "",
      fifth_workInprogress_By: report.fifth_workInprogress_By
        ? report.fifth_workInprogress_By
        : "",
      // Rectify_Received
      first_Rectify_Received: report.first_Rectify_Received
        ? report.first_Rectify_Received
        : "",
      second_Rectify_Received: report.second_Rectify_Received
        ? report.second_Rectify_Received
        : "",
      third_Rectify_Received: report.third_Rectify_Received
        ? report.third_Rectify_Received
        : "",
      fourth_Rectify_Received: report.fourth_Rectify_Received
        ? report.fourth_Rectify_Received
        : "",
      fifth_Rectify_Received: report.fifth_Rectify_Received
        ? report.fifth_Rectify_Received
        : "",
      // Rectify_Completed
      first_Rectify_Completed: report.first_Rectify_Completed
        ? report.first_Rectify_Completed
        : "",
      second_Rectify_Completed: report.second_Rectify_Completed
        ? report.second_Rectify_Completed
        : "",
      third_Rectify_Completed: report.third_Rectify_Completed
        ? report.third_Rectify_Completed
        : "",
      fourth_Rectify_Completed: report.fourth_Rectify_Completed
        ? report.fourth_Rectify_Completed
        : "",
      fifth_Rectify_Completed: report.fifth_Rectify_Completed
        ? report.fifth_Rectify_Completed
        : "",
      // Deviation Received
      first_deviation_Received: report.first_deviation_Received
        ? report.first_deviation_Received
        : "",
      second_deviation_Received: report.second_deviation_Received
        ? report.second_deviation_Received
        : "",
      third_deviation_Received: report.third_deviation_Received
        ? report.third_deviation_Received
        : "",
      fourth_deviation_Received: report.fourth_deviation_Received
        ? report.fourth_deviation_Received
        : "",
      fifth_deviation_Received: report.fifth_deviation_Received
        ? report.fifth_deviation_Received
        : "",
      // deviation Completed
      first_deviation_Completed: report.first_deviation_Completed
        ? report.first_deviation_Completed
        : "",
      second_deviation_Completed: report.second_deviation_Completed
        ? report.second_deviation_Completed
        : "",
      third_deviation_Completed: report.third_deviation_Completed
        ? report.third_deviation_Completed
        : "",
      fourth_deviation_Completed: report.fourth_deviation_Completed
        ? report.fourth_deviation_Completed
        : "",
      fifth_deviation_Completed: report.fifth_deviation_Completed
        ? report.fifth_deviation_Completed
        : "",
      // deviation by
      first_deviation_By: report.first_deviation_By
        ? report.first_deviation_By
        : "",
      second_deviation_By: report.second_deviation_By
        ? report.second_deviation_By
        : "",
      third_deviation_By: report.third_deviation_By
        ? report.third_deviation_By
        : "",
      fourth_deviation_By: report.fourth_deviation_By
        ? report.fourth_deviation_By
        : "",
      fifth_deviation_By: report.fifth_deviation_By
        ? report.fifth_deviation_By
        : "",
    }));

    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="mt-1 mb-1">File Logs</h5>
                        </div>

                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-xl-3 col-md-3">
                              <h6>From</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                disabledDate={this.disabledDate}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodFromDate"
                                  )
                                }
                                value={moment(
                                  this.state.eodFromDate,
                                  "DD.MM.YYYY"
                                )}
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-3 col-md-3">
                              <h6>To</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                disabledDate={this.disabledDate}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodToDate"
                                  )
                                }
                                value={moment(
                                  this.state.eodToDate,
                                  "DD.MM.YYYY"
                                )}
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-3 col-md-3">
                              <h6>LH Branch</h6>
                              {lhBranchShow ? (
                                <>
                                  {this.state.lawhandsBranches &&
                                    this.state.lawhandsBranches.length > 0 &&
                                    this.state.lawhandsBranches.map(
                                      (opt: any) => {
                                        opt.label = opt.name;
                                        opt.value = opt.id;
                                      }
                                    ) && (
                                      <Select
                                        options={lawhandsBranchesoptions}
                                        name="lawHandsBranch"
                                        value={this.state.lawhandsBranches.find(
                                          (o: any) =>
                                            o.value ===
                                            this.state.lawHandsBranch
                                        )}
                                        onChange={(e) =>
                                          this.handleChange1(
                                            e,
                                            "lawHandsBranch",
                                            "singleselect"
                                          )
                                        }
                                      />
                                    )}
                                </>
                              ) : (
                                <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={
                                    lawBranchName
                                      ? () => this.lawBranchName(1)
                                      : () => this.lawBranchName(2)
                                  }
                                  onChange={this.handleChange}
                                >
                                  <option value="">Select</option>
                                  {this.state.lawhandsBranches &&
                                    this.state.lawhandsBranches.length
                                    ? this.state.lawhandsBranches.map(
                                      (lawBranch: any, index: number) => {
                                        return (
                                          <option
                                            key={index}
                                            value={lawBranch.id}
                                          >
                                            {lawBranch.name}
                                          </option>
                                        );
                                      }
                                    )
                                    : ""}
                                </select>
                              )}
                            </div>

                            <div className="col-xl-3 col-md-3">
                              <h6>Bank Name</h6>
                              {showBank ? (
                                <>
                                  {" "}
                                  {this.state.banks &&
                                    this.state.banks.length > 0 &&
                                    this.state.banks.map((opt: any) => {
                                      opt.label = opt.name;
                                      opt.value = opt.id;
                                    }) && (
                                      <Select
                                        options={banksoptions}
                                        name="bank_private_name"
                                        value={this.state.banks.find(
                                          (o: any) =>
                                            o.value ===
                                            this.state.bank_private_name
                                        )}
                                        onClick={
                                          bankName
                                            ? () => this.bankName(1)
                                            : () => this.bankName(2)
                                        }
                                        onChange={(e) =>
                                          this.handleChange1(
                                            e,
                                            "bank_private_name",
                                            "singleselect"
                                          )
                                        }
                                      />
                                    )}
                                </>
                              ) : (
                                <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={
                                    bankName
                                      ? () => this.bankName(1)
                                      : () => this.bankName(2)
                                  }
                                  onChange={this.handleChange}
                                >
                                  <option value="">Select</option>
                                  {this.state.lawhandsBranches &&
                                    this.state.lawhandsBranches.length
                                    ? this.state.lawhandsBranches.map(
                                      (lawBranch: any, index: number) => {
                                        return (
                                          <option
                                            key={index}
                                            value={lawBranch.id}
                                          >
                                            {lawBranch.name}
                                          </option>
                                        );
                                      }
                                    )
                                    : ""}
                                </select>
                              )}
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-xl-3 col-md-3">
                              <h6>Bank Branch</h6>
                              {bankBranch ? (
                                <>
                                  {this.state.branches &&
                                    this.state.branches.length > 0 &&
                                    this.state.branches.map((opt: any) => {
                                      opt.label = opt.name;
                                      opt.value = opt.id;
                                    }) && (
                                      <Select
                                        options={filteredBranchesoptions}
                                        name="branch"
                                        value={this.state.filteredBranches.find(
                                          (o: any) =>
                                            o.value === this.state.branch
                                        )}
                                        onClick={
                                          branchName
                                            ? () => this.branchName(1)
                                            : () => this.branchName(2)
                                        }
                                        onChange={(e) =>
                                          this.handleChange1(
                                            e,
                                            "branch",
                                            "singleselect"
                                          )
                                        }
                                      />
                                    )}{" "}
                                </>
                              ) : (
                                <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={
                                    branchName
                                      ? () => this.branchName(1)
                                      : () => this.branchName(2)
                                  }
                                  onChange={this.handleChange}
                                >
                                  <option value="">Select</option>
                                  {this.state.lawhandsBranches &&
                                    this.state.lawhandsBranches.length
                                    ? this.state.lawhandsBranches.map(
                                      (lawBranch: any, index: number) => {
                                        return (
                                          <option
                                            key={index}
                                            value={lawBranch.id}
                                          >
                                            {lawBranch.name}
                                          </option>
                                        );
                                      }
                                    )
                                    : ""}
                                </select>
                              )}
                            </div>
                            <div className="col-xl-3 col-md-3">
                              <h6>Employee Name</h6>
                              {this.state.users &&
                                this.state.users.length >= 0 &&
                                this.state.users.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select
                                    options={usersoptions}
                                    name="assigned_to"
                                    value={this.state.users.find(
                                      (o: any) =>
                                        o.value === this.state.assigned_to
                                    )}
                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "assigned_to",
                                        "singleselect"
                                      )
                                    }
                                    required
                                  />
                                )}
                            </div>
                            <div className="col-xl-3 col-md-3">
                              <h6>File.No</h6>
                              <input
                                type="text"
                                name="file_id"
                                className="form-control"
                                placeholder="Eg.12306"
                                value={this.state.file_id}
                                onChange={(e) => this.handleChange(e)}
                              />
                            </div>
                            <div className="col-xl-3 col-md-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={() => this.getEodTableData()}
                              >
                                {this.state.isLoading ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  "Search"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="file-time">
                            <div
                              className="file-time-item"
                              style={{ background: "#2c9bf0" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Total <br /> Time
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_time
                                  ? this.state.total_time
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#6d302b" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Front Office <br /> Time
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_fc
                                  ? this.state.total_fc
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#046808" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Process <br /> WIP
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_wip
                                  ? this.state.total_wip
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#652cb0" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Query Waiting <br /> Time
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_qr
                                  ? this.state.total_qr
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#dc7b02" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Verification <br /> WIP
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_vc
                                  ? this.state.total_vc
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#f14724" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Queue <br /> Time
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_queue
                                  ? this.state.total_queue
                                  : "00:00:00"}
                              </span>
                            </div>
                            <div
                              className="file-time-item"
                              style={{ background: "#f9c710" }}
                            >
                              <p className="color-white text-center">
                                <b>
                                  Extended <br /> Hour
                                </b>
                              </p>
                              <span className="color-white">
                                {this.state.total_ex
                                  ? this.state.total_ex
                                  : "00:00:00"}
                              </span>
                            </div>
                          </div>
                          <div className="">
                            {this.state.loadingexcel === true && (
                              <div className="d-flex">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="export-btn"
                                >
                                  <CSVLink
                                    headers={excelColumn}
                                    data={excelRowData}
                                    filename={"File-Logs"}
                                    style={{
                                      textDecoration: "none",
                                      color: "#fff",
                                    }}
                                  >
                                    {!this.state.loadingexcel
                                      ? "Loading csv..."
                                      : "Export Data"}
                                  </CSVLink>
                                </Button>
                                <p className="ml-5 total-file-count">
                                  <b>
                                    Total Count -{" "}
                                    {this.state.timeDifferences.length}
                                  </b>
                                </p>
                              </div>
                            )}
                            <ReactFlexyTable
                              className="assigned-table"
                              data={processedData}
                              columns={columns}
                              sortable
                              globalSearch
                              pageSize={20}
                            />
                            <div className="pagination-container">
                              {/* <div>
                                                                <span>Show</span>
                                                                <Select className="select-container" variant="outlined" value={this.state.rowCount} onChange={e => this.handlePageLimit(e)}>
                                                                    {this.limitList.map((limitValue, index) => (
                                                                        <MenuItem key={index} value={limitValue}>
                                                                            {limitValue}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <span>Rows</span>
                                                            </div> */}
                              {noOfPages > 1 && (
                                <Pagination
                                  count={noOfPages}
                                  defaultPage={this.state.pageNumber}
                                  onChange={this.handlePagination}
                                  showFirstButton
                                  showLastButton
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div
          className="modal fade hide in"
          id="out_reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                <h3 style={{ margin: "auto", fontSize: "17px" }}>
                  Please Select The Reason For To Close The File.No:
                  {sessionStorage.getItem("currentfileId")}
                </h3>
              </div>
              <div
                className="form-group"
                style={{ width: "74%", margin: "auto" }}
              >
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: "8px" }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>
                </select>
                {this.state.selectVal ? (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Please Select Your Reason{" "}
                  </p>
                ) : null}
              </div>
              <div className="modal-footer" style={{ marginTop: "27px" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: "76px", fontSize: "17px" }}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{
                    width: "107px",
                    fontSize: "14px",
                    border: "1px solid black",
                    height: "43px",
                  }}
                >
                  {" "}
                  Back To File
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div
          className="modal hide fade in"
          id="another-reason"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog ">
            <div
              className="modal-content"
              style={{ borderRadius: "10px", boxShadow: "0 0 15px #000" }}
            >
              <h5 style={{ marginTop: "31px", textAlign: "center" }}>
                Please Type a Reason To Close The File.No:{" "}
                {sessionStorage.getItem("currentfileId")}{" "}
              </h5>
              <div
                className="modal-body"
                style={{ padding: "30px 23px 29px 24px" }}
              >
                {/* <div className="col-md-5"> */}
                <div
                  className="form-group"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason"
                    onChange={this.onchange}
                  />
                  {this.state.selectVal ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      Please Select Your Reason{" "}
                    </p>
                  ) : null}
                </div>
                {/* </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: "76px", fontSize: "17px" }}
                  disabled={this.state.isLoading}
                >
                  {" "}
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "OK"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{
                    width: "76px",
                    fontSize: "17px",
                    border: "1px solid black",
                  }}
                >
                  {" "}
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileLog;
