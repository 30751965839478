import React, { Component } from "react";
import { Resizable } from 'react-resizable';
// import { Document, Page } from "react-pdf";
import Select from 'react-select';
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { isEmpty } from 'lodash';
import { API_ENDPOINT, callApi } from "../../utils/api";
import PDFHighlighter from '../PDF-Highlighter';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import CountdownTimer from "../file/CountdownTimer";
interface AllProps {
  match: any;
  history: any;
  location: any;
}
export default class ECForm extends Component<AllProps> {
  state = {
    base: "",
    pageNumber: 1,
    numPages: 0,
    fod_fk: "", //used
    doc: "",
    ec_id: "", //used
    ec_entry: "", //used
    entry_date: "",  //used //used status
    sale_date: "",
    sro: "",  //used
    date: "", //used
    documents: [],
    date_list: [], //used
    type: "",
    selectedDoc: "",
    org_pres: "", //used
    originalPresents: [],
    ec_no: "", //used
    ec_entries: "",
    categories: [],
    sroList: [],
    docNoList: [],
    remark: "", //used
    deeds: [],
    isLoading: false,
    documentWidth: 50, documentHeight: 700,
    formWidth: 45, formHeight: 900,
    inactive: 0,
    url: '',
    highlights: [], //used
    rotation: 0,
    disabled: true,
    enableWheel: false,
    panningEnabled: false,
    transformEnabled: false,
    pinchEnabled: false,
    enableTouchPadPinch: false,
    velocityEqualToMove: false,
    enableVelocity: false,
    limitsOnWheel: true,
    isHightlightsLoaded: false,
    required: false,
    autoAssign: [
      {
        "id": "1",
        "module_name": "Auto Assign",
        "status": true
      }
    ],
    docToPreview: false,
  };
  document = {
    base: "",
    numPages: 0,
    pageNumber: 1
  };
  countdownTimerRef: CountdownTimer | null = null;
  componentDidMount() {
    this.getDeeds();
    this.getECDocs();
    this.getCategories();
    this.getSroList();
    this.getOriginalPresents();
    this.getDocNoList();
    // this.getAutoAssignStatus();
  }

  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDocumentResize = (event, { element, size, handle }) => {
    if (size.width <= 60) {
      this.setState({ documentWidth: size.width, documentHeight: size.height, formWidth: 90 - size.width });
    }
  };
  onFormResize = (event, { element, size, handle }) => {
    this.setState({ formWidth: size.width, formHeight: size.height, documentWidth: 90 - size.width });
  };
  getCategories = () => {
    callApi("POST", "category.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ categories: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getSroList = () => {
    callApi("POST", "sro_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ sroList: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  dynamicCheckbox = () => {
    // this.state.date_list = [];
    var length = 0;
    console.log("this.state.date_list", this.state.date_list);

    if (this.state.date_list == null) {
      setTimeout(() => {
        this.dynamicCheckbox();
      }, 50);
      length = JSON.parse(this.state.ec_entries);
      for (let index = 0; index < length; index++) {
        const element = index + 1;
        console.log("element", element);
        this.addCheckboxes();
      }
    } else {
      if (JSON.parse(this.state.ec_entries) > this.state.date_list.length) {
        length = JSON.parse(this.state.ec_entries) - this.state.date_list.length;
        for (let index = 0; index < length; index++) {
          const element = index + 1;
          console.log("element", element);
          this.addCheckboxes();
        }
      } else {
        length = this.state.date_list.length - JSON.parse(this.state.ec_entries);
        console.log("length---->>>", length);

        for (let index = 0; index < length; index++) {
          const element = index + 1;
          console.log("element", element);
          this.removeCheckboxes();
        }
        // length = this.state.date_list.length - length;
      }
    }

    // console.log("12345637u6", this.state.ec_entries);
    // length = JSON.parse(this.state.ec_entries);

  }
  getOriginalPresents = () => {
    callApi("POST", "original_get.php")
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const originalPresents = response.data;
          this.setState({ originalPresents });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getECDocs = () => {
    callApi("POST", "ec_get_doc.php", {
      file_idfk: this.props.match.params.fileid
    })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const state = this.state;
          state.selectedDoc = this.props.match.params.id
            ? this.props.match.params.id
            : response.data[0].id;
          this.setState({ documents: response.data });
          this.getRevenueDocs(state.selectedDoc);
          this.getECDocForm(state.selectedDoc);
        } else {
          this.props.history.push(
            "/work-file/2/" + this.props.match.params.fileid
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getECDocForm = docId => {
    callApi("POST", "ec_get.php", {
      file_idfk: docId
    })
      .then(res => res.data)
      .then(response => {
        if (response.data) {
          const state = response.data;
          state.highlights = response.data.highlights || [];
          state.inactive = response.data.not_related_status;
          if (state.date_list && state.date_list.length) {
            state.date_list.forEach((itm: any) => {
              if (itm.is_other_doc) {
                itm.otherDoc = itm.doc;
                itm.doc = "other";
              } else {
                itm.is_other_doc = false;
                itm.disableEcEntry = true;
              }
            });
          }
          this.setState({ ...state, isHighlightsLoaded: true });
          console.log("this.state----->", this.state);
        } else {
          this.setState({ isHighlightsLoaded: true });
        }
      })
      .catch(err => {
        this.setState({ isHighlightsLoaded: true });
        console.log(err);
      });
  };
  getRevenueDocs = docId => {
    this.setState({ isHighlightsLoaded: false });
    callApi("POST", "getbaseby_id.php", { id: docId })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = response.data[0].base[0].base;
          const state = this.state;
          state.type = response.data[0].base[0].type;
          this.setState({ ...document, isHightlightsLoaded: true });
        }
      })
      .catch(err => {
        this.setState({ isHighlightsLoaded: true });
        console.log(err);
      });
  };
  getDocNoList = () => {
    callApi("POST", "doc_no_list.php", { id: this.props.match.params.fileid })
      .then(res => res.data)
      .then(response => {
        const docNoList: any = response.data ?? [];
        docNoList.push({ name: 'Other', id: 'other' })
        this.setState({ docNoList });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getDeeds = () => {
    callApi("GET", "deed_master_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ deeds: response.data });
        console.log("deedsvalue=========================>", this.state.deeds);

      })
      .catch(err => {
        console.log(err);
      });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  handleChange = (e, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    if (fieldName === "selectedDoc") {
      this.getRevenueDocs(fieldValue);
      this.getECDocForm(fieldValue);
    }

    this.setState({ [fieldName]: fieldValue }, () => {
    });
  };

  addItem = () => {
    const date_list: any = this.state.date_list ? this.state.date_list : [];
    date_list.push({
      date: "",
      doc: "",
      otherDoc: "",
      deed: "",
      not_related: false,
      is_other_doc: false,
      favour: "",
      executant: "",
      extent: "",
      sheetCount: "",
      // avail_sheetCount : "",
      // missing_sheetCount: "",
      disableEcEntry: false
    });
    this.setState({ date_list });
  };
  removeCheckboxes = () => {
    const date_list: any = this.state.date_list ? this.state.date_list : [];
    date_list.pop({
      not_related: false,
      date: "",
      doc: "",
      otherDoc: "",
      deed: "",
      is_other_doc: false,
      favour: "",
      executant: "",
      extent: "",
      sheetCount: "",
      // avail_sheetCount:"",
      // missing_sheetCount:"",
      disableEcEntry: false
    });
    this.setState({ date_list });
  }
  addCheckboxes = () => {
    const date_list: any = this.state.date_list ? this.state.date_list : [];
    date_list.push({
      not_related: false,
      date: "",
      doc: "",
      otherDoc: "",
      deed: "",
      is_other_doc: false,
      favour: "",
      executant: "",
      extent: "",
      sheetCount: "",
      // avail_sheetCount:"",
      // missing_sheetCount:"",
      disableEcEntry: false
    });
    this.setState({ date_list });
  };
  saveRelatedDocs = () => {
    console.log("this.state.date_list", this.state.date_list);
  }
  insertItem = (index: number) => {
    const date_list: any = this.state.date_list ? this.state.date_list : [];
    date_list.splice(index + 1, 0, {
      date: "",
      doc: "",
      otherDoc: "",
      deed: "",
      not_related: false,
      is_other_doc: false,
      favour: "",
      executant: "",
      extent: "",
      sheetCount: "",
      // avail_sheetCount:"",
      // missing_sheetCount:"",
      disableEcEntry: false
    });
    this.setState({ date_list });
  };
  removeItem = (index: number) => {
    const date_list = this.state.date_list;
    date_list.splice(index, 1);
    this.setState({ date_list });
  };
  handleDateChange(e, index, date_id?, propName?, fieldType?) {
    console.log("1234,", fieldType);
    const date_list: any = this.state.date_list;
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    date_list.forEach((itm: any, i) => {
      if (i === index) {
        itm[fieldName] = fieldValue;
        if (fieldName === "doc") {
          itm.is_other_doc = fieldValue === "other" ? true : false;
          // this.getFavourExtentEntry(fieldValue, date_id, index)
        }
        itm[fieldName] = fieldValue;
        if (fieldName === "not_related") {
          itm[fieldName] = e.target.checked;
        }
      }
    });
    this.setState({ date_list });
  }
  changeFileType = () => {
    callApi("POST", "file_type.php", {
      id: this.state.selectedDoc,
      type: this.state.type
    })
      .then(res => res.data)
      .then(response => {
        if (this.state.type === "title_deed") {
          this.props.history.push(
            "/general-detail/1/" +
            this.props.match.params.fileid +
            "/" +
            this.props.match.params.id
          );
        } else if (
          this.state.type === "deed" ||
          this.state.type === "rev_rec_doc" ||
          this.state.type === "sale_agreement" ||
          this.state.type === "mother_doc"
        ) {
          this.props.history.push(
            "/scrutiny/" +
            this.props.match.params.fileid +
            "/" +
            this.props.match.params.id +
            "/" +
            this.props.match.params.tabId
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getFavourExtentEntry = (doc, date_id, ind) => {
    const date_list: any = this.state.date_list;
    if (doc !== 'other') {
      callApi("POST", "ec_date_get.php", { date_id, doc })
        .then(res => res.data)
        .then(response => {
          if (response.data && response.data.length) {
            date_list[ind]['favour'] = response.data[0].favour;
            date_list[ind]['executant'] = response.data[0].executant;
            date_list[ind]['extent'] = response.data[0].extent;
            if (response.data[0].sheetCount)
              date_list[ind]['sheetCount'] = response.data[0].sheetCount;
            // date_list[ind]['avail_sheetCount'] = response.data[0].avail_sheetCount;
            // date_list[ind]['missing_sheetCount'] = response.data[0].missing_sheetCount;

            date_list[ind]['disableEcEntry'] = true;
          } else {
            date_list[ind]['disableEcEntry'] = false;
          }
          this.setState({ date_list })
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      date_list[ind]['disableEcEntry'] = false;
      this.setState({ date_list })
    }
  };
  onAddingItem = (item) => {
    var self = this;
    const value = item.target.type === 'checkbox' ? item.target.checked : item.target.value;
    // var newArray = self.state.product.slice();
    // if(item.target.checked){
    //     newArray.push(item.target.value);
    //     self.setState({addProducts:value, product:newArray})
    // } else {
    //   newArray.splice(item.target.value, 1); //remove element
    //   self.setState({addProducts:value, product:newArray}); //update state
    // }
  }
  saveEC = saveStatus => {
    if ((!this.state.sro || this.state.sro == "0") || this.state.date == "" || this.state.sale_date == "" || this.state.entry_date == "" || this.state.remark == "" || (!this.state.org_pres || this.state.org_pres == "0")) {
      this.setState({ required: true });
      ToastsStore.error("Some Required fields are empty123");
    } else if (this.state.date_list && this.state.date_list.length) {
      const isValid = this.validateDocuments();
      if (!isValid) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else {
        this.setState({ required: false });
        this.confirmSaveEC(saveStatus);
      }
    } else {
      this.setState({ required: false });
      this.confirmSaveEC(saveStatus);
    }
  }
  confirmSaveEC = saveStatus => {
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const state: any = this.state;
    state.file_idfk = this.props.match.params.fileid;
    state.fod_fk = this.state.selectedDoc;
    state.status = saveStatus;
    if (state.date_list && state.date_list.length) {
      state.date_list.map(date => {
        date.doc = date.doc === "other" ? date.otherDoc : date.doc;
      });
    }
    console.log("state----->>>", state);
    delete state.base;
    delete state.deeds;
    delete state.location;
    delete state.originalPresents;
    delete state.categories;
    delete state.sroList;
    delete state.docNoList;
    delete state.pageNumber;
    delete state.numPages;
    delete state.doc;
    delete state.documents;
    delete state.type;
    delete state.selectedDoc;
    delete state.originalPresents;
    delete state.categories;
    delete state.sroList;
    delete state.docNoList;
    delete state.deeds;
    delete state.isLoading;
    delete state.documentWidth;
    delete state.documentHeight;
    delete state.formWidth;
    delete state.formHeight;
    delete state.url;
    delete state.rotation;
    delete state.disabled;
    delete state.enableWheel;
    delete state.panningEnabled;
    delete state.transformEnabled;
    delete state.pinchEnabled;
    delete state.enableTouchPadPinch;
    delete state.velocityEqualToMove;
    delete state.enableVelocity;
    delete state.limitsOnWheel;
    delete state.isHightlightsLoaded;
    delete state.required;
    if (state.date_list == "[]" || state.date_list == null) {
      this.setState({ isLoading: true });
      sessionStorage.removeItem('overviewDocument');
      callApi("POST", "ec.php", state)
        .then(res => res.data)
        .then(response => {
          delete state.base;
          delete state.deeds;
          delete state.location;
          delete state.originalPresents;
          console.log(response);
          this.getECDocs();
          // ToastsStore.success("EC Form Saved Successfully");
          this.setState({ isLoading: false }, () => {
            if (isVerifyFlow) {
              ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
              setTimeout(() => {
                window.close();
              }, 3000);
            } else this.props.history.push(
              "/work-file/2/" + this.props.match.params.fileid
            );
          });

        })
        .catch(err => {
          ToastsStore.error("Unable To Save EC Form!");
          console.log(err);
          this.setState({ isLoading: false });
        });
    } else {
      if (state.date_list == "[]" || this.state.inactive == 1) {
        this.setState({ isLoading: true });
        sessionStorage.removeItem('overviewDocument');
        callApi("POST", "ec.php", state)
          .then(res => res.data)
          .then(response => {
            delete state.base;
            delete state.deeds;
            delete state.location;
            delete state.originalPresents;
            console.log(response);
            this.getECDocs();
            // ToastsStore.success("EC Form Saved Successfully");
            this.setState({ isLoading: false }, () => {
              if (isVerifyFlow) {
                ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                setTimeout(() => {
                  window.close();
                }, 3000);
              } else this.props.history.push(
                "/work-file/2/" + this.props.match.params.fileid
              );
            });

          })
          .catch(err => {
            ToastsStore.error("Unable To Save EC Form!");
            console.log(err);
            this.setState({ isLoading: false });
          });
      } else {
        if (!state.date_list.not_related) {
          this.setState({ isLoading: true });
          sessionStorage.removeItem('overviewDocument');
          callApi("POST", "ec.php", state)
            .then(res => res.data)
            .then(response => {
              delete state.base;
              delete state.deeds;
              delete state.location;
              delete state.originalPresents;
              console.log(response);
              this.getECDocs();
              // ToastsStore.success("EC Form Saved Successfully");
              this.setState({ isLoading: false }, () => {
                if (isVerifyFlow) {
                  ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                  setTimeout(() => {
                    window.close();
                  }, 3000);
                } else this.props.history.push(
                  "/work-file/2/" + this.props.match.params.fileid
                );
              });

            })
            .catch(err => {
              ToastsStore.error("Unable To Save EC Form!");
              console.log(err);
              this.setState({ isLoading: false });
            });
        } else {
          if (!state.date_list[0].date || !state.date_list[0].doc || !this.state.remark || !this.state.date || !this.state.sro || !this.state.org_pres || !this.state.sale_date || !this.state.entry_date) {
            this.setState({ required: true });
            ToastsStore.error("Some required fields are missing");
          }
          else {
            this.setState({ isLoading: true });
            sessionStorage.removeItem('overviewDocument');
            callApi("POST", "ec.php", state)
              .then(res => res.data)
              .then(response => {
                delete state.base;
                delete state.deeds;
                delete state.location;
                delete state.originalPresents;
                console.log(response);
                this.getECDocs();
                // ToastsStore.success("EC Form Saved Successfully");
                this.setState({ isLoading: false }, () => {
                  if (isVerifyFlow) {
                    ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                    setTimeout(() => {
                      window.close();
                    }, 3000);
                  } else this.props.history.push(
                    "/work-file/2/" + this.props.match.params.fileid
                  );
                });

              })
              .catch(err => {
                ToastsStore.error("Unable To Save EC Form!");
                console.log(err);
                this.setState({ isLoading: false });
              });
          }
        }
      }
    }
  };
  validateDocuments = () => {
    let datelist: any = this.state.date_list
    let isValid = true
    for (let i = 0; i < datelist.length; i++) {
      const document = datelist[i];
      if (document.not_related == true) {
        console.log("notrelatedcontent-----------------------------------...", i, document.not_related);
        if (document.deed == "" || document.doc == "" || document.date == "") {
          isValid = false;
          datelist[i].isValid = true;
        } else {
          datelist[i].isValid = false;
        }
      }
    }
    return isValid;
  }
  deleteDoc = () => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "scrutiny_delete.php", {
      id: this.state.selectedDoc,
      userId
    })
      .then(res => res.data)
      .then(response => {
        ToastsStore.success("EC Doc Deleted Successfully");
        this.getECDocs();
      })
      .catch(err => {
        console.log(err);
      });
  };
  toggleDocStatus = () => {
    const inactive = this.state.inactive ? 0 : 1;
    callApi("POST", "doc_status_update.php", { id: this.props.match.params.id, inactive, flag: 'scruty' })
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('overviewDocument');
        this.setState({ inactive })
      })
      .catch(err => {
        console.log(err);
      });
  };
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    console.log("rotation ", newRotation);
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({ rotation: newRotation })
    console.log("rotation degree");
  }
  onHighlightsChange = (highlights) => {
    this.setState({ highlights });
  }
  updatedRemainingTime(time) {
  }
  render() {
    const { rotation, velocityEqualToMove, enableVelocity, highlights, isHightlightsLoaded, base } = this.state;
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const takenfile_id: any = sessionStorage.getItem("takenfile_id");
    const currentfile_Id: any = sessionStorage.getItem("currentfileId");
    let takenfile = null;
    if (takenfile_id) {
      try {
        takenfile = JSON.parse(takenfile_id);
      } catch (error) {
        console.error("Error parsing takenfile_id JSON:", error);
      }
    };
    const currentfile = JSON.parse(currentfile_Id);
    const user: any = sessionStorage.getItem("user");
    const roletype = user ? JSON.parse(user)["roleType"] : "";
    let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
    return (
      <div className="pcoded-content">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-12">
                          {initialTimeInMinutes && initialTimeInMinutes > 0 && (
                            <CountdownTimer
                              initialTime={initialTimeInMinutes}
                              updatedTime={(time) => this.updatedRemainingTime(time)}
                              ref={(ref) => (this.countdownTimerRef = ref)}
                            />
                          )}
                        </div><br />
                      </div>
                      <h5>Document</h5>
                    </div>
                    <div className="card-body">
                      <div className="inline-block">
                        <Resizable id="frame" className="box" height={this.state.documentHeight} width={this.state.documentWidth} onResize={this.onDocumentResize} resizeHandles={['e']}>
                          <div
                            // className="col-md-8"
                            style={{
                              overflow: "auto", marginRight: '0', maxWidth: '60%', minWidth: '40%', float: 'left', width: this.state.documentWidth + '%'
                            }}
                          >
                            <div className="container" style={{
                              border: "1px solid #e3e5e8",
                              borderRadius: "10px",
                            }}>
                              <div className="row" style={{
                                border: "1px solid #e3e5e8",
                                width: "100%",
                                padding: "10px",
                                paddingTop: "8px",
                                display: "flex",
                                borderRadius: "10px",
                                margin: "auto"
                              }}>
                                <div className="col-md-6">
                                  <label>Document</label>
                                  {this.state.documents && this.state.documents.length > 0 && this.state.documents.map((opt: any) => {
                                    opt.label = 'Document - ' + opt.id + (opt.status ? "-" + opt.status : "");
                                    opt.value = opt.id
                                  }) &&
                                    <Select options={this.state.documents} name="selectedDoc"
                                      value={this.state.documents.find((o: any) => o.value === this.state.selectedDoc)}
                                      onChange={(e) => this.handleChange(e, 'selectedDoc', 'singleselect')}
                                    />
                                  }
                                </div>
                                <div className="col-md-2"
                                  style={{ margin: "auto", display: "block" }}>
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-rounded btn-danger"
                                    onClick={this.deleteDoc}
                                  >
                                    <i className="feather icon-minus" />
                                  </button>
                                </div>
                                <div className="col-md-4">
                                  <label>Not Related Doc</label>
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" checked={this.state.inactive == 1 ? true : false} onChange={this.toggleDocStatus} />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                  <label style={{ margin: '0px 10px' }}>No/Yes</label>
                                </div>
                              </div>
                              <br />
                              <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                  <a className="nav-link active" id="old-view-tab" data-toggle="tab"
                                    href="#old-viewer" role="tab" aria-controls="home" aria-selected="true">
                                    Old Viewer
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                    href="#new-viewer" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.setState({ docToPreview: true })}>
                                    New Viewer
                                  </a>
                                </li>
                              </ul>
                              <div className="tab-content" id="tab-content" style={{
                                border: "1px solid #e2e5e8 !important",
                                borderBottomRightRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                width: "100%",
                                padding: "0px"
                              }}>
                                <div className="tab-pane fade show active" id="old-viewer" role="tabpanel" aria-labelledby="old-viewer">
                                  <iframe
                                    src={this.document.base}
                                    width='100%'
                                    height={this.state.documentHeight}
                                  />
                                </div>
                                <div className="tab-pane fade" id="new-viewer" role="tabpanel" aria-labelledby="new-viewer">
                                  <p style={{ color: 'red' }}>NOTE: TO HIGHLIGHT [ ALT + RIGHT CLICK ON MOUSE AND DRAG ]</p>
                                  <br />
                                  <div className="App" style={{ display: 'flex' }}>
                                    <TransformWrapper
                                      pan={{
                                        disabled: true,
                                        velocityEqualToMove,
                                        velocity: enableVelocity
                                      }}
                                      wheel={{
                                        disabled: true
                                      }}
                                    >
                                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                        <React.Fragment>
                                          <div className="tools">
                                            <Rotate90DegreesCcwIcon className="pdf-icon rotate-icon" onClick={this.rotate} />
                                            <ZoomInIcon className="pdf-icon" onClick={zoomIn} />
                                            <ZoomOutIcon className="pdf-icon" onClick={zoomOut} />
                                            <FullscreenExitIcon className="pdf-icon" onClick={resetTransform} />
                                            {this.state.base && this.state.isHightlightsLoaded && <FullscreenIcon className="pdf-icon" onClick={() => this.setState({ docToPreview: true })} />}
                                          </div>
                                          <TransformComponent>
                                            <div
                                              style={{
                                                height: 'calc(99vh - 110px)',
                                                width: '45vw',
                                                position: 'relative',
                                                transform: `rotate(${rotation}deg)`
                                              }}
                                            >
                                              {base && !this.state.docToPreview && <PDFHighlighter url={base} onHighlightsChange={this.onHighlightsChange} highlights={highlights} />}
                                            </div>
                                          </TransformComponent>
                                        </React.Fragment>
                                      )}
                                    </TransformWrapper>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Resizable>
                        <Resizable className="box" height={this.state.formHeight} width={this.state.formWidth} onResize={this.onFormResize} resizeHandles={[]}>
                          <div style={{ width: this.state.formWidth + '%', height: this.state.formHeight + 'px', float: 'right' }}>
                            <div className="row form-document-ec">
                              <div className="col-md-12">
                                <div className="row" style={{
                                  border: "1px solid #e3e5e8",
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  margin: "auto"
                                }}>
                                  <div className="col-md-8">
                                    <label>Category</label>
                                    {this.state.categories && this.state.categories.length > 0 && this.state.categories.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                      <Select options={this.state.categories} name="type"
                                        value={this.state.categories.find((o: any) => o.value === this.state.type)}
                                        onChange={(e) => this.handleChange(e, "type", 'singleselect')}
                                      />
                                    }
                                  </div>
                                  <div
                                    className="col-md-4">
                                    <button
                                      className="btn btn-primary sw-btn-next"
                                      style={{ margin: "29px" }}
                                      type="button"
                                      onClick={() => this.changeFileType()}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row"> */}
                              <div className="row" style={{
                                width: "100%",
                                border: "1px solid #e3e5e8",
                                borderRadius: "10px",
                                margin: "20px",
                                padding: "4px",
                                // height: "19%"
                              }}>
                                {/* <div className="col-md-6">
                                  <div className="form-group" style={{
                                    margin: "15px",
                                    float: "right",
                                    marginRight: "16%"
                                  }}>
                                    <label>How many EC Entries?</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <button
                                      className="btn btn-primary col-md-8"
                                      onClick={this.addItem}
                                    >
                                      + Add EC Entries
                                    </button>
                                  </div>
                                </div> */}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Date<span style={{ color: "#FF0000" }}>*</span></label>
                                    {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder="dd/mm/yyyy"
                                    name="date"
                                    value={this.state.date}
                                    onChange={e => this.handleChange(e)}
                                  /> */}
                                    <MaskedInput
                                      className={this.state.required && this.state.date === '' ? 'form-control is-invalid' : 'form-control'}
                                      mask={[
                                        /[0-3]/,
                                        /\d/,
                                        ".",
                                        /[0-1]/,
                                        /\d/,
                                        ".",
                                        /[1-2]/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder="dd.mm.yyyy"
                                      name="date"
                                      value={this.state.date}
                                      onChange={e => this.handleChange(e)}
                                      onBlur={e => this.handleChange(e)}

                                    />
                                    <div className="invalid-feedback">This is a required field</div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>SRO<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Name of Sub Registrar Office</span></label>
                                    {this.state.sroList && this.state.sroList.length > 0 && this.state.sroList.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                      <Select options={this.state.sroList} name="sro"
                                        className={this.state.required && isEmpty(this.state.sro) ? 'form-select is-invalid' : 'form-select'}
                                        value={this.state.sroList.find((o: any) => o.value === this.state.sro)}
                                        onChange={(e) => this.handleChange(e, "sro", 'singleselect')}
                                      />
                                    }
                                    {this.state.required && !this.state.sro ?
                                      <div style={{ display: "block" }} className="invalid-feedback">This is a required field</div> : ""}

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>EC Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Eg. 1237"
                                      name="ec_no"
                                      value={this.state.ec_no}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>EC Entries</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Eg. 100"
                                      name="ec_entries"
                                      value={this.state.ec_entries}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <button
                                    className="btn btn-primary sw-btn-next"
                                    style={{ margin: "29px" }}
                                    type="button"
                                    onClick={() => this.dynamicCheckbox()}
                                  >
                                    Confirm
                                  </button>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Original present<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Current Document Submitted</span></label>
                                    {this.state.originalPresents && this.state.originalPresents.length > 0 && this.state.originalPresents.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                      <Select options={this.state.originalPresents} name="org_pres"
                                        // className={this.state.required && isEmpty(this.state.org_pres) ? 'form-select is-invalid' : 'form-select'}
                                        // value={this.state.originalPresents.find((o: any) => o.value === this.state.org_pres)}
                                        className={(!this.state.org_pres || this.state.org_pres == "0") && this.state.required ? 'form-select is-invalid' : 'form-select'}
                                        value={this.state.originalPresents.find((o: any) => o.value === this.state.org_pres)}
                                        onChange={(e) => this.handleChange(e, "org_pres", 'singleselect')}
                                      />
                                    }
                                    {(!this.state.org_pres || this.state.org_pres == "0") && this.state.required ?
                                      <div style={{ display: "block" }} className="invalid-feedback">This is a required field</div>
                                      : ""}
                                  </div>
                                </div>
                                <div className="col-md-6" >
                                  <div className="form-group" >

                                    <label>From Date<span style={{ color: "#FF0000" }}>*</span></label>
                                    {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder="dd/mm/yyyy"
                                    name="sale_date"
                                    value={this.state.sale_date}
                                    onChange={e => this.handleChange(e)}
                                  />                             */}
                                    <MaskedInput
                                      className={this.state.required && !this.state.sale_date ? 'form-control is-invalid' : 'form-control'}
                                      mask={[
                                        /[0-3]/,
                                        /\d/,
                                        ".",
                                        /[0-1]/,
                                        /\d/,
                                        ".",
                                        /[1-2]/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder="dd.mm.yyyy"
                                      name="sale_date"
                                      value={this.state.sale_date}
                                      onChange={e => this.handleChange(e)}
                                      onBlur={e => this.handleChange(e)}
                                    />
                                    {this.state.required && !this.state.sale_date ? <div style={{ display: "block", userSelect: "none" }} className="invalid-feedback">This is a required field</div> : ""}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>To Date<span style={{ color: "#FF0000" }}>*</span></label>
                                    <MaskedInput
                                      className={this.state.required && this.state.entry_date === '' ? 'form-control is-invalid' : 'form-control'}
                                      mask={[
                                        /[0-3]/,
                                        /\d/,
                                        ".",
                                        /[0-1]/,
                                        /\d/,
                                        ".",
                                        /[1-2]/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder="dd.mm.yyyy"
                                      name="entry_date"
                                      value={this.state.entry_date}
                                      onChange={e => this.handleChange(e)}
                                      onBlur={e => this.handleChange(e)}
                                    />
                                    <div className="invalid-feedback">This is a required field</div>
                                  </div>
                                </div>
                              </div> <h6 style={{
                                textAlign: "center",
                                margin: "auto",
                                fontSize: "20px !important"
                              }}>Click the related Entries</h6>
                              <div className="row" style={{
                                width: "100%",
                                border: "1px solid #e3e5e8",
                                borderRadius: "10px",
                                margin: "20px",
                                padding: "4px"
                              }}>
                                {!this.state.date_list && (
                                  <h6>Please enter EC Entries</h6>
                                )}
                                {this.state.date_list && this.state.date_list.length
                                  ? this.state.date_list.map((ec: any, ind) => {
                                    return (
                                      <div className="checkboxCol">
                                        <label style={{
                                          textAlign: "center",
                                          display: "block",
                                          fontSize: "16px",
                                          marginBottom: "12%"
                                        }}>{ind + 1}</label>
                                        <div className="col-md-2">
                                          <input
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                            type="checkbox"
                                            name="not_related"
                                            value={ec.not_related}
                                            checked={ec.not_related}
                                            onChange={e =>
                                              this.handleDateChange(e, ind)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )
                                  })
                                  : ""}
                              </div>

                              {this.state.date_list && this.state.date_list.length
                                ? this.state.date_list.map((ec: any, ind) => {
                                  return ec.not_related == true ?
                                    <div className="col-sm-12">
                                      <div className="border" style={{
                                        borderRadius: "10px",
                                        padding: "23px"
                                      }} key={ind}>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="numberHighlight">
                                              {ind + 1}
                                            </label>
                                          </div>
                                          <div className="col-md-6">
                                            <span>Related</span> &nbsp;
                                            &nbsp;
                                            <input
                                              style={{
                                                width: "20px",
                                                height: "20px"
                                              }}
                                              type="checkbox"
                                              name="not_related"
                                              value={ec.not_related}
                                              checked={ec.not_related}
                                              onChange={e =>
                                                this.handleDateChange(e, ind)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Date<span style={{ color: "#FF0000" }}>*</span></label>
                                              <MaskedInput
                                                className={this.state.required && ec.date === '' ? 'form-control is-invalid' : 'form-control'}
                                                mask={[
                                                  /[0-3]/,
                                                  /\d/,
                                                  ".",
                                                  /[0-1]/,
                                                  /\d/,
                                                  ".",
                                                  /[1-2]/,
                                                  /\d/,
                                                  /\d/,
                                                  /\d/
                                                ]}
                                                placeholder="dd.mm.yyyy"
                                                name="date"
                                                value={ec.date}
                                                onChange={e =>
                                                  this.handleDateChange(e, ind)
                                                }
                                              />
                                              <div className="invalid-feedback">This is a required field</div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Document Number<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Eg. 129/2010</span></label>
                                              {this.state.docNoList && this.state.docNoList.length > 0 && this.state.docNoList.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                                <Select options={this.state.docNoList} name="doc"
                                                  className={this.state.required && (!ec.deeds) ? 'form-select is-invalid' : 'form-select'}
                                                  value={this.state.docNoList.find((o: any) => o.value === ec.doc)}
                                                  onChange={(e) => this.handleDateChange(e, ind, ec.date_id, "doc", 'singleselect')}
                                                />
                                              }{(isEmpty(ec.doc) || !ec.doc) && this.state.required ?
                                                <div style={{ display: "block" }} className="invalid-feedback">This is a required field</div>
                                                : ""}
                                            </div>
                                          </div>
                                          {ec.is_other_doc && (
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Other Document</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Other Document"
                                                  name="otherDoc"
                                                  value={ec.otherDoc}
                                                  onChange={e =>
                                                    this.handleDateChange(
                                                      e,
                                                      ind
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Deed<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Eg. Sale Deed / Will Deed Etc...</span></label>
                                              {this.state.deeds && this.state.deeds.length > 0 && this.state.deeds.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                                <Select options={this.state.deeds} name="deed"
                                                  className={this.state.required && (!ec.deeds) ? 'form-select is-invalid' : 'form-select'}
                                                  value={this.state.deeds.find((o: any) => o.value === ec.deed)}
                                                  onChange={(e) => this.handleDateChange(e, ind, '', "deed", 'singleselect')}
                                                />
                                              }{(isEmpty(ec.deed) || !ec.deed) && this.state.required ?
                                                <div style={{ display: "block" }} className="invalid-feedback">This is a required field</div>
                                                : ""}
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Favour/Claimant</label>
                                              <input type="text" className="form-control" disabled={ec.disableEcEntry && ec.favour} name="favour" placeholder="Favour" value={ec.favour} onChange={(e) => this.handleDateChange(e, ind)} />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Executant</label>
                                              <input type="text" className="form-control" disabled={ec.disableEcEntry && ec.executant} name="executant" placeholder="Executant" value={ec.executant} onChange={(e) => this.handleDateChange(e, ind)} />
                                            </div>
                                          </div>
                                          {/* <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Extent</label>
                                              <input type="text" className="form-control" disabled={ec.disableEcEntry && ec.extent} name="extent" placeholder="Extent" value={ec.extent} onChange={(e) => this.handleDateChange(e, ind)} />
                                            </div>
                                          </div> */}
                                          {/* <div className="col-md-6">
                                            <div className="form-group">
                                              <label>Sheet Count</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Eg. 20"
                                                disabled={ec.disableEcEntry && ec.sheetCount}
                                                name="sheetCount"
                                                min={0}
                                                value={ec.sheetCount}
                                                onChange={e => this.handleDateChange(e, ind)}
                                              />
                                            </div>
                                          </div> */}
                                          {/* <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Available Sheet Count</label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Eg. 18"
                                                  disabled={ec.disableEcEntry && ec.avail_sheetCount}
                                                  name="avail_sheetCount"
                                                  min={0}
                                                  value={ec.avail_sheetCount}
                                                  onChange={e => this.handleDateChange(e, ind)}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Missing Sheet Count</label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Eg. 2 sheet - 1, 2"
                                                  disabled={ec.disableEcEntry && ec.missing_sheetCount}
                                                  name="missing_sheetCount"
                                                  min={0}
                                                  value={ec.missing_sheetCount}
                                                  onChange={e => this.handleDateChange(e, ind)}
                                                />
                                              </div>
                                            </div> */}
                                          {/* <button
                                            type="button"
                                            className="btn btn-icon btn-rounded btn-primary"
                                            onClick={() => this.insertItem(ind)}
                                          >
                                            <i className="feather icon-plus" />
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-icon btn-rounded btn-danger"
                                            onClick={() => this.removeItem(ind)}
                                          >
                                            <i className="feather icon-minus" />
                                          </button> */}
                                        </div>
                                      </div>
                                    </div>

                                    : null

                                })
                                : ""}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Remark<span style={{ color: "#FF0000" }}>*</span></label>
                                  <textarea
                                    className={this.state.required && !this.state.remark ? 'form-control is-invalid' : 'form-control'}
                                    name="remark"
                                    id="remark"
                                    placeholder="Incase of any particular remarks, It may display after the entries of EC"
                                    value={this.state.remark}
                                    onChange={e => this.handleChange(e)}
                                  />
                                  {this.state.required && !this.state.remark ? <div style={{ display: "block" }} className="invalid-feedback">This is a required field</div> : ""}
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                            <div style={{ float: "right" }}>
                              <button
                                style={{ margin: "auto" }}
                                className="btn btn-warning sw-btn-next"
                                type="button"
                                onClick={() => {
                                  isVerifyFlow ? window.close() : this.props.history.push("/work-file/2/" + this.props.match.params.fileid);
                                }}
                              >
                                Back
                              </button>
                              {this.state.autoAssign && this.state.autoAssign.find(
                                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                                <>
                                  {takenfile == currentfile ? (
                                    <>
                                      {!isVerifyFlow ?
                                        <button
                                          type="submit"
                                          style={{ margin: "6px" }}
                                          className="btn btn-primary"
                                          onClick={() => this.saveEC(1)}
                                          disabled={this.state.isLoading}>
                                          {this.state.isLoading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                          )}
                                          Save
                                        </button>
                                        : null}
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => this.saveEC(2)}
                                        disabled={this.state.isLoading}>
                                        {this.state.isLoading && (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        )}
                                        Save & Submit
                                      </button>
                                    </>
                                  ) :
                                    <>
                                      {roletype == 8 ? <>
                                        {!isVerifyFlow ?
                                          <button
                                            type="submit"
                                            style={{ margin: "6px" }}
                                            className="btn btn-primary"
                                            onClick={() => this.saveEC(1)}
                                            disabled={this.state.isLoading}>
                                            {this.state.isLoading && (
                                              <i className="fa fa-spinner fa-spin"></i>
                                            )}
                                            Save
                                          </button>
                                          : null}
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                          onClick={() => this.saveEC(2)}
                                          disabled={this.state.isLoading}>
                                          {this.state.isLoading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                          )}
                                          Save & Submit
                                        </button>
                                      </> : ""}
                                    </>}
                                </> :
                                <>
                                  {!isVerifyFlow ?
                                    <button
                                      type="submit"
                                      style={{ margin: "6px" }}
                                      className="btn btn-primary"
                                      onClick={() => this.saveEC(1)}
                                      disabled={this.state.isLoading}>
                                      {this.state.isLoading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                    : null}
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={() => this.saveEC(2)}
                                    disabled={this.state.isLoading}>
                                    {this.state.isLoading && (
                                      <i className="fa fa-spinner fa-spin"></i>
                                    )}
                                    Save & Submit
                                  </button>
                                </>}
                            </div>
                          </div>
                        </Resizable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${this.state.docToPreview ? 'open' : ''}`}
                id="preview-document"
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl" role="document" style={{ maxWidth: '90%' }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close cursor-pointer"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.setState({ docToPreview: false })}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body" style={{ transform: `rotate(${this.state.rotation}deg)` }}>
                      {this.state.base && this.state.isHightlightsLoaded &&
                        <PDFHighlighter url={this.state.base} onHighlightsChange={this.onHighlightsChange} highlights={this.state.highlights} rotation={this.state.rotation} isPopup />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}
