import React from 'react';
import { CSVLink } from 'react-csv';
const DeviationExcelComponent = ({ data }) => {
    const csvData = data.map((item, index) => {
        const pendingQueries = item['pending_queries']?.length > 0 ? item['pending_queries'].map((deviation, index) => `${index + 1}] ${deviation.query}`).join('\n') : "";
        const firstRequestBy = item['first_deviation']?.length > 0 ? item['first_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const firstRequestQReason = item['first_deviation']?.length > 0 ? item['first_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const firstDeviationQuries = item['first_deviation']?.length > 0 ? item['first_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const firstDeviationBy = item['first_deviation']?.length > 0 ? item['first_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const firstStatus = item['first_status']?.length > 0 ? item['first_status'].map((status, index) => `${index + 1}] ${status.status}`).join('\n') : "";
        const firstDeviationReason = item['first_deviation']?.length > 0 ? item['first_deviation'].map((status, index) => `${index + 1}] ${status.deviation_reason}`).join('\n') : "";
        const firstDeviationDate = item['first_deviation']?.length > 0 ? item['first_deviation'].map((status, index) => `${index + 1}] ${status.deviation_date}`).join('\n') : "";

        const secondRequestBy = item['second_deviation']?.length > 0 ? item['second_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const secondRequestQReason = item['second_deviation']?.length > 0 ? item['second_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const secondDeviationQuries = item['second_deviation']?.length > 0 ? item['second_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const secondDeviationBy = item['second_deviation']?.length > 0 ? item['second_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const secondStatus = item['second_status']?.length > 0 ? item['second_status'].map((status, index) => status.status ? `${index + 1}] ${status.status}` : "").join('\n') : "";
        const secondDeviationReason = item['second_deviation']?.length > 0 ? item['second_deviation'].map((status, index) => status.deviation_reason ? `${index + 1}] ${status.deviation_reason}` : "").join('\n') : "";
        const secondDeviationDate = item['second_deviation']?.length > 0 ? item['second_deviation'].map((status, index) => status.deviation_date ? `${index + 1}] ${status.deviation_date}` : "").join('\n') : "";

        const thirdRequestBy = item['third_deviation']?.length > 0 ? item['third_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const thirdRequestQReason = item['third_deviation']?.length > 0 ? item['third_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const thirdDeviationQuries = item['third_deviation']?.length > 0 ? item['third_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const thirdDeviationBy = item['third_deviation']?.length > 0 ? item['third_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const thirdStatus = item['third_status']?.length > 0 ? item['third_status'].map((status, index) => status.status ? `${index + 1}] ${status.status}` : "").join('\n') : "";
        const thirdDeviationReason = item['third_deviation']?.length > 0 ? item['third_deviation'].map((status, index) => status.deviation_reason ? `${index + 1}] ${status.deviation_reason}` : "").join('\n') : "";
        const thirdDeviationDate = item['third_deviation']?.length > 0 ? item['third_deviation'].map((status, index) => status.deviation_date ? `${index + 1}] ${status.deviation_date}` : "").join('\n') : "";

        const fourthRequestBy = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const fourthRequestQReason = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const fourthDeviationQuries = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const fourthDeviationQueriesBy = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const fourthStatus = item['fourth_status']?.length > 0 ? item['fourth_status'].map((status, index) => status.status ? `${index + 1}] ${status.status}` : "").join('\n') : "";
        const fourthDeviationReason = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((status, index) => status.deviation_reason ? `${index + 1}] ${status.deviation_reason}` : "").join('\n') : "";
        const fourthDeviationDate = item['fourth_deviation']?.length > 0 ? item['fourth_deviation'].map((status, index) => status.deviation_date ? `${index + 1}] ${status.deviation_date}` : "").join('\n') : "";


        const fifthRequestBy = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const fifthRequestQReason = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const fifthDeviationQuries = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const fifthDeviationBy = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const fifthStatus = item['fifth_status']?.length > 0 ? item['fifth_status'].map((status, index) => status.status ? `${index + 1}] ${status.status}` : "").join('\n') : "";
        const fifthDeviationReason = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((status, index) => status.deviation_reason ? `${index + 1}] ${status.deviation_reason}` : "").join('\n') : "";
        const fifthDeviationDate = item['fifth_deviation']?.length > 0 ? item['fifth_deviation'].map((status, index) => status.deviation_date ? `${index + 1}] ${status.deviation_date}` : "").join('\n') : "";

        const sixthRequestBy = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((deviation, index) => deviation.req_by != "" ? `${index + 1}] ${deviation.req_by}` : "").join('\n') : "";
        const sixthRequestQReason = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const sixthDeviationQuries = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((deviation, index) => deviation.query != "" ? `${index + 1}] ${deviation.query}` : "").join('\n') : "";
        const sixthDeviationBy = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const sixthStatus = item['sixth_status']?.length > 0 ? item['sixth_status'].map((status, index) => status.status ? `${index + 1}] ${status.status}` : "").join('\n') : "";
        const sixthDeviationReason = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((status, index) => status.deviation_reason ? `${index + 1}] ${status.deviation_reason}` : "").join('\n') : "";
        const sixthDeviationDate = item['sixth_deviation']?.length > 0 ? item['sixth_deviation'].map((status, index) => status.deviation_date ? `${index + 1}] ${status.deviation_date}` : "").join('\n') : "";

        const Queries = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.query}\n Reason: ${deviation.reason}`).join('\n') : "";
        const Reason = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.reason}`).join('\n') : "";
        const requestReason = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => deviation.req_reason != "" ? `${index + 1}] ${deviation.req_reason}` : "Nil").join('\n') : "";
        const requestBy = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.req_by}`).join('\n') : "";
        const requestDt = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.req_date}`).join('\n') : "";
        const deviationReason = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.deviation_reason}`).join('\n') : "";
        const deviationBy = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => deviation.deviation_by != "" ? `${index + 1}] ${deviation.deviation_by}` : "").join('\n') : "";
        const deviationDt = item['total_query_details']?.length > 0 ? item['total_query_details'].map((deviation, index) => `${index + 1}] ${deviation.deviation_date}`).join('\n') : "";
        return {
            "Sl.No": index + 1,
            "File No": item.file_name,
            "Process Officer Name": item.po_name,
            "Bank Name": item.bank,
            "LH Branch Name": item.lawhands_branch_name,
            "Total Pending Queries": pendingQueries,
            "Queries for Deviation": Queries,
            // "Reasons": Reason,
            "Request Reason": requestReason,
            // "Request By": requestBy,
            // "Request Date": requestDt,
            // "Deviation Reason": deviationReason,
            // "Deviation By": deviationBy,
            // "Deviation Date": deviationDt,
            "1st Deviation Request Queries": firstDeviationQuries,
            "1st Deviation Request By": firstRequestBy,
            "1st Deviation Request Reason": firstRequestQReason,
            "1st Deviation Reason": firstDeviationReason,
            "1st Deviation Taken By": firstDeviationBy,
            "1st Each Deviation Status": firstStatus,
            "1st Deviation File Status": item.first_deviation_status,
            "1st Deviation Date": firstDeviationDate,
            "2nd Deviation Requert Queries": secondDeviationQuries,
            "2nd Deviation Request By": secondRequestBy,
            "2nd Deviation Request Reason": secondRequestQReason,
            "2nd Deviation Reason": secondDeviationReason,
            "2nd Deviation Taken By": secondDeviationBy,
            "2nd Each Deviation Status": secondStatus,
            "2nd Deviation File Status": item.second_deviation_status,
            "2nd Deviation Date": secondDeviationDate,
            "3rd Deviation Requert Queries": thirdDeviationQuries,
            "3rd Deviation Request By": thirdRequestBy,
            "3rd Deviation Request Reason": firstRequestQReason,
            "3rd Deviation Reason": thirdDeviationReason,
            "3rd Deviation Taken By": thirdDeviationBy,
            "3rd Each Deviation Status": thirdStatus,
            "3rd Deviation File Status": item.third_deviation_status,
            "3rd Deviation Date": thirdDeviationDate,
            "4th Deviation Requert Queries": fourthDeviationQuries,
            "4th Deviation Request By": fourthRequestBy,
            "4th Deviation Request Reason": fourthRequestQReason,
            "4th Deviation Reason": fourthDeviationReason,
            "4th Deviation Taken By": fourthDeviationQueriesBy,
            "4th Each Deviation Status": fourthStatus,
            "4th Deviation File Status": item.fourth_deviation_status,
            "4th Deviation Date": fourthDeviationDate,
            "5th Deviation Requert Queries": fifthDeviationQuries,
            "5th Deviation Request By": fifthRequestBy,
            "5th Deviation Request Reason": fifthRequestQReason,
            "5th Deviation Reason": fifthDeviationReason,
            "5th Deviation Taken By": fifthDeviationBy,
            "5th Each Deviation Status": fifthStatus,
            "5th Deviation File Status": item.fifth_deviation_status,
            "5th Deviation Date": fifthDeviationDate,
            "6th Deviation Requert Queries": sixthDeviationQuries,
            "6th Deviation Request By": sixthRequestBy,
            "6th Deviation Request Reason": sixthRequestQReason,
            "6th Deviation Reason": sixthDeviationReason,
            "6th Deviation Taken By": sixthDeviationBy,
            "6th Each Deviation Status": sixthStatus,
            "6th Deviation File Status": item.sixth_deviation_status,
            "6th Deviation Date": sixthDeviationDate,
        };
    });
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return (
        <CSVLink data={csvData} className="btn btn-outline-success" filename={`Deviation Report - ${formattedDate}.csv`}>
            Download Excel
        </CSVLink>
    );
};
export default DeviationExcelComponent;
