import React, { Component } from "react";
export interface ScheduleDeed {
  schedule_id?: number;
  schedule?: string;
  alloted_to?: string;
  current_survey?: string;
  extent?: string;
}
interface AllProps {
  stateToProps: any;
  scheduleDeed: ScheduleDeed[];
  // selectedTemplate: any;
  // templates: any;
}
export default class ScrutinyScheduleItem extends Component<AllProps> {
  state = {
    schedule_deed: this.props.scheduleDeed,
    delete_items_schedule: []
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleDeed && this.state.schedule_deed.length) {
      this.setState({ schedule_deed: nextProps.scheduleDeed });
    } else if (!this.state.schedule_deed || !this.state.schedule_deed.length) {
      this.setState({ schedule_deed: [] });
      this.addItem();
    }
  }
  addItem = (): void => {
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed ? this.state.schedule_deed : [];
    schedule_deed.push({
      schedule: "",
      alloted_to: "",
      current_survey: "",
      extent: ""
    });
    this.setState({ schedule_deed });
  };
  removeItem = (modal: ScheduleDeed, index: number): void => {
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed;
    schedule_deed.splice(index, 1);

    const delete_items_schedule: any = this.state.delete_items_schedule;
    delete_items_schedule.push(modal.schedule_id);
    this.setState({ schedule_deed, delete_items_schedule });

    this.props.stateToProps({
      name: "delete_items_schedule",
      value: this.state.delete_items_schedule
    });
  };
  handleChange = (e: any, index: number) => {
    const schedule_deed: ScheduleDeed[] = this.state.schedule_deed;
    schedule_deed.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ schedule_deed });
    this.props.stateToProps({
      name: "schedule_deed",
      value: this.state.schedule_deed
    });
  };
  render() {
    return this.state.schedule_deed && this.state.schedule_deed.length
      ? this.state.schedule_deed.map((sched: ScheduleDeed, index) => {
          // console.log(sched);
          return (
            <div className="col-md-12" key={index}>
              <div className="col-md-12 sec_measurement">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Schedule</label>
                      <input
                        className="form-control"
                        name="schedule"
                        type="text"
                        value={sched.schedule}
                        onChange={e => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label id="select_east">Alloted To</label>
                      <input
                        className="form-control"
                        name="alloted_to"
                        value={sched.alloted_to}
                        type="text"
                        onChange={e => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-2" style={{ top: "28px" }}>
                    <button
                      type="button"
                      className="btn btn-icon btn-rounded btn-primary"
                      onClick={this.addItem}
                    >
                      <i className="feather icon-plus" />
                    </button>
                  </div>
                  <div className="col-md-2" style={{ top: "28px" }}>
                    <button
                      type="button"
                      className="btn btn-icon btn-rounded btn-danger"
                      disabled={this.state.schedule_deed.length === 1}
                      onClick={() => this.removeItem(sched, index)}
                    >
                      <i className="feather icon-minus" />
                    </button>
                  </div>
                  {/* {this.props.selectedTemplate && this.props.selectedTemplate == true && (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label id="select_east">Relationship</label>
                      <input
                        className="form-control"
                        name="relationship"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                )} */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="select_west">Survey No.</label>
                      <input
                        className="form-control"
                        name="current_survey"
                        type="text"
                        value={sched.current_survey}
                        onChange={e => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="select_north">Extent</label>
                      <input
                        className="form-control"
                        name="extent"
                        type="text"
                        value={sched.extent}
                        onChange={e => this.handleChange(e, index)}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-primary"
                        onClick={this.addItem}
                      >
                        <i className="feather icon-plus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-icon btn-rounded btn-danger"
                        disabled={this.state.schedule_deed.length === 1}
                        onClick={() => this.removeItem(sched, index)}
                      >
                        <i className="feather icon-minus" />
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          );
        })
      : "";
  }
}
