import React, { Component } from "react";
import "./ReportGenerate.css";

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}

export default class ReportGenerate extends Component<AllProps> {
  COMPONENT_TYPES = {
    INPUT_BOX: "INPUT_BOX",
    TITLE: "TITLE",
    TABLE: "TABLE",
  };
  state = {
    componentList: {},
    documentComponentList: [],
    showElementList: false,
    modalElement: {
      [this.COMPONENT_TYPES.INPUT_BOX]: {
        style: {
          alignMent: "",
        },
      },
      [this.COMPONENT_TYPES.TITLE]: {
        text: "",
        style: {
          alignMent: "",
        },
      },
    },
  };

  componentDidMount() {
    this.setState({
      componentList: {
        INPUT_BOX: {
          id: 1,
          type: this.COMPONENT_TYPES.INPUT_BOX,
          component: this.InputComponent,
          values: {
            value: "",
          },
          props: {},
        },
        TITLE: {
          id: 2,
          type: this.COMPONENT_TYPES.TITLE,
          component: this.TitleComponent,
          values: {},
          props: {
            title: "Text",
          },
        },
      },
    });
  }

  InputComponent = (props) => {
    const { index } = props;
    const { name, onChange } = props.props;
    const { value } = props.values;
    console.log(props);
    return (
      <div>
        <input
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e, index);
          }}
        />
      </div>
    );
  };

  TitleComponent = ({ title }) => {
    return <div>{title}</div>;
  };

  addElement = (type, params?) => {
    // params.style
    const { componentList, documentComponentList }: any = this.state;
    console.log(componentList[type]);
    if (componentList[type]) {
      let currentComponentList = { ...componentList[type] };
      const nextOrder = documentComponentList.length + 1;
      if (type === this.COMPONENT_TYPES.INPUT_BOX) {
        currentComponentList.props = {
          ...currentComponentList.props,
          name: type,
          onChange: (e, index) => {
            const { documentComponentList }: any = this.state;
            documentComponentList[index].values = {
              ...documentComponentList[index].values,
              value: e.target.value,
            };
            this.setState({ documentComponentList });
          },
        };
      }

      if (type === this.COMPONENT_TYPES.TITLE) {
        currentComponentList = {
          ...currentComponentList,
          title: "Text",
        };
      }
      console.log("currentComponentList", currentComponentList);
      documentComponentList.push({
        order: nextOrder,
        ...currentComponentList,
      });
      this.setState({
        documentComponentList,
      });
    }
  };
  render() {
    const { componentList, showElementList, documentComponentList }: any =
      this.state;
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="report-body">
                      <div className="report-main">
                        {documentComponentList?.map(
                          (currentComponent, currentIndex) => {
                            const ItemComponent = currentComponent?.component;
                            return ItemComponent ? (
                              <div>
                                {currentComponent.order}
                                <ItemComponent
                                  key={
                                    currentComponent.order + "" + currentIndex
                                  }
                                  {...currentComponent}
                                  index={currentIndex}
                                />
                              </div>
                            ) : (
                              <React.Fragment />
                            );
                          }
                        )}
                        <button
                          onClick={() => {
                            this.setState({
                              showElementList: !showElementList,
                            });
                          }}
                        >
                          {showElementList ? "Hide" : "Add"} Element
                        </button>
                        {showElementList && (
                          <div>
                            <button
                              onClick={() => {
                                // Get data from Modal
                                this.addElement(
                                  this.COMPONENT_TYPES.INPUT_BOX,
                                  {
                                    // All Modal Data
                                  }
                                );
                              }}
                            >
                              Input
                            </button>
                            <button
                              onClick={() => {
                                this.addElement(this.COMPONENT_TYPES.TITLE);
                              }}
                            >
                              Title
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
