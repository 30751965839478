/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  MenuItem
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";

import { callApi } from "../../utils/api";

const limitList = [ 20, 40, 60 ];

const AddVideoCategory = () => {
  const [ videoCategoryList, setVideoCategoryList ] = useState([]);
  const [ rowCount, setRowCount ] = useState(limitList[0]);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ noOfPages, setNoOfPages ] = useState(Math.ceil(totalCount / rowCount));
  const [ showPopup, setShowPopup ] = useState(false);
  const [ categoryName, setCategoryName ] = useState('');
  const [ categoryId, setCategoryId ] = useState('');

  useEffect(() => {
    getVideoCategoryList(pageNumber, rowCount);
  }, []);

  useEffect(() => {
    setNoOfPages(Math.ceil(totalCount / rowCount));
  }, [ totalCount, rowCount ]);

  const getVideoCategoryList = (page_id, row_count) => {
    callApi("POST", "video_category_getdata.php", { page_id, row_count })
      .then(res => res.data)
      .then(response => {
        setVideoCategoryList(response.data);
        setTotalCount(response.count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteVideoCategory = (id) => {
    callApi("POST", "video_category_delete.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.statuscode) {
          getVideoCategoryList(pageNumber, rowCount);
          ToastsStore.success("Video Category Deleted Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const addVideoCategory = () => {
    callApi("POST", "video_category_add.php", { categoryname: categoryName })
      .then(res => res.data)
      .then(response => {
        handleResetData();
        if (response.statuscode) {
          getVideoCategoryList(pageNumber, rowCount);
          ToastsStore.success("Video Category Created Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        handleResetData();
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const updateVideoCategory = () => {
    callApi("POST", "video_category_update.php", { categoryname: categoryName, id: categoryId })
      .then(res => res.data)
      .then(response => {
        handleResetData();
        if (response.statuscode) {
          getVideoCategoryList(pageNumber, rowCount);
          ToastsStore.success("Video Category Updated Successfully");
        } else {
          ToastsStore.error("Something went wrong, please try again later");
        }
      })
      .catch(err => {
        handleResetData();
        ToastsStore.error("Something went wrong, please try again later");
        console.log(err);
      });
  }

  const handleEditVideoCategory = (videoCategory) => {
    const { category_name, id } = videoCategory;
    setCategoryName(category_name);
    setCategoryId(id);
    setShowPopup(true);
  }

  const handleResetData = () => {
    setCategoryName('');
    setCategoryId('');
    setShowPopup(false);
  }

  const handlePageLimit = (event) => {
    setRowCount(event.target.value);
    setPageNumber(1);
    getVideoCategoryList(pageNumber, rowCount);
  }

  const handlePagination = (event, value) => {
    setPageNumber(value);
    getVideoCategoryList(value, rowCount);
  }

  return <div className="right-container">
    {/* <ToastsContainer
      store={ToastsStore}
      position={ToastsContainerPosition.TOP_CENTER}
    /> */}
    <div className="video-category-conatiner">
      <h5 className="video-category">Video Category</h5>
      <div className="video-category-wrapper">
        <a
          className="btn btn-primary m-b-20"
          data-toggle="modal"
          data-target="#video-form"
          onClick={() => setShowPopup(true)}
        >
          + Add Video Category
        </a>
        <div>
          <TableContainer className="table-container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell >S.NO</TableCell>
                  <TableCell >Category Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videoCategoryList && videoCategoryList.length > 0 && videoCategoryList.map((videoCategory, index) => {
                  const { category_name, id } = videoCategory;
                  const serialNumber = rowCount * (pageNumber - 1) + index + 1
                  return (
                    <TableRow hover tabIndex={-1} className="cursor-pointer" key={id}>
                      <TableCell className="index-table-cell">{serialNumber}</TableCell>
                      <TableCell>{category_name}</TableCell>
                      <TableCell>
                        <div className="btn-group video-action-button">
                          <button type="button" className="btn btn-primary">
                            Action
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="sr-only">Toggle Dropdown</span>
                          </button>
                          <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              transform:
                                "translate3d(88px, -2px, 0px)"
                            }}
                          >
                            <a className="dropdown-item" onClick={() => handleEditVideoCategory(videoCategory)}>Edit</a>
                            <a className="dropdown-item" onClick={() => deleteVideoCategory(id)}>Delete</a>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )})
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination-container">
            <div>
              <span>Show</span>
              <Select className="select-container" variant="outlined" value={rowCount} onChange={e => handlePageLimit(e)}>
                {limitList.map((limitValue, index) => (
                  <MenuItem key={index} value={limitValue}>
                    {limitValue}
                  </MenuItem>
                ))}
              </Select>
              <span>Rows</span>
            </div>
            {noOfPages > 1 && (
              <Pagination
                count={noOfPages}
                defaultPage={pageNumber}
                onChange={handlePagination}
                showFirstButton
                showLastButton
              />
            )}
          </div>
        </div>
      </div>
    </div>
    {showPopup && <div
      className="dialog-modal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="dialog-modal-body" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Video Category</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleResetData}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label className="col-form-label">Category Name:</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Category Name"
                  onChange={e => {
                    setCategoryName(e.target.value);
                  }}
                  value={categoryName}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleResetData}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={categoryId ? updateVideoCategory: addVideoCategory }
              disabled={!categoryName}
            >
              {categoryId ? 'Save' : 'Add Category' }
            </button>
          </div>
        </div>
      </div>
    </div>}
  </div>;
}

export default AddVideoCategory