import * as React from "react";
import { callApi } from "../../utils/api";
import Select from "react-select";
import DatePicker from "antd/lib/date-picker";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import Pagination from '@material-ui/lab/Pagination';
import { Button } from "@material-ui/core";
import { CSVLink } from 'react-csv';
import moment from "moment";
import { getFileStatus } from "../../utils/fileStatus";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
}
class RectificationFiles extends React.Component<AllProps> {
    limitList = [20, 40, 60]
    state = {
        lhBranchShow: false,
        bankBranch: false,
        branchName: false,
        lawBranchName: false,
        showBank: false,
        bankName: false,
        selectVal: false,
        overview: [],
        employees: [],
        branchInfo: [],
        branches: [],
        fromdate: "",
        eodDate: "",
        eodFromDate: "",
        eodToDate: "",
        todate: "",
        branch: "",
        assigned_to: "",
        users: [],
        eodData: [],
        branchEodData: [],
        eodReportType: "Branch",
        eodEmployeeList: [],
        eodEmployee: "",
        lawhandsBranches: [],
        bank_private_name: "",
        file_id: "",
        banks: [],
        filteredBranches: [],
        lawHandsBranch: "",
        isDateSearchClicked: false,
        total_file: {
            total_time_sum: "",
            front_office_sum: "",
            work_in_progress_sum: "",
            query_waiting_time_sum: "",
            verification_wip_sum: "",
            waiting_time_sum: "",
            extended_hours_sum: "",
        },
        files: [],
        pageNumber: 1,
        rowCount: this.limitList[0],
        totalCount: 0,
        excelData: [],
        loadingexcel: false,
        outreason: "",
        isLoading: false,
    };
    componentDidMount() {
        $(".datepicker").on("change", this.handleChange);
        this.getUsers();
        this.getOutTime();
        this.ouTime();
    }

    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    getBanks() {
        callApi("GET", "bank_get.php")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ banks: result.data });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then((res) => res.data)
            .then((response) => {
                const user: any = sessionStorage.getItem("user");
                const userDetails = user ? JSON.parse(user) : "";
                let lawhandsBranches = response.data;
                if (
                    lawhandsBranches &&
                    lawhandsBranches.length > 0 &&
                    userDetails.role_id !== "1" &&
                    userDetails.my_branchs
                ) {
                    const myBranchList = userDetails.my_branchs.split(",");
                    lawhandsBranches = lawhandsBranches.filter((value) =>
                        myBranchList.includes(value.id)
                    );
                }
                this.setState({ lawhandsBranches });
            })
            .catch((err) => {
                console.log({ err });
            });
    }
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    getBankBranches() {
        callApi("GET", "bank_branch_get.php")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ branches: result.data, filteredBranches: [] });
                if (this.state.bank_private_name) {
                    this.filterBranches(this.state.bank_private_name);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    filterBranches = (bankid) => {
        const filteredBranches = this.state.branches.filter(
            (branch: any) => branch.bank_id === bankid
        );
        const state = this.state;
        state.filteredBranches = filteredBranches;
        this.setState({ ...state });
    };

    getEodTableData = (page_id, row_count) => {
        this.setState({ isLoading: true });
        const {
            eodFromDate,
            eodToDate,
            lawHandsBranch,
            bank_private_name,
            branch,
            assigned_to,
            file_id
        } = this.state;

        let obj: any = {
            lawhands_branch: lawHandsBranch,
            from_date: eodFromDate,
            to_date: eodToDate,
            bank_private_name: bank_private_name,
            branch: branch,
            // page_id: page_id,
            // row_count: row_count,
            assigned_to: assigned_to,
            id: file_id
        };
        callApi("POST", "rectification.php", obj)
            .then((res) => res.data)
            .then((result) => {
                this.setState({ isLoading: false });
                const files = result.data;
                const total_file = result.total_values;
                const totalCount = result.count;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                if (files && files.length > 0) {
                    this.setState({ files: result.data, isDateSearchClicked: true, totalCount, total_file });
                } else {
                    this.setState({ files: [], total_file: {} })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleChange1 = (e: any, propName?, fieldType?) => {
        const bank_private_name = this.state.bank_private_name;
        let fieldName = fieldType === "singleselect" ? propName : e.target.name;
        let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
        if (fieldName === "bank_private_name") {
            this.branchName(2)
            this.filterBranches(bank_private_name);
        }
        this.setState({ bank_private_name });

        if (fieldName === "lawHandsBranch") {
            const lawHandsBranch = this.state.lawHandsBranch;
            const branch = fieldValue;
            this.setState({ lawHandsBranch });
        }
        this.setState({ [fieldName]: fieldValue });
    };
    handleChange = (e, stateName?, type?) => {
        if (e.target.name === "eodToDate") {
            this.setState({ todate: e.target.value });
        }
        if (e.target.name === "eodfromDate") {
            this.setState({
                fromdate: e.target.value,
            });
        }
        if (stateName && type === "dropdown") {
            this.setState({
                [stateName]: e.value,
            });
        } else this.setState({ [e.target.name]: e.target.value });
        if (["eodFromDate", "eodToDate"].includes(e.target.name)) {
            this.setState({ isDateSearchClicked: false });
        }
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    getUsers = () => {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        if (isAdmin) {
            callApi("GET", "user_get.php")
                .then(res => res.data)
                .then(response => { this.setState({ users: response.data }); })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            callApi("POST", "team_leader.php", { "user_id": user_id })
                .then(res => res.data)
                .then(response => {
                    console.log("210", response);
                    this.setState({ users: response.data });

                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    lawBranchName = (type: any) => {
        if (type == 2) {
            console.log("431", type)
            this.setState({ lhBranchShow: true })
            this.getLawHandsBranches();

        } else {
            console.log("436", type)
        }
    }
    bankName = (type: any) => {

        if (type == 2) {
            console.log("431", type)
            this.setState({ showBank: true })
            this.getBanks();
        } else {
            console.log("445", type)
        }
    }
    branchName = (type: any) => {
        if (type == 2) {
            console.log("450", type)
            this.setState({ bankBranch: true })
            this.getBankBranches();
        } else {
            console.log("454", type)
        }
    }
    goToFileHistory = id => {
        if (id) {
            // const url = `/inprogress-file`;
            // localStorage.setItem("url", url);
            // let obj = { "file_id": id }
            // localStorage.setItem("thisPopup", JSON.stringify(obj))
            // sessionStorage.setItem("currentfileId", JSON.stringify(id));
            this.props.history.push("/file-history/" + id);
        } else {
            console.log("no fileId");
        }
    };
    handlePageLimit = (event) => {
        const rowCount = event.target.value;
        const pageNumber = 1;
        this.setState({ pageNumber, rowCount });
        this.getEodTableData(pageNumber, rowCount);
    }
    handlePagination = (event, value) => {
        this.setState({ pageNumber: value });
        this.getEodTableData(value, this.state.rowCount);
    }
    render() {
        const columns = [
            {
                header: "S.No",
                key: "serial",
                td: (file) => (
                    <td>{file.serial}</td>
                ),
            },
            {
                header: "File ID",
                key: "file_id",
                td: (file) => (
                    <td>
                        {file.file_id}
                    </td>
                ),
            },
            {
                header: "Property Owner Name",
                key: "owner_name",
                td: (file) => (
                    <td>
                        {file.owner_name}
                    </td>
                ),
            },
            {
                header: "Borrower Name",
                key: "borrower_name",
                td: (file) => (
                    <td>
                        {file.borrower_name}
                    </td>
                ),
            },
            {
                header: "File Status",
                key: "file_status",
                td: (file) => (
                    <td>
                        {getFileStatus(file.file_status)}
                    </td>
                ),
            },
            {
                header: "Rectification Status",
                key: "rectify_status",
                td: (file) => (
                    <td>
                        {file.rectify_status}
                    </td>
                ),
            },
            {
                header: "Bank Name",
                key: "bank_name",
                td: (file) => (
                    <td>
                        {file.bank_name}
                    </td>
                ),
            },
            {
                header: "Bank Branch Name",
                key: "bank_branch",
                td: (file) => (
                    <td>
                        {file.bank_branch}
                    </td>
                ),
            },
            {
                header: "Law Hands Branch",
                key: "lh_branch",
                td: (file) => (
                    <td>
                        {file.lh_branch}
                    </td>
                ),
            },
            {
                header: "Rectification Received Date",
                key: "rectification_received_dt",
                td: (file) => (
                    <td>
                        {file.rectification_received_dt}
                    </td>
                ),
            },
            {
                header: "Rectification Completed Date",
                key: "rectification_completed_dt",
                td: (file) => (
                    <td>
                        {file.rectification_completed_dt}
                    </td>
                ),
            },
        ];
        const data = this.state.files;
        const excelData = this.state.excelData;
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const {
            lawBranchName, bankBranch
            , branchName, bankName, showBank, lhBranchShow
        } = this.state;
        const userDetails = user ? JSON.parse(user) : "";
        const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const lawhandsBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.lawhandsBranches
        ];
        const banksoptions = [
            { value: "", label: "All" },
            ...this.state.banks
        ];
        const filteredBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.filteredBranches
        ];
        const usersoptions = [
            { value: "", label: "All" },
            ...this.state.users
        ];
        const noOfPages = Math.ceil(this.state.totalCount / this.state.rowCount);

        const processedData = data.map((report: any, index) => ({
            serial: index + 1,
            file_id: report.file_id,
            owner_name: report.owner_name ? report.owner_name : "",
            borrower_name: report.borrower_name ? report.borrower_name : "",
            file_status: report.file_status,
            rectify_status: report.rectify_status ? report.rectify_status : "",
            bank_name: report.bank_name ? report.bank_name : "",
            bank_branch: report.bank_branch ? report.bank_branch : "",
            lh_branch: report.lh_branch ? report.lh_branch : "",
            rectification_received_dt: report.rectification_received_dt ? report.rectification_received_dt : "",
            rectification_completed_dt: report.rectification_completed_dt ? report.rectification_completed_dt : "",
        }));

        const excelRowData = excelData.map((report: any, index) => ({
            serial: index + 1,
            file_id: report.file_id,
            owner_name: report.owner_name ? report.owner_name : "",
            borrower_name: report.borrower_name ? report.borrower_name : "",
            file_status: getFileStatus(report.file_status),
            rectify_status: report.rectify_status ? report.rectify_status : "",
            bank_name: report.bank_name ? report.bank_name : "",
            bank_branch: report.bank_branch ? report.bank_branch : "",
            lh_branch: report.lh_branch ? report.lh_branch : "",
            rectification_received_dt: report.rectification_received_dt ? report.rectification_received_dt : "",
            rectification_completed_dt: report.rectification_completed_dt ? report.rectification_completed_dt : "",
        }));

        return (
            <div className="pcoded-main-container" >
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row pr-3 pl-3">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="mt-1 mb-1">Rectification</h5>
                                                </div>

                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>From</h6>
                                                            <DatePicker
                                                                format={"DD.MM.YYYY"}
                                                                onChange={(date, dateStr) =>
                                                                    this.handleDateChange(
                                                                        date,
                                                                        dateStr,
                                                                        "eodFromDate"
                                                                    )
                                                                }
                                                                style={{ height: "39px" }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>To</h6>
                                                            <DatePicker
                                                                format={"DD.MM.YYYY"}
                                                                onChange={(date, dateStr) =>
                                                                    this.handleDateChange(
                                                                        date,
                                                                        dateStr,
                                                                        "eodToDate"
                                                                    )
                                                                }
                                                                style={{ height: "39px" }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>LH Branch</h6>
                                                            {lhBranchShow ? <>{this.state.lawhandsBranches &&
                                                                this.state.lawhandsBranches.length > 0 &&
                                                                this.state.lawhandsBranches.map((opt: any) => {
                                                                    opt.label = opt.name;
                                                                    opt.value = opt.id;
                                                                }) && (
                                                                    <Select
                                                                        options={lawhandsBranchesoptions}
                                                                        name="lawHandsBranch"
                                                                        value={this.state.lawhandsBranches.find(
                                                                            (o: any) =>
                                                                                o.value === this.state.lawHandsBranch
                                                                        )}

                                                                        onChange={(e) =>
                                                                            this.handleChange1(
                                                                                e,
                                                                                "lawHandsBranch",
                                                                                "singleselect"
                                                                            )
                                                                        }
                                                                    />
                                                                )}</> : <select
                                                                    className="custom-select"
                                                                    id="lawhands_branch"
                                                                    name="lawhands_branch"
                                                                    onClick={lawBranchName ? () => this.lawBranchName(1) : () => this.lawBranchName(2)}
                                                                    onChange={this.handleChange}
                                                                >
                                                                <option value="">Select</option>
                                                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                                                    ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                                                        return (
                                                                            <option key={index} value={lawBranch.id}>
                                                                                {lawBranch.name}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </select>}
                                                        </div>

                                                        <div className="col-xl-3 col-md-3" >
                                                            <h6>Bank Name</h6>
                                                            {showBank ? <> {this.state.banks &&
                                                                this.state.banks.length > 0 &&
                                                                this.state.banks.map((opt: any) => {
                                                                    opt.label = opt.name;
                                                                    opt.value = opt.id;
                                                                }) && (
                                                                    <Select

                                                                        options={banksoptions}
                                                                        name="bank_private_name"
                                                                        value={this.state.banks.find(
                                                                            (o: any) =>
                                                                                o.value === this.state.bank_private_name
                                                                        )}
                                                                        onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                                                        onChange={(e) =>
                                                                            this.handleChange1(
                                                                                e,
                                                                                "bank_private_name",
                                                                                "singleselect"
                                                                            )
                                                                        }
                                                                    />
                                                                )}</> : <select
                                                                    className="custom-select"
                                                                    id="lawhands_branch"
                                                                    name="lawhands_branch"
                                                                    onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                                                    onChange={this.handleChange}
                                                                >
                                                                <option value="">Select</option>
                                                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                                                    ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                                                        return (
                                                                            <option key={index} value={lawBranch.id}>
                                                                                {lawBranch.name}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </select>}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>Bank Branch</h6>
                                                            {bankBranch ? <>{this.state.branches &&
                                                                this.state.branches.length > 0 &&
                                                                this.state.branches.map((opt: any) => {
                                                                    opt.label = opt.name;
                                                                    opt.value = opt.id;
                                                                }) && (
                                                                    <Select
                                                                        options={filteredBranchesoptions}
                                                                        name="branch"
                                                                        value={this.state.filteredBranches.find(
                                                                            (o: any) => o.value === this.state.branch
                                                                        )}
                                                                        onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                                                        onChange={(e) =>
                                                                            this.handleChange1(
                                                                                e,
                                                                                "branch",
                                                                                "singleselect"
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            } </> : <select
                                                                className="custom-select"
                                                                id="lawhands_branch"
                                                                name="lawhands_branch"
                                                                onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                                                onChange={this.handleChange}
                                                            >
                                                                <option value="">Select</option>
                                                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                                                    ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                                                        return (
                                                                            <option key={index} value={lawBranch.id}>
                                                                                {lawBranch.name}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </select>}
                                                        </div>
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>Employee Name</h6>
                                                            {this.state.users && this.state.users.length >= 0 && this.state.users.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id

                                                            }) &&
                                                                <Select options={usersoptions} name="assigned_to"
                                                                    value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
                                                                    onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
                                                                    required
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-3 col-md-3">
                                                            <h6>File.No</h6>
                                                            <input type="text" name="file_id" className="form-control"
                                                                placeholder="Eg.12306"
                                                                value={this.state.file_id}
                                                                onChange={e => this.handleChange(e)}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3 col-md-3 mt-3">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                style={{ float: 'right' }}
                                                                onClick={() => this.getEodTableData(this.state.pageNumber, this.state.rowCount)}
                                                            >
                                                                {this.state.isLoading ? (
                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                ) : "Search"}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        {/* {this.state.loadingexcel === true &&
                                                            <div className="d-flex">
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className='export-btn'
                                                                >
                                                                    <CSVLink
                                                                        headers={excelColumn}
                                                                        data={excelRowData}
                                                                        filename={"File-Logs"}
                                                                        style={{ "textDecoration": "none", "color": "#fff" }}
                                                                    >
                                                                        {!this.state.loadingexcel ? 'Loading csv...' : 'Export Data'}
                                                                    </CSVLink>
                                                                </Button>
                                                                <p className="ml-5 total-file-count"><b>Total Count - {this.state.totalCount}</b></p>
                                                            </div>
                                                        } */}
                                                        <ReactFlexyTable
                                                            className="assigned-table"
                                                            data={processedData}
                                                            columns={columns}
                                                            sortable
                                                            globalSearch
                                                            pageSize={20}
                                                        />
                                                        <div className="pagination-container">
                                                            {noOfPages > 1 && (
                                                                <Pagination
                                                                    count={noOfPages}
                                                                    defaultPage={this.state.pageNumber}
                                                                    onChange={this.handlePagination}
                                                                    showFirstButton
                                                                    showLastButton
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RectificationFiles;
