import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import AssignBankUser from "./AssignBankUser";
import FileSearchModal from "./FileSearchModal";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    Select,
    MenuItem
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";

interface AllProps {
    history: any;
}
declare var $: any;
class RectificationInprogressFile extends React.Component<AllProps> {
    state = {
        selectVal: false,
        outreason: "",
        filesCount: '',
        fileToBeAssigned: 0,
        isFileRefresh: false,
        totalCount: 0,
        takeFiles: [],
        autoAssign: [
            {
                "id": "1",
                "module_name": "Auto Assign",
                "status": true
            }
        ],
        takenfiles: [],
        queuefiles: "",
        takeTime: false,
        isLoading: false
    };
    componentDidMount() {
        this.gettakenFiles();
        this.getOutTime();
        this.ouTime();
        // this.getAutoAssignStatus();
        this.getDeviationQueueFiles();
    }
    getDeviationQueueFiles = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let req = {
            userId: userId
        }
        callApi("POST", "get_rectify_files.php", req)
            .then((res) => res.data)
            .then((response) => {
                const files = response.data;
                this.setState({ filesCount: files.length });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getAutoAssignStatus = () => {
        callApi("GET", "module_option_select.php", "")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ autoAssign: result.responseJson.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    GotoWorkFile = (id) => {
        sessionStorage.setItem("currentfileId", JSON.stringify(id));
        let obj = { file_id: id };
        localStorage.setItem("thisPopup", JSON.stringify(obj));
        this.timeUpdate(id);
        this.props.history.push("/work-file/7/" + id);
    };
    timeUpdate = (fileid) => {
        const loginuser: any = sessionStorage.getItem("user1");
        let req;
        this.state.autoAssign.find(
            (data: any) => {
                if (data.module_name === "Auto Assign" && data.status == true) {
                    req = {
                        query_raised_by: JSON.parse(loginuser),
                        id: fileid,
                        reason: "Rectification Work Inprogress",
                        created_by: JSON.parse(loginuser),
                        color: "rgb(209 251 128)"
                    }
                }
            })
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
            });
    }

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");
        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        let updatedRemainingTime: any = sessionStorage.getItem('updatedRemainingTime');
        let updatedtime = Math.round(updatedRemainingTime);
        const status: any = sessionStorage.getItem("dashboard_status");
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent,
                "balance_min": updatedtime,
                "dashboard_status": status
            }
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    this.setState({ isLoading: false });
                    sessionStorage.removeItem('updatedRemainingTime');
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    sessionStorage.removeItem('takenfile_id');
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let file_id = JSON.parse(fileid1);
                    this.gettakenFiles();
                    this.timeUpdate(file_id);
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    onchangeReason = (e: any) => {
        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    gettakenFiles = () => {
        this.setState({ isFileRefresh: false })
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const roleid = user ? JSON.parse(user)["role_id"] : "";
        const roletype = user ? JSON.parse(user)["roleType"] : "";
        const req = {
            user_id: userId,
            role_id: roleid,
            role_type: roletype
        }
        callApi("POST", "rectification_taken_file.php", req)
            .then(res => res.data)
            .then(response => {
                const files = response.file_id;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                    sessionStorage.setItem("takenfile_id", JSON.stringify(files[0].id));
                }
                this.setState({ takenfiles: files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };
    goToFileDashboard = (id) => {
        this.timeUpdate(id);
        const url = `/rectification-work-inprogress`;
        localStorage.setItem("url", url);
        let obj = { "file_id": id }
        localStorage.setItem("thisPopup", JSON.stringify(obj))
        sessionStorage.setItem("currentfileId", JSON.stringify(id));
        this.props.history.push("/file-dashboard/" + id);
    };

    //take new file from auto assign
    gettakefile = () => {
        var randomNumber = Math.floor(Math.random() * (30 - 10 + 1)) + 10;
        console.log("randomNumber", randomNumber);
        this.setState({ takeTime: true });
        setTimeout(() => {
            console.log("randomNumber--", randomNumber);
            this.setState({ takeTime: false });
        }, randomNumber * 1000);
        this.setState({ isFileRefresh: false })
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const roleid = user ? JSON.parse(user)["role_id"] : "";
        const roletype = user ? JSON.parse(user)["roleType"] : "";
        const user_grade: any = sessionStorage.getItem("user_grade");
        const e_grade: any = sessionStorage.getItem("e_grade");
        const v_grade: any = sessionStorage.getItem("v_grade");
        const req = {
            user_id: userId,
            role_id: roleid,
            role_type: roletype,
            user_grade: user_grade,
            e_grade: e_grade,
            v_grade: v_grade
        }
        callApi("POST", "rectification_auto_assign.php", req)
            .then(res => res.data)
            .then(response => {
                const files: any = [];
                if (response && response.file_id) {
                    let fileid = response.file_id;
                    let reassignStatus = response.reassignStatus;
                    let processoffice_id = response.process_office_id;
                    sessionStorage.setItem("takenfile_id", JSON.stringify(fileid));
                    sessionStorage.setItem("process_office_id", processoffice_id);
                    sessionStorage.setItem("verification_office_id", userId);
                    let redirect_to = response.type;
                    if (redirect_to == "w_f") {
                        this.goToFileDashboard(fileid);
                    }
                } else if (response && response.status == 201) {
                    $('#not-a-rectify-officer').modal('show');
                } else {
                    $('#no-queue-popup').modal('show');
                }
                this.setState({ takeFiles: files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        const columns = [
            {
                header: 'ID',
                key: 'id',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.index}</td>
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <button
                                onClick={() => this.GotoWorkFile(file.id)}
                                style={{
                                    border: "none",
                                    background: "none",
                                    display: "block",
                                    margin: "auto",
                                }}
                            >
                                Work File
                            </button>
                        </div>
                    </div >
                }
            },
            {
                header: 'File ID',
                key: 'file_id',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.file_id}</td>
            },
            {
                header: 'Bank Name',
                key: 'bank_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }> {file.bank_name} {file.product_name ? <>({file.product_name})</> : ""}</td>
            },
            {
                header: 'Bank Branch Name',
                key: 'branch_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.branch_name}</td>
            },
            {
                header: 'Property Owner Name',
                key: 'property_ownername',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.property_ownername}</td>
            },
            {
                header: 'Law Hands Branch',
                key: 'law_hand_branch',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.law_hand_branch}</td>
            },
            {
                header: 'Login By',
                key: 'login_by',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.login_by}</td>
            },
            {
                header: 'To Follow',
                key: 'assigned_to',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.assigned_to}</td>
            },
            {
                header: 'Created Date',
                key: 'date_time',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.date_time}</td>
            }
        ];
        const data = this.state.takenfiles;
        const { selectVal } = this.state;
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="file-status-header">Rectification Work InProgress Files</h5>
                                                    <div className="file-search-button">
                                                        {this.state.autoAssign && this.state.autoAssign.find(
                                                            (data: any) => data.module_name === "Auto Assign" && data.status == false) ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    data-toggle="modal"
                                                                    data-target="#file-search-modal"
                                                                >
                                                                    File Search
                                                                </button>
                                                            </> : ""}

                                                    </div>
                                                </div>
                                                {this.state.autoAssign && this.state.autoAssign.find(
                                                    (data: any) => data.module_name === "Auto Assign" && data.status) &&
                                                    <>
                                                        <div className="card-body" style={{ marginTop: "2%" }}>
                                                            {!data ?
                                                                <>
                                                                    <div className="" style={{ display: "flex", justifyContent: "center", padding: "18%" }}>
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                onClick={this.gettakefile}
                                                                                disabled={this.state.takeTime}
                                                                            >
                                                                                Take file
                                                                            </button><br />
                                                                            <b style={{ fontSize: "17px" }}>{this.state.filesCount ? this.state.filesCount : "0"} Files Waiting in Queue</b>
                                                                        </div>
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    {data && this.state.isFileRefresh &&
                                                                        <>
                                                                            {data.length > 0 &&
                                                                                <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                                                                            }
                                                                        </>
                                                                    }</>
                                                            }
                                                        </div>
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal"
                    id="no-queue-popup"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document" style={{
                        display: "grid !importent", placeItems: "center !importent", margin: "10% auto !importent", justifyContent: "center !importent"
                    }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <h5 className="modal-title" style={{ textAlign: "center" }}>In Queue there is no Files, please wait for some times.</h5>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal"
                    id="not-a-rectify-officer"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document" style={{
                        display: "grid !importent", placeItems: "center !importent", margin: "10% auto !importent", justifyContent: "center !importent"
                    }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <h5 className="modal-title" style={{ textAlign: "center" }}>You are not a recitification officer, so the files cannot be assigned to you.</h5>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}

                                >{this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={() => this.previousPop()}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}
export default RectificationInprogressFile;
