import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { EcVerify } from './sopDataTemplate';
import DataModel from './sopDataModel';

interface AllProps {
    data: EcVerify;
    handleEcVerifyChange: any;
}
export class ECVerification extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d',
        responseData: {},
        isUpdateAvailable: false
    }
    componentDidMount() {
        const { data } = this.props;
        this.setState({
            responseData: JSON.parse(JSON.stringify(data))
        })
    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (e) => {
        const { data } = this.props;
        data[e.target.name] = e.target.value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleEcVerifyChange(data);
    }

    handleChildInputChange = (key, name, value, index?) => {
        // console.log("key & value", `${key}-${name}`);
        const { data } = this.props;
        if (index !== undefined) data[key][index][name] = value;
        else data[key][name] = value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleEcVerifyChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }
      
    handleChildRadioChange = (key, name, val, index?) => {
        // console.log("key & value", `${key}-${name}`);
        const { data } = this.props;
        if (index !== undefined) {
            data[key][index][name] = val;
        }
        else {
            data[key][name] = val;
        }
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleEcVerifyChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }


    checkOperation = (type, value, index) => {
        let isEditable = false
        const { responseData } = this.state;
        if (responseData[type] && responseData[type].length < (index + 1)) {
            isEditable = true;
        };
        return isEditable;
    }

    addChild = (type) => {
        // console.log("add type--->>", type);

        const { data } = this.props;
        data[type].push({
            // ...JSON.parse(JSON.stringify(DataModel['ecVerify'][type][0]))
            "noOfEcEntries": "",
            "relatedEcEntries": "",
            "notRelatedVerified": false,
            "onlineEcVerified": false,
            "isCourtAttachment": false,
            "isAttachmentCanceled": false,
            "leaseInEc": false,
            "leaseCancellation": false,
            "isMortgageInEc": false,
            "isReceiptInEc": false,
            "isAgreementInEc": false,
            "isAgreementCanceled": false,
            "largerExtent": "",
            "nonRelatedSNo": "",
            "differentPlotNo": false,
        });
        this.props.handleEcVerifyChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }

    // removeChild = (type, index) => {
    //     const { data } = this.props;
    //     delete data[type][index];
    //     this.props.handleEcVerifyChange(data);
    //     this.setState({
    //         isUpdateAvailable: !this.state.isUpdateAvailable
    //     })
    // }
    
    removeChild = (type, index) => {
        const { data } = this.props;
        delete data[type][index];
        const updatedEcverify = data[type].filter((item) => item !== null); // Filter out null elements
        data[type] = updatedEcverify;
        this.props.handleEcVerifyChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        });
    };

    getAddButton = (title, value) => {
        // console.log("add btn type--->>", `${title}---${value}`);
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6><b></b></h6>
                <div onClick={() => this.addChild(value)}>
                    <button className='btn btn-sm btn-primary' style={{ fontSize: 'smaller' }}>Add {title}</button>
                </div>
            </div>
        )
    }

    render() {
        const { data } = this.props;
        const { isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section ec-verify">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="4" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>EC Verification</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                <div>
                                    {this.getAddButton('EcVerify', 'ecentry')}
                                    {data.ecentry && Array.isArray(data.ecentry) && data.ecentry.map((value, index) => {
                                        // console.log("responseData EcVerify--->>>", value);
                                        return (
                                            <div className="row mb-10">
                                                <div className="form-group col-md-5">
                                                    <label className="label" style={{ padding: 0 }}>No Of EC Entries</label>
                                                    <input
                                                        className="form-control"
                                                        name={"noOfEcEntries" + (value?.noOfEcEntries || '') + index}
                                                        type="text"
                                                        value={value?.noOfEcEntries || ''}
                                                        onChange={(e) => this.handleChildInputChange("ecentry", 'noOfEcEntries', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-5">
                                                    <label className="label" style={{ padding: 0 }}>No Of Not Related EC Entries</label>
                                                    <input
                                                        className="form-control"
                                                        name={"relatedEcEntries" + (value?.relatedEcEntries || '') + index}
                                                        type="text"
                                                        value={value?.relatedEcEntries || ''}
                                                        onChange={(e) => this.handleChildInputChange("ecentry", 'relatedEcEntries', e.target.value, index)}
                                                    />
                                                </div>
                                                {this.checkOperation('ecentry', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('ecentry', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                                <div className="col-md-6 mb-10">
                                                    <label className="label" style={{ padding: 0 }}>Online EC Verified?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"onlineEcVerified" + (value && value.onlineEcVerified ? value.onlineEcVerified : '') + index}
                                                            type="radio"
                                                            checked={value && value.onlineEcVerified !== null && value.onlineEcVerified !== undefined ? value.onlineEcVerified : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "onlineEcVerified", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"onlineEcVerified" + (value && !value.onlineEcVerified ? !value.onlineEcVerified : '') + index}
                                                            type="radio"
                                                            checked={value && value.onlineEcVerified !== null && value.onlineEcVerified !== undefined ? !value.onlineEcVerified : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "onlineEcVerified", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-10">
                                                    <label className="label" style={{ padding: 0 }}>Verified Not Related EC?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'notRelatedVerified' + (value && value.notRelatedVerified ? value.notRelatedVerified : "") + index}
                                                            type="radio"
                                                            checked={value && value.notRelatedVerified !== null && value.notRelatedVerified !== undefined ? value.notRelatedVerified : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "notRelatedVerified", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"notRelatedVerified" + (value && !value.notRelatedVerified ? !value.notRelatedVerified : '') + index}
                                                            type="radio"
                                                            checked={value && value.notRelatedVerified !== null && value.notRelatedVerified !== undefined ? !value.notRelatedVerified : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "notRelatedVerified", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Larger Extent</label>
                                                    <input
                                                        className="form-control"
                                                        name={"largerExtent" + (value && value.largerExtent ? value.largerExtent : '') + index} // Updated null check
                                                        type="text"
                                                        value={value && value.largerExtent ? value.largerExtent : ''} // Updated null check
                                                        onChange={(e) => this.handleChildInputChange("ecentry", 'largerExtent', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Not Related S.No</label>
                                                    <input
                                                        className="form-control"
                                                        name={"nonRelatedSNo" + (value && value.nonRelatedSNo ? value.nonRelatedSNo : '') + index} // Updated null check
                                                        type="text"
                                                        value={value && value.nonRelatedSNo ? value.nonRelatedSNo : ''} // Updated null check
                                                        onChange={(e) => this.handleChildInputChange("ecentry", 'nonRelatedSNo', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-10">
                                                    <label className="label" style={{ padding: 0 }}>Different Plot No</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'differentPlotNo' + (value && value.differentPlotNo ? value.differentPlotNo : "") + index}
                                                            type="radio"
                                                            checked={value && value.differentPlotNo !== null && value.differentPlotNo !== undefined ? value.differentPlotNo : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "differentPlotNo", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"differentPlotNo" + (value && !value.differentPlotNo ? !value.differentPlotNo : '') + index}
                                                            type="radio"
                                                            checked={value && value.differentPlotNo !== null && value.differentPlotNo !== undefined ? !value.differentPlotNo : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "differentPlotNo", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="label" style={{ padding: 0 }}>Court Attachment</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"isCourtAttachment" + (value && value.isCourtAttachment !== null && value.isCourtAttachment !== undefined ? value.isCourtAttachment : '') + index}
                                                            type="radio"
                                                            checked={value && value.isCourtAttachment !== null && value.isCourtAttachment !== undefined ? value.isCourtAttachment : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isCourtAttachment", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"isCourtAttachment" + (value && value.isCourtAttachment !== null && !value.isCourtAttachment ? !value.isCourtAttachment : '') + index}
                                                            type="radio"
                                                            checked={value && value.isCourtAttachment !== null && value.isCourtAttachment !== undefined ? !value.isCourtAttachment : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isCourtAttachment", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {value && value.isCourtAttachment && <div className="col-md-6">
                                                    <label className="label" style={{ padding: 0 }}>Attachment Cancelled?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'isAttachmentCanceled' + (value && value.isAttachmentCanceled ? value.isAttachmentCanceled : "") + index}
                                                            type="radio"
                                                            checked={value && value.isAttachmentCanceled !== null && value.isAttachmentCanceled !== undefined ? value.isAttachmentCanceled : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isAttachmentCanceled", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"isAttachmentCanceled" + (value && !value.isAttachmentCanceled ? !value.isAttachmentCanceled : '') + index}
                                                            type="radio"
                                                            checked={value && value.isAttachmentCanceled !== null && value.isAttachmentCanceled !== undefined ? !value.isAttachmentCanceled : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isAttachmentCanceled", false, index)}
                                                        />
                                                    </div>
                                                </div>}

                                                <div className="col-md-6 ">
                                                    <label className="label" style={{ padding: 0, marginTop: '10px' }}>Lease In EC</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'leaseInEc' + (value && value.leaseInEc ? value.leaseInEc : "") + index}
                                                            type="radio"
                                                            checked={value && value.leaseInEc !== null && value.leaseInEc !== undefined ? value.leaseInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "leaseInEc", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"leaseInEc" + (value && !value.leaseInEc ? !value.leaseInEc : '') + index}
                                                            type="radio"
                                                            checked={value && value.leaseInEc !== null && value.leaseInEc !== undefined ? !value.leaseInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "leaseInEc", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {value && value.leaseInEc && <div className="col-md-6 ">
                                                    <label className="label" style={{ padding: 0, marginTop: '10px' }}>Lease Cancellation</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'leaseCancellation' + (value && value.leaseCancellation ? value.leaseCancellation : "") + index}
                                                            type="radio"
                                                            checked={value && value.leaseCancellation !== null && value.leaseCancellation !== undefined ? value.leaseCancellation : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "leaseCancellation", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"leaseCancellation" + (value && !value.leaseCancellation ? !value.leaseCancellation : '') + index}
                                                            type="radio"
                                                            checked={value && value.leaseCancellation !== null && value.leaseCancellation !== undefined ? !value.leaseCancellation : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "leaseCancellation", false, index)}
                                                        />
                                                    </div>
                                                </div>}
                                                <div className="col-md-6 ">
                                                    <label className="label" style={{ padding: 0, marginTop: '10px' }}>Mortgage In EC</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'isMortgageInEc' + (value && value.isMortgageInEc ? value.isMortgageInEc : "") + index}
                                                            type="radio"
                                                            checked={value && value.isMortgageInEc !== null && value.isMortgageInEc !== undefined ? value.isMortgageInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isMortgageInEc", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"isMortgageInEc" + (value && !value.isMortgageInEc ? !value.isMortgageInEc : '') + index}
                                                            type="radio"
                                                            checked={value && value.isMortgageInEc !== null && value.isMortgageInEc !== undefined ? !value.isMortgageInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isMortgageInEc", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <label className="label" style={{ padding: 0, marginTop: '10px' }}>Receipt In EC</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'isReceiptInEc' + (value && value.isReceiptInEc ? value.isReceiptInEc : "") + index}
                                                            type="radio"
                                                            checked={value && value.isReceiptInEc !== null && value.isReceiptInEc !== undefined ? value.isReceiptInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isReceiptInEc", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"isReceiptInEc" + (value && !value.isReceiptInEc ? !value.isReceiptInEc : '') + index}
                                                            type="radio"
                                                            checked={value && value.isReceiptInEc !== null && value.isReceiptInEc !== undefined ? !value.isReceiptInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isReceiptInEc", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <label className="label" style={{ padding: 0, marginTop: '10px' }}>Agreement In EC</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'isAgreementInEc' + (value && value.isAgreementInEc ? value.isAgreementInEc : "") + index}
                                                            type="radio"
                                                            checked={value && value.isAgreementInEc !== null && value.isAgreementInEc !== undefined ? value.isAgreementInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isAgreementInEc", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"isAgreementInEc" + (value && !value.isAgreementInEc ? !value.isAgreementInEc : '') + index}
                                                            type="radio"
                                                            checked={value && value.isAgreementInEc !== null && value.isAgreementInEc !== undefined ? !value.isAgreementInEc : false}
                                                            onChange={() => this.handleChildRadioChange("ecentry", "isAgreementInEc", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {value && value.isAgreementInEc &&
                                                    <div className="col-md-6 ">
                                                        <label className="label" style={{ padding: 0, marginTop: '10px' }}>Agreement Canceled?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={'isAgreementCanceled' + (value && value.isAgreementCanceled ? value.isAgreementCanceled : "") + index}
                                                                type="radio"
                                                                checked={value && value.isAgreementCanceled !== null && value.isAgreementCanceled !== undefined ? value.isAgreementCanceled : false}
                                                                onChange={() => this.handleChildRadioChange("ecentry", "isAgreementCanceled", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"isAgreementCanceled" + (value && !value.isAgreementCanceled ? !value.isAgreementCanceled : '') + index}
                                                                type="radio"
                                                                checked={value && value.isAgreementCanceled !== null && value.isAgreementCanceled !== undefined ? !value.isAgreementCanceled : false}
                                                                onChange={() => this.handleChildRadioChange("ecentry", "isAgreementCanceled", false, index)}
                                                            />
                                                        </div>
                                                    </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default ECVerification
