
import * as React from "react";
import { callApi } from "../../utils/api";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
    id: any;
}
class VisitorList extends React.Component<AllProps> {
    state = {
        bank_name: "",
        selectVal: false,
        setResponse: true,
        lookup: '',
        fileId: '',
        sampleId: "",
        id: "",
        fromDate: "",
        toDate: "",
        notications: [],

        users: [],
        total: '',
        green: '',
        red: '',
    };
    obj: any;
    table: any;
    componentDidMount() {
        this.getVisitorList();
        const user: any = sessionStorage.getItem("user");
        this.setState({ user: user ? JSON.parse(user)["userId"] : "" })
    }
    getVisitorList = () => {
        callApi("POST", "visitor_get.php")
            .then((res: any) => res.data)
            .then(response => {
                var visitors = response.data
                visitors = visitors.reverse();
                console.log("response---->>>>", visitors);
                let notications = [];
                // if (response.data && response.data.responseJson && response.data.responseJson.data) {
                // 	notications = response.data.responseJson.data;
                // }
                this.setState({ notications: visitors });
                // const user: any = sessionStorage.getItem("user");
                // const role_id = user ? JSON.parse(user)["role_id"] : "";
                // const user_id = user ? JSON.parse(user)["userId"] : null;
                // const userDetails = user ? JSON.parse(user) : "";
                // const currentDate = new Date();
                // var dateString = moment(currentDate).format('DD-MM-YYYY')
                if ($.fn.dataTable.isDataTable('#processeodTable')) {
                    this.table = $('#processeodTable').DataTable({
                        paging: true,
                        dom: 'Bfrtip',
                        buttons: [{ extend: 'excel', text: "Download As Excel" }]
                    });

                }
                else {
                    this.table = $('#processeodTable').DataTable({
                        paging: true,
                        dom: 'Bfrtip',
                        buttons: [{ extend: 'excel', text: "Download As Excel" }]
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };


    handleChange1 = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName === "lawhands_branch") {
            const branch = fieldValue;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ [fieldName]: fieldValue });
            this.setState({ filteredUsers });
        }
        if (fieldName === "assigned_to") {
            const employee_id = fieldValue;
            this.setState({ [fieldName]: fieldValue });
        }
        if (fieldName === "bank_name") {
            const bank_name = fieldValue;
            console.log("267", bank_name);
            this.setState({ [fieldName]: fieldValue });
        }
    }
    handleChange = (e, stateName?, type?) => {
        if (e.target.name === "bank_name") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "toDate") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "fromDate") {
            this.setState({ fromdate: e.target.value })
        }
        if (e.target.name === "id") {
            this.setState({ id: e.target.value })
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };
    handleDateChange = (date, dateStr, type) => {
        // console.log(date, dateStr);
        this.setState({ [type]: dateStr });
    };
    render() {
        const data = this.state.notications;
        const downloadExcelProps = {
            type: 'filtered',
            title: 'test',
            showLabel: true
        }
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        // const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        const { toDate, setResponse, selectVal } = this.state;
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card" style={{ overflow: 'hidden' }}>
                                                <div className="card-header" style={{
                                                    padding: "10px",
                                                    textAlign: "center"
                                                }}>
                                                    <h5 style={{
                                                        fontSize: "21px",
                                                        fontWeight: "bold"
                                                    }}>Visitor's List</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dt-responsive table-responsive">
                                                        <table
                                                            id="processeodTable"
                                                            className="table table-striped table-bordered nowrap"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>visitor Name</th>
                                                                    <th>Phone Number</th>
                                                                    <th>Bank Name</th>
                                                                    <th>Purpose</th>
                                                                    <th>Visited Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.notications && this.state.notications.length
                                                                    ? this.state.notications.map(
                                                                        (query: any, index: number) => {
                                                                            return (
                                                                                <tr className="query-table-row cursor-pointer" key={index}>
                                                                                    <td className="text-align-center">{index + 1}</td>
                                                                                    <td className="click">
                                                                                        {query.visitor_name}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.phone_no}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.bank_name}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.purpose}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.date}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                    : ""}
                                                            </tbody>
                                                            {/* <tfoot>
															<tr>
																	<th>S.No</th>
																	<th>File No</th>
																	<th>Team Lead Name</th>
																	<th>Process Officer Name</th>
																	<th>Process Start Date</th>
																	<th>File Branch</th>
																	<th>Bank Name</th>
																	<th>New Login</th>
																	<th>Highlights</th>
																	<th>Data Entry</th>
																	<th>Flow Chart</th>
																	<th>Queries</th>
																	<th>Status</th>
																	<th>Total Mints</th>
																	<th>File Start Time</th>
																	<th>File End Time</th>

																</tr>
															</tfoot> */}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default VisitorList;

function x(arg0: string, x: any) {
    throw new Error("Function not implemented.");
}
