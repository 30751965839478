import { callApi } from "./api";

export const pushnotificationApi=(notifyData: any,userid:any)=>{
    let obj={...notifyData,userid}
    callApi("POST", "pushnotification.php",obj)
    .then((res) => res.data)
    .then((response) => {
    })
    .catch((err) => {
      console.log(err);
    });
}