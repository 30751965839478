import { getDatabase, off, onValue, ref, set } from "firebase/database";
import { v4 as uuid } from "uuid";

const FIREBASE_PUSH_DB_NAME = "push-notifications";

/**
 * notifyType
 *   'message' - Simple notification without user response (for 2 seconds)
 *   'response' - Notification with Accept/Reject option
 *   'notify' - Notication with Okay Button
 */

/**
 *
 * @param type 'user'
 * @param notifyData Notification Data
 * @param userIdList List of users to be notified
 */

export const notifyToUser = (
  type: string,
  notifyData: any,
  userIdList?: any
) => {
  const firebaseDB = getDatabase();
  const pushNotification = ref(firebaseDB, FIREBASE_PUSH_DB_NAME);
  let userNotify;
  if (type === "user") {
    let prevNotifyData: any;
    // To get all DB values
    userNotify = onValue(
      pushNotification,
      (snapshot) => {
        const data = snapshot.val();
        prevNotifyData = { ...data };
      },
      {
        onlyOnce: true,
      }
    );
    userIdList.filter((userId) => {
      const userNotification = prevNotifyData[userId]
        ? prevNotifyData[userId].notify
        : [];
        userNotification.push({ ...notifyData, status: "unread", id: uuid() });
      // To Update all values to DB (Current + New Data)

      set(ref(firebaseDB, `${FIREBASE_PUSH_DB_NAME}/${userId}`), {
        notify: userNotification,
      });
    });
  }
};

// const notifyData = {
//   fileId: this.props.fileToBeAssigned,
//   bank: "test_bank",
//   branch: "test_branch",
//   assignedTo: this.state.user,
//   assignedBy: userId,
//   notifyType: "message",
// };
// notifyToUser("user", {...notifyData,notifyType:'message'}, ["147"]);
export const updatePNFirebaseReadStatus = (
  notificationId: string,
  userId: string,
  text: string = "",
  notificationStatus: string,
  status: string = "read"
) => {
  const firebaseDB = getDatabase();
  const pushNotification = ref(firebaseDB, FIREBASE_PUSH_DB_NAME);
  let prevNotifyData: any;
  onValue(
    pushNotification,
    (snapshot) => {
      const data = snapshot.val();
      prevNotifyData = { ...data };
    },
    {
      onlyOnce: true,
    }
  );
  let userAllNotification = prevNotifyData[userId]
    ? prevNotifyData[userId].notify
    : [];
  let userNotificationIndex = userAllNotification.findIndex(
    (value) => value.id === notificationId
  );
  if (userNotificationIndex >-1) {
    userAllNotification[userNotificationIndex].status = status;
    userAllNotification[userNotificationIndex].notificationStatus =
      notificationStatus;
    userAllNotification[userNotificationIndex].reason = text;
    set(ref(firebaseDB, `${FIREBASE_PUSH_DB_NAME}/${userId}`), {
      notify: userAllNotification,
    });
  }
};
