import React, { Component } from 'react';
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import moment from 'moment';

interface AllProps {
    complaints: any;
    user: any;
    handleStatusChange: any;
    currentPath: string;
}

interface AllState {
    statuses: string[];
    forSoftwareTeamStatuses: string[];
    currentPath: string;
}

export class ComplaintTable extends Component<AllProps, AllState> {
    state = {
        statuses: [
            "", "Valid", "Invalid", "New Requirement"
        ],
        forSoftwareTeamStatuses: [
            "", "Temporary Solution", "Completed", "Pending"
        ],
        currentPath: ""
    }
    componentDidMount() {
        const { currentPath } = this.props;
        this.setState({ currentPath });
        console.log("navigateto", currentPath);
        sessionStorage.setItem("complaint_route", currentPath);
    }


    render() {
        // const { complaints, user } = this.props;
        // complaints.forEach((complaint, index) => { complaint.serial = index + 1; });
        const { complaints, user } = this.props;
        complaints.forEach((complaint, index) => { complaint.serial = index + 1; });
        const { statuses, forSoftwareTeamStatuses, currentPath } = this.state;
        const User: any = sessionStorage.getItem("user");
        const userId = User ? JSON.parse(User)["userId"] : "";
        const roleType = User ? JSON.parse(User)["roleType"] : "";
        const role_label = User ? JSON.parse(User)["role_label"] : "";
        const complaintsdata = complaints.map((report, index) => ({
            serial: index + 1,
            ticketId: report.ticketId,
            fileNo: report.fileNo ? report.fileNo : "",
            bankFormat: report.bankFormat ? report.bankFormat : "",
            location: report.location,
            alreadyExist: report.alreadyExist ? report.alreadyExist : "",
            remark: report.remark ? report.remark : "",
            reply: report.reply ? report.reply : "",
            createdDate: report.createdDate ? report.createdDate : "",
            updatedDate: report.updatedDate ? report.updatedDate : "",
            raisedBy: report.raisedBy ? report.raisedBy : "",
            status: report.status ? report.status : "",
            softwarestatus: report.softwarestatus ? report.softwarestatus : "",
        }));
        // const columns = [
        //     {
        //         header: 'S.no',
        //         key: 'serial',
        //         td: (item) => <span>{item.serial}</span>
        //     },
        //     {
        //         header: 'Complaint No',
        //         key: 'ticketId',
        //         td: (item) => <span>{item.ticketId}</span>
        //     },
        //     {
        //         header: 'File No',
        //         key: 'fileNo',
        //         td: (item) => <span >{item.fileNo == "0" || item.fileNo == "" ? "-" : item.fileNo}</span>
        //     },
        //     {
        //         header: 'Raised By',
        //         key: 'raisedBy',
        //         td: (item) => <span>{item.raisedBy}</span>
        //     },
        //     {
        //         header: 'Raised At',
        //         key: 'createdDate',
        //         td: (item) => <span>{item.createdDate != null && item.createdDate != "" ? moment(item.createdDate).format("DD/MM/YYYY") : "-"}</span>
        //     },
        //     {
        //         header: 'Completed At',
        //         key: 'updatedDate',
        //         td: (item) => <span>{item.updatedDate != null && item.updatedDate != "" ? moment(item.updateDate).format("DD/MM/YYYY") : "-"}</span>
        //     },
        //     {
        //         header: 'LH Status',
        //         key: 'status',
        //         td: (item) => <span>{item.status ? item.status : "Pending"}</span>
        //     },
        //     {
        //         header: 'S/W Status',
        //         key: 'status',
        //         td: (item) => <span>{item.softwarestatus ? item.softwarestatus : "Pending"}</span>
        //     },
        //     {
        //         header: '',
        //         key: 'ticketId',
        //         td: (item) =>
        //             <span >
        //                 <Link to={`/view-complaint/${item.ticketId}`}>
        //                     View Details
        //                 </Link>
        //             </span>
        //     }
        // ];

        const columnsCommon = [
            {
                header: 'S.no',
                key: 'serial',
                td: (item) => <span>{item.serial}</span>
            },
            {
                header: 'Complaint No',
                key: 'ticketId',
                td: (item) => <span>{item.ticketId}</span>
            },
            {
                header: 'File No',
                key: 'fileNo',
                td: (item) => <span>{item.fileNo == "0" || item.fileNo == "" ? "-" : item.fileNo}</span>
            },
            // Add other common columns here
            {
                header: 'Raised At',
                key: 'createdDate',
                td: (item) => <span>{item.createdDate != null && item.createdDate != "" ? moment(item.createdDate).format("DD/MM/YYYY") : "-"}</span>
            },
            {
                header: 'Completed At',
                key: 'updatedDate',
                td: (item) => <span>{item.updatedDate != null && item.updatedDate != "" ? moment(item.updateDate).format("DD/MM/YYYY") : "-"}</span>
            },
            {
                header: 'LH Status',
                key: 'status',
                td: (item) => <span>{item.status ? item.status : "Pending"}</span>
            },
            {
                header: 'S/W Status',
                key: 'softwarestatus',
                td: (item) => <span>{item.softwarestatus ? item.softwarestatus : "Pending"}</span>
            },
            {
                header: '',
                key: 'ticketId',
                td: (item) => (
                    <span>
                        <Link to={`/view-complaint/${item.ticketId}`}>
                            View Details
                        </Link>
                    </span>
                )
            }
        ];

        const raisedByColumn = {
            header: 'Raised By',
            key: 'raisedBy',
            td: (item) => <span>{item.raisedBy}</span>
        };

        // const columns = currentPath === "/register-complaint" ? columnsCommon : [...columnsCommon.slice(0, 3), raisedByColumn, ...columnsCommon.slice(3)];
        const columns = [...columnsCommon];
        if (currentPath !== "/register-complaint") {
            columns.splice(3, 0, raisedByColumn);
        }
        
        return (
            <div className="">
                <ReactFlexyTable className="assigned-table" data={complaintsdata} columns={columns} sortable pageSize={10} showExcelButton />
            </div>
        )
    }
}