import * as React from "react";
import moment from "moment";

export function FileLogCalculation(groupedFiles, userList) {
    const getNameById = (id) => {
        const user = userList.find((user) => user.id === id);
        return user ? user.name : null;
    };
    const calculate = () => {
        const timeDifferences: {
            file_id: string;
            file_status: string;
            app_id: string;
            borrower_name: string;
            property_ownername: string;
            bank_name: string;
            product: string;
            bank_branch: string;
            lh_branch: string;
            front_office: string;
            login_date: any;
            login_by: string;
            scan_date: any;
            assigned_to: any;
            verified_by: any;
            taken_date: any;
            query_raised_dt: any;
            query_reiceved_date: any;
            typing_complited_dt: any;
            verification_picked_dt: any;
            verification_completed_dt: any;
            work_in_progress: any;
            d_work_in_progress: any;
            d_queue_time: any;
            rectify_work_in_progress: any;
            rectify_queue_time: any;
            query_diff: any;
            queryRaisedDate: any;
            queryReceivedDate: any;
            query_raised: any;
            verification_completed: any;
            total_wip_file: string;
            total_hold_time: string;
            total_unhold_time: string;
            total_rectify_queue_time: string;
            total_rectify_wip_file: string;
            total_dwip_file: string;
            total_dqueue_time: string;
            total_qr_file: string;
            total_vc_file: string;
            total_ex_file: string;
            total_filequeue_time: string;
            file_taken_time: string;
            total_Vfilequeue_time: string;
            s_extendHours: string;
            p_extendHours: string;
            v_extendHours: string;
            total_queue_file: string;
            total_time: string;
            extended_hours: any;
            s_Hours: any;
            p_Hours: any;
            v_Hours: any;
            queueTime: any;
            first_Query_Raise: any;
            second_Query_Raise: any;
            third_Query_Raise: any;
            foutth_Query_Raise: any;
            fifth_Query_Raise: any;
            first_Query_Received: any;
            second_Query_Received: any;
            third_Query_Received: any;
            fourth_Query_Received: any;
            fifth_Query_Received: any;
            first_Query_Received_WIP_By: any;
            second_Query_Received_WIP_By: any;
            third_Query_Received_WIP_By: any;
            fourth_Query_Received_WIP_By: any;
            fifth_Query_Received_WIP_By: any;
            first_Query_Received_WIP_Time: any;
            second_Query_Received_WIP_Time: any;
            third_Query_Received_WIP_Time: any;
            fourth_Query_Received_WIP_Time: any;
            fifth_Query_Received_WIP_Time: any;
            first_Typing_completed_Time: any;
            second_Typing_completed_Time: any;
            third_Typing_completed_Time: any;
            fourth_Typing_completed_Time: any;
            fifth_Typing_completed_Time: any;
            first_Verification_Picked_Time: any;
            second_Verification_Picked_Time: any;
            third_Verification_Picked_Time: any;
            fourth_Verification_Picked_Time: any;
            fifth_Verification_Picked_Time: any;
            first_Verification_Completed_Time: any;
            second_Verification_Completed_Time: any;
            third_Verification_Completed_Time: any;
            fourth_Verification_Completed_Time: any;
            fifth_Verification_Completed_Time: any;
            first_Rectify_Received: any;
            second_Rectify_Received: any;
            third_Rectify_Received: any;
            fourth_Rectify_Received: any;
            fifth_Rectify_Received: any;
            first_Rectify_Completed: any;
            second_Rectify_Completed: any;
            third_Rectify_Completed: any;
            fourth_Rectify_Completed: any;
            fifth_Rectify_Completed: any;
            first_deviation_Received: any;
            second_deviation_Received: any;
            third_deviation_Received: any;
            fourth_deviation_Received: any;
            fifth_deviation_Received: any;
            first_deviation_Completed: any;
            second_deviation_Completed: any;
            third_deviation_Completed: any;
            fourth_deviation_Completed: any;
            fifth_deviation_Completed: any;
            first_deviation_By: any;
            second_deviation_By: any;
            third_deviation_By: any;
            fourth_deviation_By: any;
            fifth_deviation_By: any;
            first_hold_By: any;
            second_hold_By: any;
            third_hold_By: any;
            fourth_hold_By: any;
            fifth_hold_By: any;
            first_hold_Time: any;
            second_hold_Time: any;
            third_hold_Time: any;
            fourth_hold_Time: any;
            fifth_hold_Time: any;
            first_unhold_By: any;
            second_unhold_By: any;
            third_unhold_By: any;
            fourth_unhold_By: any;
            fifth_unhold_By: any;
            first_unhold_Time: any;
            second_unhold_Time: any;
            third_unhold_Time: any;
            fourth_unhold_Time: any;
            fifth_unhold_Time: any;
            first_workInprogress_By: any;
            second_workInprogress_By: any;
            third_workInprogress_By: any;
            fourth_workInprogress_By: any;
            fifth_workInprogress_By: any;
        }[] = [];
        for (const fileId in groupedFiles) {
            if (groupedFiles.hasOwnProperty(fileId)) {
                const filesArray = groupedFiles[fileId];

                if (filesArray.length != 0) {
                    let file_status = filesArray[0].file_status;
                    let front_office = "00:00:00";
                    let workInProgressDiff: string = "";
                    let queryRaisedDiff: string = "";
                    let verificationCompletedDiff: string = "";
                    let s_extendedHoursDiff: string = "";
                    let p_extendedHoursDiff: string = "";
                    let v_extendedHoursDiff: string = "";
                    let extendedDiff: string = "";
                    let scan_queue: string = "";
                    let tc_queue: string = "";
                    let hold_queue: string = "";
                    let unhold_queue: string = "";
                    let pr_queue: string = "";
                    let qr_queue: string = "";
                    let vr_queue: string = "";
                    let total_waiting_time: string = "00:00:00";
                    let total_filequeue_time: string = "00:00:00";
                    let file_taken_time: string = "00:00:00";
                    let total_Vfilequeue_time: string = "00:00:00";
                    let s_extendHours: string = "00:00:00";
                    let p_extendHours: string = "00:00:00";
                    let v_extendHours: string = "00:00:00";
                    //for excel data
                    let login_date = "";
                    let login_by = "";
                    let scan_date = "";
                    let assigned_to = "";
                    let verified_by = "";
                    let taken_date = "";
                    let query_raised_dt = "";
                    let query_reiceved_date = "";
                    let typing_complited_dt = "";
                    let verification_picked_dt = "";
                    let verification_completed_dt = "";
                    let app_id = "";
                    let borrower_name = "";
                    let property_ownername = "";
                    let bank_name = "";
                    let product = "";
                    let bank_branch = "";
                    let lh_branch = "";
                    app_id = filesArray[0].app_id;
                    borrower_name = filesArray[0].borrower_name;
                    property_ownername = filesArray[0].property_ownername;
                    bank_name = filesArray[0].bank_name;
                    product = filesArray[0].product_name;
                    bank_branch = filesArray[0].bank_branch_name;
                    lh_branch = filesArray[0].lh_branch_name;
                    const queueTime: {
                        scan_time: any;
                        queryreceived_time: any;
                        fileQueueTime: any;
                        fileTakenTime: any;
                        fileVQueueTime: any;
                        typing_completed: any;
                        reassign_process: any;
                        reassign_verfication: any;
                        hold_time: any;
                        unhold_time: any;
                        total_waiting_time: any;
                    }[] = [];
                    let queryDiff = "";
                    let queryRaisedDate: any = [];
                    let queryReceivedDate: any = [];
                    if (filesArray.length >= 2) {
                        const queryRaisedDates = filesArray.filter(
                            (file) => file.reason === "Query Raised"
                        );
                        const queryReceivedDates = filesArray.filter(
                            (file) => file.reason === "Query Received"
                        );
                        for (
                            let i = 0;
                            i <
                            Math.min(queryRaisedDates.length, queryReceivedDates.length);
                            i++
                        ) {
                            queryRaisedDate.push(
                                new Date(queryRaisedDates[i].created_dt)
                            );
                            queryReceivedDate.push(
                                new Date(queryReceivedDates[i].created_dt)
                            );
                            const currentQueryDiff = getqueryTimeDiff(
                                queryRaisedDate[i],
                                queryReceivedDate[i]
                            );
                            queryDiff = addTime(queryDiff, currentQueryDiff);
                        }
                        const firstDate = new Date(filesArray[0].created_dt);
                        const secondDate = new Date(filesArray[1].created_dt);
                        login_date = filesArray[0].created_dt;
                        login_by = filesArray[0].created_by;
                        assigned_to = filesArray[0].assigned_to;
                        verified_by = filesArray[0].verified_by;
                        query_reiceved_date = filesArray[0].query_reiceved_date;
                        for (let index = 0; index < filesArray.length; index++) {
                            if (filesArray[index].reason === "Scan Completed") {
                                scan_date = filesArray[index].created_dt;
                                break;
                            }
                        }
                        for (let index = 0; index < filesArray.length; index++) {
                            if (filesArray[index].reason === "Work Inprogress") {
                                taken_date = filesArray[index].created_dt;
                                break;
                            }
                        }
                        for (let index = 0; index < filesArray.length; index++) {
                            if (filesArray[index].reason === "Typing Completed") {
                                typing_complited_dt = filesArray[index].created_dt;
                                // break;
                            }
                        }
                        for (let index = 0; index < filesArray.length; index++) {
                            if (filesArray[index].reason === "Verification Picked") {
                                verification_picked_dt = filesArray[index].created_dt;
                                // break;
                            }
                        }
                        for (let index = 0; index < filesArray.length; index++) {
                            if (filesArray[index].reason === "Verification Completed") {
                                verification_completed_dt = filesArray[index].created_dt;
                                break;
                            }
                        }
                    }
                    const frontOfficeDifferences = [""];
                    const workInProgressDifferences = [""];
                    const deviationworkInProgressDifferences = [""];
                    const deviationQueueDifferences = [""];
                    const rectificationworkInProgressDifferences = [""];
                    const rectifyQueueDifferences = [""];
                    const queryRaisedDifferences = [""];
                    const verificationCompletedDifferences = [""];
                    const extendedHoursDifferences = [""];
                    const s_extendedHoursDifferences = [""];
                    const p_extendedHoursDifferences = [""];
                    const v_extendedHoursDifferences = [""];
                    const scanWaitingTime = [""];
                    const tcWaitingTime = [""];
                    const fileQueueTime = [""];
                    const fileTakenTime = [""];
                    const queryRaisedDt = [""];
                    const fileVQueueTime = [""];
                    const holdWaitingTime = [""];
                    const unholdWaitingTime = [""];
                    const holdTime = [""];
                    const unholdTime = [""];
                    const qrWaitingTime = [""];
                    const vrWaitingTime = [""];
                    const prWaitingTime = [""];
                    const totalTimeDifference = [""];
                    let totalWaitingTime = [""];
                    let totalWipTime = "00:00:00";
                    let totalHoldTime = "00:00:00";
                    let totalUnholdTime = "00:00:00";
                    let totaldWipTime = "00:00:00";
                    let totaldQueueTime = "00:00:00";
                    let totalrectifyQueueTime = "00:00:00";
                    let totalrectifyWipTime = "00:00:00";
                    let totalQrTime = "00:00:00";
                    let totalTime = "00:00:00";
                    let totalVcTime = "00:00:00";
                    let totalExTime = "00:00:00";
                    let total_qt = "00:00:00";
                    frontOfficeDifferences.shift();
                    workInProgressDifferences.shift();
                    deviationworkInProgressDifferences.shift();
                    deviationQueueDifferences.shift();
                    rectifyQueueDifferences.shift();
                    rectificationworkInProgressDifferences.shift();
                    queryRaisedDifferences.shift();
                    verificationCompletedDifferences.shift();
                    extendedHoursDifferences.shift();
                    s_extendedHoursDifferences.shift();
                    p_extendedHoursDifferences.shift();
                    v_extendedHoursDifferences.shift();
                    scanWaitingTime.shift();
                    fileQueueTime.shift();
                    fileTakenTime.shift();
                    queryRaisedDt.shift();
                    fileVQueueTime.shift();
                    tcWaitingTime.shift();
                    qrWaitingTime.shift();
                    totalWaitingTime.shift();
                    holdWaitingTime.shift();
                    unholdWaitingTime.shift();
                    holdTime.shift();
                    unholdTime.shift();
                    prWaitingTime.shift();
                    vrWaitingTime.shift();
                    totalTimeDifference.shift();
                    let typingCompletedDates: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let verificationPickedDates: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let verificationCompleted: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let queryRaisedDates: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let queryReceivedDates: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let rectifyReceivedData: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let rectifyCompletedData: {
                        created_dt: any;
                        reason: string;
                    }[] = [];
                    let deviationReceivedData: {
                        created_dt: any;
                        reason: string;
                        created_by: string;
                    }[] = [];
                    let deviationCompletedData: {
                        created_dt: any;
                        reason: string;
                        created_by: string;
                    }[] = [];
                    let queryReceivedAfterWIP: any = [];
                    let holdData: any = [];
                    let unholdData: any = [];
                    let workInprogress: any = [];
                    for (let i = 0; i < filesArray.length; i++) {
                        const currentFile = filesArray[i];
                        if (currentFile.reason == "Query Raised") {
                            queryRaisedDates.push(currentFile);
                        }
                        if (currentFile.reason == "Query Received") {
                            queryReceivedDates.push(currentFile);
                        }
                        if (currentFile.reason == "Typing Completed") {
                            typingCompletedDates.push(currentFile);
                        }
                        if (currentFile.reason == "Verification Picked") {
                            verificationPickedDates.push(currentFile);
                        }
                        if (currentFile.reason == "Verification Completed") {
                            verificationCompleted.push(currentFile);
                        }
                        if (currentFile.reason == "Rectification Received") {
                            rectifyReceivedData.push(currentFile);
                        }
                        if (currentFile.reason == "Rectification Completed") {
                            rectifyCompletedData.push(currentFile);
                        }
                        if (currentFile.reason == "Request for Deviation") {
                            deviationReceivedData.push(currentFile);
                        }
                        if (currentFile.reason == "Deviation Completed") {
                            deviationCompletedData.push(currentFile);
                        }
                        if (currentFile.reason == "Hold") {
                            holdData.push(currentFile);
                        }
                        if (currentFile.reason == "Unhold") {
                            unholdData.push(currentFile);
                        }
                        if (currentFile.reason == "Work Inprogress") {
                            workInprogress.push(currentFile);
                        }
                    }

                    for (let i = 0; i < filesArray.length - 1; i++) {
                        const currentFile = filesArray[i];
                        const nextFile = filesArray[i + 1];

                        if (
                            filesArray[i].created_dt.substring(11) > "19:00:00" ||
                            filesArray[i].created_dt.substring(11) < "09:00:00"
                        ) {
                            const extendedDate = new Date(currentFile.created_dt);
                            const extendedNextDate = new Date(nextFile.created_dt);
                            extendedDiff = getTimeDiff(
                                extendedDate,
                                extendedNextDate
                            );
                            extendedHoursDifferences.push(extendedDiff);

                            if (currentFile.reason === "Login") {
                                const seDate = new Date(currentFile.created_dt);
                                const seNextDate = new Date(nextFile.created_dt);
                                s_extendedHoursDiff = getTimeDiff(seDate, seNextDate);
                                s_extendedHoursDifferences.push(s_extendedHoursDiff);
                            }
                            if (currentFile.reason === "Work Inprogress") {
                                const peDate = new Date(currentFile.created_dt);
                                const peNextDate = new Date(nextFile.created_dt);
                                p_extendedHoursDiff = getTimeDiff(peDate, peNextDate);
                                p_extendedHoursDifferences.push(p_extendedHoursDiff);
                            }
                            if (currentFile.reason === "Verification Picked") {
                                const veDate = new Date(currentFile.created_dt);
                                const veNextDate = new Date(nextFile.created_dt);
                                v_extendedHoursDiff = getTimeDiff(veDate, veNextDate);
                                v_extendedHoursDifferences.push(v_extendedHoursDiff);
                            }
                        }
                        if (currentFile.reason === "Scan Completed") {
                            const scanDate = new Date(currentFile.created_dt);
                            if (nextFile.reason === "Scan Completed") {
                                const typingDate = new Date(nextFile.created_dt);
                                const timeDiff = getTimeDiff(scanDate, typingDate);
                                scanWaitingTime.push(timeDiff);
                                fileQueueTime.push(timeDiff);
                                fileTakenTime.push(timeDiff);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Work Inprogress") {
                                        const typingDate = new Date(nextFile.created_dt);
                                        const timeDiff = getTimeDiff(scanDate, typingDate);
                                        scanWaitingTime.push(timeDiff);
                                        fileQueueTime.push(timeDiff);
                                        fileTakenTime.push(timeDiff);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Query Received") {
                            if (nextFile.reason === "Query Received") {
                                queryReceivedAfterWIP.push(nextFile);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Work Inprogress") {
                                        queryReceivedAfterWIP.push(nextFile);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Login") {
                            if (nextFile.reason === "Scan Completed") {
                                let front_office = getTimeDiff(currentFile.created_dt, nextFile.created_dt);
                                frontOfficeDifferences.push(front_office);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Scan Completed") {
                                        let front_office = getTimeDiff(currentFile.created_dt, nextFile.created_dt);
                                        frontOfficeDifferences.push(front_office);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Typing Completed") {
                            const tcDate = new Date(currentFile.created_dt);
                            if (nextFile.reason === "Typing Completed") {
                                const tcNextDate = new Date(nextFile.created_dt);
                                tc_queue = getTimeDiff(tcDate, tcNextDate);
                                tcWaitingTime.push(tc_queue);
                                fileVQueueTime.push(tc_queue);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Verification Picked") {
                                        const tcNextDate = new Date(nextFile.created_dt);
                                        tc_queue = getTimeDiff(tcDate, tcNextDate);
                                        tcWaitingTime.push(tc_queue);
                                        fileVQueueTime.push(tc_queue);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Hold") {
                            const holdDate = new Date(currentFile.created_dt);
                            const holdNextDate = new Date(nextFile.created_dt);
                            hold_queue = getTimeDiff(holdDate, holdNextDate);
                            holdTime.push(hold_queue);
                        }
                        if (currentFile.reason === "Unhold") {
                            const unholdDate = new Date(currentFile.created_dt);
                            const unholdNextDate = new Date(nextFile.created_dt);
                            unhold_queue = getTimeDiff(unholdDate, unholdNextDate);
                            unholdTime.push(unhold_queue);
                            fileVQueueTime.push(unhold_queue);
                        }
                        if (currentFile.reason === "Process Reassign") {
                            const prDate = new Date(currentFile.created_dt);
                            const prNextDate = new Date(nextFile.created_dt);
                            pr_queue = getTimeDiff(prDate, prNextDate);
                            prWaitingTime.push(pr_queue);
                        }
                        if (currentFile.reason === "Verification Reassign") {
                            const vrDate = new Date(currentFile.created_dt);
                            const vrNextDate = new Date(nextFile.created_dt);
                            vr_queue = getTimeDiff(vrDate, vrNextDate);
                            vrWaitingTime.push(vr_queue);
                        }
                        if (currentFile.reason === "Query Received") {
                            const qrDate = new Date(currentFile.created_dt);
                            if (nextFile.reason === "Query Received") {
                                const qrNextDate = new Date(nextFile.created_dt);
                                qr_queue = getTimeDiff(qrDate, qrNextDate);
                                qrWaitingTime.push(qr_queue);
                                fileQueueTime.push(qr_queue);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Work Inprogress") {
                                        const qrNextDate = new Date(nextFile.created_dt);
                                        qr_queue = getTimeDiff(qrDate, qrNextDate);
                                        qrWaitingTime.push(qr_queue);
                                        fileQueueTime.push(qr_queue);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Work Inprogress") {
                            const workprogressDate = new Date(currentFile.created_dt);
                            const queryraisedDate = new Date(nextFile.created_dt);
                            workInProgressDiff = getTimeDiff(
                                workprogressDate,
                                queryraisedDate
                            );
                            workInProgressDifferences.push(workInProgressDiff);
                        }
                        if (currentFile.reason === "Request for Deviation") {
                            const deviationreceiveDate = new Date(
                                currentFile.created_dt
                            );
                            if (nextFile.reason === "Request for Deviation") {
                                const deviationReceiveNextDate = new Date(
                                    nextFile.created_dt
                                );
                                let timeDiffer = getTimeDiff(
                                    deviationreceiveDate,
                                    deviationReceiveNextDate
                                );
                                deviationQueueDifferences.push(timeDiffer);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Deviation Work Inprogress") {
                                        const deviationReceiveNextDate = new Date(
                                            nextFile.created_dt
                                        );
                                        let timeDiffer = getTimeDiff(
                                            deviationreceiveDate,
                                            deviationReceiveNextDate
                                        );
                                        deviationQueueDifferences.push(timeDiffer);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Deviation Work Inprogress") {
                            const deviationreceiveDate = new Date(
                                currentFile.created_dt
                            );
                            const deviationReceiveNextDate = new Date(
                                nextFile.created_dt
                            );
                            let timeDiffer = getTimeDiff(
                                deviationreceiveDate,
                                deviationReceiveNextDate
                            );
                            deviationworkInProgressDifferences.push(timeDiffer);
                        }
                        if (currentFile.reason === "Rectification Received") {
                            const rectifyreceiveDate = new Date(
                                currentFile.created_dt
                            );
                            if (nextFile.reason === "Rectification Received") {
                                const rectifyReceiveNextDate = new Date(
                                    nextFile.created_dt
                                );
                                let timeDiffer = getTimeDiff(
                                    rectifyreceiveDate,
                                    rectifyReceiveNextDate
                                );
                                rectifyQueueDifferences.push(timeDiffer);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Rectification Work Inprogress") {
                                        const rectifyReceiveNextDate = new Date(
                                            nextFile.created_dt
                                        );
                                        let timeDiffer = getTimeDiff(
                                            rectifyreceiveDate,
                                            rectifyReceiveNextDate
                                        );
                                        rectifyQueueDifferences.push(timeDiffer);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Rectification Work Inprogress") {
                            const rectifyworkprogressDate = new Date(
                                currentFile.created_dt
                            );
                            const rectifycompleteDate = new Date(nextFile.created_dt);
                            workInProgressDiff = getTimeDiff(
                                rectifyworkprogressDate,
                                rectifycompleteDate
                            );
                            rectificationworkInProgressDifferences.push(
                                workInProgressDiff
                            );
                        }
                        if (currentFile.reason === "Query Raised") {
                            const queryRaisedDate = new Date(currentFile.created_dt);
                            if (nextFile.reason === "Query Raised") {
                                const queryraisedNextDate = new Date(nextFile.created_dt);
                                queryRaisedDiff = getTimeDiff(queryRaisedDate, queryraisedNextDate);
                                queryRaisedDifferences.push(queryRaisedDiff);
                            } else {
                                for (let j = i + 1; j < filesArray.length; j++) {
                                    const nextFile = filesArray[j];
                                    if (nextFile.reason === "Query Received") {
                                        const queryraisedNextDate = new Date(nextFile.created_dt);
                                        queryRaisedDiff = getTimeDiff(queryRaisedDate, queryraisedNextDate);
                                        queryRaisedDifferences.push(queryRaisedDiff);
                                        break;
                                    }
                                }
                            }
                        }
                        if (currentFile.reason === "Verification Picked") {
                            const vcDate = new Date(currentFile.created_dt);
                            const vcNextDate = new Date(nextFile.created_dt);
                            verificationCompletedDiff = getTimeDiff(
                                vcDate,
                                vcNextDate
                            );
                            verificationCompletedDifferences.push(
                                verificationCompletedDiff
                            );
                            for (let j = i + 1; j < filesArray.length; j++) {
                                const nextFile = filesArray[j];
                                if (nextFile.reason === "Unhold") {
                                    const pickedDate = new Date(filesArray[j + 1].created_dt);
                                    const unholdDate = new Date(nextFile.created_dt);
                                    let timeDiffer = getTimeDiff(unholdDate, pickedDate);
                                    verificationCompletedDifferences.push(timeDiffer);
                                }
                            }
                        }
                    }
                    for (let i = 0; i < frontOfficeDifferences.length; i++) {
                        front_office = getTotalTimeDiff(
                            front_office,
                            frontOfficeDifferences[i]
                        );
                    }
                    totalTimeDifference.push(front_office);
                    for (let i = 0; i < workInProgressDifferences.length; i++) {
                        totalWipTime = getTotalTimeDiff(
                            totalWipTime,
                            workInProgressDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totalWipTime);
                    for (let i = 0; i < unholdTime.length; i++) {
                        totalUnholdTime = getTotalTimeDiff(
                            totalUnholdTime,
                            unholdTime[i]
                        );
                    }
                    totalTimeDifference.push(totalUnholdTime);
                    for (let i = 0; i < holdTime.length; i++) {
                        totalHoldTime = getTotalTimeDiff(
                            totalHoldTime,
                            holdTime[i]
                        );
                    }
                    totalTimeDifference.push(totalHoldTime);
                    for (let i = 0; i < deviationQueueDifferences.length; i++) {
                        totaldQueueTime = getTotalTimeDiff(
                            totaldQueueTime,
                            deviationQueueDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totaldQueueTime);
                    for (
                        let i = 0;
                        i < deviationworkInProgressDifferences.length;
                        i++
                    ) {
                        totaldWipTime = getTotalTimeDiff(
                            totaldWipTime,
                            deviationworkInProgressDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totaldWipTime);
                    for (let i = 0; i < rectifyQueueDifferences.length; i++) {
                        totalrectifyQueueTime = getTotalTimeDiff(
                            totalrectifyQueueTime,
                            rectifyQueueDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totalrectifyQueueTime);
                    for (
                        let i = 0;
                        i < rectificationworkInProgressDifferences.length;
                        i++
                    ) {
                        totalrectifyWipTime = getTotalTimeDiff(
                            totalrectifyWipTime,
                            rectificationworkInProgressDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totalrectifyWipTime);
                    for (let i = 0; i < extendedHoursDifferences.length; i++) {
                        totalExTime = getTotalTimeDiff(
                            totalExTime,
                            extendedHoursDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totalExTime);
                    for (let i = 0; i < queryRaisedDifferences.length; i++) {
                        totalQrTime = getTotalTimeDiff(
                            totalQrTime,
                            queryRaisedDifferences[i]
                        );
                    }
                    for (
                        let i = 0;
                        i < verificationCompletedDifferences.length;
                        i++
                    ) {
                        totalVcTime = getTotalTimeDiff(
                            totalVcTime,
                            verificationCompletedDifferences[i]
                        );
                    }
                    totalTimeDifference.push(totalVcTime);
                    if (scanWaitingTime.length != 0) {
                        for (let i = 0; i < scanWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                scanWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    total_waiting_time = "00:00:00";
                    if (qrWaitingTime.length != 0) {
                        for (let i = 0; i < qrWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                qrWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    file_taken_time = "00:00:00";
                    if (fileTakenTime.length != 0) {
                        for (let i = 0; i < fileTakenTime.length; i++) {
                            file_taken_time = getTotalTimeDiff(
                                file_taken_time,
                                fileTakenTime[i]
                            );
                        }
                    } else {
                        file_taken_time = "00:00:00";
                    }
                    total_filequeue_time = "00:00:00";
                    if (fileQueueTime.length != 0) {
                        for (let i = 0; i < fileQueueTime.length; i++) {
                            total_filequeue_time = getTotalTimeDiff(
                                total_filequeue_time,
                                fileQueueTime[i]
                            );
                        }
                    } else {
                        total_filequeue_time = "00:00:00";
                    }
                    total_Vfilequeue_time = "00:00:00";
                    if (fileVQueueTime.length != 0) {
                        for (let i = 0; i < fileVQueueTime.length; i++) {
                            total_Vfilequeue_time = getTotalTimeDiff(
                                total_Vfilequeue_time,
                                fileVQueueTime[i]
                            );
                        }
                    } else {
                        total_Vfilequeue_time = "00:00:00";
                    }
                    v_extendHours = "00:00:00";
                    if (v_extendedHoursDifferences.length != 0) {
                        for (let i = 0; i < v_extendedHoursDifferences.length; i++) {
                            v_extendHours = getTotalTimeDiff(
                                v_extendHours,
                                v_extendedHoursDifferences[i]
                            );
                        }
                    } else {
                        v_extendHours = "00:00:00";
                    }
                    p_extendHours = "00:00:00";
                    if (p_extendedHoursDifferences.length != 0) {
                        for (let i = 0; i < p_extendedHoursDifferences.length; i++) {
                            p_extendHours = getTotalTimeDiff(
                                p_extendHours,
                                p_extendedHoursDifferences[i]
                            );
                        }
                    } else {
                        p_extendHours = "00:00:00";
                    }
                    s_extendHours = "00:00:00";
                    if (s_extendedHoursDifferences.length != 0) {
                        for (let i = 0; i < s_extendedHoursDifferences.length; i++) {
                            s_extendHours = getTotalTimeDiff(
                                s_extendHours,
                                s_extendedHoursDifferences[i]
                            );
                        }
                    } else {
                        s_extendHours = "00:00:00";
                    }
                    total_waiting_time = "00:00:00";
                    if (tcWaitingTime.length != 0) {
                        for (let i = 0; i < tcWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                tcWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    total_waiting_time = "00:00:00";
                    if (holdWaitingTime.length != 0) {
                        for (let i = 0; i < holdWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                holdWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    total_waiting_time = "00:00:00";
                    if (unholdWaitingTime.length != 0) {
                        for (let i = 0; i < unholdWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                unholdWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    total_waiting_time = "00:00:00";
                    if (prWaitingTime.length != 0) {
                        for (let i = 0; i < prWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                prWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    total_waiting_time = "00:00:00";
                    if (vrWaitingTime.length != 0) {
                        for (let i = 0; i < vrWaitingTime.length; i++) {
                            total_waiting_time = getTotalTimeDiff(
                                total_waiting_time,
                                vrWaitingTime[i]
                            );
                        }
                    } else {
                        total_waiting_time = "00:00:00";
                    }
                    totalWaitingTime.push(total_waiting_time);
                    queueTime.push({
                        scan_time: scanWaitingTime,
                        queryreceived_time: qrWaitingTime,
                        fileQueueTime: fileQueueTime,
                        fileTakenTime: fileTakenTime,
                        fileVQueueTime: fileVQueueTime,
                        typing_completed: tcWaitingTime,
                        reassign_process: prWaitingTime,
                        reassign_verfication: vrWaitingTime,
                        hold_time: holdWaitingTime,
                        unhold_time: unholdWaitingTime,
                        total_waiting_time: totalWaitingTime,
                    });
                    for (let j = 0; j < queueTime[0].total_waiting_time.length; j++) {
                        total_qt = getTotalTimeDiff(
                            total_qt,
                            queueTime[0].total_waiting_time[j]
                        );
                    }
                    totalTimeDifference.push(total_qt);
                    for (let i = 0; i < totalTimeDifference.length; i++) {
                        totalTime = getTotalTimeDiff(
                            totalTime,
                            totalTimeDifference[i]
                        );
                    }

                    //query raise
                    let firstQueryRaise: any;
                    let secondQueryRaise: any;
                    let thirdQueryRaise: any;
                    let fourthQueryRaise: any;
                    let fifthQueryRaise: any;
                    //query received
                    let firstqueryReceived: any;
                    let secondqueryReceived: any;
                    let thirdqueryReceived: any;
                    let fourthqueryReceived: any;
                    let fifthqueryReceived: any;
                    //typing completed
                    let firstTypingCompletedTime: any;
                    let secondTypingCompletedTime: any;
                    let thirdTypingCompletedTime: any;
                    let fourthTypingCompletedTime: any;
                    let fifthTypingCompletedTime: any;
                    //verification picked
                    let firstVerificationPickedTime: any;
                    let secondVerificationPickedTime: any;
                    let thirdVerificationPickedTime: any;
                    let fourthVerificationPickedTime: any;
                    let fifthVerificationPickedTime: any;
                    //verification completed
                    let firstVerificationCompletedTime: any;
                    let secondVerificationCompletedTime: any;
                    let thirdVerificationCompletedTime: any;
                    let fourthVerificationCompletedTime: any;
                    let fifthVerificationCompletedTime: any;
                    //query received WIP
                    let firstqueryReceivedWIPBy: any;
                    let secondqueryReceivedWIPBy: any;
                    let thirdqueryReceivedWIPBy: any;
                    let fourthqueryReceivedWIPBy: any;
                    let fifthqueryReceivedWIPBy: any;
                    //query received WIP time
                    let firstqueryReceivedWIPTime: any;
                    let secondqueryReceivedWIPTime: any;
                    let thirdqueryReceivedWIPTime: any;
                    let fourthqueryReceivedWIPTime: any;
                    let fifthqueryReceivedWIPTime: any;
                    // rectify receive
                    let firstRectifyReceived: any;
                    let secondRectifyReceived: any;
                    let thirdRectifyReceived: any;
                    let fourthRectifyReceived: any;
                    let fifthRectifyReceived: any;
                    // rectify firstRectifyCompleted
                    let firstRectifyCompleted: any;
                    let secondRectifyCompleted: any;
                    let thirdRectifyCompleted: any;
                    let fourthRectifyCompleted: any;
                    let fifthRectifyCompleted: any;
                    let firstdeviationReceived: any;
                    let seconddeviationReceived: any;
                    let thirddeviationReceived: any;
                    let fourthdeviationReceived: any;
                    let fifthdeviationReceived: any;
                    // rectify firstRectifyCompleted
                    let firstdeviationCompleted: any;
                    let seconddeviationCompleted: any;
                    let thirddeviationCompleted: any;
                    let fourthdeviationCompleted: any;
                    let fifthdeviationCompleted: any;
                    let firstdeviationBy: any;
                    let seconddeviationBy: any;
                    let thirddeviationBy: any;
                    let fourthdeviationBy: any;
                    let fifthdeviationBy: any;
                    let firstHoldBy: any;
                    let secondHoldBy: any;
                    let thirdHoldBy: any;
                    let fourthHoldBy: any;
                    let fifthHoldBy: any;
                    let firstHoldTime: any;
                    let secondHoldTime: any;
                    let thirdHoldTime: any;
                    let fourthHoldTime: any;
                    let fifthHoldTime: any;
                    let firstUnHoldBy: any;
                    let secondUnHoldBy: any;
                    let thirdUnHoldBy: any;
                    let fourthUnHoldBy: any;
                    let fifthUnHoldBy: any;
                    let firstUnHoldTime: any;
                    let secondUnHoldTime: any;
                    let thirdUnHoldTime: any;
                    let fourthUnHoldTime: any;
                    let fifthUnHoldTime: any;
                    let firstworkInprogressBy: any;
                    let secondworkInprogressBy: any;
                    let thirdworkInprogressBy: any;
                    let fourthworkInprogressBy: any;
                    let fifthworkInprogressBy: any;
                    //query raise
                    if (queryRaisedDates.length >= 1) {
                        firstQueryRaise = queryRaisedDates[0].created_dt;
                    }
                    if (queryRaisedDates.length >= 2) {
                        secondQueryRaise = queryRaisedDates[1].created_dt;
                    }
                    if (queryRaisedDates.length >= 3) {
                        thirdQueryRaise = queryRaisedDates[2].created_dt;
                    }
                    if (queryRaisedDates.length >= 4) {
                        fourthQueryRaise = queryRaisedDates[3].created_dt;
                    }
                    if (queryRaisedDates.length >= 5) {
                        fifthQueryRaise = queryRaisedDates[4].created_dt;
                    }
                    //query receive
                    if (queryReceivedDates.length >= 1) {
                        firstqueryReceived = queryReceivedDates[0].created_dt;
                    }
                    if (queryReceivedDates.length >= 2) {
                        secondqueryReceived = queryReceivedDates[1].created_dt;
                    }
                    if (queryReceivedDates.length >= 3) {
                        thirdqueryReceived = queryReceivedDates[2].created_dt;
                    }
                    if (queryReceivedDates.length >= 4) {
                        fourthqueryReceived = queryReceivedDates[3].created_dt;
                    }
                    if (queryReceivedDates.length >= 5) {
                        fifthqueryReceived = queryReceivedDates[4].created_dt;
                    }
                    //typing completed
                    if (typingCompletedDates.length >= 1) {
                        firstTypingCompletedTime = typingCompletedDates[0].created_dt;
                    }
                    if (typingCompletedDates.length >= 2) {
                        secondTypingCompletedTime = typingCompletedDates[1].created_dt;
                    }
                    if (typingCompletedDates.length >= 3) {
                        thirdTypingCompletedTime = typingCompletedDates[2].created_dt;
                    }
                    if (typingCompletedDates.length >= 4) {
                        fourthTypingCompletedTime = typingCompletedDates[3].created_dt;
                    }
                    if (typingCompletedDates.length >= 5) {
                        fifthTypingCompletedTime = typingCompletedDates[4].created_dt;
                    }
                    //Verification picked
                    if (verificationPickedDates.length >= 1) {
                        firstVerificationPickedTime = verificationPickedDates[0].created_dt;
                    }
                    if (verificationPickedDates.length >= 2) {
                        secondVerificationPickedTime = verificationPickedDates[1].created_dt;
                    }
                    if (verificationPickedDates.length >= 3) {
                        thirdVerificationPickedTime = verificationPickedDates[2].created_dt;
                    }
                    if (verificationPickedDates.length >= 4) {
                        fourthVerificationPickedTime = verificationPickedDates[3].created_dt;
                    }
                    if (verificationPickedDates.length >= 5) {
                        fifthVerificationPickedTime = verificationPickedDates[4].created_dt;
                    }
                    //Verification completed
                    if (verificationCompleted.length >= 1) {
                        firstVerificationCompletedTime = verificationCompleted[0].created_dt;
                    }
                    if (verificationCompleted.length >= 2) {
                        secondVerificationCompletedTime = verificationCompleted[1].created_dt;
                    }
                    if (verificationCompleted.length >= 3) {
                        thirdVerificationCompletedTime = verificationCompleted[2].created_dt;
                    }
                    if (verificationCompleted.length >= 4) {
                        fourthVerificationCompletedTime = verificationCompleted[3].created_dt;
                    }
                    if (verificationCompleted.length >= 5) {
                        fifthVerificationCompletedTime = verificationCompleted[4].created_dt;
                    }
                    //query receive after WIP
                    if (queryReceivedAfterWIP.length >= 1) {
                        firstqueryReceivedWIPBy = queryReceivedAfterWIP[0].created_by;
                    }
                    if (queryReceivedAfterWIP.length >= 2) {
                        secondqueryReceivedWIPBy = queryReceivedAfterWIP[1].created_by;
                    }
                    if (queryReceivedAfterWIP.length >= 3) {
                        thirdqueryReceivedWIPBy = queryReceivedAfterWIP[2].created_by;
                    }
                    if (queryReceivedAfterWIP.length >= 4) {
                        fourthqueryReceivedWIPBy = queryReceivedAfterWIP[3].created_by;
                    }
                    if (queryReceivedAfterWIP.length >= 5) {
                        fifthqueryReceivedWIPBy = queryReceivedAfterWIP[4].created_by;
                    }
                    //query receive after WIP time
                    if (queryReceivedAfterWIP.length >= 1) {
                        firstqueryReceivedWIPTime = queryReceivedAfterWIP[0].created_dt;
                    }
                    if (queryReceivedAfterWIP.length >= 2) {
                        secondqueryReceivedWIPTime =
                            queryReceivedAfterWIP[1].created_dt;
                    }
                    if (queryReceivedAfterWIP.length >= 3) {
                        thirdqueryReceivedWIPTime = queryReceivedAfterWIP[2].created_dt;
                    }
                    if (queryReceivedAfterWIP.length >= 4) {
                        fourthqueryReceivedWIPTime =
                            queryReceivedAfterWIP[3].created_dt;
                    }
                    if (queryReceivedAfterWIP.length >= 5) {
                        fifthqueryReceivedWIPTime = queryReceivedAfterWIP[4].created_dt;
                    }
                    //get five rectify Received Date
                    if (rectifyReceivedData.length >= 1) {
                        firstRectifyReceived = rectifyReceivedData[0].created_dt;
                    }
                    if (rectifyReceivedData.length >= 2) {
                        secondRectifyReceived = rectifyReceivedData[1].created_dt;
                    }
                    if (rectifyReceivedData.length >= 3) {
                        thirdRectifyReceived = rectifyReceivedData[2].created_dt;
                    }
                    if (rectifyReceivedData.length >= 4) {
                        fourthRectifyReceived = rectifyReceivedData[3].created_dt;
                    }
                    if (rectifyReceivedData.length >= 5) {
                        fifthRectifyReceived = rectifyReceivedData[4].created_dt;
                    }
                    //get five rectify Completed Date
                    if (rectifyCompletedData.length >= 1) {
                        firstRectifyCompleted = rectifyCompletedData[0].created_dt;
                    }
                    if (rectifyCompletedData.length >= 2) {
                        secondRectifyCompleted = rectifyCompletedData[1].created_dt;
                    }
                    if (rectifyCompletedData.length >= 3) {
                        thirdRectifyCompleted = rectifyCompletedData[2].created_dt;
                    }
                    if (rectifyCompletedData.length >= 4) {
                        fourthRectifyCompleted = rectifyCompletedData[3].created_dt;
                    }
                    if (rectifyCompletedData.length >= 5) {
                        fifthRectifyCompleted = rectifyCompletedData[4].created_dt;
                    }
                    //get deviation
                    if (deviationReceivedData.length >= 1) {
                        firstdeviationReceived = deviationReceivedData[0].created_dt;
                    }
                    if (deviationReceivedData.length >= 2) {
                        seconddeviationReceived = deviationReceivedData[1].created_dt;
                    }
                    if (deviationReceivedData.length >= 3) {
                        thirddeviationReceived = deviationReceivedData[2].created_dt;
                    }
                    if (deviationReceivedData.length >= 4) {
                        fourthdeviationReceived = deviationReceivedData[3].created_dt;
                    }
                    if (deviationReceivedData.length >= 5) {
                        fifthdeviationReceived = deviationReceivedData[4].created_dt;
                    }
                    //get five deviation Completed Date
                    if (deviationCompletedData.length >= 1) {
                        firstdeviationCompleted = deviationCompletedData[0].created_dt;
                    }
                    if (deviationCompletedData.length >= 2) {
                        seconddeviationCompleted = deviationCompletedData[1].created_dt;
                    }
                    if (deviationCompletedData.length >= 3) {
                        thirddeviationCompleted = deviationCompletedData[2].created_dt;
                    }
                    if (deviationCompletedData.length >= 4) {
                        fourthdeviationCompleted = deviationCompletedData[3].created_dt;
                    }
                    if (deviationCompletedData.length >= 5) {
                        fifthdeviationCompleted = deviationCompletedData[4].created_dt;
                    }
                    //get five deviation by
                    if (deviationCompletedData.length >= 1) {
                        firstdeviationBy = deviationCompletedData[0].created_by;
                    }
                    if (deviationCompletedData.length >= 2) {
                        seconddeviationBy = deviationCompletedData[1].created_by;
                    }
                    if (deviationCompletedData.length >= 3) {
                        thirddeviationBy = deviationCompletedData[2].created_by;
                    }
                    if (deviationCompletedData.length >= 4) {
                        fourthdeviationBy = deviationCompletedData[3].created_by;
                    }
                    if (deviationCompletedData.length >= 5) {
                        fifthdeviationBy = deviationCompletedData[4].created_by;
                    }
                    //get five holdData by
                    if (holdData.length >= 1) {
                        firstHoldBy = holdData[0].created_by;
                    }
                    if (holdData.length >= 2) {
                        secondHoldBy = holdData[1].created_by;
                    }
                    if (holdData.length >= 3) {
                        thirdHoldBy = holdData[2].created_by;
                    }
                    if (holdData.length >= 4) {
                        fourthHoldBy = holdData[3].created_by;
                    }
                    if (holdData.length >= 5) {
                        fifthHoldBy = holdData[4].created_by;
                    }
                    //get five holdData time
                    if (holdData.length >= 1) {
                        firstHoldTime = holdData[0].created_dt;
                    }
                    if (holdData.length >= 2) {
                        secondHoldTime = holdData[1].created_dt;
                    }
                    if (holdData.length >= 3) {
                        thirdHoldTime = holdData[2].created_dt;
                    }
                    if (holdData.length >= 4) {
                        fourthHoldTime = holdData[3].created_dt;
                    }
                    if (holdData.length >= 5) {
                        fifthHoldTime = holdData[4].created_dt;
                    }
                    //get five unholdData by
                    if (unholdData.length >= 1) {
                        firstUnHoldBy = unholdData[0].created_by;
                    }
                    if (unholdData.length >= 2) {
                        secondUnHoldBy = unholdData[1].created_by;
                    }
                    if (unholdData.length >= 3) {
                        thirdUnHoldBy = unholdData[2].created_by;
                    }
                    if (unholdData.length >= 4) {
                        fourthUnHoldBy = unholdData[3].created_by;
                    }
                    if (unholdData.length >= 5) {
                        fifthUnHoldBy = unholdData[4].created_by;
                    }
                    //get five unholdData time
                    if (unholdData.length >= 1) {
                        firstUnHoldTime = unholdData[0].created_dt;
                    }
                    if (unholdData.length >= 2) {
                        secondUnHoldTime = unholdData[1].created_dt;
                    }
                    if (unholdData.length >= 3) {
                        thirdUnHoldTime = unholdData[2].created_dt;
                    }
                    if (unholdData.length >= 4) {
                        fourthUnHoldTime = unholdData[3].created_dt;
                    }
                    if (unholdData.length >= 5) {
                        fifthUnHoldTime = unholdData[4].created_dt;
                    }
                    //get five workInprogress by
                    if (workInprogress.length >= 1) {
                        firstworkInprogressBy = workInprogress[0].created_by;
                    }
                    if (workInprogress.length >= 2) {
                        secondworkInprogressBy = workInprogress[1].created_by;
                    }
                    if (workInprogress.length >= 3) {
                        thirdworkInprogressBy = workInprogress[2].created_by;
                    }
                    if (workInprogress.length >= 4) {
                        fourthworkInprogressBy = workInprogress[3].created_by;
                    }
                    if (workInprogress.length >= 5) {
                        fifthworkInprogressBy = workInprogress[4].created_by;
                    }
                    //get last query raised data
                    let get_last_query_raised_dt = queryRaisedDates.reverse();
                    if (get_last_query_raised_dt.length > 0) {
                        query_raised_dt = get_last_query_raised_dt[0].created_dt;
                    }
                    let get_last_query_received_dt = queryReceivedDates.reverse();
                    if (get_last_query_received_dt.length > 0) {
                        query_reiceved_date = get_last_query_received_dt[0].created_dt;
                    }
                    let total_Ex_Time: string = "00:00:00";
                    total_Ex_Time = formatDuration(
                        parseDuration(s_extendHours) +
                        parseDuration(p_extendHours) +
                        parseDuration(v_extendHours)
                    );
                    timeDifferences.push({
                        file_id: fileId,
                        file_status: file_status,
                        login_date: login_date,
                        query_diff: queryDiff,
                        queryRaisedDate: queryRaisedDate,
                        queryReceivedDate: queryReceivedDate,
                        login_by: login_by,
                        scan_date: scan_date,
                        assigned_to: assigned_to,
                        verified_by: verified_by,
                        taken_date: taken_date,
                        query_raised_dt: query_raised_dt,
                        // query raised date
                        first_Query_Raise: firstQueryRaise,
                        second_Query_Raise: secondQueryRaise,
                        third_Query_Raise: thirdQueryRaise,
                        foutth_Query_Raise: fourthQueryRaise,
                        fifth_Query_Raise: fifthQueryRaise,
                        // query received date
                        first_Query_Received: firstqueryReceived,
                        second_Query_Received: secondqueryReceived,
                        third_Query_Received: thirdqueryReceived,
                        fourth_Query_Received: fourthqueryReceived,
                        fifth_Query_Received: fifthqueryReceived,
                        // query received WIP
                        first_Query_Received_WIP_By: getNameById(
                            firstqueryReceivedWIPBy
                        ),
                        second_Query_Received_WIP_By: getNameById(
                            secondqueryReceivedWIPBy
                        ),
                        third_Query_Received_WIP_By: getNameById(
                            thirdqueryReceivedWIPBy
                        ),
                        fourth_Query_Received_WIP_By: getNameById(
                            fourthqueryReceivedWIPBy
                        ),
                        fifth_Query_Received_WIP_By: getNameById(
                            fifthqueryReceivedWIPBy
                        ),
                        // query received WIP time
                        first_Query_Received_WIP_Time: firstqueryReceivedWIPTime,
                        second_Query_Received_WIP_Time: secondqueryReceivedWIPTime,
                        third_Query_Received_WIP_Time: thirdqueryReceivedWIPTime,
                        fourth_Query_Received_WIP_Time: fourthqueryReceivedWIPTime,
                        fifth_Query_Received_WIP_Time: fifthqueryReceivedWIPTime,
                        // typing completed date
                        first_Typing_completed_Time: firstTypingCompletedTime,
                        second_Typing_completed_Time: secondTypingCompletedTime,
                        third_Typing_completed_Time: thirdTypingCompletedTime,
                        fourth_Typing_completed_Time: fourthTypingCompletedTime,
                        fifth_Typing_completed_Time: fifthTypingCompletedTime,
                        // verification picked
                        first_Verification_Picked_Time: firstVerificationPickedTime,
                        second_Verification_Picked_Time: secondVerificationPickedTime,
                        third_Verification_Picked_Time: thirdVerificationPickedTime,
                        fourth_Verification_Picked_Time: fourthVerificationPickedTime,
                        fifth_Verification_Picked_Time: fifthVerificationPickedTime,
                        // verification completed
                        first_Verification_Completed_Time: firstVerificationCompletedTime,
                        second_Verification_Completed_Time: secondVerificationCompletedTime,
                        third_Verification_Completed_Time: thirdVerificationCompletedTime,
                        fourth_Verification_Completed_Time: fourthVerificationCompletedTime,
                        fifth_Verification_Completed_Time: fifthVerificationCompletedTime,
                        // rectify received data
                        first_Rectify_Received: firstRectifyReceived,
                        second_Rectify_Received: secondRectifyReceived,
                        third_Rectify_Received: thirdRectifyReceived,
                        fourth_Rectify_Received: fourthRectifyReceived,
                        fifth_Rectify_Received: fifthRectifyReceived,
                        //
                        // rectify Completed data
                        first_Rectify_Completed: firstRectifyCompleted,
                        second_Rectify_Completed: secondRectifyCompleted,
                        third_Rectify_Completed: thirdRectifyCompleted,
                        fourth_Rectify_Completed: fourthRectifyCompleted,
                        fifth_Rectify_Completed: fifthRectifyCompleted,
                        //deviation received data
                        first_deviation_Received: firstdeviationReceived,
                        second_deviation_Received: seconddeviationReceived,
                        third_deviation_Received: thirddeviationReceived,
                        fourth_deviation_Received: fourthdeviationReceived,
                        fifth_deviation_Received: fifthdeviationReceived,
                        //
                        // deviation Completed data
                        first_deviation_Completed: firstdeviationCompleted,
                        second_deviation_Completed: seconddeviationCompleted,
                        third_deviation_Completed: thirddeviationCompleted,
                        fourth_deviation_Completed: fourthdeviationCompleted,
                        fifth_deviation_Completed: fifthdeviationCompleted,
                        //
                        // deviation By
                        first_deviation_By: getNameById(firstdeviationBy),
                        second_deviation_By: getNameById(seconddeviationBy),
                        third_deviation_By: getNameById(thirddeviationBy),
                        fourth_deviation_By: getNameById(fourthdeviationBy),
                        fifth_deviation_By: getNameById(fifthdeviationBy),
                        //
                        // hold By
                        first_hold_By: getNameById(firstHoldBy),
                        second_hold_By: getNameById(secondHoldBy),
                        third_hold_By: getNameById(thirdHoldBy),
                        fourth_hold_By: getNameById(fourthHoldBy),
                        fifth_hold_By: getNameById(fifthHoldBy),
                        //
                        // hold time
                        first_hold_Time: firstHoldTime,
                        second_hold_Time: secondHoldTime,
                        third_hold_Time: thirdHoldTime,
                        fourth_hold_Time: fourthHoldTime,
                        fifth_hold_Time: fifthHoldTime,
                        //
                        // unhold By
                        first_unhold_By: getNameById(firstUnHoldBy),
                        second_unhold_By: getNameById(secondUnHoldBy),
                        third_unhold_By: getNameById(thirdUnHoldBy),
                        fourth_unhold_By: getNameById(fourthUnHoldBy),
                        fifth_unhold_By: getNameById(fifthUnHoldBy),
                        //
                        // unhold time
                        first_unhold_Time: firstUnHoldTime,
                        second_unhold_Time: secondUnHoldTime,
                        third_unhold_Time: thirdUnHoldTime,
                        fourth_unhold_Time: fourthUnHoldTime,
                        fifth_unhold_Time: fifthUnHoldTime,
                        //
                        // unhold By
                        first_workInprogress_By: getNameById(
                            firstworkInprogressBy
                        ),
                        second_workInprogress_By: getNameById(
                            secondworkInprogressBy
                        ),
                        third_workInprogress_By: getNameById(
                            thirdworkInprogressBy
                        ),
                        fourth_workInprogress_By: getNameById(
                            fourthworkInprogressBy
                        ),
                        fifth_workInprogress_By: getNameById(
                            fifthworkInprogressBy
                        ),
                        //
                        query_reiceved_date: query_reiceved_date,
                        typing_complited_dt: typing_complited_dt,
                        verification_picked_dt: verification_picked_dt,
                        verification_completed_dt: verification_completed_dt,
                        front_office: front_office,
                        work_in_progress: workInProgressDifferences,
                        rectify_queue_time: rectifyQueueDifferences,
                        rectify_work_in_progress:
                            rectificationworkInProgressDifferences,
                        d_work_in_progress: deviationworkInProgressDifferences,
                        d_queue_time: deviationQueueDifferences,
                        query_raised: queryRaisedDifferences,
                        verification_completed: verificationCompletedDifferences,
                        total_wip_file: totalWipTime,
                        total_hold_time: totalHoldTime,
                        total_unhold_time: totalUnholdTime,
                        total_rectify_queue_time: totalrectifyQueueTime,
                        total_rectify_wip_file: totalrectifyWipTime,
                        total_dwip_file: totaldWipTime,
                        total_dqueue_time: totaldQueueTime,
                        total_qr_file: totalQrTime,
                        total_vc_file: totalVcTime,
                        total_ex_file: total_Ex_Time,
                        total_filequeue_time: total_filequeue_time,
                        file_taken_time: file_taken_time,
                        total_Vfilequeue_time: total_Vfilequeue_time,
                        s_extendHours: s_extendHours,
                        p_extendHours: p_extendHours,
                        v_extendHours: v_extendHours,
                        s_Hours: s_extendedHoursDifferences,
                        p_Hours: p_extendedHoursDifferences,
                        v_Hours: v_extendedHoursDifferences,
                        total_queue_file: total_qt,
                        total_time: totalTime,
                        extended_hours: extendedHoursDifferences,
                        queueTime: queueTime,
                        app_id: app_id,
                        borrower_name: borrower_name,
                        property_ownername: property_ownername,
                        bank_name: bank_name,
                        product: product,
                        bank_branch: bank_branch,
                        lh_branch: lh_branch,
                    });
                }
            }
        }
        return timeDifferences;
    }
    return calculate();
};

export function getTimeDiff(date1, date2) {
    const diff = moment(date2).diff(moment(date1));
    const duration = moment.duration(diff);
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
};

// getTimeDiff function
export function getqueryTimeDiff(date1: any, date2: any) {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    const diff = momentDate2.diff(momentDate1);
    const duration = moment.duration(diff);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export function addTime(time1: string, time2: string) {
    const momentTime1 = moment.duration(time1);
    const momentTime2 = moment.duration(time2);
    const totalDuration = momentTime1.add(momentTime2);
    const hours = Math.floor(totalDuration.asHours());
    const minutes = Math.floor(totalDuration.asMinutes()) % 60;
    const seconds = Math.floor(totalDuration.asSeconds()) % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export function getTotalTimeDiff(time1, time2) {
    const moment1 = moment.duration(time1);
    const moment2 = moment.duration(time2);
    const totalDuration = moment1.add(moment2);
    // Get the hours, minutes, and seconds from the total duration
    const hours = Math.floor(totalDuration.asHours());
    const minutes = totalDuration.minutes();
    const seconds = totalDuration.seconds();
    // Format the result as "HH:mm:ss"
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
};

// Function to parse a duration string in "HH:mm:ss" format and return the total number of seconds
export function parseDuration(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}
// Function to format a duration given the total number of seconds into "HH:mm:ss" format
export function formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(
        seconds
    )}`;
}
// Function to pad a single digit number with a leading zero
export function padZero(num: number): string {
    return num.toString().padStart(2, "0");
}