import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import AssignBankUser from "./AssignBankUser";
import FileSearchModal from "./FileSearchModal";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  MenuItem
} from '@material-ui/core';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";

declare var $: any;
interface AllProps {
  history: any;
}
class ManageQueryReceivedFiles extends React.Component<AllProps> {
  limitList = [20, 40, 60]
  state = {
    selectVal: false,
    outreason: "",
    files: [],
    fileToBeAssigned: 0,
    isFileRefresh: false,
    pageNumber: 1,
    rowCount: this.limitList[0],
    totalCount: 0,
    isLoading: false
  };
  componentDidMount() {
    this.getFiles(this.state.pageNumber, this.state.rowCount);
    this.getOutTime();
    this.ouTime();
  }

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }

    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent
      }
      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          console.log("76", response);
          this.setState({ isLoading: false });
          // sessionStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          const query_status: any = localStorage.getItem("query_raised_status");
          const file_completed = localStorage.getItem("file_completed_status");
          if (process_officer_id == user_id && take_file != "" && take_file === fileid1 && query_status == "false" && file_completed == "false") {
            this.fileout(fileid1);
          } else {
            sessionStorage.removeItem('currentfileId');
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
          }
        }).catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('currentfileId');
        localStorage.removeItem("query_raised_status");
        localStorage.removeItem("file_completed_status");
        // sessionStorage.removeItem("takenfile_id");
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getFiles = (page_id, row_count) => {
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "get_by_status.php", { status: 8, userId, page_id, row_count })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        const totalCount = response.count;
        // if (files && files.length > 0) {
        //   files.forEach((file, index) => {
        //     Object.keys(file).forEach(fileKey => {
        //       file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
        //     })
        //     file.index = index + 1;
        //   })
        // }
        this.setState({ files, totalCount, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeAssigned: modal.id });
  };
  goToFileDashboard = id => {
    const url = "/query-received-files";
    localStorage.setItem("url", url);
    let obj = { file_id: id }
    localStorage.setItem("thisPopup", JSON.stringify(obj))
    sessionStorage.setItem("currentfileId", JSON.stringify(id));
    this.props.history.push("/file-dashboard/" + id);
  };
  fileSearch = modal => {
    this.setState({ isFileRefresh: false })
    callApi("POST", "file_search.php", modal)
      .then(res => res.data)
      .then(response => {
        const files: any = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((file: any, index: number) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
            files.push(file)
          })
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      })
  }

  GotoWorkFile = (id) => {
    const url = "/query-received-files";
    localStorage.setItem("url", url);
    let obj = { file_id: id };
    localStorage.setItem("thisPopup", JSON.stringify(obj));
    sessionStorage.setItem("currentfileId", JSON.stringify(id));
    const user: any = sessionStorage.getItem("user");
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    this.props.history.push("/work-file/7/" + id);
  };

  handlePageLimit = (event) => {
    const rowCount = event.target.value;
    const pageNumber = 1;
    this.setState({ pageNumber, rowCount });
    this.getFiles(pageNumber, rowCount);
  }
  handlePagination = (event, value) => {
    this.setState({ pageNumber: value });
    this.getFiles(value, this.state.rowCount);
  }
  render() {
    const columns = [
      {
        header: 'ID',
        key: 'id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.index}</td>
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              <a
                className="dropdown-item"
                onClick={() =>
                  this.assignFile(file)
                }
                data-toggle="modal"
                data-target="#assign-modal"
                data-whatever="@mdo"
              >
                Reassign
              </a>
              {/* <Link
                to={
                  "/work-file/7/" + file.id
                }
                className="dropdown-item"
              >
                Work File
              </Link> */}
              <button
                onClick={() => this.GotoWorkFile(file.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Work File
              </button>
            </div>
          </div>
        }
      },
      {
        header: 'File ID',
        key: 'file_id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.file_id}</td>
      },
      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.bank_name}</td>
      },
      {
        header: 'Bank Branch Name',
        key: 'branch_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.branch_name}</td>
      },
      {
        header: 'Property Owner Name',
        key: 'property_ownername',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.property_ownername}</td>
      },
      {
        header: 'Login By',
        key: 'login_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.login_by}</td>
      },
      {
        header: 'Process Officer Name',
        key: 'assigned_to',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.assigned_to}</td>
      },
      {
        header: 'Created Date',
        key: 'date_time',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id)
        }>{file.date_time}</td>
      }
    ];
    const data = this.state.files;
    const { selectVal } = this.state;
    const noOfPages = Math.ceil(this.state.totalCount / this.state.rowCount);
    // const user: any = sessionStorage.getItem("user");
    // const role_id = user ? JSON.parse(user)["role_id"] : "";
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="file-status-header">Work In Progress Files Manage</h5>
                          <div className="file-search-button"><button
                            type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#file-search-modal"
                          >
                            File Search
                          </button>
                          </div>
                        </div>
                        <div className="card-body">
                          {/* {this.state.isFileRefresh &&
                            <ReactFlexyTable className="inprogress-table" data={data} columns={columns} sortable globalSearch />
                          } */}
                          {this.state.isFileRefresh &&
                            <>
                              <TableContainer className="table-container">
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell >S.NO</TableCell>
                                      <TableCell>Actions</TableCell>
                                      <TableCell>File ID</TableCell>
                                      <TableCell>Bank Name</TableCell>
                                      <TableCell>Bank Branch Name</TableCell>
                                      <TableCell>Property Owner Name</TableCell>
                                      <TableCell>Law Hands Branch</TableCell>
                                      <TableCell>Login By</TableCell>
                                      <TableCell>To Follow</TableCell>
                                      <TableCell>Created Date</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data && data.length > 0 && data.map((file: any, index) => {
                                      const { id, file_id, bank_name, branch_name, property_ownername, law_hand_branch, login_by, assigned_to, date_time } = file;
                                      const props = { onClick: () => this.goToFileDashboard(id) }
                                      const serialNumber = this.state.rowCount * (this.state.pageNumber - 1) + index + 1
                                      return (
                                        <TableRow hover tabIndex={-1} key={index} className="cursor-pointer">
                                          <TableCell className="index-table-cell" {...props}>{serialNumber}</TableCell>
                                          <TableCell>
                                            <div className="btn-group mb-2 mr-2">
                                              <button type="button" className="btn btn-primary">
                                                Action
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <span className="sr-only">Toggle Dropdown</span>
                                              </button>
                                              <div
                                                className="dropdown-menu"
                                                x-placement="top-start"
                                                style={{
                                                  position: "absolute",
                                                  top: "0px",
                                                  left: "0px",
                                                  transform:
                                                    "translate3d(88px, -2px, 0px)"
                                                }}
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    this.assignFile(file)
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#assign-modal"
                                                  data-whatever="@mdo"
                                                >
                                                  Reassign
                                                </a>
                                                <button
                                                  onClick={() => this.GotoWorkFile(file.id)}
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                    display: "block",
                                                    margin: "auto",
                                                  }}
                                                >
                                                  Work File
                                                </button>
                                                {/* <Link to={`/work-file/7/${id}`} className="dropdown-item">Work File</Link> */}
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell {...props}>{file_id}</TableCell>
                                          <TableCell {...props}>{bank_name}</TableCell>
                                          <TableCell {...props}>{branch_name}</TableCell>
                                          <TableCell {...props}>{property_ownername}</TableCell>
                                          <TableCell {...props}>{law_hand_branch}</TableCell>
                                          <TableCell {...props}>{login_by}</TableCell>
                                          <TableCell {...props}>{assigned_to}</TableCell>
                                          <TableCell {...props}>{date_time}</TableCell>
                                        </TableRow>
                                      )
                                    })
                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <div className="pagination-container">
                                <div>
                                  <span>Show</span>
                                  <Select className="select-container" variant="outlined" value={this.state.rowCount} onChange={e => this.handlePageLimit(e)}>
                                    {this.limitList.map((limitValue, index) => (
                                      <MenuItem key={index} value={limitValue}>
                                        {limitValue}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <span>Rows</span>
                                </div>
                                {noOfPages > 1 && (
                                  <Pagination
                                    count={noOfPages}
                                    defaultPage={this.state.pageNumber}
                                    onChange={this.handlePagination}
                                    showFirstButton
                                    showLastButton
                                  />
                                )}
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}
                  disabled={this.state.isLoading}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
        <AssignBankUser
          fileToBeAssigned={this.state.fileToBeAssigned}
          getFiles={this.getFiles}
          assignedFrom={"inprogress"}
        />
        <FileSearchModal fileStatus={2} searchFileCallback={(data) => this.fileSearch(data)} />
      </section>
    );
  }
}
export default ManageQueryReceivedFiles;
