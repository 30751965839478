import * as React from "react";
import { callApi } from "../../utils/api";
import MaskedInput from "react-text-mask";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import FileSearchModal from "../file/FileSearchModal";
import { PendingFile } from "../file/ManagePendingFile";
import { User } from "../user/ManageUser";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { V } from "jointjs";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
    id: any;
}

class Deviationreport extends React.Component<AllProps> {
    state = {
        deviation: "",
        Manager: false,
        file_id: "",
        banks: [],
        selectVal: false,
        setResponse: true,
        myJSON: '',
        lookup: '',
        fileId: '',
        sampleId: "",
        id: "",
        fromDate: "",
        toDate: "",
        files: [],
        fileNo: '',
        lawhandsBranches: [],
        lawhands_branch: "",
        assigned_to: "",
        users: [],
        total: '',
        green: '',
        red: '',
        OutTime: 0,
        inTime: 0,
        inreason: "",
        outreason: "",
        bank_name: "",
        products: [],
        filteredProduct: [],
        product_id: "",
        isLoading: false
    };
    obj: any;
    table: any;
    componentDidMount() {
        // this.reGetvalue()
        this.getLawHandsBranches()
        this.getUsers();
        this.getOutTime();
        this.ouTime();
        this.getBanks();
        this.listBankproduct();
    }
    listBankproduct = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "bank_product_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                this.setState({ products: files, filteredProduct: files });
            })
            .catch(err => {
                console.log(err);
            });
    };
    deviationSubmit = (id: any) => {
        this.setState({ file_id: id });
        $("#deviation-reason").modal("show");
    }
    SubmitReason = () => {
        const { deviation } = this.state;
        if (deviation.includes("Accept") || deviation.includes("accepted") || deviation.includes("accept") || deviation.includes("success") || deviation.includes("Success")) {
            let obj = {
                "file_id": this.state.file_id,
                "review": this.state.deviation,
                "status": 1
            }
            callApi("POST", "report_eod_insert.php", obj)
                .then(res => res.data)
                .then(result => {
                    this.setState({ banks: result.data });
                    $("#deviation-reason").modal("hide");
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            let obj = {
                "file_id": this.state.file_id,
                "review": this.state.deviation,
                "status": 0
            }
            callApi("POST", "report_eod_insert.php", obj)
                .then(res => res.data)
                .then(result => {
                    this.setState({ banks: result.data });
                    $("#deviation-reason").modal("hide");
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }
    getBanks() {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ banks: result.data });
            })
            .catch(err => {
                console.log(err);
            });
    }


    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (sessionStorage.getItem("currentfileId")) {
            $("#deviation-reason").modal("show");
        }
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");
        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", JSON.parse(intime));
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);

        let obj = {
            "file_id": JSON.parse(fileid1),
            "user_id": JSON.parse(userid1),
            "in_reason": JSON.parse(inreason),
            "out_reason": this.state.outreason,
            "intime": JSON.parse(intime),
            "outtime": JSON.parse(outtime),
            "insert_date": JSON.parse(outdate),
            "total_seconds": secondsSpent
        }

        // console.log("72",obj);
        callApi("POST", "process_eod_insert.php", obj)
            .then(res => res.data)
            .then(response => {
                this.setState({ isLoading: false });
                console.log("76", response);
                // sessionStorage.removeItem("currentfileId");
                localStorage.removeItem("in_reason");
                localStorage.removeItem("outreason");
                localStorage.removeItem("IntimeGet");
                localStorage.removeItem("OutDateGet");
                $("#out_reason").modal("hide");
                $("#another-reason").modal("hide");
                ToastsStore.success("Your Reason Saved Successfully");
                let process_officerId: any = sessionStorage.getItem("process_office_id");
                let process_officer_id = JSON.parse(process_officerId);
                const userid1: any = sessionStorage.getItem("user1");
                let user_id = JSON.parse(userid1);
                const take_file: any = sessionStorage.getItem("takenfile_id");
                const user: any = sessionStorage.getItem("user");
                const getfileId: any = sessionStorage.getItem("currentfileId");
                if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                    this.fileout(fileid1);
                } else {
                    sessionStorage.removeItem('currentfileId');
                }
            }).catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                ToastsStore.error("Your Reason Not Stored");
            })
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {

        this.setState({ [e.target.name]: e.target.value, selectVal: false });

    };
    // reGetvalue = () => {
    //     let obj = localStorage.getItem("EodReport");
    //     console.log("55", obj);
    //     callApi("POST", "en_report.php", obj)
    //         .then(res => {
    //             let data = [];
    //             localStorage.removeItem("EodReport");
    //             console.log(localStorage.clear());
    //             if (res.data && res.data.responseJson && res.data.responseJson.data) {
    //                 data = res.data.responseJson.data;
    //             }
    //             this.setState({ files: data ? data : [] });
    //             // this.props.history.push("/entry-tracking-report" + this.state.id);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             this.setState({ files: [] });
    //         })
    // }
    getUsers = () => {
        callApi("GET", "user_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };

    goToFileDashboard = fileName => {
        const url = "/bank-wise-report";
        localStorage.setItem("url", url);
        let obj = { "file_id": fileName }
        localStorage.setItem("thisPopup", JSON.stringify(obj))
        sessionStorage.setItem("currentfileId", JSON.stringify(fileName));
        this.props.history.push("/file-dashboard/" + fileName);
    };

    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data });


            })
            .catch(err => {
                console.log(err);
            });
    }


    getEodTableData = () => {
        let obj: any = {
            id: this.state.id,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            employee_id: this.state.assigned_to,
            lawhands_branch: this.state.lawhands_branch,
            bankname: this.state.bank_name,
            product_id: this.state.product_id,
        };
        console.log(this.state);

        localStorage.setItem("EodReport", JSON.stringify(obj));

        callApi("POST", "deviation_report.php", obj)
            .then((res: any) => res.data)
            .then(response => {
                var visitors = response.data;
                let data = [];
                this.setState({ files: visitors });
                visitors.forEach(element => {
                    console.log("element", element.query);

                });
                // this.setState({ files: data ? data : [] });
                if ($.fn.dataTable.isDataTable('#processeodTable')) {
                    this.table = $('#processeodTable').DataTable({
                        paging: false,
                        dom: 'Bfrtip',
                        buttons: [{ extend: 'excel', text: "Download As Excel", title: `Over All Growth Report ${this.state.fromDate} To ${this.state.toDate} ` }]
                    });

                }
                else {
                    this.table = $('#processeodTable').DataTable({
                        paging: false,
                        dom: 'Bfrtip',
                        buttons: [{ extend: 'excel', text: "Download As Excel", title: `Over All Growth Report ${this.state.fromDate} To ${this.state.toDate}` }]
                    });
                }
                // this.props.history.push("/entry-tracking-report" + this.state.id);
            })
            .catch(err => {
                console.log(err);
                this.setState({ files: [] });
            })

    }

    handleChange1 = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName === "lawhands_branch") {
            const branch = fieldValue;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ [fieldName]: fieldValue });
            this.setState({ filteredUsers });
        }
        if (fieldName === "assigned_to") {
            const employee_id = fieldValue;
            this.setState({ [fieldName]: fieldValue });
        }
        if (fieldName === "bank_name") {
            const bank_id = fieldValue;
            const state = this.state;
            state[fieldName] = fieldValue;
            console.log("bank_name", this.state.bank_name);
            this.filterProduct(fieldValue);
        }
        if (fieldName === "product_id") {
            let newProductId = e.id;
            this.setState({ [fieldName]: newProductId }, () => {
                console.log("this.state.product_id", this.state.product_id);
            });
        }
    }

    filterProduct = (bankid: any) => {
        console.log("product id--", bankid,);

        if (bankid) {
            const filteredProduct = this.state.products.filter(
                (branch: any) => branch.bank_id === bankid
            );
            const state = this.state;
            state.filteredProduct = filteredProduct;
            this.setState({ ...state });
        } else {
            this.setState({ product_id: "" })
        }
    };

    handleChange = (e, stateName?, type?) => {
        if (e.target.name === "toDate") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "fromDate") {
            this.setState({ fromdate: e.target.value })
        }
        if (e.target.name === "id") {
            this.setState({ id: e.target.value })
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };
    handleDateChange = (date, dateStr, type) => {
        // console.log(date, dateStr);
        this.setState({ [type]: dateStr });
    };
    disabledDate = (current) => {
        // Disable dates after today and dates before the last 3 months
        return current && (current > moment().endOf('day') || current < moment().subtract(3, 'months').startOf('day'));
    };
    render() {
        const data = this.state.files;
        const { toDate, setResponse, selectVal, Manager } = this.state;
        const lawhandsBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.lawhandsBranches
        ];
        const banksoptions = [
            { value: "", label: "All" },
            ...this.state.banks
        ];
        const filteredProduct = [
            { value: "", label: "" },
            ...this.state.filteredProduct
        ];
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card" style={{ boxShadow: '0 0 15px #010101a1' }}>
                                                <div className="card-header">
                                                    <h2 className="file-status-header" style={{ display: "flex", justifyContent: "center" }}>Deviation Report</h2>
                                                </div>
                                                <div className="card-header">
                                                    <h2 className="file-status-header" style={{ display: "flex", justifyContent: "center" }}>Bank Wise Report</h2>
                                                    <div className="row mb-3">
                                                        <div className="col-xl-2 col-md-2 col-sm-2">
                                                            <h6>From</h6>
                                                            <DatePicker format={'DD-MM-YYYY'}
                                                                disabledDate={this.disabledDate}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 col-sm-2">
                                                            <h6>To</h6>
                                                            <DatePicker format={'DD-MM-YYYY'}
                                                                disabledDate={this.disabledDate}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 col-sm-2">
                                                            <h6>LH Branch</h6>
                                                            {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={lawhandsBranchesoptions} name="lawhands_branch"
                                                                    value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                                                                    onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>Bank Name</h6>
                                                            {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={banksoptions} name="bank_name"
                                                                    value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
                                                                    onChange={(e) => this.handleChange1(e, 'bank_name', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            {/* <div className="form-group"> */}
                                                            <h6>Product</h6>
                                                            {this.state.products && this.state.products.map((opt: any) => {
                                                                opt.label = opt.product_name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={filteredProduct} name="product_id"
                                                                    value={this.state.filteredProduct.find((o: any) => o.product_id === this.state.product_id ? this.state.product_id : "")}
                                                                    onChange={(e) => this.handleChange1(e, 'product_id', 'singleselect')}
                                                                />
                                                            }
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h5>File.No</h5>
                                                            <input type="id" name="id" className="form-control" id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={this.state.id}
                                                                onChange={e => this.handleChange(e)}
                                                            />
                                                        </div>

                                                        {/*  */}
                                                        <div className="col-xl-12 col-md-12 col-sm-12">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                style={{
                                                                    float: "right"
                                                                }}
                                                                onClick={() => this.getEodTableData()}
                                                            >
                                                                Search
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dt-responsive table-responsive">
                                                        <table
                                                            id="processeodTable"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <thead>
                                                                <tr style={{ boxShadow: "none", background: '#2196f3' }}>
                                                                    <th>S.No</th>
                                                                    <th>LH Branch</th>
                                                                    <th>Bank</th>
                                                                    <th>File Name</th>
                                                                    <th>File No</th>
                                                                    <th>Po Name</th>
                                                                    <th>Queries</th>
                                                                    <th>Received Docs</th>
                                                                    <th>Deviation List</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.files && this.state.files.length
                                                                    ? this.state.files.map(
                                                                        (query: any, index: number) => {
                                                                            return (
                                                                                <tr className="query-table-row cursor-pointer" key={index}>
                                                                                    <td className="text-align-center">{index + 1}</td>
                                                                                    <td className="click">
                                                                                        {query.lawhands_branch}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.bank_name} {query.product_name ? <>({query.product_name})</> : ""}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.file_name}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.file_id}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.process_officer_name}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.query.map((list: any, index: any) => { return (<><p>{list.query ? list.query : ""}</p></>) })}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.recived.map((list: any, index: any) => { return (<><p>{list.recived ? list.recived : ""}</p></>) })}
                                                                                    </td>
                                                                                    <td className="click">
                                                                                        {query.review.map((list: any, index: any) => { return (<><p>{list.review ? list.review : ""}</p></>) })}
                                                                                    </td>
                                                                                    {query.reviewstatus == 1 ? (<td className="click">Deviation Takened</td>) : (<td className="click">
                                                                                        <button onClick={() => this.deviationSubmit(query.file_id)}>DeviationTaken</button>
                                                                                    </td>)}

                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                    : ""}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="modal hide fade in" id="deviation-reason" data-backdrop="static" data-keyboard="false">
                                            <div className="modal-dialog ">
                                                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                                                    <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                                                    <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                                        {/* <div className="col-md-5"> */}
                                                        <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Type Your Reason"
                                                                name="deviation" onChange={this.onchange} />
                                                            {Manager ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Type Proper Reason </p> : null}
                                                        </div>
                                                        {/* </div> */}

                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => this.SubmitReason()}
                                                            style={{ width: '76px', fontSize: '17px' }}

                                                        > Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>




            </section>
        );
    }
}


export default Deviationreport;

function x(arg0: string, x: any) {
    throw new Error("Function not implemented.");
}
