import React from 'react';
import './ReactResizable.css'
const Spinner = () => (
    <>

    <div className="lds-spinner">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
        
        </>
  );
  export default Spinner;