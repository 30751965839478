import * as React from "react";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import { Branch } from "../branch/ManageBranch";
import Select from "react-select";
interface AllProps {
  history: any;
  match: any;
}
class AddUser extends React.Component<AllProps> {
  state = {
    errorteamType: false,
    errorFind: false,
    userdetail: {

      teamType: "",
      roleType: "",
      id: 0,
      name: "",
      email: "",
      mob_no: "",
      branch: "",
      role: "",
      report_to: "",
      password: "",
      confirmpassword: "",
      mybranch: ''
    },
    branches: [],
    role: [],
    reporters: [],
    selectedBranchIds: [] as number[],
    formFields: {
      selectedBranchId: [] as number[],
    },
  };
  componentDidMount() {
    this.listBranches();
    this.getUserDetails();
    this.listReporters();
    this.listRole();
    // this.liveinsert();
  }
  // liveinsert=()=>{
  //   const user: any = sessionStorage.getItem("user");
  //   const userId = user ? JSON.parse(user)["userId"] : "";
  //   const user_type = user ? JSON.parse(user)["roleType"] : "";
  //   const userName = user ? JSON.parse(user)["userName"] : "";
  // const textRay ="hiii i am yuvaraj";
  //   let obj={
  //     "user_id":userId,
  //     "user_name":userName,
  //     "user_type":user_type,
  //     "working_progress":textRay
  //     // "current_page":0
  //   }
  //   callApi("POST","live_track_insert.php",obj)
  //   .then(res=>res.data)
  //   .then(response=>{
  //     console.log("44",response);
  //   })
  // }
  listBranches = () => {
    callApi("GET", "get_branch.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ branches: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  listRole = () => {
    callApi("GET", "get_role.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ role: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  listReporters = () => {
    callApi("GET", "report_to.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ reporters: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getUserDetails = () => {
    callApi("POST", "get_user_single.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response && response.data && response.data.length) {
          const user = response.data[0];
          const selectedBranchId = user.my_branchs.split(',').map(id => id.trim());
          if (response.data[0].password) {
            response.data[0].confirmpassword = response.data[0].password;
          }
          this.setState({ userdetail: response.data[0] });

          this.setState({
            formFields: {
              ...this.state.formFields,
              selectedBranchId
            }
          });
        }
      });
  };
  handleChange = (e: any) => {
    const userdetail: any = this.state.userdetail;
    // if (e.target.name === "email") {
    //   if (
    //     !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
    //   ) {
    //     alert(234);
    //   }
    // }
    // if (e.target.name === "mob_no") {
    //   if (
    //     !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
    //   ) {
    //     return;
    //   }
    // }
    userdetail[e.target.name] = e.target.value;


    this.setState({});
  };
  // varientChange = (e: any) => {

  //   const userdetail: any = this.state.userdetail;
  //   const roleType: any = this.state.userdetail.roleType;
  //   userdetail[e.target.name] = e.target.value;
  //   if (roleType == "1" || roleType == "2" || roleType=="12") {
  //     const userdetail: any = this.state.userdetail;
  //     userdetail.role = "1";
  //     this.setState({ userdetail: userdetail });
  //   } else if (roleType == "3" || roleType == "4") {
  //     const userdetail: any = this.state.userdetail;
  //     userdetail.role = "2";
  //     this.setState({ userdetail: userdetail });

  //   } else if (roleType == "5" || roleType == "6" || roleType == "7" || roleType == "8") {
  //     const userdetail: any = this.state.userdetail;
  //     userdetail.role = "3";
  //     this.setState({ userdetail: userdetail });

  //   } else if (roleType == "9" || roleType == "10" || roleType == "11"){
  //     const userdetail: any = this.state.userdetail;
  //     userdetail.role = "4";
  //     this.setState({ userdetail: userdetail });

  //   }
  //   else{
  //   }

  // }
  saveUser = (e) => {
    e.preventDefault();
    this.props.match.params.id = Number(this.props.match.params.id);
    if (this.props.match.params.id) {
      delete this.state.userdetail.password;
      delete this.state.userdetail.confirmpassword;
      callApi("POST", "user_update.php", { ...this.state.userdetail, selectedBranchId: this.state.formFields.selectedBranchId })
        .then(res => res.data)
        .then(response => {
          this.props.history.push("/user");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      console.log("add users", this.state.userdetail);

      if (this.state.userdetail.roleType == "1" || this.state.userdetail.roleType == "12" || this.state.userdetail.roleType == "2" || this.state.userdetail.roleType == "4" || this.state.userdetail.roleType == "3") {
        if (
          this.state.userdetail.password !== this.state.userdetail.confirmpassword
        ) {
          ToastsStore.error("Password mismatch!");
        } else {
          callApi("POST", "user_create.php", { ...this.state.userdetail, selectedBranchId: this.state.formFields.selectedBranchId })
            .then(res => res.data)
            .then(response => {
              this.props.history.push("/user");
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else if (this.state.userdetail.roleType == "7") {
        if (this.state.userdetail.teamType == "") {
          this.setState({ errorteamType: true });
        } else if (this.state.userdetail.report_to == "") {
          this.setState({ errorteamType: false });
          this.setState({ errorFind: true });
        } else {
          if (
            this.state.userdetail.password !== this.state.userdetail.confirmpassword
          ) {
            ToastsStore.error("Password mismatch!");
          } else {
            callApi("POST", "user_create.php", { ...this.state.userdetail, selectedBranchId: this.state.formFields.selectedBranchId })
              .then(res => res.data)
              .then(response => {
                this.props.history.push("/user");
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
      } else if (this.state.userdetail.roleType == "8" || this.state.userdetail.roleType == "9" || this.state.userdetail.roleType == "10" || this.state.userdetail.roleType == "11") {
        if (this.state.userdetail.report_to == "") {
          this.setState({ errorFind: true });
        } else {
          if (
            this.state.userdetail.password !== this.state.userdetail.confirmpassword
          ) {
            ToastsStore.error("Password mismatch!");
          } else {
            callApi("POST", "user_create.php", { ...this.state.userdetail, selectedBranchId: this.state.formFields.selectedBranchId })
              .then(res => res.data)
              .then(response => {
                this.props.history.push("/user");
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
      else {
        if (
          this.state.userdetail.password !== this.state.userdetail.confirmpassword
        ) {
          ToastsStore.error("Password mismatch!");
        } else {
          callApi("POST", "user_create.php", { ...this.state.userdetail, selectedBranchId: this.state.formFields.selectedBranchId })
            .then(res => res.data)
            .then(response => {
              this.props.history.push("/user");
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  };

  handleBranchChange = (selectedOptions: any) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        selectedBranchId: selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []
      }
    }, () => { });
  };

  render() {
    const { branches, formFields } = this.state;

    const options = branches.map((opt: any) => ({
      label: opt.name,
      value: opt.id
    }));

    const selectedValues = formFields.selectedBranchId.map(id =>
      options.find(option => option.value === id)
    );

    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>User Form</h5>
                          <button
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            onClick={() => this.props.history.goBack()}
                          >
                            Back
                          </button>
                        </div>
                        <div className="card-body">
                          <form className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Full Name"
                                  onChange={e => this.handleChange(e)}
                                  name="name"
                                  value={this.state.userdetail.name}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Mobile Number </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact"
                                  name="mob_no"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.userdetail.mob_no}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email address</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  name="email"
                                  required
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.userdetail.email}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Branch</label>
                                <select
                                  className="custom-select"
                                  name="branch"
                                  value={this.state.userdetail.branch}
                                  onChange={e => this.handleChange(e)}
                                >
                                  <option value="">Select the Branch</option>
                                  {this.state.branches &&
                                    this.state.branches.length
                                    ? this.state.branches.map((itm: Branch) => {
                                      return (
                                        <option value={itm.id}>
                                          {itm.name}
                                        </option>
                                      );
                                    })
                                    : ""}
                                </select>
                              </div>
                            </div>

                            {this.props.match.params.id === "0" && (
                              <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Enter Password"
                                      name="password"
                                      onChange={e => this.handleChange(e)}
                                      value={this.state.userdetail.password}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Re-enter Password"
                                      name="confirmpassword"
                                      onChange={e => this.handleChange(e)}
                                      value={
                                        this.state.userdetail.confirmpassword
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Role Type</label>
                                <select
                                  className="custom-select"
                                  name="roleType"
                                  value={this.state.userdetail.roleType}
                                  onChange={e => this.handleChange(e)}
                                >
                                  <option value="">Select the Role</option>
                                  {this.state.role &&
                                    this.state.role.length
                                    ? this.state.role.map((itm: any) => {
                                      return (
                                        <option value={itm.role_id}>
                                          {itm.name}
                                        </option>
                                      );
                                    })
                                    : ""}
                                </select>

                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Select The Team(Process Team)</label>
                                <select
                                  className="custom-select"
                                  name="teamType"
                                  value={this.state.userdetail.teamType}
                                  onChange={e => this.handleChange(e)}
                                >
                                  <option value=""> Select One</option>
                                  <option value="1">Alpha Team</option>
                                  <option value="2">Bravo Team</option>
                                  <option value="3">Cobra Team</option>
                                  <option value="4">Delta Team</option>
                                </select>
                                {this.state.errorteamType ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Type </p> : null}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Team Leader</label>
                                <select
                                  className="custom-select"
                                  name="report_to"
                                  value={this.state.userdetail.report_to}
                                  onChange={e => this.handleChange(e)}
                                >
                                  <option value=""> Select</option>
                                  {this.state.reporters.length
                                    ? this.state.reporters.map(
                                      (report: any, index: number) => {
                                        return (
                                          <option
                                            key={index}
                                            value={report.id}
                                          >
                                            {report.name}
                                          </option>
                                        );
                                      }
                                    )
                                    : ""}
                                </select>
                                {this.state.errorFind ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Type </p> : null}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>My Branch</label>
                                {this.state.branches && this.state.branches.length > 0 && (
                                  <Select
                                    options={options}
                                    name="mybranch"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={selectedValues}
                                    onChange={this.handleBranchChange}
                                  />
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="form-group">
                                <label>Subordinate</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Subordinate"
                                  name="subordinate"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.userdetail.subordinate}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ marginTop: "27px" }}
                                onClick={(e) => this.saveUser(e)}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddUser;
