import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { SurveyNoVerify } from './sopDataTemplate';
interface AllProps {
    data: SurveyNoVerify;
    handleSurveyNoVerify: any;
}
export class SurveyNoVerification extends Component<AllProps> {

    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d',
        responseData: {}

    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            responseData: JSON.parse(JSON.stringify(data))
        })
    }

    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }

    handleChildChange = (e, type, index?) => {
        const { data } = this.props;
        if (index !== undefined) data[type][index][e.target.name] = e.target.value;
        else data[type][e.target.name] = e.target.value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleSurveyNoVerify(data);
    }
    handleChange = (e) => {
        const { data } = this.props;
        data[e.target.name] = e.target.value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleSurveyNoVerify(data);
    }
    checkOperation = (type, value, index) => {
        let isEditable = false
        const { responseData } = this.state;
        if (responseData[type] && responseData[type].length < (index + 1)) {
            isEditable = true;
        };
        return isEditable;
    }
    addChild = (type) => {
        const { data } = this.props;
        data[type].push({
            "surveyNo": "",
            "tslrNo": "",
            "extent": "",
            "name": ""
        });
        this.props.handleSurveyNoVerify(data);
    }

    removeChild = (type, index) => {
        const { data } = this.props;
        delete data[type][index];
        this.props.handleSurveyNoVerify(data);
    }

    getAddButton = (title, value) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {title && title === "A Register" ?
                    <h6><b>{title} / From Para Patta</b></h6> : <h6><b>{title}</b></h6>
                }
                <div onClick={() => this.addChild(value)}>
                    <button className='btn btn-sm btn-primary' style={{ fontSize: 'smaller' }}>Add {title}</button>

                </div>
            </div>
        )
    }

    render() {
        const { data } = this.props;
        const { isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section survey-no-verify">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="1" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>Survey No Verification</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div>
                                    {this.getAddButton('A Register', 'register')}
                                    {data.register.length > 0 && data.register.map((registerValue, valueIndex) => {
                                        return (
                                            <div className="row" key={valueIndex}>
                                                <div className="form-group col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Survey No</label>
                                                    <input
                                                        className="form-control"
                                                        name="surveyNo"
                                                        type="text"
                                                        value={registerValue.surveyNo}
                                                        onChange={(e) => this.handleChildChange(e, "register", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>TSLR No</label>
                                                    <input
                                                        className="form-control"
                                                        name="tslrNo"
                                                        type="text"
                                                        value={registerValue.tslrNo}
                                                        onChange={(e) => this.handleChildChange(e, "register", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Extent</label>
                                                    <input
                                                        className="form-control"
                                                        name="extent"
                                                        type="text"
                                                        value={registerValue.extent}
                                                        onChange={(e) => this.handleChildChange(e, "register", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Name</label>
                                                    <input
                                                        className="form-control"
                                                        name="name"
                                                        type="text"
                                                        value={registerValue.name}
                                                        onChange={(e) => this.handleChildChange(e, "register", valueIndex)}
                                                    />
                                                </div>
                                                {this.checkOperation('register', registerValue, valueIndex) &&
                                                    <div className='form-group col-md-1' style={{ marginBottom: 0, alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('register', valueIndex)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Title Deed', 'titleDeed')}
                                    {data.titleDeed.length > 0 && data.titleDeed.map((titleDeedValue, valueIndex) => {
                                        return (
                                            <div className="row" key={valueIndex}>
                                                <div className="form-group  col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Survey No</label>
                                                    <input
                                                        className="form-control"
                                                        name="surveyNo"
                                                        type="text"
                                                        value={titleDeedValue.surveyNo}
                                                        onChange={(e) => this.handleChildChange(e, "titleDeed", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>TSLR No</label>
                                                    <input
                                                        className="form-control"
                                                        name="tslrNo"
                                                        type="text"
                                                        value={titleDeedValue.tslrNo}
                                                        onChange={(e) => this.handleChildChange(e, "titleDeed", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Extent</label>
                                                    <input
                                                        className="form-control"
                                                        name="extent"
                                                        type="text"
                                                        value={titleDeedValue.extent}
                                                        onChange={(e) => this.handleChildChange(e, "titleDeed", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Name</label>
                                                    <input
                                                        className="form-control"
                                                        name="name"
                                                        type="text"
                                                        value={titleDeedValue.name}
                                                        onChange={(e) => this.handleChildChange(e, "titleDeed", valueIndex)}
                                                    />
                                                </div>
                                                {this.checkOperation('titleDeed', titleDeedValue, valueIndex) &&
                                                    <div className='form-group col-md-1' style={{ marginBottom: 0, alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('titleDeed', valueIndex)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Current Patta', 'currentPatta')}
                                    {data.currentPatta.length > 0 && data.currentPatta.map((pattaValue, valueIndex) => {
                                        return (
                                            <div className="row">
                                                <div className="form-group  col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Survey No</label>
                                                    <input
                                                        className="form-control"
                                                        name="surveyNo"
                                                        type="text"
                                                        value={pattaValue.surveyNo}
                                                        onChange={(e) => this.handleChildChange(e, "currentPatta", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>TSLR No</label>
                                                    <input
                                                        className="form-control"
                                                        name="tslrNo"
                                                        type="text"
                                                        value={pattaValue.tslrNo}
                                                        onChange={(e) => this.handleChildChange(e, "currentPatta", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5 no-right-padding" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Extent</label>
                                                    <input
                                                        className="form-control"
                                                        name="extent"
                                                        type="text"
                                                        value={pattaValue.extent}
                                                        onChange={(e) => this.handleChildChange(e, "currentPatta", valueIndex)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-5" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Name</label>
                                                    <input
                                                        className="form-control"
                                                        name="name"
                                                        type="text"
                                                        value={pattaValue.name}
                                                        onChange={(e) => this.handleChildChange(e, "currentPatta", valueIndex)}
                                                    />
                                                </div>
                                                {this.checkOperation('currentPatta', pattaValue, valueIndex) &&
                                                    <div className='form-group col-md-1' style={{ marginBottom: 0, alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('currentPatta', valueIndex)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    <div className="row">
                                        <div className="form-group  col-md-4 no-right-padding">
                                            <label className="label" style={{ padding: 0 }}>Total Survey No In Title Deed</label>
                                            <input
                                                className="form-control"
                                                name="totalTitleDeed"
                                                type="text"
                                                value={data.totalTitleDeed}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group  col-md-4 no-right-padding">
                                            <label className="label" style={{ padding: 0 }}>Total Survey No In EC</label>
                                            <input
                                                className="form-control"
                                                name="totalEc"
                                                type="text"
                                                value={data.totalEc}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group  col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Diffrence</label>
                                            <input
                                                className="form-control"
                                                name="difference"
                                                type="text"
                                                value={data.difference}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group  col-md-4 no-right-padding">
                                            <label className="label" style={{ padding: 0 }}>Missing Survey No In EC</label>
                                            <input
                                                className="form-control"
                                                name="missingEc"
                                                type="text"
                                                value={data.missingEc}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group  col-md-4 no-right-padding">
                                            <label className="label" style={{ padding: 0 }}>Land Type</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Nanjai"
                                                    name="type"
                                                    type="radio"
                                                    checked={data.type}
                                                    onChange={() => this.handleChange({ target: { name: "type", value: true } })}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Punjai"
                                                    name="type"
                                                    type="radio"
                                                    checked={!data.type}
                                                    onChange={() => this.handleChange({ target: { name: "type", value: false } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group  col-md-4">
                                            <label className="label" style={{ padding: 0 }}>Condition Patta</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="conditionPatta"
                                                    type="radio"
                                                    checked={data.conditionPatta}
                                                    onChange={() => this.handleChange({ target: { name: "conditionPatta", value: true } })}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="conditionPatta"
                                                    type="radio"
                                                    checked={!data.conditionPatta}
                                                    onChange={() => this.handleChange({ target: { name: "conditionPatta", value: false } })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}

export default SurveyNoVerification
