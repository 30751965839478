import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import Select from 'react-select';
import ReactFileReader from "react-file-reader";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import moment from 'moment';
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
import { ComplaintTable } from './complaintTable';
import DatePicker from "antd/lib/date-picker";
interface Complaint {
    fileNo: "";
    bankFormat: "";
    location: "";
    alreadyExist: "";
    toBeChange: "";
    remark: "";

}
export class RegisterComplaint extends Component {
    state = {
        fileNo: "",
        bankFormat: "",
        location: "",
        alreadyExist: "",
        toBeChange: "",
        remark: "",
        userId: "",
        bank_templates: [],
        complaints: [],
        filteredComplaints: [],
        user: {
            role_id: ""
        },
        statuses: [
            { value: 'valid', label: 'Valid' },
            { value: 'invalid', label: 'Invalid' },
            { value: 'newrequirement', label: 'New Requirement' }
        ],
        selectedStatus: "pending",
        files: [],
        searchText: "",
        docToPreview: "",
        formSubmit: false,
        imageNotUpload: false,
        complaintStatus: "",
        fromDate: "",
        toDate: "",
        isLoading: false,
        pageDynamic: [],
    }
    componentDidMount() {
        this.getTemplates();
        this.getComplaint();
        this.getPageDynamicData();
        const user: any = sessionStorage.getItem("user");
        this.setState({ user: user ? JSON.parse(user) : "" });
    }
    getPageDynamicData = () => {
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        let obj = {
            roleId: userDetails ? userDetails.role_id : null,
            userId: userDetails ? userDetails.userId : null,
            roleType: userDetails ? userDetails.roleType : null,
        };
        callApi("POST", "authenticating_pages_list_new.php", obj)
            .then((res) => res.data)
            .then((result) => {
                this.setState({ pageDynamic: result.responseJson.data, });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getTemplates = () => {
        callApi("POST", "bank_temp_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ bank_templates: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getComplaint = () => {
        const user: any = sessionStorage.getItem("user");
        callApi("POST", "complaint_list.php", { userId: user ? JSON.parse(user)["userId"] : "" })
            .then(res => res.data)
            .then(response => {
                this.setState({
                    complaints: response.data,
                    filteredComplaints: response.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange = (e) => {console.log(e);
    
        this.setState({ [e.target.name]: e.target.value });
    }
    handleTempChange = (data) => {
        this.setState({ bankFormat: data.value });
    }
    handleStatusChange = (data, ticket, currentPath) => {
        // this.setState({selectedStatus: data.value});
        const User: any = sessionStorage.getItem("user");
        const roleType = User ? JSON.parse(User)["roleType"] : "";
        const userId = User ? JSON.parse(User)["userId"] : "";
        const role_label = User ? JSON.parse(User)["role_label"] : "";
        let statusId;
        // if (userId == "1" || roleType == "1" || roleType == "2" || role_label == "ADMIN") {
        if (currentPath == "/all-complaints") {
            statusId = data === "Temporary Solution" ? "4" : data === "Completed" ? "5" : "6";
        } else {
            statusId = data === "Invalid" ? "1" : data === "Valid" ? "2" : "3";
        }
        callApi("POST", "complaint_status_change.php", { ticketId: ticket.ticketId, Status: statusId })
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint status changed successfully");
                this.resetForm();
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }

    handleSearchChange = (e) => {
        const { complaints } = this.state;

        const list = complaints.filter((item: any) => {
            const id = (item.ticketId || '').toLowerCase();
            const alreadyExist = (item.alreadyExist || '').toLowerCase();
            const bankFormat = (item.bankFormat || '').toLowerCase();
            const createdDate = (item.createdDate || '').toLowerCase();
            const fileNo = (item.fileNo || '').toLowerCase();
            const location = (item.location || '').toLowerCase();
            const remark = (item.remark || '').toLowerCase();
            const status = (item.status || '').toLowerCase();
            const toBeChange = (item.toBeChange || '').toLowerCase();
            const updatedDate = (item.updatedDate || '').toLowerCase();
            const userName = (item.userName || '').toLowerCase();

            return (
                id.includes(e.target.value.toLowerCase()) ||
                alreadyExist.includes(e.target.value.toLowerCase()) ||
                bankFormat.includes(e.target.value.toLowerCase()) ||
                createdDate.includes(e.target.value.toLowerCase()) ||
                fileNo.includes(e.target.value.toLowerCase()) ||
                location.includes(e.target.value.toLowerCase()) ||
                remark.includes(e.target.value.toLowerCase()) ||
                status.includes(e.target.value.toLowerCase()) ||
                toBeChange.includes(e.target.value.toLowerCase()) ||
                updatedDate.includes(e.target.value.toLowerCase()) ||
                userName.includes(e.target.value.toLowerCase())
            );
        });

        console.log(e.target.value, list);
        this.setState({
            searchText: e.target.value,
            filteredComplaints: list,
        });
    };
    deleteDoc = (i) => {
        let { files } = this.state;
        files = files.filter((file, index) => index !== i);
        this.setState({ files });
    };
    submitComplaint = () => {
        const { fileNo, bankFormat, location, alreadyExist, toBeChange, remark, files } = this.state;
        const user: any = sessionStorage.getItem("user");
        const complaint = {
            fileNo: fileNo,
            bankFormat: bankFormat,
            location: location,
            alreadyExist: alreadyExist,
            toBeChange: toBeChange,
            remark: remark,
            createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            userId: user ? JSON.parse(user)["userId"] : "",
            files
        }
        callApi("POST", "complaint_register.php", complaint)
            .then(res => res.data)
            .then(response => {
                ToastsStore.success("Complaint Added successfully");
                this.resetForm();
                this.getComplaint();
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleFiles = (files: any) => {
        console.log(files);
        this.setState({ files: [] });
        let baseFile: any = this.state.files || [];
        const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
        if (Number(fileSize) > 26214400) {
            ToastsStore.error("File(s) size shouldn't exceed 25 MB");
        } else {
            // files.base64.map((file: any, index: number) => {
            //     baseFile.push({ fileContent: file, fileName: files.fileList[index].name });
            // });
            // this.setState({ files: [...baseFile] });
            // this.setState({ imageNotUpload: false, formSubmit: false });

            callApi("POST", "s3_complaint_image_upload.php", {
                base: files.base64,
            })
                .then((res) => {
                    if (res.data && res.data.file) {
                        // baseArray[index] = {
                        //   ...baseArray[index],
                        //   base: files.fileList[0].name,
                        //   base_url: res.data.url,
                        //   doc_path: files.base64
                        // };
                        // files.base64.map((file: any, index: number) => {
                        baseFile.push({ fileContent: res.data.url, fileName: files.fileList[0].name, s3fileName: res.data.file_name });
                        // });
                        this.setState({ files: [...baseFile] });
                        this.setState({ imageNotUpload: false, formSubmit: false });
                    } else {
                        ToastsStore.error("Failed to upload file(s)");
                    }
                })
                .catch((err) => {
                    ToastsStore.error("Failed to upload file(s)");
                });
        }
    }
    validComplaint = () => {
        console.log("validComplaint", this.state);

        const { fileNo, bankFormat, location, alreadyExist, toBeChange, remark, files }: any = this.state;
        if (location && alreadyExist && toBeChange && bankFormat) {
            if (files.length > 0) {
                this.submitComplaint();
                this.setState({ imageNotUpload: false, formSubmit: false });
            } else {
                this.setState({ imageNotUpload: true, formSubmit: true });
                ToastsStore.error("Please upload image.");
            }
        } else {
            this.setState({ formSubmit: true, imageNotUpload: true });
        }
    }
    uploadFiles = () => {
        console.log(this.state.files);

    }
    resetForm = () => {
        this.setState({
            fileNo: "",
            bankFormat: "",
            location: "",
            alreadyExist: "",
            toBeChange: "",
            remark: "",
            userId: "",
            files: [],
            formSubmit: false
        });
    }
    previewDoc = (url) => {
        this.setState({ docToPreview: url })
    };
    handleTabSelect = (key) => {
        const { complaints } = this.state;
        let list;
        if (key == "invalidKey") {
            list = complaints.filter((item: any) => {
                return item.status == "Invalid";
            });
        } else if (key == "validKey") {
            list = complaints.filter((item: any) => {
                return item.status == "Valid";
            });
        }
        else if (key == "newrequirementKey") {
            list = complaints.filter((item: any) => {
                return item.status == "New Requirement";
            });
        } else { }

        if (key == "allKey") {
            this.setState({
                searchText: '',
                filteredComplaints: complaints,
            });
        } else {
            this.setState({
                searchText: '',
                filteredComplaints: list,
            });
        }
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    handlestatusChange = (data, currentPath) => {
        this.setState({ complaintStatus: data.id, currentPath });
    }
    getSearchComplaint = () => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        if (this.state.fromDate != "" && this.state.toDate != "") {
            this.setState({ isLoading: true });
            let obj = {
                from_date: this.state.fromDate,
                to_date: this.state.toDate,
                userId: userId
                // complaint_Status: this.state.complaintStatus
            }
            console.log("date", obj);
            callApi("POST", "filter_complaint.php", obj)
                .then(res => res.data)
                .then(response => {
                    if (response.status == 200) {
                        this.setState({
                            complaints: response.data,
                            filteredComplaints: response.data
                        });
                        console.log("filter_complaint", response);
                        this.setState({ isLoading: false });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ isLoading: false });
                });
        }
        else {
            ToastsStore.error("Please select the date.");
        }
    }
    render() {
        const { pageDynamic, bank_templates, bankFormat, fileNo, location, alreadyExist,
            toBeChange, remark, complaints, user, statuses, files, filteredComplaints, searchText, formSubmit, imageNotUpload }: any = this.state;
        const forSoftwareTeamStatuses = [
            { id: '', name: 'All' },
            { id: '1', name: 'Invalid' },
            { id: '2', name: 'Valid' },
            { id: '3', name: 'New Requirement' },
            { id: '4', name: 'Temporary Solution' },
            { id: '5', name: 'Completed' },
            { id: '6', name: 'Pending' },
        ];

        const extraOptions = [
            { value: "Dashboard", label: "Dashboard" },
            { value: "Entry Tracking Report", label: "Entry Tracking Report" },
            { value: "Process Officer Eod", label: "Process Officer Eod" },
            { value: "Verification Eod", label: "Verification Eod" },
            { value: "GOLM Report", label: "GOLM Report" },
            { value: "Deviation Report", label: "Deviation Report" },
            { value: "File Completed Report", label: "File Completed Report" },
            { value: "vetting Report", label: "vetting Report" },
            { value: "Lawhands Mis", label: "Lawhands Mis" },
            { value: "Query", label: "Query" },
            { value: "Global Search", label: "Global Search" },
            { value: "External References", label: "External References" },
            { value: "Register Complaint", label: "Register Complaint" },
            { value: "Complaint List", label: "Complaint List" },
            { value: "Send Notice", label: "Send Notice" },
            { value: "Live Track", label: "Live Track" },
            { value: "Add File", label: "Add File" },
            { value: "Notification", label: "Notification" },
            { value: "Search", label: "Search" },
            { value: "File Dashboard", label: "File Dashboard" },
            { value: "Raise Query", label: "Raise Query" },
            { value: "Flowchart", label: "Flowchart" },
            { value: "Hint About File", label: "Hint About File" },
            { value: "Scheduled of property", label: "Scheduled of property" },
            { value: "All Documents", label: "All Documents" },
            { value: "List Of Documents", label: "List Of Documents" },
            { value: "Flowchart", label: "Flowchart" },
            { value: "Addon Features", label: "Addon Features" },
            { value: "Document Overview", label: "Document Overview" },
            { value: "Check Option", label: "Check Option" },
            { value: "verification Checklist", label: "verification Checklist" },
            { value: "Flowchart", label: "Flowchart" },
        ];

        const pagelist = [
            { value: "", label: "Select Page" },
            ...pageDynamic.map((page) => ({
                value: page.page_name,
                label: page.page_name
            })),
            ...extraOptions,
        ];

        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>Register Complaint</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="form-group row">
                                                            <div className="col-sm-4">
                                                                <label> File No</label>
                                                                <input type="text" className="form-control"
                                                                    name="fileNo" placeholder=""
                                                                    value={fileNo}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label>Page Name<span style={{ color: "red" }}>*</span></label>
                                                                {/* {bank_templates && bank_templates.length > 0 && bank_templates.map((opt: any) => {
                                                                    opt.label = opt.name; opt.value = opt.id
                                                                }) &&
                                                                    <Select options={bank_templates} name="bank_id"
                                                                        value={bank_templates.find((o: any) => o.value === bankFormat)}
                                                                        onChange={this.handleTempChange}
                                                                    />
                                                                } */}
                                                                {pagelist && pagelist.length > 0 && pagelist.map((opt: any) => {
                                                                    opt.label = opt.label; opt.value = opt.value
                                                                }) &&
                                                                    <Select options={pagelist} name="bank_id"
                                                                        value={pagelist.find((o: any) => o.value === bankFormat)}
                                                                        onChange={this.handleTempChange}
                                                                    />
                                                                }
                                                                {!bankFormat && formSubmit && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label> Location<span style={{ color: "red" }}>*</span></label>
                                                                <input type="text" className="form-control"
                                                                    name="location" placeholder=""
                                                                    value={location}
                                                                    onChange={this.handleChange}
                                                                />
                                                                {!location && formSubmit && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-6">
                                                                <label> Problem<span style={{ color: "red" }}>*</span></label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="alreadyExist"
                                                                    value={alreadyExist}
                                                                    onChange={this.handleChange}
                                                                />
                                                                {!alreadyExist && formSubmit && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label> Expectation<span style={{ color: "red" }}>*</span></label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="toBeChange"
                                                                    value={toBeChange}
                                                                    onChange={this.handleChange}
                                                                />
                                                                {!toBeChange && formSubmit && <p style={{ color: "red", fontSize: "14px" }}>Please fill the field.</p>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-10">
                                                                <label> Remark</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="remark"
                                                                    value={remark}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <label></label>
                                                                <ReactFileReader
                                                                    fileTypes={[".png", ".jpeg", ".jpg", ".svg"]}
                                                                    base64={true}
                                                                    multipleFiles={false}
                                                                    handleFiles={this.handleFiles}
                                                                >
                                                                    <button type="button" className="ml2 mt-4 btn btn-primary btnupload query-upload">
                                                                        Upload Image
                                                                    </button>
                                                                </ReactFileReader>
                                                                {imageNotUpload && formSubmit && <p style={{ color: "red", fontSize: "14px" }}>Please upload the image.</p>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            {files && files.length > 0 && files.map((itm: any, index) =>
                                                                <div className="col-sm-4" key={index}>
                                                                    <div className="card card-border-c-green">
                                                                        {/* <span
                                                                            onClick={() => this.deleteDoc(index)}
                                                                        >
                                                                            <i className="feather icon-x cursor-pointer" />
                                                                        </span> */}
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <span className="mb-1 text-c-green">
                                                                                    <i className="feather icon-file-text" />
                                                                                </span>
                                                                                <span>
                                                                                    {itm.fileName || ""}
                                                                                </span>
                                                                                <span style={{ float: 'right' }}
                                                                                    className="cursor-pointer"
                                                                                    data-toggle="modal"
                                                                                    data-target="#preview-document"
                                                                                    onClick={() =>
                                                                                        this.previewDoc(itm.fileContent)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-eye" />
                                                                                </span>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group row justify-content-end">
                                                            <div className="col-sm-2">
                                                                <button type="reset" className="btn btn-primary"
                                                                    onClick={this.resetForm}>Clear</button>
                                                                <button type="button" className="btn btn-primary"
                                                                    onClick={this.validComplaint}
                                                                >Submit</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>
                                                        <b>Complaint List</b>
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    <Accordion className="mainAccordion">
                                                        <Tabs defaultActiveKey="allKey" onSelect={this.handleTabSelect}
                                                            style={{ padding: 0 }}>
                                                            <Tab eventKey="allKey" title="All Complaints">
                                                                <div className="row m-3">
                                                                    <div className="col-md-3">
                                                                        <h6>From</h6>
                                                                        <DatePicker format={'DD-MM-YYYY'}
                                                                            onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h6>To</h6>
                                                                        <DatePicker format={'DD-MM-YYYY'}
                                                                            onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
                                                                    </div>
                                                                    {/* <div className="col-md-3">
                                                                        <h6>Complaint Status</h6>
                                                                        {forSoftwareTeamStatuses && forSoftwareTeamStatuses.length > 0 && forSoftwareTeamStatuses.map((opt: any) => {
                                                                            opt.label = opt.name; opt.value = opt.id
                                                                        }) &&
                                                                            <Select options={forSoftwareTeamStatuses} name="complaintStatus"
                                                                                value={forSoftwareTeamStatuses.find((o: any) => o.value == this.state.complaintStatus)}
                                                                                onChange={(e) => this.handlestatusChange(e, "/all-complaints")}
                                                                            />
                                                                        }
                                                                    </div> */}
                                                                    <div className="col-md-3">
                                                                        <div className="form-group row justify-content-center">
                                                                            <div className="col-sm-3 mt-3 mr-5">
                                                                                <button type="button" className="btn btn-primary"
                                                                                    onClick={this.getSearchComplaint}
                                                                                    disabled={this.state.isLoading}
                                                                                >{this.state.isLoading ? (
                                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                                ) : "Search"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/register-complaint"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="invalidKey" title="Invalid">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/register-complaint"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="validKey" title="Valid">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/register-complaint"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="newrequirementKey" title="New Requirement">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="dt-responsive table-responsive">
                                                                            {filteredComplaints && filteredComplaints.length > 0 ?
                                                                                (<>
                                                                                    <div className="row justify-content-end w-100">
                                                                                        <div className="col-sm-3">
                                                                                            <input type="text" className="form-control mb-10"
                                                                                                name="search" placeholder="search"
                                                                                                value={searchText}
                                                                                                onChange={this.handleSearchChange}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ComplaintTable
                                                                                        complaints={filteredComplaints}
                                                                                        user={user}
                                                                                        handleStatusChange={this.handleStatusChange}
                                                                                        currentPath="/register-complaint"
                                                                                    />
                                                                                </>
                                                                                ) : (
                                                                                    <div className="row justify-content-center w-100 p-10 mt-5">
                                                                                        <p style={{ fontSize: "18px" }}>No result found</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </Accordion>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="preview-document"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Preview</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <iframe
                                    width="100%"
                                    height="400px"
                                    src={this.state.docToPreview}
                                ></iframe> */}
                                <img
                                    width="100%"
                                    height="400px"
                                    src={this.state.docToPreview}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.setState({ docToPreview: "" });
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RegisterComplaint
