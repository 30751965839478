import * as React from "react";
import DeviationInprogressFile from "../file/DeviationInprogressFile";
interface AllProps {
    history: any;
}
class ManagedeviationInprogressFile extends React.Component<AllProps> {
    render() {
        return (
            <DeviationInprogressFile history={this.props.history} />
        );
    }
}
export default ManagedeviationInprogressFile;