import * as React from "react";
import { callApi } from "../../utils/api";
import MaskedInput from "react-text-mask";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import FileSearchModal from "../file/FileSearchModal";
import { PendingFile } from "../file/ManagePendingFile";
import './process_eod.css'
import { User } from "../user/ManageUser";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { V } from "jointjs";
import {
	ToastsContainer,
	ToastsContainerPosition,
	ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
	match: any;
	history: any;
	router: any;
	route: any;
	id: any;
}



class ProcessEod extends React.Component<AllProps> {
	state = {
		backReason: "",
		userVAlue: false,
		banks: [],
		bank_name: "",
		selectVal: false,
		setResponse: true,
		myJSON: '',
		lookup: '',
		fileId: '',
		sampleId: "",
		id: "",
		fromDate: "",
		toDate: "",
		files: [],
		fileNo: '',
		lawhandsBranches: [],
		// lawHandsBranch: "",
		lawhands_branch: "",
		assigned_to: "",
		users: [],
		total: '',
		green: '',
		red: '',
		outreason: "",
		// fromDate: moment().format('YYYY-MM-DD'),
		// toDate: moment().format('YYYY-MM-DD'),
		isLoading: false
	};
	obj: any;
	table: any;
	componentDidMount() {
		this.reGetvalue()
		this.getLawHandsBranches()
		this.getUsers()
		this.getOutTime();
		this.ouTime();
		this.getBanks();

	}
	previousPop = () => {
		$("#out_reason").modal("show");
		$("#another-reason").modal("hide");
	}
	BacktoFile = () => {
		$("#out_reason").modal("hide");
		const fileid1: any = sessionStorage.getItem("currentfileId");

		this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
	}

	getOutTime = () => {
		let date = new Date();
		let stratTime = date.getTime();
		localStorage.setItem("startTime", JSON.stringify(stratTime))
		let outDate = date.toLocaleDateString();
		localStorage.setItem("OutDateGet", JSON.stringify(outDate));
		var Railway = date.getMinutes();
		if (Railway < 10) {
			var TotalMinutes = "0" + Railway;
			let ouTtime = date.getHours() + ":" + TotalMinutes;
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}
		else {
			let ouTtime: any = date.getHours() + ":" + date.getMinutes();
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}

	}
	ouTime = () => {
		const getfileId: any = sessionStorage.getItem("currentfileId");
		console.log("54", JSON.parse(getfileId))
		console.log("32", getfileId);
		if (sessionStorage.getItem("currentfileId")) {
			$("#out_reason").modal("show");
		}
	}

	outimeGet = () => {
		this.setState({ isLoading: true });
		const fileid1: any = sessionStorage.getItem("currentfileId");
		const userid1: any = sessionStorage.getItem("user1");
		const inreason: any = localStorage.getItem("in_reason");
		const intime: any = localStorage.getItem("IntimeGet");
		console.log("70", intime);
		const outtime: any = localStorage.getItem("outtimeGet");
		console.log("73", outtime);
		const outdate: any = localStorage.getItem("OutDateGet");
		const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
		console.log('Second Spn', secondsSpent);
		if (this.state.outreason == "") {
			this.setState({ selectVal: true });
		}
		else {
			let obj = {
				"file_id": JSON.parse(fileid1),
				"user_id": JSON.parse(userid1),
				"in_reason": JSON.parse(inreason),
				"out_reason": this.state.outreason,
				"intime": JSON.parse(intime),
				"outtime": JSON.parse(outtime),
				"insert_date": JSON.parse(outdate),
				"total_seconds": secondsSpent
			}

			// console.log("72",obj);
			callApi("POST", "process_eod_insert.php", obj)
				.then(res => res.data)
				.then(response => {
					console.log("76", response);
					this.setState({ isLoading: false });
					// sessionStorage.removeItem("currentfileId");
					localStorage.removeItem("in_reason");
					localStorage.removeItem("outreason");
					localStorage.removeItem("IntimeGet");
					localStorage.removeItem("OutDateGet");
					$("#out_reason").modal("hide");
					$("#another-reason").modal("hide");
					ToastsStore.success("Your Reason Saved Successfully");
					let process_officerId: any = sessionStorage.getItem("process_office_id");
					let process_officer_id = JSON.parse(process_officerId);
					const userid1: any = sessionStorage.getItem("user1");
					let user_id = JSON.parse(userid1);
					const take_file: any = sessionStorage.getItem("takenfile_id");
					const user: any = sessionStorage.getItem("user");
					const getfileId: any = sessionStorage.getItem("currentfileId");
					if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
						this.fileout(fileid1);
					} else {
						sessionStorage.removeItem('currentfileId');
					}
				}).catch((err) => {
					this.setState({ isLoading: false });
					console.log(err);
					ToastsStore.error("Your Reason Not Stored");
				})
		}
	}
	fileout = (fileid1) => {
		let fileId = JSON.parse(fileid1);
		const loginuser: any = sessionStorage.getItem("user1");
		let req = {
			id: fileId,
			reason: "File Out",
			created_by: JSON.parse(loginuser)
		}
		callApi("POST", "time_update.php", req)
			.then(res => res.data)
			.then(response => {
				sessionStorage.removeItem('currentfileId');
				// sessionStorage.removeItem("takenfile_id");
			});
	}
	onchangeReason = (e: any) => {

		if (e.target.value == "Other") {
			this.setState({ in_reason: "", selectVal: false })
			$("#out_reason").modal("hide");
			$("#another-reason").modal("show");
		}
		else if (e.target.value == "select") {
			this.setState({ setResponse: false })
		}
		else {
			// this.setState({})
			this.setState({ [e.target.name]: e.target.value, selectVal: false });
		}
	};
	onchange = (e: any) => {
		this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
	};

	reGetvalue = () => {
		// $("#processeodTable").fnClearTable(this)
		let obj = localStorage.getItem("EodReport");
		console.log("55", obj);
		callApi("POST", "process_eod.php", obj)
			.then(res => {
				let data = [];
				localStorage.removeItem("EodReport");
				console.log(localStorage.removeItem("EodReport"));
				if (res.data && res.data.responseJson && res.data.responseJson.data) {
					data = res.data.responseJson.data;
				}
				this.setState({ files: data ? data : [] });

				const user: any = sessionStorage.getItem("user");
				const role_id = user ? JSON.parse(user)["role_id"] : "";
				const user_id = user ? JSON.parse(user)["userId"] : null;
				const userDetails = user ? JSON.parse(user) : "";
				const currentDate = new Date();
				var dateString = moment(currentDate).format('DD-MM-YYYY')

				$("#processeodTable").DataTable({
					dom: 'Bfrtip',
					buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
				});
			})
			.catch(err => {
				console.log(err);
				this.setState({ files: [] });
			})
	}
	getUsers = () => {
		const user: any = sessionStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		if (isAdmin) {
			callApi("GET", "user_get.php")
				.then(res => res.data)
				.then(response => { this.setState({ users: response.data }); })
				.catch(err => {
					console.log(err);
				});
		}
		else {
			callApi("POST", "team_leader.php", { "user_id": user_id })
				.then(res => res.data)
				.then(response => {
					console.log("210", response);
					this.setState({ users: response.data });

				})
				.catch(err => {
					console.log(err);
				});
		}


	};
	goToFileDashboard = fileName => {
		const url = "/process-eod";
		localStorage.setItem("url", url);
		let obj = { "file_id": fileName }
		localStorage.setItem("thisPopup", JSON.stringify(obj))
		sessionStorage.setItem("currentfileId", JSON.stringify(fileName));
		this.props.history.push("/file-dashboard/" + fileName);
	};

	getLawHandsBranches() {
		callApi("GET", "get_branch.php")
			.then(res => res.data)
			.then(response => {
				this.setState({ lawhandsBranches: response.data });


			})
			.catch(err => {
				console.log(err);
			});
	}

	getEodTableData = () => {
		var table = $('#processeodTable').DataTable();
		table.destroy();
		const user: any = sessionStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		if (isAdmin) {
			let obj: any = {
				id: this.state.id,
				from_date: this.state.fromDate,
				to_date: this.state.toDate,
				employee_id: this.state.assigned_to,
				lawhands_branch: this.state.lawhands_branch,
				bank_name: this.state.bank_name
			};

			localStorage.setItem("EodReport", JSON.stringify(obj));
			callApi("POST", "process_eod.php", obj)
				.then(res => {
					console.log("116", res);
					// localStorage.setItem("eodreport",obj);
					console.log("98", localStorage.getItem("EodReport"));

					let data = [];
					if (res.data && res.data.responseJson && res.data.responseJson.data) {
						data = res.data.responseJson.data;
					}
					this.setState({ files: data ? data : [] });

					const user: any = sessionStorage.getItem("user");
					const role_id = user ? JSON.parse(user)["role_id"] : "";
					const user_id = user ? JSON.parse(user)["userId"] : null;
					const userDetails = user ? JSON.parse(user) : "";
					const currentDate = new Date();
					var dateString = moment(currentDate).format('DD-MM-YYYY')
					if ($.fn.dataTable.isDataTable('#processeodTable')) {
						this.table = $('#processeodTable').DataTable({
							paging: true,
							dom: 'Bfrtip',
							buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
						});

					}
					else {
						this.table = $('#processeodTable').DataTable({
							paging: true,
							dom: 'Bfrtip',
							buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
						});
					}


					// this.props.history.push("/entry-tracking-report" + this.state.id);
				})
				.catch(err => {
					console.log(err);
					this.setState({ files: [] });
				})
		}
		else {
			if (this.state.assigned_to == "") {
				this.setState({ userVAlue: true })
			}
			else {
				let obj: any = {
					id: this.state.id,
					from_date: this.state.fromDate,
					to_date: this.state.toDate,
					employee_id: this.state.assigned_to,
					lawhands_branch: this.state.lawhands_branch,
					bank_name: this.state.bank_name
				};
				this.setState({ userVAlue: false })
				localStorage.setItem("EodReport", JSON.stringify(obj));
				callApi("POST", "process_eod.php", obj)
					.then(res => {
						console.log("116", res);
						// localStorage.setItem("eodreport",obj);
						console.log("98", localStorage.getItem("EodReport"));

						let data = [];
						if (res.data && res.data.responseJson && res.data.responseJson.data) {
							data = res.data.responseJson.data;

						}
						this.setState({ files: data ? data : [] });

						const user: any = sessionStorage.getItem("user");
						const role_id = user ? JSON.parse(user)["role_id"] : "";
						const user_id = user ? JSON.parse(user)["userId"] : null;
						const userDetails = user ? JSON.parse(user) : "";
						const currentDate = new Date();
						var dateString = moment(currentDate).format('DD-MM-YYYY')

						if ($.fn.dataTable.isDataTable('#processeodTable')) {
							this.table = $('#processeodTable').DataTable({
								retrieve: true,
								paging: true,
								dom: 'Bfrtip',
								buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
							});
						}
						else {
							this.table = $('#processeodTable').DataTable({
								paging: true,
								dom: 'Bfrtip',
								buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
							});
						}

						// this.props.history.push("/entry-tracking-report" + this.state.id);
					})
					.catch(err => {
						console.log(err);
						this.setState({ files: [] });
					})
			}
		}

	}
	getBanks() {
		callApi("GET", "bank_get.php")
			.then(res => res.data)
			.then(result => {
				this.setState({ banks: result.data });
			})
			.catch(err => {
				console.log(err);
			});
	}
	handleChange1 = (e, propName?, fieldType?) => {
		let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
		let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
		if (fieldName === "lawhands_branch") {
			const branch = fieldValue;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ [fieldName]: fieldValue });
			this.setState({ filteredUsers });
		}
		if (fieldName === "assigned_to") {
			const employee_id = fieldValue;
			this.setState({ [fieldName]: fieldValue });
		}
		if (fieldName === "bank_name") {
			const bank_name = fieldValue;
			console.log("267", bank_name);
			this.setState({ [fieldName]: fieldValue });
		}
	}
	handleChange = (e, stateName?, type?) => {
		if (e.target.name === "bank_name") {
			this.setState({ todate: e.target.value })
		}
		if (e.target.name === "toDate") {
			this.setState({ todate: e.target.value })
		}
		if (e.target.name === "fromDate") {
			this.setState({ fromdate: e.target.value })
		}
		if (e.target.name === "id") {
			this.setState({ id: e.target.value })
		}
		if (e.target.name === "lawhands_branch") {
			const branch = e.target.value;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ filteredUsers });
		}
		this.setState({ [e.target.name]: e.target.value });
	};
	handleDateChange = (date, dateStr, type) => {
		// console.log(date, dateStr);
		this.setState({ [type]: dateStr });
	};
	disabledDate = (current) => {
		// Disable dates after today and dates before the last 3 months
		return current && (current > moment().endOf('day') || current < moment().subtract(3, 'months').startOf('day'));
	};
	render() {

		const columns = [

			{
				header: 'S.No',
				key: 'index',
				td: (file) => <td onClick={() =>
					this.goToFileDashboard(file.id.slice(3))
				}>{file.index}</td>
			},
			{
				// front_officer: "Priyanga", verify_officer
				header: 'Team Lead Name',
				key: 'team_leader',
				// key: 'front_officer',
				// td: (file) => <h6 onClick={() =>this.goToFileDashboard(file.id.slice(3))}>{file.front_officer}</h6>
				td: (file) =>
					<h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.team_leader}</h6>
			},
			{
				header: 'Process Officer Name',
				key: 'process_officer',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.process_officer}</h6>
			},

			{
				header: 'Process Start Date',
				key: 'process_date',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.process_date}</h6>
			},
			{
				header: "File No",
				key: 'id',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.id}</h6>

			},
			{
				header: 'File Branch',
				key: 'lawhands_branch',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.lawhands_branch}</h6>
			},
			{
				header: 'Bank Name',
				key: 'bank_name',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.bank_name}</h6>
			},
			{
				header: 'New Login ',
				key: 'intime_reason',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.intime_reason}</h6>
			},
			{

				header: 'Highlights',
				key: 'highlight',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.highlight === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Data Entry',
				key: 'date_enter',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.date_enter === "YES" ? "text-c-green1" : "text-c-red1"}></div>
				// <span className="slash">/</span>

			},
			{
				header: 'Flow Chart',
				key: 'flowchart',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.flowchart === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Queries',
				key: 'query',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.query === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			}, {
				header: 'Status',
				key: 'outtime_reason',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.outtime_reason}</h6>
			}, {
				header: 'Total Mints',
				key: 'total_minutes',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.total_minutes}:Mints</h6>
			},
			{
				header: 'File Start Time',
				key: 'intime',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.intime}</h6>
			},
			{
				header: 'File End Time',
				key: 'outtime',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.outtime}</h6>
			}
			// ,{
			// 	header: 'Daily Meeting Attended',
			// 	key: 'highlight',
			// 	td: (file) => <div onClick={() =>this.goToFileDashboard(file.id.slice(3))} className={file.typing_complete === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			// },{
			// 	header: 'Remarks',
			// 	key: 'highlight',
			// 	td: (file) => <div onClick={() =>this.goToFileDashboard(file.id.slice(3))} className={file.typing_complete === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			// },
		];
		const data = this.state.files;
		// const downloadExcelProps = {
		// 	type: 'filtered',
		// 	title: 'test',
		// showLabel: true
		//   }
		const user: any = sessionStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		// const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		const { toDate, setResponse, selectVal } = this.state;

		const lawhandsBranchesoptions = [
			{ value: "", label: "All" },
			...this.state.lawhandsBranches
		];
		const banksoptions = [
			{ value: "", label: "All" },
			...this.state.banks
		];
		const usersoptions = [
			{ value: "", label: "All" },
			...this.state.users
		];
		return (
			<section className="pcoded-main-container">
				<ToastsContainer
					store={ToastsStore}
					position={ToastsContainerPosition.TOP_RIGHT}
				/>{" "}
				<div className="pcoded-wrapper">
					<div className="pcoded-content">
						<div className="pcoded-inner-content">
							<div className="main-body">
								<div className="page-wrapper">
									<div className="row">
										<div className="col-sm-12">
											<div className="card" style={{ overflow: 'hidden' }}>
												<div className="card-header">
													<h2 className="file-status-header" style={{ display: "flex", justifyContent: "center" }}>Process Eod Report</h2>
													<div className="row mb-3">
														<div className="col-xl-2 col-md-2">
															<h6>From</h6>
															<DatePicker format={'DD-MM-YYYY'}
																disabledDate={this.disabledDate}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>To</h6>
															<DatePicker format={'DD-MM-YYYY'}
																disabledDate={this.disabledDate}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>Bank Name</h6>
															{this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id
															}) &&
																<Select options={banksoptions} name="bank_name"
																	value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
																	onChange={(e) => this.handleChange1(e, 'bank_name', 'singleselect')}
																/>
															}
															{/* <select
                                                        className="custom-select"
                                                        name=""
                                                        onChange={this.handleChange}
                                                        value={this.state.bank_name}
                                                    >
                                                        <option value="">Select</option>
                                                        {this.state.banks && this.state.banks.length
                                                            ? this.state.banks.map((bank: any, index: number) => {
                                                                return (
                                                                    <option key={index} value={bank.id}>
                                                                        {bank.name}
                                                                    </option>
                                                                );
                                                            })
                                                            : ""}
                                                    </select> */}
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>LH Branch</h6>
															{this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id
															}) &&
																<Select options={lawhandsBranchesoptions} name="lawhands_branch"
																	value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
																	onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
																/>
															}
														</div>
														{isFront ? (<>
															<div className="col-xl-2 col-md-2">
																<h6>Employee Name</h6>
																{this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
																	opt.label = opt.name; opt.value = opt.id

																}) &&

																	<Select options={this.state.users} name="assigned_to"
																		value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
																		onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
																		required
																	/>

																}
																{this.state.userVAlue ? (<><p style={{ color: 'red', fontSize: '10px', textAlign: 'center' }}>*Please Select The Employe Name</p></>) : null}
															</div></>) : (<>
																<div className="col-xl-2 col-md-2">
																	<h6>Employee Name</h6>
																	{this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
																		opt.label = opt.name; opt.value = opt.id
																	}) &&
																		<Select options={usersoptions} name="assigned_to"
																			value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
																			onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}

																		/>
																	}
																</div></>)}

														{/* <div className="col-xl-2 col-md-2">
															<h6>Employee Name</h6>
															{this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id
															}) &&
																<Select options={this.state.users} name="assigned_to"
																	value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
																	onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
																	required
																/>
															}
														</div> */}

														<div className="col-xl-2 col-md-2">
															<h5>File.No</h5>
															<input type="id" name="id" className="form-control" id="exampleFormControlInput1"
																placeholder=""
																value={this.state.id}
																onChange={e => this.handleChange(e)}
															/>
														</div>
														<div className="col-xl-4 col-md-4" style={{ left: '88%' }}>
															<button
																type="submit"
																className="btn btn-primary"
																// style={{ alignSelf: 'end' }}
																onClick={() => this.getEodTableData()}
															>
																Search
															</button>
														</div>
													</div>
												</div>
												{/* <div className="card-body">
													{/* <ReactFlexyTable data={data} columns={columns} sortable showExcelButton /> */}
												{/* </div> */}
												<div className="card-body">
													<div className="dt-responsive table-responsive">
														<table
															id="processeodTable"
															className="table table-striped table-bordered nowrap"
														>
															<thead>
																<tr>
																	<th>Sl.No</th>
																	<th>Team Leader</th>
																	<th>Process Officer </th>
																	<th>Process Start Date</th>
																	<th>File No</th>
																	<th>File Branch</th>
																	<th>Bank Name</th>
																	<th>In Reason</th>
																	<th>Highlights</th>
																	<th>Data Entry</th>
																	<th>Flow Chart</th>
																	<th>Queries</th>
																	<th>Out Reason</th>
																	<th>File Start Time</th>
																	<th>File End Time</th>
																	<th>Total Mints</th>
																</tr>
															</thead>
															<tbody>
																{this.state.files && this.state.files.length
																	? this.state.files.map(
																		(query: any, index: number) => {
																			return (
																				<tr className="query-table-row cursor-pointer" key={index} onClick={() => this.goToFileDashboard(query.id.slice(3))}>
																					<td className="text-align-center">{index + 1}</td>
																					<td className="click">
																						{query.team_leader}
																					</td>
																					<td className="click">
																						{query.process_officer}
																					</td>
																					<td className="click">
																						{query.process_date}
																					</td>
																					<td className="click">
																						{query.id}
																					</td>
																					<td className="click">
																						{query.lawhands_branch}
																					</td>
																					<td className="click">
																						{query.bank_name}
																					</td>
																					<td className="click">
																						{query.intime_reason}
																					</td>
																					<td className={query.highlight === "YES" ? "text-c-green1 click" : "text-c-red1 click"}>
																						{query.highlight}
																					</td>
																					<td className={query.date_enter === "YES" ? "text-c-green1 click" : "text-c-red1 click"}>
																						{query.date_enter}
																					</td>
																					<td className={query.flowchart === "YES" ? "text-c-green1 click" : "text-c-red1 click"}>
																						{query.flowchart}
																					</td>
																					<td className={query.query === "YES" ? "text-c-green1 click" : "text-c-red1 click"}>
																						{query.query}
																					</td>
																					<td className="click">
																						{query.outtime_reason}
																					</td>
																					<td className="click">
																						{query.intime}
																					</td>
																					<td className="click">
																						{query.outtime}
																					</td>
																					<td className="click">
																						{query.total_minutes}

																					</td>
																				</tr>
																			);
																		}
																	)
																	: ""}
															</tbody>
															{/* <tfoot>
															<tr>
																	<th>S.No</th>
																	<th>File No</th>
																	<th>Team Lead Name</th>
																	<th>Process Officer Name</th>
																	<th>Process Start Date</th>
																	<th>File Branch</th>
																	<th>Bank Name</th>
																	<th>New Login</th>
																	<th>Highlights</th>
																	<th>Data Entry</th>
																	<th>Flow Chart</th>
																	<th>Queries</th>
																	<th>Status</th>
																	<th>Total Mints</th>
																	<th>File Start Time</th>
																	<th>File End Time</th>

																</tr>
															</tfoot> */}
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* THIS FOR OUT REASON MODAL */}
				<div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog ">
						<div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
							<div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
								<h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
							</div>
							<div className="form-group" style={{ width: '74%', margin: 'auto' }}>
								<select
									className="custom-select"
									name="outreason"
									value={this.state.outreason}
									onChange={(e) => this.onchangeReason(e)}
									required
									id="select1"
									style={{ marginTop: '8px' }}
								>
									<option value="select">Select Your Reason</option>
									<option value="Query Raised">Query Raised</option>
									<option value=" Re-Query Raised"> Re-Query Raised</option>
									<option value="Hold ">Hold </option>
									<option value="Completed">Completed</option>
									<option value="Other">Others</option>

								</select>
								{selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
							</div>
							<div className="modal-footer" style={{ marginTop: '27px' }}>
								<button
									type="button"
									className="btn btn-primary"
									disabled={this.state.isLoading}
									onClick={this.outimeGet}
									style={{ width: '76px', fontSize: '17px' }}
								> {this.state.isLoading ? (
									<i className="fa fa-spinner fa-spin"></i>
								) : "OK"}</button>
								<button
									type="button"
									className="btn btn-outline"
									onClick={this.BacktoFile}
									style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
								> Back To File</button>
							</div>
						</div>
					</div>
				</div>
				{/* OTHER REASON MODAL */}
				<div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog ">
						<div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
							<h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
							<div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

								{/* <div className="col-md-5"> */}
								<div className="form-group" style={{ width: '98%', margin: 'auto' }}>
									<textarea
										className="form-control"
										placeholder="Type Your Reason"
										name="outreason" onChange={this.onchange} />
									{selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
								</div>
								{/* </div> */}

							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => this.outimeGet()}
									style={{ width: '76px', fontSize: '17px' }}
									disabled={this.state.isLoading}
								> {this.state.isLoading ? (
									<i className="fa fa-spinner fa-spin"></i>
								) : "OK"}</button>
								<button
									type="button"
									className="btn btn-outline"

									onClick={() => this.previousPop()}
									// onClick={this.disableModalHandler}
									style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
								> Back</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


export default ProcessEod;

function x(arg0: string, x: any) {
	throw new Error("Function not implemented.");
}
