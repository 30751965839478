import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import DataModel from './sopDataModel';

import { Deeds } from './sopDataTemplate';
interface AllProps {
    data: Deeds;
    handleDeedChange: any;
}

export class Deed extends Component<AllProps> {
    state = {
        isOptionOpen: false,
        bgColor: '#ffff8d',
        responseData: {},
        isUpdateAvailable: false
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            responseData: JSON.parse(JSON.stringify(data))
        })
    }


    changeBgColor = () => {
        const { bgColor } = this.state;
        if (bgColor !== 'lightgreen') {
            this.setState({ bgColor: 'lightgreen' })
        }
    }
    handleChange = (name, val) => {
        const { data } = this.props;
        data[name] = val;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleDeedChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }
    handleChildInputChange = (key, name, value, index?) => {
        // console.log("key & value", `${key}-${name}`);
        const { data } = this.props;
        if (index !== undefined) data[key][index][name] = value;
        else data[key][name] = value;
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleDeedChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }
    handleChildRadioChange = (key, name, val, index?) => {
        // console.log("key & value", `${key}-${name}`);
        const { data } = this.props;
        if (index !== undefined) {
            data[key][index][name] = val;
        }
        else {
            data[key][name] = val;
        }
        this.changeBgColor();
        data.bgColor = 'lightgreen';
        this.props.handleDeedChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }


    checkOperation = (type, value, index) => {
        let isEditable = false
        const { responseData } = this.state;
        if (responseData[type] && responseData[type].length < (index + 1)) {
            isEditable = true;
        };
        return isEditable;
    }

    addChild = (type) => {
        const { data } = this.props;
        if (type == "saleDeed") {
            data[type].push({
                // ...JSON.parse(JSON.stringify(DataModel['deed'][type][0]))
                "saleDeedAmountTransfered": false,
            });
        } else if (type == "giftDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "condition": false,
                "conditionFulfilled": false,
                "mentionCondition": ""
            });
        } else if (type == "settlementDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "condition": false,
                "conditionFulfilled": false,
                "mentionCondition": ""
            });
        } else if (type == "partitionDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "condition": false,
                "conditionFulfilled": false,
                "mentionCondition": "",
                "docAllotedForMortgage": "",
                "originalCopyAvailable": ""
            });
        } else if (type == "willDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "condition": false,
                "conditionFulfilled": false,
                "mentionCondition": ""
            });
        } else if (type == "leaseDeed") {
            data[type].push({
                "leasePeriodFromDate": "",
                "leasePeriodToDate": "",
                "nocAvailable": false
            });
        } else if (type == "attorneyDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "isInForce": false,
                "isCertifiedCopyAvailable": false
            });
        } else if (type == "releaseDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "condition": false,
                "fulFilled": false,
                "propertyshare": "",
                "mentionCondition": "",
            });
        } else if (type == "rectificationDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "mentionDetails": "",
                "correctDetails": "",
            });
        } else if (type == "consentDeed") {
            data[type].push({
                "derivedBy": "",
                "registered": false,
                "reason": "",
            });
        }
        else {
            // data[type].push({
            //     ...JSON.parse(JSON.stringify(DataModel['deed'][type][0]))
            // });
        }
        this.props.handleDeedChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }

    removeChild = (type, index) => {
        const { data } = this.props;
        delete data[type][index];
        this.props.handleDeedChange(data);
        this.setState({
            isUpdateAvailable: !this.state.isUpdateAvailable
        })
    }

    getAddButton = (title, value) => {
        // console.log("add btn type--->>", `${title}---${value}`);
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6><b>{title}</b></h6>
                <div onClick={() => this.addChild(value)}>
                    <button className='btn btn-sm btn-primary' style={{ fontSize: 'smaller' }}>Add {title}</button>
                </div>
            </div>
        )
    }


    render() {
        const { data } = this.props;
        const { isOptionOpen, bgColor } = this.state;
        return (
            <div className="collapse-section deed-section">
                <Accordion>
                    <Card>
                        <Accordion.Toggle style={{ background: data.bgColor ? data.bgColor : bgColor }} as={Card.Header} eventKey="2" onClick={() => { this.setState({ isOptionOpen: !isOptionOpen }) }}>
                            <h5>
                                <b>Deed</b>
                            </h5>
                            <i className={`icon feather icon-${!isOptionOpen ? 'plus' : 'minus'}`} style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', float: 'right' }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div>
                                    {this.getAddButton('Sale Deed', 'saleDeed')}
                                    {data.saleDeed && data.saleDeed.map((value: any, index: number) => {
                                        return (
                                            <div className="row">
                                                <div className='col-md-5'>
                                                    <label className="label" style={{ padding: 0 }}>Amount Fully Transfered?</label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={'saleDeed' + (value && value.saleDeedAmountTransfered ? value.saleDeedAmountTransfered : "") + index}
                                                        type="radio"
                                                        checked={value && value.saleDeedAmountTransfered !== null && value.saleDeedAmountTransfered !== undefined ? value.saleDeedAmountTransfered : false}
                                                        onChange={(e) => this.handleChildRadioChange("saleDeed", 'saleDeedAmountTransfered', true, index)}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name={"saleDeed" + (value && !value.saleDeedAmountTransfered ? !value.saleDeedAmountTransfered : '') + index}
                                                        type="radio"
                                                        checked={value && value.saleDeedAmountTransfered !== null && value.saleDeedAmountTransfered !== undefined ? !value.saleDeedAmountTransfered : false}
                                                        onChange={(e) => this.handleChildRadioChange("saleDeed", 'saleDeedAmountTransfered', false, index)}
                                                    />
                                                </div>
                                                {this.checkOperation('saleDeed', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('saleDeed', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Gift Deed', 'giftDeed')}
                                    {data.giftDeed && Array.isArray(data.giftDeed) && data.giftDeed.map((value, index) => {
                                        return (
                                            <div className="row">
                                                <div className="form-group  col-md-4" >
                                                    <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                    <input
                                                        className="form-control"
                                                        name={'giftDeed' + value.derivedBy + index}
                                                        type="text"
                                                        value={value.derivedBy}
                                                        onChange={(e) => this.handleChildInputChange("giftDeed", 'derivedBy', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'giftDeedregistered' + value.registered + index}
                                                            type="radio"
                                                            checked={value.registered}
                                                            onChange={() => this.handleChildRadioChange("giftDeed", "registered", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={'giftDeedregistered' + !value.registered + index}
                                                            type="radio"
                                                            checked={!value.registered}
                                                            onChange={() => this.handleChildRadioChange("giftDeed", "registered", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-md-3">
                                                    <label className="label" style={{ padding: 0 }}>condition?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={'giftDeedcondition' + value.condition + index}
                                                            type="radio"
                                                            checked={value.condition}
                                                            onChange={() => this.handleChildRadioChange("giftDeed", "condition", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={'giftDeedcondition' + !value.condition + index}
                                                            type="radio"
                                                            checked={!value.condition}
                                                            onChange={() => this.handleChildRadioChange("giftDeed", "condition", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {this.checkOperation('giftDeed', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('giftDeed', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                                {value.condition ?
                                                    <>
                                                        <div className="form-group  col-md-4" style={{ marginBottom: 0 }}>
                                                            <label className="label" style={{ padding: 0 }}>condition Fullfilled?</label>
                                                            <div className="row" style={{ marginLeft: 0 }}>
                                                                <Form.Check
                                                                    inline
                                                                    label="Yes"
                                                                    name={"giftDeedconditionFulfilled" + value.conditionFulfilled + index}
                                                                    type="radio"
                                                                    checked={value.conditionFulfilled}
                                                                    onChange={() => this.handleChildRadioChange("giftDeed", "conditionFulfilled", true, index)}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="No"
                                                                    name={"giftDeedconditionFulfilled" + !value.conditionFulfilled + index}
                                                                    type="radio"
                                                                    checked={!value.conditionFulfilled}
                                                                    onChange={() => this.handleChildRadioChange("giftDeed", "conditionFulfilled", false, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group  col-md-8" style={{ marginBottom: 0 }}>
                                                            <label className="label" style={{ padding: 0 }}>Mention Condition</label>
                                                            <input
                                                                className="form-control"
                                                                name={"giftDeedmentionCondition" + value.mentionCondition + index}
                                                                type="text"
                                                                value={value.mentionCondition}
                                                                onChange={(e) => this.handleChildInputChange("giftDeed", 'mentionCondition', e.target.value, index)}
                                                            />
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Settlement Deed', 'settlementDeed')}
                                    {data.settlementDeed && Array.isArray(data.settlementDeed) && data.settlementDeed.map((value, index) => {
                                        return (
                                            <div className="row">
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                    <input
                                                        className="form-control"
                                                        name={"settlementDeedderivedBy" + value.derivedBy + index}
                                                        type="text"
                                                        value={value.derivedBy}
                                                        onChange={(e) => this.handleChildInputChange("settlementDeed", 'derivedBy', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"settlementDeedregistered" + value.registered + index}
                                                            type="radio"
                                                            checked={value.registered}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "registered", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"settlementDeedregistered" + !value.registered + index}
                                                            type="radio"
                                                            checked={!value.registered}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "registered", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-md-3">
                                                    <label className="label" style={{ padding: 0 }}>condition?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"settlementDeedcondition" + value.condition + index}
                                                            type="radio"
                                                            checked={value.condition}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "condition", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"settlementDeedcondition" + !value.condition + index}
                                                            type="radio"
                                                            checked={!value.condition}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "condition", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {this.checkOperation('settlementDeed', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('settlementDeed', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                                <div className="form-group  col-md-4" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>condition Fullfilled?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"settlementDeedconditionFulfilled" + value.conditionFulfilled + index}
                                                            type="radio"
                                                            checked={value.conditionFulfilled}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "conditionFulfilled", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"settlementDeedconditionFulfilled" + !value.conditionFulfilled + index}
                                                            type="radio"
                                                            checked={!value.conditionFulfilled}
                                                            onChange={() => this.handleChildRadioChange("settlementDeed", "conditionFulfilled", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-md-8" style={{ marginBottom: 0 }}>
                                                    <label className="label" style={{ padding: 0 }}>Mention Condition</label>
                                                    <input
                                                        className="form-control"
                                                        name={"settlementDeedmentionCondition" + value.mentionCondition + index}
                                                        type="text"
                                                        value={value.mentionCondition}
                                                        onChange={(e) => this.handleChildInputChange("settlementDeed", 'mentionCondition', e.target.value, index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Partition Deed', 'partitionDeed')}
                                    {data.partitionDeed && Array.isArray(data.partitionDeed) && data.partitionDeed.map((value, index) => {
                                        return (
                                            <>
                                                <div className="row">
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                        <input
                                                            className="form-control"
                                                            name={"partitionDeedderivedBy" + value.derivedBy + index}
                                                            type="text"
                                                            value={value.derivedBy}
                                                            onChange={(e) => this.handleChildInputChange("partitionDeed", 'derivedBy', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"partitionDeedregistered" + value.registered + index}
                                                                type="radio"
                                                                checked={value.registered}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "registered", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"partitionDeedregistered" + !value.registered + index}
                                                                type="radio"
                                                                checked={!value.registered}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "registered", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group  col-md-3">
                                                        <label className="label" style={{ padding: 0 }}>condition?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"partitionDeedcondition" + value.condition + index}
                                                                type="radio"
                                                                checked={value.condition}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "condition", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"partitionDeedcondition" + !value.condition + index}
                                                                type="radio"
                                                                checked={!value.condition}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "condition", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('partitionDeed', value, index) &&
                                                        <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('partitionDeed', index)}
                                                        >
                                                            <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>condition Fullfilled?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"partitionDeedconditionFulfilled" + value.conditionFulfilled + index}
                                                                type="radio"
                                                                checked={value.conditionFulfilled}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "conditionFulfilled", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"partitionDeedconditionFulfilled" + !value.conditionFulfilled + index}
                                                                type="radio"
                                                                checked={!value.conditionFulfilled}
                                                                onChange={() => this.handleChildRadioChange("partitionDeed", "conditionFulfilled", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group col-md-8'>
                                                        <label className="label" style={{ padding: 0 }}>Original/ simultaneous copy available?</label>
                                                        <input
                                                            className="form-control"
                                                            name={"partitionDeedoriginalCopyAvailable" + value.originalCopyAvailable + index}
                                                            type="text"
                                                            value={value.originalCopyAvailable}
                                                            onChange={(e) => this.handleChildInputChange("partitionDeed", 'originalCopyAvailable', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='form-group col-md-12' style={{ marginBottom: 0 }}>
                                                        <label className="label" style={{ padding: 0 }}>Mention Condition</label>
                                                        <input
                                                            className="form-control"
                                                            name={"partitionDeedmentionCondition" + value.mentionCondition + index}
                                                            type="text"
                                                            value={value.mentionCondition}
                                                            onChange={(e) => this.handleChildInputChange("partitionDeed", 'mentionCondition', e.target.value, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Will Deed', 'willDeed')}
                                    {data.willDeed && Array.isArray(data.willDeed) && data.willDeed.map((value, index) => {
                                        return (
                                            <div className="row">
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                    <input
                                                        className="form-control"
                                                        name={"willDeedderivedBy" + value.derivedBy + index}
                                                        type="text"
                                                        value={value.derivedBy}
                                                        onChange={(e) => this.handleChildInputChange("willDeed", 'derivedBy', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"willDeedregistered" + value.registered + index}
                                                            type="radio"
                                                            checked={value.registered}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "registered", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"willDeedregistered" + !value.registered + index}
                                                            type="radio"
                                                            checked={!value.registered}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "registered", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-md-3">
                                                    <label className="label" style={{ padding: 0 }}>condition?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"willDeedcondition" + value.condition + index}
                                                            type="radio"
                                                            checked={value.condition}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "condition", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"willDeedcondition" + !value.condition + index}
                                                            type="radio"
                                                            checked={!value.condition}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "condition", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {this.checkOperation('willDeed', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('willDeed', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>condition Fullfilled?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"willDeedconditionFulfilled" + value.conditionFulfilled + index}
                                                            type="radio"
                                                            checked={value.conditionFulfilled}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "conditionFulfilled", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"willDeedconditionFulfilled" + !value.conditionFulfilled + index}
                                                            type="radio"
                                                            checked={!value.conditionFulfilled}
                                                            onChange={() => this.handleChildRadioChange("willDeed", "conditionFulfilled", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-md-8" style={{ marginBottom: 0 }} >
                                                    <label className="label" style={{ padding: 0 }}>Mention Condition</label>
                                                    <input
                                                        className="form-control"
                                                        name={"willDeedmentionCondition" + value.mentionCondition + index}
                                                        type="text"
                                                        value={value.mentionCondition}
                                                        onChange={(e) => this.handleChildInputChange("willDeed", 'mentionCondition', e.target.value, index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    {this.getAddButton('Lease Deed', 'leaseDeed')}
                                    {data.leaseDeed && Array.isArray(data.leaseDeed) && data.leaseDeed.map((value, index) => {
                                        return (
                                            <div className="row">
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Lease Period From Date</label>
                                                    <input
                                                        className="form-control"
                                                        name={"leaseDeedleasePeriodFromDate" + value.leasePeriodFromDate + index}
                                                        type="text"
                                                        value={value.leasePeriodFromDate}
                                                        onChange={(e) => this.handleChildInputChange("leaseDeed", 'leasePeriodFromDate', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-4">
                                                    <label className="label" style={{ padding: 0 }}>Lease Period To Date</label>
                                                    <input
                                                        className="form-control"
                                                        name={"leaseDeedleasePeriodToDate" + value.leasePeriodToDate + index}
                                                        type="text"
                                                        value={value.leasePeriodToDate}
                                                        onChange={(e) => this.handleChildInputChange("leaseDeed", 'leasePeriodToDate', e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group  col-md-3">
                                                    <label className="label" style={{ padding: 0 }}>NOC Available?</label>
                                                    <div className="row" style={{ marginLeft: 0 }}>
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name={"leaseDeednocAvailable" + value.nocAvailable + index}
                                                            type="radio"
                                                            checked={value.nocAvailable}
                                                            onChange={() => this.handleChildRadioChange("leaseDeed", "nocAvailable", true, index)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name={"leaseDeednocAvailable" + !value.nocAvailable + index}
                                                            type="radio"
                                                            checked={!value.nocAvailable}
                                                            onChange={() => this.handleChildRadioChange("leaseDeed", "nocAvailable", false, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {this.checkOperation('leaseDeed', value, index) &&
                                                    <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                        onClick={() => this.removeChild('leaseDeed', index)}
                                                    >
                                                        <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />




                                <div>
                                    {this.getAddButton('Partition Release Deed', 'releaseDeed')}
                                    {data.releaseDeed && Array.isArray(data.releaseDeed) && data.releaseDeed.map((value, index) => {
                                        return (
                                            <>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                        <input
                                                            className="form-control"
                                                            name={"releaseDeedderivedBy" + value.derivedBy + index}
                                                            type="text"
                                                            value={value.derivedBy}
                                                            onChange={(e) => this.handleChildInputChange("releaseDeed", 'derivedBy', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-3">
                                                        <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"releaseDeedregistered" + value.registered + index}
                                                                type="radio"
                                                                checked={value.registered}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "registered", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"releaseDeedregistered" + !value.registered + index}
                                                                type="radio"
                                                                checked={!value.registered}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "registered", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group  col-md-3">
                                                        <label className="label" style={{ padding: 0 }}>Condition?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"releaseDeedcondition" + value.condition + index}
                                                                type="radio"
                                                                checked={value.condition}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "condition", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"releaseDeedcondition" + !value.condition + index}
                                                                type="radio"
                                                                checked={!value.condition}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "condition", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('releaseDeed', value, index) &&
                                                        <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('releaseDeed', index)}
                                                        >
                                                            <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Condition Fulfilled?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"releaseDeedfulFilled" + value.fulFilled + index}
                                                                type="radio"
                                                                checked={value.fulFilled}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "fulFilled", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"releaseDeedfulFilled" + !value.fulFilled + index}
                                                                type="radio"
                                                                checked={!value.fulFilled}
                                                                onChange={() => this.handleChildRadioChange("releaseDeed", "fulFilled", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Property Share? <span>Eg: 1/3, 4/7</span></label>
                                                        <input
                                                            className="form-control"
                                                            name={"releaseDeedpropertyshare" + value.propertyshare + index}
                                                            type="text"
                                                            value={value.propertyshare}
                                                            onChange={(e) => this.handleChildInputChange("releaseDeed", 'propertyshare', e.target.value, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-10">
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Mention Condition</label>
                                                        <input
                                                            className="form-control"
                                                            name={"releaseDeedmentionCondition" + value.mentionCondition + index}
                                                            type="text"
                                                            value={value.mentionCondition}
                                                            onChange={(e) => this.handleChildInputChange("releaseDeed", 'mentionCondition', e.target.value, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr />


                                <div>
                                    {this.getAddButton('Rectification Deed', 'rectificationDeed')}
                                    {data.rectificationDeed && Array.isArray(data.rectificationDeed) && data.rectificationDeed.map((value, index) => {
                                        return (
                                            <>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                        <input
                                                            className="form-control"
                                                            name={"rectificationDeedderivedBy" + value.derivedBy + index}
                                                            type="text"
                                                            value={value.derivedBy}
                                                            onChange={(e) => this.handleChildInputChange("rectificationDeed", 'derivedBy', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"rectificationDeedregistered" + value.registered + index}
                                                                type="radio"
                                                                checked={value.registered}
                                                                onChange={() => this.handleChildRadioChange("rectificationDeed", "registered", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"rectificationDeedregistered" + !value.registered + index}
                                                                type="radio"
                                                                checked={!value.registered}
                                                                onChange={() => this.handleChildRadioChange("rectificationDeed", "registered", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('rectificationDeed', value, index) &&
                                                        <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('rectificationDeed', index)}
                                                        >
                                                            <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mb-10">
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Wrongly Mentioned Details</label>
                                                        <input
                                                            className="form-control"
                                                            name={"rectificationDeedmentionDetails" + value.mentionDetails + index}
                                                            type="text"
                                                            value={value.mentionDetails}
                                                            onChange={(e) => this.handleChildInputChange("rectificationDeed", 'mentionDetails', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Correct Details</label>
                                                        <input
                                                            className="form-control"
                                                            name={"rectificationDeedcorrectDetails" + value.correctDetails + index}
                                                            type="text"
                                                            value={value.correctDetails}
                                                            onChange={(e) => this.handleChildInputChange("rectificationDeed", 'correctDetails', e.target.value, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr />


                                <div>
                                    {this.getAddButton('Consent Deed', 'consentDeed')}
                                    {data.consentDeed && Array.isArray(data.consentDeed) && data.consentDeed.map((value, index) => {
                                        return (
                                            <>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                        <input
                                                            className="form-control"
                                                            name={"consentDeedderivedBy" + value.derivedBy + index}
                                                            type="text"
                                                            value={value.derivedBy}
                                                            onChange={(e) => this.handleChildInputChange("consentDeed", 'derivedBy', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-4">
                                                        <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"consentDeedregistered" + value.registered + index}
                                                                type="radio"
                                                                checked={value.registered}
                                                                onChange={() => this.handleChildRadioChange("consentDeed", "registered", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"consentDeedregistered" + !value.registered + index}
                                                                type="radio"
                                                                checked={!value.registered}
                                                                onChange={() => this.handleChildRadioChange("consentDeed", "registered", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('consentDeed', value, index) &&
                                                        <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('consentDeed', index)}
                                                        >
                                                            <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mb-10">
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Reason for Consent Deed</label>
                                                        <input
                                                            className="form-control"
                                                            name={"consentDeedreason" + value.reason + index}
                                                            type="text"
                                                            value={value.reason}
                                                            onChange={(e) => this.handleChildInputChange("consentDeed", 'reason', e.target.value, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr />





                                <div>
                                    {this.getAddButton('General Power Of Attorney Deed', 'attorneyDeed')}
                                    {data.attorneyDeed && Array.isArray(data.attorneyDeed) && data.attorneyDeed.map((value, index) => {
                                        return (
                                            <>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Executant Derived by</label>
                                                        <input
                                                            className="form-control"
                                                            name={"attorneyDeedderivedBy" + value.derivedBy + index}
                                                            type="text"
                                                            value={value.derivedBy}
                                                            onChange={(e) => this.handleChildInputChange("attorneyDeed", 'derivedBy', e.target.value, index)}
                                                        />
                                                    </div>
                                                    <div className="form-group  col-md-5">
                                                        <label className="label" style={{ padding: 0 }}>Registered?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"attorneyDeedregistered" + value.registered + index}
                                                                type="radio"
                                                                checked={value.registered}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "registered", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"attorneyDeedregistered" + !value.registered + index}
                                                                type="radio"
                                                                checked={!value.registered}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "registered", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.checkOperation('attorneyDeed', value, index) &&
                                                        <div className='form-group col-md-1' style={{ alignSelf: 'center', fontSize: 'initial', cursor: 'pointer' }}
                                                            onClick={() => this.removeChild('attorneyDeed', index)}
                                                        >
                                                            <i style={{ color: 'red' }} className="feather icon-minus-circle" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='row mb-10'>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Is in force?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"attorneyDeedisInForce" + value.isInForce + index}
                                                                type="radio"
                                                                checked={value.isInForce}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "isInForce", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"attorneyDeedisInForce" + !value.isInForce + index}
                                                                type="radio"
                                                                checked={!value.isInForce}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "isInForce", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group  col-md-6">
                                                        <label className="label" style={{ padding: 0 }}>Is certified copy available?</label>
                                                        <div className="row" style={{ marginLeft: 0 }}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name={"attorneyDeedisCertifiedCopyAvailable" + value.isCertifiedCopyAvailable + index}
                                                                type="radio"
                                                                checked={value.isCertifiedCopyAvailable}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "isCertifiedCopyAvailable", true, index)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No"
                                                                name={"attorneyDeedisCertifiedCopyAvailable" + !value.isCertifiedCopyAvailable + index}
                                                                type="radio"
                                                                checked={!value.isCertifiedCopyAvailable}
                                                                onChange={() => this.handleChildRadioChange("attorneyDeed", "isCertifiedCopyAvailable", false, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Plot Regularization</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-12">
                                            <label className="label" style={{ padding: 0 }}>Is Title Deed Registered After 30-09-2016?</label>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isDeedRegisteredAfter30_9_2016"
                                                    type="radio"
                                                    checked={data.plotRegularization.isDeedRegisteredAfter30_9_2016}
                                                    onChange={() => this.handleChildRadioChange("plotRegularization", "isDeedRegisteredAfter30_9_2016", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isDeedRegisteredAfter30_9_2016"
                                                    type="radio"
                                                    checked={!data.plotRegularization.isDeedRegisteredAfter30_9_2016}
                                                    onChange={() => this.handleChildRadioChange("plotRegularization", "isDeedRegisteredAfter30_9_2016", false)}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-10">
                                        <div className="col-md-12">
                                            <label className="label" style={{ padding: 0 }}>Is Any Of the Following Obtained?
                                                <div>1. Plot Regularization, 2. DCTP Approval, 3. Building Approval</div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isPlotObtained"
                                                    type="radio"
                                                    checked={data.plotRegularization.isPlotObtained}
                                                    onChange={() => this.handleChildRadioChange("plotRegularization", "isPlotObtained", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isPlotObtained"
                                                    type="radio"
                                                    checked={!data.plotRegularization.isPlotObtained}
                                                    onChange={() => this.handleChildRadioChange("plotRegularization", "isPlotObtained", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Foriegn Power</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <label className="label" style={{ padding: 0 }}>Is The Foriegn Power Registered?</label>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="isForiegnPowerRegistered"
                                                    type="radio"
                                                    checked={data.foriegnPower.isForiegnPowerRegistered}
                                                    onChange={() => this.handleChildRadioChange("foriegnPower", "isForiegnPowerRegistered", true)}

                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="isForiegnPowerRegistered"
                                                    type="radio"
                                                    checked={!data.foriegnPower.isForiegnPowerRegistered}
                                                    onChange={() => this.handleChildRadioChange("foriegnPower", "isForiegnPowerRegistered", false)}
                                                />
                                            </div>
                                        </div>
                                        {data.foriegnPower.isForiegnPowerRegistered &&
                                            <div className="col-md-6">
                                                <label className="label" style={{ padding: 0 }}>Doc No</label>
                                                <input
                                                    className="form-control"
                                                    name="docNo"
                                                    type="text"
                                                    value={data.foriegnPower.docNo}
                                                    onChange={(e) => this.handleChildInputChange("foriegnPower", 'docNo', e.target.value)}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h6><b>Power To Mortgage</b></h6>
                                    <div className="row mb-10">
                                        <div className="col-md-12">
                                            <label className="label" style={{ padding: 0 }}>Is There A Word MORTGAGE Mentioned In Power Document?
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="mortgageInPowerDoc"
                                                    type="radio"
                                                    checked={data.mortgageInPowerDoc}
                                                    onChange={() => this.handleChange("mortgageInPowerDoc", true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="mortgageInPowerDoc"
                                                    type="radio"
                                                    checked={!data.mortgageInPowerDoc}
                                                    onChange={() => this.handleChange("mortgageInPowerDoc", false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion >
            </div >
        )
    }
}

export default Deed
