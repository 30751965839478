import * as React from "react";
import { callApi } from "../../utils/api";
import "react-flexy-table/dist/index.css";
import moment from "moment";
declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
  location: {
    state: {
      fileData: any;
      groupedFiles: any;
    };
  };
}
class FileHistory extends React.Component<AllProps> {
  state = {
    file_log: {
      file_id: "",
      front_office: "",
      work_in_progress: [],
      query_raised: [],
      verification_completed: [],
      total_wip_file: "",
      total_qr_file: "",
      total_vc_file: "",
      total_ex_file: "",
      total_queue_file: "",
      total_time: "",
      extended_hours: [],
      queueTime: [
        {
          scan_time: [],
          queryreceived_time: [],
          typing_completed: [],
          reassign_process: [],
          reassign_verfication: [],
          hold_time: [],
          unhold_time: [],
          total_waiting_time: [],
        },
      ],
    },
    groupedFiles: [
      {
        color: "",
        file_id: "",
        reason: "",
        created_by: "",
        created_dt: "",
      },
    ],
    groupedTime: [],
    groupedDeviationTime: [],
    users: [],
    fileDetails: [
      {
        bank_name: "",
        banK_branch_name: "",
        lawhands_branch_name: "",
      },
    ],
    again_query: []

  };

  componentDidMount() {
    console.log("Component mounted");
    const fileLogHistoryString = localStorage.getItem("filelog_history_data");
    if (fileLogHistoryString) {
      const fileLogHistory = JSON.parse(fileLogHistoryString);
      if (fileLogHistory) {
        this.setState(
          {
            file_log: fileLogHistory.fileData,
            groupedFiles: fileLogHistory.groupedFiles,
          },
          () => {
            console.log(
              "File history loaded from localStorage:",
              this.state.file_log,
              this.state.groupedFiles
            );
            this.getTime(this.state.groupedFiles);
            this.getDeviationTime(this.state.groupedFiles);
          }
        );
      }
    } else {
      this.getFileHistory();
      localStorage.setItem("filelog_history", JSON.stringify(true));
    }
    this.getFiles();
    this.getUsers();
    this.getAgainQuery();
  }

  componentWillUnmount() {
    localStorage.removeItem("filelog_history_data");
  }

  getFiles = () => {
    callApi("POST", "search.php", {
      file_name: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        this.setState({ fileDetails: files });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getAgainQuery = () => {
    callApi("POST", "again_query_upload.php", {
      file_id: this.props.match.params.id,
    })
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        this.setState({ again_query: files });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFileHistory = () => {
    console.log("Props:", this.props);
    const { location } = this.props;
    if (
      location &&
      location.state &&
      location.state.fileData &&
      location.state.groupedFiles
    ) {
      const { fileData, groupedFiles } = location.state;
      this.setState({ file_log: fileData, groupedFiles: groupedFiles }, () => {
        console.log("selectedFile", fileData);
        console.log("groupedFiles", groupedFiles);
        this.getTime(groupedFiles);
        this.getDeviationTime(groupedFiles);
        localStorage.setItem(
          "filelog_history_data",
          JSON.stringify({ fileData, groupedFiles })
        );
      });
    } else {
      console.error("File data or grouped files not found in props.");
    }
  };

  getTime = (groupedFiles: any) => {
    const filteredFiles = groupedFiles.filter(file => !file.reason.includes("Deviation"));
    let groupedTime = [""];
    groupedTime.shift();
    let timeDiff: string = "";
    for (let index = 0; index < filteredFiles.length - 1; index++) {
      let firstDate = new Date(filteredFiles[index].created_dt);
      let secondDate = new Date(filteredFiles[index + 1].created_dt);
      timeDiff = this.getTimeDiff(firstDate, secondDate);
      groupedTime.push(timeDiff);
      timeDiff = "";
    }
    this.setState({ groupedTime: groupedTime });
    console.log("groupedTime", groupedTime);
  };

  getDeviationTime = (groupedFiles: any) => {
    const filteredFiles = groupedFiles.filter(file => file.reason.includes("Deviation"));
    let groupedDeviationTime = [""];
    groupedDeviationTime.shift();
    let timeDiff: string = "";
    for (let index = 0; index < filteredFiles.length - 1; index++) {
      let firstDate = new Date(filteredFiles[index].created_dt);
      let secondDate = new Date(filteredFiles[index + 1].created_dt);
      timeDiff = this.getTimeDiff(firstDate, secondDate);
      groupedDeviationTime.push(timeDiff);
      timeDiff = "";
    }
    this.setState({ groupedDeviationTime: groupedDeviationTime });
    console.log("groupedDeviationTime", groupedDeviationTime);
  };


  getTimeDiff = (date1: Date, date2: Date) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    const diff = momentDate2.diff(momentDate1);
    const duration = moment.duration(diff);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  getUsers = () => {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getNameById(id) {
    console.log("check id --->",id);
    
    const { users }: any = this.state;
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        return users[i].name;
      }
    }
    return null;
  }

  render() {
    const { file_log, groupedFiles, groupedTime, fileDetails, groupedDeviationTime }: any =
      this.state;
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="mt-1 mb-1">File History</h5>
                        </div>
                        <div className="latest-update-card">
                          <div className="card-body pt-3">
                            <div className="row mb-3">
                              <div className="col-xl-8 col-sm-8 col-md-8">
                                <div className="file-details">
                                  <h6 className="m-1">
                                    File No : <span>{file_log.file_id}</span>
                                  </h6>
                                  <h6 className="m-1">
                                    Bank Name :{" "}
                                    <span>
                                      {fileDetails &&
                                        fileDetails.length > 0 &&
                                        fileDetails[0].bank_name
                                        ? fileDetails[0].bank_name
                                        : ""}
                                    </span>
                                  </h6>
                                  <h6 className="m-1">
                                    Bank Branch :{" "}
                                    <span>
                                      {fileDetails &&
                                        fileDetails.length > 0 &&
                                        fileDetails[0].bank_branch_name
                                        ? fileDetails[0].bank_branch_name
                                        : ""}
                                    </span>
                                  </h6>
                                  <h6 className="m-1">
                                    LH Branch :{" "}
                                    <span>
                                      {fileDetails &&
                                        fileDetails.length > 0 &&
                                        fileDetails[0].lh_branch_name
                                        ? fileDetails[0].lh_branch_name
                                        : ""}
                                    </span>
                                  </h6>
                                </div>
                                <div className="latest-update-box latest-update-box-file-log mt-3 file-main-card">
                                  {groupedFiles &&
                                    groupedFiles
                                      .filter(file => !file.reason.includes("Deviation"))
                                      .map((file, index) => (
                                        <>
                                          {file.created_dt &&
                                            file.created_dt !== "" ? (
                                            <>
                                              <div
                                                key={index}
                                                className={
                                                  index === 0
                                                    ? "row p-t-20 p-b-20"
                                                    : "row p-b-20"
                                                }
                                              >
                                                <div className="col-auto text-right update-meta">
                                                  <i className="bi bi-star-fill"></i>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-star-fill svg-icon"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                  </svg>
                                                </div>
                                                <div className="col p-l-5">
                                                  <div
                                                    className="row file-status-card mb-3"
                                                    style={
                                                      (file.created_dt &&
                                                        file.created_dt.substring(
                                                          11
                                                        ) > "19:00:00") ||
                                                        file.created_dt.substring(
                                                          11
                                                        ) < "09:00:00"
                                                        ? {
                                                          backgroundColor:
                                                            "yellow",
                                                        }
                                                        : {}
                                                    }
                                                  >
                                                    <div className="col-md-4">
                                                      <div className="d-flex mt-3">
                                                        <span className="pcoded-micon">
                                                          <i
                                                            className="feather icon-file-text"
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                          />
                                                        </span>
                                                        <p
                                                          className="ml-1"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        >
                                                          <b>{file.reason}</b>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 item-center">
                                                      <p
                                                        style={{
                                                          marginBottom: "5px",
                                                          color: "black",
                                                        }}
                                                      >
                                                        <b>
                                                          {this.getNameById(
                                                            file.created_by
                                                          )}
                                                        </b>
                                                      </p>
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {file.created_dt}
                                                      </span>
                                                    </div>
                                                    <div className="col-md-2 item-center">
                                                      {file.estimated_time ? (
                                                        <p
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          Estimated Time:{" "}
                                                          {file.estimated_time}
                                                        </p>
                                                      ) : (
                                                        " "
                                                      )}
                                                    </div>
                                                  </div>
                                                  {groupedTime &&
                                                    index <
                                                    groupedTime.length && (
                                                      <div
                                                        className="file-duration mb-2"
                                                        style={
                                                          file.color
                                                            ? {
                                                              backgroundColor:
                                                                file.color,
                                                            }
                                                            : {
                                                              backgroundColor:
                                                                "#b3b0b0",
                                                            }
                                                        }
                                                      >
                                                        {groupedTime[index]}
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))}
                                </div>
                              </div>
                              <div className="col-xl-4 col-sm-4 col-md-4">
                                <div className="file-time-history">
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#2c9bf0" }}
                                  >
                                    <p className="color-white">
                                      <b>Total</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_time
                                        ? file_log.total_time
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#6d302b" }}
                                  >
                                    <p className="color-white">
                                      <b>Front Office</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.front_office
                                        ? file_log.front_office
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#046808" }}
                                  >
                                    <p className="color-white">
                                      <b>Process WIP</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_wip_file
                                        ? file_log.total_wip_file
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "rgb(224 59 221)" }}
                                  >
                                    <p className="color-white">
                                      <b>Deviation Queue Time</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_dqueue_time
                                        ? file_log.total_dqueue_time
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "rgb(156 38 154)" }}
                                  >
                                    <p className="color-white">
                                      <b>Deviation WIP</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_dwip_file
                                        ? file_log.total_dwip_file
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#652cb0" }}
                                  >
                                    <p className="color-white">
                                      <b>Query Waiting Time</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.query_diff
                                        ? file_log.query_diff
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#dc7b02" }}
                                  >
                                    <p className="color-white">
                                      <b>verification WIP</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_vc_file
                                        ? file_log.total_vc_file
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#f14724" }}
                                  >
                                    <p className="color-white">
                                      <b>Queue Time</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_queue_file
                                        ? file_log.total_queue_file
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                  <div
                                    className="file-time-item"
                                    style={{ background: "#f9c710" }}
                                  >
                                    <p className="color-white">
                                      <b>Extended Hour</b>
                                    </p>
                                    <span className="color-white">
                                      {file_log.total_ex_file
                                        ? file_log.total_ex_file
                                        : "00:00:00"}
                                    </span>
                                  </div>
                                </div>
                                <div className="file-time-history">
                                  {this.state.again_query &&
                                    this.state.again_query.length > 0 &&
                                    this.state.again_query.map(
                                      (query: any, index: number) => (
                                        <>
                                          {index == 0 && (
                                            <h5 className="mt-3 mb-1">
                                              Unexpected Upload
                                            </h5>
                                          )}
                                          <div
                                            className="row file-status-card mt-1"
                                            style={
                                              (query.date_time &&
                                                query.date_time.substring(11) >
                                                "19:00:00") ||
                                                query.date_time.substring(11) <
                                                "09:00:00"
                                                ? {
                                                  backgroundColor: "yellow",
                                                  marginLeft: "1%",
                                                }
                                                : { marginLeft: "1%" }
                                            }
                                          >
                                            <div className="col-md-4">
                                              <div className="d-flex mt-4">
                                                <span className="pcoded-micon">
                                                  <i
                                                    className="feather icon-file-text"
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                </span>
                                                <p
                                                  className="ml-1"
                                                  style={{ color: "black" }}
                                                >
                                                  <b>{query.title}</b>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-5 item-center">
                                              <p
                                                style={{
                                                  marginBottom: "5px",
                                                  color: "black",
                                                }}
                                              >
                                                <b>{query.file_by}</b>
                                              </p>
                                              <span
                                                style={{ fontSize: "14px" }}
                                              >
                                                {query.date_time}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                </div>
                                <div className="latest-update-box latest-update-box-file-log mt-3 file-main-card">
                                  {groupedFiles &&
                                    groupedFiles
                                      .filter(file => file.reason.includes("Deviation"))
                                      .map(
                                        (file: any, index: number) => (
                                          <>
                                            {file.created_dt &&
                                              file.created_dt != "" ? (
                                              <>
                                                <div
                                                  key={index}
                                                  className={
                                                    index === 0
                                                      ? "row p-t-20 p-b-20"
                                                      : "row p-b-20"
                                                  }
                                                >
                                                  <div className="col-auto text-right update-meta">
                                                    <i className="bi bi-star-fill"></i>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      className="bi bi-star-fill svg-icon"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                  </div>
                                                  <div className="col p-l-5">
                                                    <div
                                                      className="row file-status-card mb-3"
                                                      style={
                                                        (file.created_dt &&
                                                          file.created_dt.substring(
                                                            11
                                                          ) > "19:00:00") ||
                                                          file.created_dt.substring(
                                                            11
                                                          ) < "09:00:00"
                                                          ? {
                                                            backgroundColor:
                                                              "yellow",
                                                          }
                                                          : {}
                                                      }
                                                    >
                                                      <div className="col-md-4">
                                                        <div className="d-flex mt-3">
                                                          <span className="pcoded-micon">
                                                            <i
                                                              className="feather icon-file-text"
                                                              style={{
                                                                fontSize: "20px",
                                                              }}
                                                            />
                                                          </span>
                                                          <p
                                                            className="ml-1"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            <b>{file.reason}</b>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6 item-center">
                                                        <p
                                                          style={{
                                                            marginBottom: "5px",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <b>
                                                            {this.getNameById(
                                                              file.created_by
                                                            )}
                                                          </b>
                                                        </p>
                                                        <span
                                                          style={{
                                                            fontSize: "14px",
                                                            textAlign: "center"
                                                          }}
                                                        >
                                                          {file.created_dt}
                                                        </span>
                                                      </div>
                                                      <div className="col-md-2 item-center">
                                                        {file.estimated_time ? (
                                                          <p
                                                            style={{
                                                              color: "green",
                                                            }}
                                                          >
                                                            Estimated Time:{" "}
                                                            {file.estimated_time}
                                                          </p>
                                                        ) : (
                                                          " "
                                                        )}
                                                      </div>
                                                    </div>
                                                    {groupedDeviationTime &&
                                                      index <
                                                      groupedDeviationTime.length && (
                                                        <div
                                                          className="file-duration mb-2"
                                                          style={
                                                            file.color
                                                              ? {
                                                                backgroundColor:
                                                                  file.color,
                                                                width: "35%",
                                                              }
                                                              : {
                                                                backgroundColor:
                                                                  "#b3b0b0",
                                                                width: "35%",
                                                              }

                                                          }
                                                        >
                                                          {groupedDeviationTime[index]}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileHistory;
