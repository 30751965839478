export const getFileStatus = (fileStatus) => {
  switch (fileStatus) {
    case '-1':
      return 'New Login';
    case '0':
      return 'Unassigned';
    case '1':
      return 'Assigned';
    case '2':
      return 'In Progress';
    case '3':
      return 'Hold';
    case '4':
      return 'Reject';
    case '5':
      return 'Completed';
    case '6':
      return 'To be Verify';
    case '7':
      return 'Query Raised';
    case '8':
      return 'Query Received';
    case '9':
      return 'Rectification';
    default:
      return '';
  }
};
