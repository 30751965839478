import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";



interface AllProps {
   reports: any;
   getBranch: any;
   redirectToDashboard?: any;
}

export default class ReportTablevetting extends Component<AllProps> {
    state = {

    }

    render() {
        const {reports = [], redirectToDashboard = () => {}} = this.props;
        reports && reports.forEach((report, index) => { report.serial = index + 1; });
        const columns = [
            {
                header: 'S.no',
                key: 'serial',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.serial}</span>
            },
            // {
            //     header: 'File ID',
            //     key: 'fileId',
            //     td: (item) => <span>{item.fileId}</span>
            // },
            {
                header: 'File ID',
                key: 'File_Id',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.File_Id}</span>
            },
            {
                header: 'Property Owner Name',
                key: 'Property_owner_name',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.Property_owner_name}</span>
            },
            {
                header: 'Bank Name',
                key: 'Bank_Name',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.Bank_Name}</span>
            },
            {
                header: 'Process Officer',
                key: 'Process_Officer',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.Process_Officer}</span>
            },
            {
                header: 'Verify Officer',
                key: 'Verified_By',
                td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.Verified_By}</span>
            },
            // {
            //     header: 'Status',
            //     key: 'status',
            //     td: (item) => <span onClick={() => redirectToDashboard(item.id)}>{item.status}<br></br><span className="text-c-red">Pending -  {item.pending}</span><span className="text-c-green"> / Closed -  {item.closed}</span><span className="text-c-info"> / Total -  {item.total}</span></span>
            
            // },
            {
                header: 'Completed Date',
                key: 'Completed_Date',
                td: (item)=><span onClick={() => redirectToDashboard(item.id)}>{item.Completed_Date}</span>
            },
            // {
            //     header: 'Login Date',
            //     key: 'login_date',
            //     td: (item)=><span onClick={() => redirectToDashboard(item.id)}>{item.login_date}</span>
            // }

        ]
        return (
            <div className="">
                <ReactFlexyTable className="Flexy-table" data={reports} columns={columns} sortable pageSize={10} globalSearch showExcelButton />
            </div>
        )
    }

}