import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import Select from 'react-select';
interface AllProps {
    match: any;
    history: any;
}
export class ViewComplaint extends Component<AllProps> {
    state = {
        complaint: {
            fileNo: "",
            bankFormat: "",
            location: "",
            alreadyExist: "",
            toBeChange: "",
            remark: "",
            status: "",
            lhremark: "",
            softwareremark: "",
            softwarestatus: "",
            typeofIssue: "",
            userId: "",
            files: [],
        },
        docToPreview: "",
        bank_templates: [],
        currentPath: "",
    }
    componentDidMount() {
        this.getComplaint();
        this.getTemplates();
        const currentPath = sessionStorage.getItem("complaint_route");
        this.setState({ currentPath });
    }
    getComplaint = () => {
        callApi("POST", "get_complaint_detail.php", { ticketId: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                this.setState({ complaint: response.data[0] });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getTemplates = () => {
        callApi("POST", "bank_temp_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ bank_templates: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    previewDoc = (url) => {
        this.setState({ docToPreview: url });
    };

    handleTempChange = (data, currentPath, propertyName) => {
        this.setState({
            complaint: {
                ...this.state.complaint,
                [propertyName]: data.value
            },
            currentPath
        });
    }

    handleChange = (e) => {
        this.setState({
            complaint: {
                ...this.state.complaint,
                [e.target.name]: e.target.value
            }
        });
    }
    submitStatus = () => {
        const { complaint } = this.state;
        if (complaint.status || complaint.softwarestatus) {
            callApi("POST", "complaint_status_change.php", {
                ticketId: this.props.match.params.id,
                Status: complaint.status,
                lhremark: complaint.lhremark,
                softwareremark: complaint.softwareremark,
                softwarestatus: complaint.softwarestatus,
                typeofIssue: complaint.typeofIssue,
            })
                .then(res => res.data)
                .then(response => {
                    if (response.status == 200) {
                        ToastsStore.success("Complaint status changed successfully");
                        this.getComplaint();
                    } else {
                        ToastsStore.error(response.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            ToastsStore.error("Please Select the status.");
        }

    }
    render() {
        const { complaint, currentPath } = this.state;
        const forSoftwareTeamStatuses = [
            { value: 'Temporary Solution', label: 'Temporary Solution' },
            { value: 'Completed', label: 'Completed' },
            { value: 'Pending', label: 'Pending' }
        ];
        const statuses = [
            { value: 'Valid', label: 'Valid' },
            { value: 'Invalid', label: 'Invalid' },
            { value: 'New Requirement', label: 'New Requirement' }
        ];
        const typeofIssue = [
            { value: 'Support task', label: 'Support task' },
            { value: 'Software Issue', label: 'Software Issue' },
            { value: 'Requirement Change', label: 'Requirement Change' },
            { value: 'New Requirement', label: 'New Requirement' },
            { value: 'No Issue', label: 'No Issue' },
            { value: 'Clarification', label: 'Clarification' },
            { value: 'Server Issue', label: 'Server Issue' }
        ];
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        return (
            <section className="policymain-container complaint-box">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4>
                                                        <b>View Complaint</b>
                                                    </h4>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="form-group row">
                                                            <div className="col-sm-4">
                                                                <label> File No</label>
                                                                <input type="text" className="form-control"
                                                                    name="fileNo" placeholder=""
                                                                    defaultValue={complaint.fileNo}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label> Page Name</label>
                                                                <input type="text" className="form-control"
                                                                    name="bankFormat" placeholder=""
                                                                    defaultValue={complaint.bankFormat}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label> Location</label>
                                                                <input type="text" className="form-control"
                                                                    name="location" placeholder=""
                                                                    defaultValue={complaint.location}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-6">
                                                                <label> Problem</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="alreadyExist"
                                                                    defaultValue={complaint.alreadyExist}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label> Expectation</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="toBeChange"
                                                                    defaultValue={complaint.toBeChange}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-12">
                                                                <label> Remark</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="remark"
                                                                    defaultValue={complaint.remark}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            {complaint && complaint.files && complaint.files.length > 0 && complaint.files.map((itm: any, index) =>
                                                                <div className="col-sm-4" key={index}>
                                                                    <div className="card card-border-c-green">
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <span className="mb-1 text-c-green">
                                                                                    <i className="feather icon-file-text" />
                                                                                </span>
                                                                                <span>
                                                                                    {itm.fileName || ""}
                                                                                </span>
                                                                                <span style={{ float: 'right' }}
                                                                                    className="cursor-pointer"
                                                                                    data-toggle="modal"
                                                                                    data-target="#preview-document"
                                                                                    onClick={() =>
                                                                                        this.previewDoc(itm.fileContent)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-eye" />
                                                                                </span>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {["242", "1", "401"].includes(userId) && currentPath == "/all-complaints" ? <>
                                                            <div className="form-group row lawhands-use">
                                                                <div className="col-sm-4">
                                                                    <label>Lawhands Status</label>
                                                                    {["1"].includes(userId) ? (<>
                                                                        <input type="text" className="form-control"
                                                                            name="status" placeholder=""
                                                                            defaultValue={complaint.status}
                                                                            readOnly
                                                                        />
                                                                    </>) : (<>
                                                                        {statuses && statuses.length > 0 && statuses.map((opt: any) => {
                                                                            opt.label = opt.value; opt.value = opt.value
                                                                        }) &&
                                                                            <Select
                                                                                options={statuses}
                                                                                name="status"
                                                                                value={statuses.find((o: any) => o.value === complaint.status)}
                                                                                onChange={(e) => this.handleTempChange(e, "/all-complaints", "status")}
                                                                            />
                                                                        }
                                                                    </>)}
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <label>Lawhands Remark</label>
                                                                    {["1"].includes(userId) ? (<>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="lhremark"
                                                                            value={complaint.lhremark}
                                                                            onChange={this.handleChange}
                                                                            readOnly
                                                                        />
                                                                    </>) : (<>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="lhremark"
                                                                            value={complaint.lhremark}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </>)}
                                                                </div>
                                                            </div>
                                                            <div className="form-group row softwareteam-use">
                                                                <div className="col-sm-4">
                                                                    <label>Type of Issue</label>
                                                                    {["1"].includes(userId) && typeofIssue && typeofIssue.length > 0 && typeofIssue.map((opt: any) => {
                                                                        opt.label = opt.value; opt.value = opt.value
                                                                    }) ?
                                                                        (<>
                                                                            <Select options={typeofIssue} name="typeofIssue"
                                                                                value={typeofIssue.find((o: any) => o.value === complaint.typeofIssue)}
                                                                                onChange={(e) => this.handleTempChange(e, "/all-complaints", "typeofIssue")}
                                                                            />
                                                                        </>) : (<>
                                                                            <input type="text" className="form-control"
                                                                                name="typeofIssue" placeholder=""
                                                                                defaultValue={complaint.typeofIssue}
                                                                                readOnly
                                                                            />
                                                                        </>)
                                                                    }
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <label>Software Team Status</label>
                                                                    {["1"].includes(userId) && forSoftwareTeamStatuses && forSoftwareTeamStatuses.length > 0 && forSoftwareTeamStatuses.map((opt: any) => {
                                                                        opt.label = opt.value; opt.value = opt.value
                                                                    }) ?
                                                                        (<>
                                                                            <Select options={forSoftwareTeamStatuses} name="softwarestatus"
                                                                                value={forSoftwareTeamStatuses.find((o: any) => o.value === complaint.softwarestatus)}
                                                                                onChange={(e) => this.handleTempChange(e, "/all-complaints", "softwarestatus")}
                                                                            />
                                                                        </>) : (<>
                                                                            <input type="text" className="form-control"
                                                                                name="softwarestatus" placeholder=""
                                                                                defaultValue={complaint.softwarestatus}
                                                                                readOnly
                                                                            />
                                                                        </>)
                                                                    }
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <label> Software team Remark</label>
                                                                    {["1"].includes(userId) ? (<>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="softwareremark"
                                                                            value={complaint.softwareremark}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </>) : (<>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="softwareremark"
                                                                            value={complaint.softwareremark}
                                                                            onChange={this.handleChange}
                                                                            readOnly
                                                                        />
                                                                    </>)}
                                                                </div>
                                                            </div>
                                                        </> :
                                                            // (<>
                                                            //     <div className="form-group row lawhands-use">
                                                            //         <div className="col-sm-4">
                                                            //             <label>Lawhands Status</label>
                                                            //             <input type="text" className="form-control"
                                                            //                 name="status" placeholder=""
                                                            //                 defaultValue={complaint.status}
                                                            //                 readOnly
                                                            //             />
                                                            //         </div>
                                                            //         <div className="col-sm-8">
                                                            //             <label>Lawhands Remark</label>
                                                            //             <textarea
                                                            //                 className="form-control"
                                                            //                 name="lhremark"
                                                            //                 value={complaint.lhremark}
                                                            //                 onChange={this.handleChange}
                                                            //                 readOnly
                                                            //             />
                                                            //         </div>
                                                            //     </div>
                                                            //     <div className="form-group row softwareteam-use">
                                                            //         <div className="col-sm-4">
                                                            //             <label>Type of Issue</label>
                                                            //             <input type="text" className="form-control"
                                                            //                 name="typeofIssue" placeholder=""
                                                            //                 defaultValue={complaint.typeofIssue}
                                                            //                 readOnly
                                                            //             />
                                                            //         </div>
                                                            //         <div className="col-sm-4">
                                                            //             <label>Software Team Status</label>
                                                            //             <input type="text" className="form-control"
                                                            //                 name="softwarestatus" placeholder=""
                                                            //                 defaultValue={complaint.softwarestatus}
                                                            //                 readOnly
                                                            //             />
                                                            //         </div>
                                                            //         <div className="col-sm-4">
                                                            //             <label> Software team Remark</label>
                                                            //             <textarea
                                                            //                 className="form-control"
                                                            //                 name="softwareremark"
                                                            //                 value={complaint.softwareremark}
                                                            //                 onChange={this.handleChange}
                                                            //                 readOnly
                                                            //             />
                                                            //         </div>
                                                            //     </div>
                                                            // </>)
                                                            ""
                                                        }
                                                        <div className="form-group row justify-content-end float-right">
                                                            <div className="col-sm-12">
                                                                <Link to="/register-complaint">
                                                                    <button type="button" className="btn btn-primary">Back</button>
                                                                </Link>
                                                                {["242", "1", "401"].includes(userId) && currentPath == "/all-complaints" ? <>
                                                                    <button type="button" className="btn btn-primary"
                                                                        onClick={this.submitStatus}
                                                                    >Change Status</button>
                                                                </> : ""}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="preview-document"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Preview</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img
                                    width="100%"
                                    height="400px"
                                    src={this.state.docToPreview}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.setState({ docToPreview: "" });
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default ViewComplaint
