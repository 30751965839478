import React, { Component } from "react";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import { callApi } from "../../utils/api";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
interface AllProps {
  id: number;
}
interface PreList {
  isValid: boolean;
  date: string;
  document_number: string;
  particular_deed: string;
  nature_deed: string;
}
interface PostList {
  isValid: boolean;
  date: string;
  document_number: string;
  particular_deed: string;
  nature_deed: string;
}
export default class AddOnFeature extends Component<AllProps> {
  state = {
    pre_disbursal_list: [] as PreList[],
    post_disbursal_list: [] as PostList[],
    delete_items: {
      pre_disbursal_list: [],
      post_disbursal_list: [],
    },
    banks: [],
    branches: [],
    filteredBranches: [],
    takeover_bank: "",
    there_such: "",
    note: "",
    vetting_note_list: [],
    deeds: [],
    originalXeroxList: [
      { name: "Original", id: "original" },
      { name: "Xerox", id: "xerox" },
      { name: "Certificate Copy", id: "certificate" },
      { name: "1st Simultaneous Copy", id: "1st_simulation" },
      { name: "2nd Simultaneous Copy", id: "2nd_simulation" },
      { name: "3rd Simultaneous Copy", id: "3rd_simulation" },
      { name: "4th Simultaneous Copy", id: "4th_simulation" },
      { name: "5th Simultaneous Copy", id: "5th_simulation" },
      { name: "True Copy", id: "true_copy" },
    ],
    prerequired: false,
    postrequired: false,
    total_extent: "",
    extent_unit: "",
    extentUnits: [],
    empower_mortage: ""
  };
  componentDidMount() {
    this.setState({
      pre_disbursal_list: [],
      post_disbursal_list: [],
    });
    this.getPreDisbursal();
    this.getPostDisbursal();
    this.getAddonNote();
    this.getVettingNote();
    this.getTakeOver();
    this.getDeeds();
    this.getThereSuch();
    this.getExtentUnits();
    this.getTotalExtent();
  }
  getTakeOver() {
    callApi("POST", "takeover_get.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ takeover_bank: result.data[0].takeover_bank });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getThereSuch() {
    callApi("POST", "theresuch_get.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ there_such: result.data[0].there_such });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getPreDisbursal() {
    callApi("POST", "pre_disbursal_get.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ pre_disbursal_list: result.data });
        } else {
          this.setState({
            pre_disbursal_list: [
              {
                date: "",
                document_number: "",
                particular_deed: "",
                nature_deed: "",
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getPostDisbursal() {
    callApi("POST", "post_disbursal_get.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ post_disbursal_list: result.data });
        } else {
          this.setState({
            post_disbursal_list: [
              {
                date: "",
                document_number: "",
                particular_deed: "",
                nature_deed: "",
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getVettingNote() {
    callApi("POST", "get_vetting_note.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ vetting_note_list: result.data });
        } else {
          this.setState({
            vetting_note_list: [
              {
                date: "",
                doc_no: "",
                doc_type: "",
                favour: "",
                sheet_no: "",
                missing_sheet: "",
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getAddonNote() {
    callApi("POST", "note_get.php", { file_id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        if (result.data && result.data.length) {
          this.setState({ note: result.data[0].note, empower_mortage: result.data[0].empower_mortage ? result.data[0].empower_mortage : 'There such ##power_agent## had empower to ##sale / mortgage## the property measuring to an extent of #total_extent## of land cited above & the flow of title is in legitimate order.' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getDeeds = () => {
    callApi("POST", "deed_master_get.php")
      .then((res) => res.data)
      .then((response) => {
        if (response.data && response.data.length) {
          const deeds = response.data;
          this.setState({ deeds });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleChange = (e: any, propName?, fieldType?) => {
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    this.setState({ [fieldName]: fieldValue });
  };
  handlePreDisburseChange = (e, index) => {
    const pre_disbursal_list: any[] = this.state.pre_disbursal_list;
    pre_disbursal_list.forEach((itm: any, i: number) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ pre_disbursal_list });
  };
  addPreDisburse = (): void => {
    const pre_disbursal_list: any[] = this.state.pre_disbursal_list;
    pre_disbursal_list.push({
      date: "",
      document_number: "",
      particular_deed: "",
      nature_deed: "",
    });
    this.setState({ pre_disbursal_list });
  };
  removePreDisburse = (modal: any, index: number): void => {
    const pre_disbursal_list: any[] = this.state.pre_disbursal_list;
    pre_disbursal_list.splice(index, 1);

    const delete_items: any = this.state.delete_items;
    delete_items.pre_disbursal_list.push(modal.pre_disburse_id);
    callApi("POST", "pre_disbursal_delete.php", {
      file_id: this.props.id,
      id: modal.pre_disburse_id,
    })
      .then((res) => res.data)
      .then((result) => {
        this.setState({ pre_disbursal_list, delete_items });
      })
      .catch((err) => {
        console.log(err);
      });
    // this.setState({ pre_disbursal_list, delete_items });
  };
  handlePostDisburseChange = (e, index) => {
    const post_disbursal_list: any[] = this.state.post_disbursal_list;
    post_disbursal_list.forEach((itm: any, i: number) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ post_disbursal_list });
  };
  addPostDisburse = (): void => {
    const post_disbursal_list: any[] = this.state.post_disbursal_list;
    post_disbursal_list.push({
      date: "",
      document_number: "",
      particular_deed: "",
      nature_deed: "",
    });
    this.setState({ post_disbursal_list });
  };
  removePostDisburse = (modal: any, index: number): void => {
    const post_disbursal_list: any[] = this.state.post_disbursal_list;
    post_disbursal_list.splice(index, 1);

    const delete_items: any = this.state.delete_items;
    delete_items.post_disbursal_list.push(modal.post_disburse_id);
    callApi("POST", "post_disbursal_delete.php", {
      file_id: this.props.id,
      id: modal.post_disburse_id,
    })
      .then((res) => res.data)
      .then((result) => {
        this.setState({ post_disbursal_list, delete_items });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  checkValidPreDisburse = () => {
    const { pre_disbursal_list } = this.state;
    this.setState({ prerequired: true });
    let isPreValid;
    if (pre_disbursal_list && pre_disbursal_list.length > 0) {
      isPreValid = this.preDisbursalValid();
    }
    if (isPreValid) {
      this.savePreDisburse();
      this.setState({ prerequired: false });
    } else {
      ToastsStore.error("Please fill all the fields to proceed.");
    }
  };
  checkValidPostDisburse = () => {
    const { post_disbursal_list } = this.state;
    this.setState({ postrequired: true });
    let isPostValid;
    if (post_disbursal_list && post_disbursal_list.length > 0) {
      isPostValid = this.postDisbursalValid();
    }
    if (isPostValid) {
      this.savePostDisburse();
      this.setState({ postrequired: false });
    } else {
      ToastsStore.error("Please fill all the fields to proceed.");
    }
  };
  preDisbursalValid = () => {
    const pre_disbursal_list = this.state.pre_disbursal_list;
    let isValid = true;
    for (let i = 0; i < pre_disbursal_list.length; i++) {
      const list = pre_disbursal_list[i];
      if (
        !list.particular_deed ||
        !list.nature_deed
      ) {
        isValid = false;
        if (list) {
          list.isValid = false;
        }
      } else {
        if (list) {
          list.isValid = true;
        }
      }
    }
    this.setState({ pre_disbursal_list: pre_disbursal_list });
    return isValid;
  };
  postDisbursalValid = () => {
    const post_disbursal_list = this.state.post_disbursal_list;
    let isValid = true;
    for (let i = 0; i < post_disbursal_list.length; i++) {
      const list = post_disbursal_list[i];
      if (
        !list.particular_deed ||
        !list.nature_deed
      ) {
        isValid = false;
        if (list) {
          list.isValid = false;
        }
      } else {
        if (list) {
          list.isValid = true;
        }
      }
    }
    this.setState({ post_disbursal_list: post_disbursal_list });
    return isValid;
  };
  savePreDisburse = () => {
    callApi("POST", "pre_disbursal_update.php", {
      file_id: this.props.id,
      data: this.state.pre_disbursal_list,
    })
      .then((res) => res.data)
      .then((result) => {
        sessionStorage.removeItem("overviewDocument");
        ToastsStore.success("updated successfully");
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  addFeature = () => {
    const featureInput = {
      file_id: this.props.id,
      takeover_bank: this.state.takeover_bank,
    };
    callApi("POST", "take_over_bank_update.php", featureInput)
      .then((res) => res.data)
      .then((result) => {
        ToastsStore.success("updated successfully");
        sessionStorage.removeItem("overviewDocument");

        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  addThereSuch = () => {
    const featureInput = {
      file_id: this.props.id,
      there_such: this.state.there_such,
    };
    callApi("POST", "theresuch_update.php", featureInput)
      .then((res) => res.data)
      .then((result) => {
        ToastsStore.success("updated successfully");
        this.setState({ there_such: "" });
        this.getThereSuch();
        // sessionStorage.removeItem('overviewDocument');
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  savePostDisburse = () => {
    callApi("POST", "post_disbursal_update.php", {
      file_id: this.props.id,
      data: this.state.post_disbursal_list,
    })
      .then((res) => res.data)
      .then((result) => {
        sessionStorage.removeItem("overviewDocument");
        console.log(result);
        ToastsStore.success("updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  saveNote = () => {
    callApi("POST", "add_on_note_update.php", {
      file_id: this.props.id,
      note: this.state.note,
    })
      .then((res) => res.data)
      .then((result) => {
        ToastsStore.success("updated successfully");
        sessionStorage.removeItem("overviewDocument");
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  saveEmpower = () => {
    callApi("POST", "add_on_empower_update.php", {
      file_id: this.props.id,
      empower_mortage: this.state.empower_mortage
    })
      .then((res) => res.data)
      .then((result) => {
        ToastsStore.success("updated successfully");
        sessionStorage.removeItem("overviewDocument");
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleVettingNoteChange = (e, index, propName?, fieldType?) => {
    const vetting_note_list: any[] = this.state.vetting_note_list;
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    vetting_note_list.forEach((itm: any, i: number) => {
      if (i === index) {
        itm[fieldName] = fieldValue;
      }
    });
    this.setState({ vetting_note_list });
  };
  addVettingNote = (): void => {
    const vetting_note_list: any[] = this.state.vetting_note_list;
    vetting_note_list.push({
      date: "",
      doc_no: "",
      doc_type: "",
      favour: "",
      sheet_no: "",
      missing_sheet: "",
    });
    this.setState({ vetting_note_list });
  };
  removeVettingNote = (modal: any, index: number): void => {
    const vetting_note_list: any[] = this.state.vetting_note_list;
    vetting_note_list.splice(index, 1);

    const delete_items: any = this.state.delete_items;
    delete_items.post_disbursal_list.push(modal.vetting_id);
    callApi("POST", "vetting_delete.php", {
      file_id: this.props.id,
      id: modal.vetting_id,
    })
      .then((res) => res.data)
      .then((result) => {
        this.setState({ vetting_note_list, delete_items });
        this.getVettingNote();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  saveVettingNote = () => {
    callApi("POST", "update_vetting_note.php", {
      file_id: this.props.id,
      data: this.state.vetting_note_list,
    })
      .then((res) => res.data)
      .then((result) => {
        console.log(result);
        ToastsStore.success("Vetting Notes Updated");
        this.getVettingNote();
      })
      .catch((err) => {
        ToastsStore.error("Failed to update Vetting Notes");
      });
  };
  getTotalExtent = () => {
    callApi("POST", "get_total_extent.php", { id: this.props.id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.setState({ total_extent: response.data[0].total_extend, extent_unit: response.data[0].total_unit });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getExtentUnits = () => {
    const extentUnits = [
      {
        id: 'Sq.Ft',
        name: 'Sq.Ft'
      },
      {
        id: 'Sq.Yards',
        name: 'Sq.Yards'
      },
      {
        id: 'Sq.Meter',
        name: 'Sq.Meter'
      },
      {
        id: 'Sq.Lings',
        name: 'Sq.Lings'
      },
      {
        id: 'Acres',
        name: 'Acres'
      },
      {
        id: 'Cents',
        name: 'Cents'
      },
      {
        id: 'Hectares',
        name: 'Hectares'
      },
      {
        id: 'Ground',
        name: 'Ground'
      },
      {
        id: 'Kuzhi',
        name: 'Kuzhi'
      },
      {
        id: 'Kaani',
        name: 'Kaani'
      },
      {
        id: 'Ares',
        name: 'Ares'
      },
      {
        id: 'Veesam',
        name: 'Veesam'
      },
      {
        id: 'Santhiyar',
        name: 'Santhiyar'
      }
    ]
    this.setState({ extentUnits })
  };
  onModifyTotalExtent = () => {
    callApi("POST", "total_extent.php", {
      file_id: this.props.id,
      total_extent: this.state.total_extent,
      extent_unit: this.state.extent_unit
    })
      .then(res => res.data)
      .then(response => {

      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    return (
      <div id="step-6">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="card-body">
          <section>
            <div className="accordion-addon" id="theresuch">
              <div className="card addon-card">
                <a
                  className="accordion-toggle addon-accordion"
                  style={{ backgroundColor: "#5cb85c" }}
                  data-toggle="collapse"
                  data-parent="#theresuch"
                  href="#collapseSix"
                >
                  <div className="card-header">
                    <h5>There Such</h5>
                  </div>
                </a>
                <div id="collapseSix" className="panel-body collapse">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label>
                            There such / In finally Paragraph - Property Owner
                            Name
                            <span className="inputEgMsg">
                              Eg.There Such #properyownername#
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            name="there_such"
                            value={this.state.there_such}
                            onChange={(e) => this.handleChange(e)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-2" style={{ marginTop: "6%" }}>
                        <button
                          className="btn btn-primary sw-btn-next"
                          onClick={this.addThereSuch}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Total Extent </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Extent"
                            name="total_extent"
                            value={this.state.total_extent}
                            onChange={e => this.handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label>Select Unit <span className="inputEgMsg">Eg.434 Sq.Meter / Sq.Yards Etc...</span></label>
                          {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                            <Select options={this.state.extentUnits} name="extent_unit" classNamePrefix="custom-select" menuPlacement="auto"
                              value={this.state.extentUnits.find((o: any) => o.value === this.state.extent_unit)}
                              onChange={(e) => this.handleChange(e, "extent_unit", 'singleselect')}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                          }
                        </div>
                      </div>
                      <div
                        className="col-md-2"
                        style={{
                          margin: "22px"
                        }}
                      >
                        <button
                          className="btn btn-primary sw-btn-next"
                          type="button"
                          onClick={() => this.onModifyTotalExtent()}
                        >
                          Modify
                        </button>
                      </div>
                      <span className="inputEgMsg">Note : If Partition Deed (or) Schedule Template coming from Title Deed, Property Owner Name / Total Extent cannot be reflected in legal opinion, So use this module to overwrite</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="accordion" id="takeover">
              <div className="card addon-card">
                <a
                  className="accordion-toggle addon-accordion"
                  style={{ backgroundColor: "aliceblue" }}
                  data-toggle="collapse"
                  data-parent="#takeover"
                  href="#collapseOne"
                >
                  <div className="card-header">
                    <h5>Takeover bank</h5>
                  </div>
                </a>
                <div id="collapseOne" className="panel-body collapse">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label>
                            Takeover Bank
                            <span className="inputEgMsg">
                              Eg.Except ICICI Bank Limited Mortgage
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            name="takeover_bank"
                            value={this.state.takeover_bank}
                            onChange={(e) => this.handleChange(e)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-2" style={{ marginTop: "3%" }}>
                        <button
                          className="btn btn-primary sw-btn-next"
                          onClick={this.addFeature}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="accordion" id="pre-disburse">
              <div className="card addon-card">
                <a
                  className="accordion-toggle addon-accordion"
                  style={{ backgroundColor: "antiquewhite" }}
                  data-toggle="collapse"
                  data-parent="#pre-disburse"
                  href="#collapseTwo"
                >
                  <div className="card-header">
                    <h5>Pre Disbursement Document</h5>
                  </div>
                </a>
                <div id="collapseTwo" className="panel-body collapse">
                  <div className="card-body">
                    {this.state.pre_disbursal_list &&
                      this.state.pre_disbursal_list.length > 0 &&
                      this.state.pre_disbursal_list.map(
                        (pre: any, preIndex) => {
                          return (
                            <div className="row">
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Date</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Date"
                                    name="date"
                                    value={pre.date}
                                    onChange={(e) =>
                                      this.handlePreDisburseChange(e, preIndex)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Document Number</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Document Number"
                                    name="document_number"
                                    value={pre.document_number}
                                    onChange={(e) =>
                                      this.handlePreDisburseChange(e, preIndex)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Particulars of deeds</label>
                                  <textarea
                                    placeholder="Particulars of deeds"
                                    name="particular_deed"
                                    value={pre.particular_deed}
                                    onChange={(e) =>
                                      this.handlePreDisburseChange(e, preIndex)
                                    }
                                    className="form-control"
                                    style={{ height: "8vh" }}
                                  />
                                </div>
                                {this.state.prerequired &&
                                  !pre.particular_deed && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      Required field.
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Nature of deed</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nature of deed"
                                    name="nature_deed"
                                    value={pre.nature_deed}
                                    onChange={(e) =>
                                      this.handlePreDisburseChange(e, preIndex)
                                    }
                                  />
                                </div>
                                {this.state.prerequired && !pre.nature_deed && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    Required field.
                                  </p>
                                )}
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  className="btn btn-icon btn-rounded btn-primary"
                                  onClick={this.addPreDisburse}
                                >
                                  <i className="feather icon-plus"></i>
                                </button>
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  disabled={
                                    this.state.pre_disbursal_list.length === 1
                                  }
                                  onClick={() =>
                                    this.removePreDisburse(pre, preIndex)
                                  }
                                  className="btn btn-icon btn-rounded btn-danger"
                                >
                                  <i className="feather icon-minus" />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      )}
                    <button
                      onClick={this.checkValidPreDisburse}
                      className="btn btn-rounded btn-success"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="accordion" id="post-disburse">
              <div className="card addon-card">
                <a
                  className="addon-accordion accordion-toggle"
                  data-toggle="collapse"
                  data-parent="#post-disburse"
                  href="#collapseThree"
                >
                  <div className="card-header">
                    <h5>Post Disbursement Document</h5>
                  </div>
                </a>
                <div id="collapseThree" className="panel-body collapse">
                  <div className="card-body">
                    {this.state.post_disbursal_list &&
                      this.state.post_disbursal_list.length > 0 &&
                      this.state.post_disbursal_list.map(
                        (post: any, postIndex) => {
                          return (
                            <div className="row">
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Date</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Date"
                                    name="date"
                                    value={post.date}
                                    onChange={(e) =>
                                      this.handlePostDisburseChange(
                                        e,
                                        postIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Document Number</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Document Number"
                                    name="document_number"
                                    value={post.document_number}
                                    onChange={(e) =>
                                      this.handlePostDisburseChange(
                                        e,
                                        postIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Particulars of deeds</label>
                                  <textarea
                                    name="particular_deed"
                                    value={post.particular_deed}
                                    onChange={(e) =>
                                      this.handlePostDisburseChange(
                                        e,
                                        postIndex
                                      )
                                    }
                                    className="form-control"
                                    style={{ height: "8vh" }}
                                    placeholder="Particulars of deeds"
                                  />
                                </div>
                                {this.state.postrequired &&
                                  !post.particular_deed && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      Required field.
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Nature of deed</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nature of deed"
                                    name="nature_deed"
                                    value={post.nature_deed}
                                    onChange={(e) =>
                                      this.handlePostDisburseChange(
                                        e,
                                        postIndex
                                      )
                                    }
                                  />
                                </div>
                                {this.state.postrequired &&
                                  !post.nature_deed && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      Required field.
                                    </p>
                                  )}
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  className="btn btn-icon btn-rounded btn-primary"
                                  onClick={this.addPostDisburse}
                                >
                                  <i className="feather icon-plus"></i>
                                </button>
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  disabled={
                                    this.state.post_disbursal_list.length === 1
                                  }
                                  onClick={() =>
                                    this.removePostDisburse(post, postIndex)
                                  }
                                  className="btn btn-icon btn-rounded btn-danger"
                                >
                                  <i className="feather icon-minus" />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      )}
                    <button
                      onClick={this.checkValidPostDisburse}
                      className="btn btn-rounded btn-success"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="accordion" id="addon-note">
              <div className="card addon-card">
                <a
                  className="addon-accordion accordion-toggle"
                  style={{ backgroundColor: "darkorange" }}
                  data-toggle="collapse"
                  data-parent="#addon-note"
                  href="#collapseFour"
                >
                  <div className="card-header">
                    <h5>Final Remarks</h5>
                  </div>
                </a>
                <div id="collapseFour" className="panel-body collapse">
                  <div className="card-body">
                    <h4>Final Remarks: </h4>
                    <textarea
                      name="note"
                      onChange={(e) => this.handleChange(e)}
                      className="form-control"
                      value={this.state.note}
                      placeholder="It displays at the end of the Legal Opinion or Ceritification - Notification to the bank if any mention here"
                    />
                    <div className="col-md-2" style={{ marginTop: "3%" }}>
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={this.saveNote}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="accordion" id="vetting-note">
              <div className="card addon-card">
                <a
                  className="addon-accordion accordion-toggle"
                  style={{ backgroundColor: "turquoise" }}
                  data-toggle="collapse"
                  data-parent="#vetting-note"
                  href="#collapseFive"
                >
                  <div className="card-header">
                    <h5>Vetting Note</h5>
                  </div>
                </a>
                <div id="collapseFive" className="panel-body collapse">
                  <div className="card-body">
                    {this.state.vetting_note_list &&
                      this.state.vetting_note_list.length > 0 &&
                      this.state.vetting_note_list.map(
                        (vettingNote: any, vettingInd) => {
                          return (
                            <div
                              className="row"
                              style={{
                                border: "1px solid #c0c0c0",
                                padding: "1em",
                                marginBottom: "1em",
                              }}
                            >
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Date</label>
                                  <MaskedInput
                                    className="form-control"
                                    placeholder="dd.mm.yyyy"
                                    name="date"
                                    mask={[
                                      /[0-3]/,
                                      /\d/,
                                      ".",
                                      /[0-1]/,
                                      /\d/,
                                      ".",
                                      /[1-2]/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    value={vettingNote.date}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Document Number</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Document Number"
                                    name="doc_no"
                                    value={vettingNote.doc_no}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Document Type</label>
                                  {this.state.deeds &&
                                    this.state.deeds.length > 0 &&
                                    this.state.deeds.map((opt: any) => {
                                      opt.label = opt.name;
                                      opt.value = opt.id;
                                    }) && (
                                      <Select
                                        options={this.state.deeds}
                                        name="doc_type"
                                        value={this.state.deeds.find(
                                          (o: any) =>
                                            o.value === vettingNote.doc_type
                                        )}
                                        onChange={(e) =>
                                          this.handleVettingNoteChange(
                                            e,
                                            vettingInd,
                                            "doc_type",
                                            "singleselect"
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Favour</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Favour"
                                    name="favour"
                                    value={vettingNote.favour}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Total Sheet</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Totoal Sheet"
                                    name="sheet_no"
                                    value={vettingNote.sheet_no}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>Available Sheets</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Available Sheets"
                                    name="available_sheet"
                                    value={vettingNote.available_sheet}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Missing Sheet (eg: 11, 12)</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Missing Sheet"
                                    name="missing_sheet"
                                    value={vettingNote.missing_sheet}
                                    onChange={(e) =>
                                      this.handleVettingNoteChange(
                                        e,
                                        vettingInd
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Original/Xerox</label>
                                  {this.state.originalXeroxList &&
                                    this.state.originalXeroxList.length &&
                                    this.state.originalXeroxList.map(
                                      (opt: any) => {
                                        opt.label = opt.name;
                                        opt.value = opt.id;
                                      }
                                    ) && (
                                      <Select
                                        options={this.state.originalXeroxList}
                                        name="original_xerox"
                                        value={
                                          vettingNote.original_xerox
                                            ? this.state.originalXeroxList.find(
                                              (o: any) =>
                                                o.value ===
                                                vettingNote.original_xerox
                                            )
                                            : this.state.originalXeroxList[1]
                                        }
                                        onChange={(e) =>
                                          this.handleVettingNoteChange(
                                            e,
                                            vettingInd,
                                            "original_xerox",
                                            "singleselect"
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  className="btn btn-icon btn-rounded btn-primary"
                                  onClick={this.addVettingNote}
                                >
                                  <i className="feather icon-plus"></i>
                                </button>
                              </div>
                              <div
                                className="col-md-1"
                                style={{ marginTop: "3%" }}
                              >
                                <button
                                  disabled={
                                    this.state.vetting_note_list.length === 1
                                  }
                                  onClick={() =>
                                    this.removeVettingNote(
                                      vettingNote,
                                      vettingInd
                                    )
                                  }
                                  className="btn btn-icon btn-rounded btn-danger"
                                >
                                  <i className="feather icon-minus" />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      )}
                    <button
                      style={{ marginLeft: "-1em" }}
                      onClick={this.saveVettingNote}
                      className="btn btn-rounded btn-success"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="accordion" id="addon-mortage">
              <div className="card addon-card">
                <a
                  className="addon-accordion accordion-toggle"
                  style={{ backgroundColor: "darkorange" }}
                  data-toggle="collapse"
                  data-parent="#addon-mortage"
                  href="#collapseSeven"
                >
                  <div className="card-header">
                    <h5>There Such Empower to Mortgage / Sale property</h5>
                  </div>
                </a>
                <div id="collapseSeven" className="panel-body collapse">
                  <div className="card-body">
                    {/* <h4>There Such Empower to Mortgage / Sale property: </h4> */}
                    <span className="inputEgMsg">
                      Note : If the title deed holder appoints a power agent for mortgage / sale, this tracing will display after the (There such) paragraph. Just enter ##hashtgs## words.
                    </span>
                    <textarea
                      name="empower_mortage"
                      onChange={(e) => this.handleChange(e)}
                      className="form-control"
                      value={this.state.empower_mortage}
                      placeholder="There such ##power_agent## had empower to ##sale / mortgage## the property measuring to an extent of #total_extent## of land cited above & the flow of title is in legitimate order."
                    />
                    <div className="col-md-2" style={{ marginTop: "3%" }}>
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={this.saveEmpower}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
