import React, { useState } from 'react'
import { isEmpty, get, cloneDeep } from 'lodash'
import { callApi } from '../../utils/api';
import ReactFlexyTable from "react-flexy-table";
import Select from 'react-select';

const AddPolicy = (props) => {
  const { bankPolicy, onBack, isEdit, bankList, policyList } = props
  const [ tempBankPolicyList, setTempBankPolicyList ] = useState(get(bankPolicy, 'policy', []) || []);
  const [ tempBankDontPolicyList, setTempBankDontPolicyList ] = useState(get(bankPolicy, 'dont_policy_id', []) || []);
  const defaultBankId = !isEmpty(bankPolicy) ? get(bankPolicy, 'bank_id', '') || '' : ''
  const [ bankId, setBankId ] = useState(defaultBankId);

  const handleSavePolicy = () => {
    let savePolicyApi = "bank_policy_add.php"
    let body = { bank_id: bankId, policy: tempBankPolicyList.filter(id => id && id), dont_policy_id: tempBankDontPolicyList.filter(id => id && id)}
    if (isEdit) {
      savePolicyApi = "bank_policy_update.php"
      body = { bank_policy_id: get(bankPolicy, 'id', ''), ...body }
    }
    callApi("POST", savePolicyApi, body)
      .then(res => res.data)
      .then(response => {
        onBack()
      })
      .catch(err => {
        console.log(err);
      });
  }
  const selectOption = (checked, policy, selectedPolicy, notSelectedPolicy) => {
    if (checked) {
      selectedPolicy.push(policy.policy_id);
      const policyIndex = notSelectedPolicy.findIndex(id => id === policy.policy_id);
      policyIndex >= 0 && notSelectedPolicy.splice(policyIndex, 1);
    } else {
      const policyIndex = selectedPolicy.findIndex(id => id === policy.policy_id);
      selectedPolicy.splice(policyIndex, 1);
    }
    return { selectedPolicy, notSelectedPolicy }
  }
  const handleCheckboxChange = (checked, policy, key) => {
    const policyList = cloneDeep(tempBankPolicyList)
    const dontDoPolicyList = cloneDeep(tempBankDontPolicyList)
    if (key === 'dos') {
      const { selectedPolicy, notSelectedPolicy } = selectOption(checked, policy, policyList, dontDoPolicyList)
      setTempBankPolicyList(selectedPolicy)
      setTempBankDontPolicyList(notSelectedPolicy)
    } else {
      const { selectedPolicy, notSelectedPolicy } = selectOption(checked, policy, dontDoPolicyList, policyList)
      setTempBankPolicyList(notSelectedPolicy)
      setTempBankDontPolicyList(selectedPolicy)
    }
  }

  const columns = [
    {
      header: "Do's",
      td: (policy) => {
        const checked = !isEmpty(tempBankPolicyList.find(id => id === policy.policy_id))
        return <div className="btn-group m-2" onClick={() => handleCheckboxChange(!checked, policy, 'dos')}>
          <svg className={`svg-small-icon cursor-pointer checkboxOutline${checked ? ' check-selected' : ''}`} viewBox="5.5 3 13 18"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
          {checked && <svg className="checkbox-status" viewBox="0 0 24 24"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>}
        </div>
      }
    },
    {
      header: "Don'ts",
      td: (policy) => {
        const checked = !isEmpty(tempBankDontPolicyList.find(id => id === policy.policy_id))
        return <div className="btn-group m-2" onClick={() => handleCheckboxChange(!checked, policy, 'donts')}>
          <svg className={`svg-small-icon cursor-pointer checkboxOutline${checked ? ' cross-selected' : ''}`} viewBox="5.5 3 13 18"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
          {checked && <svg className="checkbox-status" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>}
        </div>
      }
    },
    {
      header: 'Policy',
      key: 'policy',
    }
  ];
  let modifiedBankList = []
  modifiedBankList = bankList && bankList.length > 0 && bankList.map((option) => {
    return { label: option.name, value: option.id }
  })
  return (
    <div className="policymain-container">
      <div className="pcoded-content">
        <div className="card">
          <div className="card-header display-flex align-items-center policy-header">
            <button className="btn policy-back-button" onClick={onBack}><i className="feather icon-arrow-left"></i></button>
            <h5>{isEdit ? 'Edit Bank Policy' : 'Add Bank Policy'}</h5>
          </div>
          <div className="card-body bank-policy-wrapper">
            <div className="display-flex align-items-center">
              <div>Bank Name : </div>
              {isEdit
                ? <div className="bank-select">{get(bankPolicy, 'bank_name', '')}</div>
                : <div className="bank-select">
                    <Select options={modifiedBankList} name="bank_id"
                      value={modifiedBankList.find((option) => option.value === bankId)}
                      onChange={e => setBankId(e.value)}
                    />
                  </div>
              }
            </div>
            <ReactFlexyTable data={policyList} columns={columns} globalSearch pageSize={policyList.length} pageSizeOptions={[]} />
            <div className="float-right">
              <button className="btn btn-success" onClick={handleSavePolicy}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPolicy