import React from "react";
import { Route, Switch } from "react-router-dom";
import AddBankBranch from "./components/bank-branch/AddBankBranch";
import ManageBankBranch from "./components/bank-branch/ManageBankBranch";
import ManageBank from "./components/bank/ManageBank";
import AddBranch from "./components/branch/AddBranch";
import ManageBranch from "./components/branch/ManageBranch";
import Dashboard from "./components/dashboard/Dashboard";
import ManageDeedTemplate from "./components/deed-template/ManageDeedTemplate";
import ManageDeed from "./components/deed/ManageDeed";
import AddFile from "./components/file/AddFile";
import FileDashboard from "./components/file/FileDashboard";
import ManageAssignedFile from "./components/file/ManageAssignedFile";
import ManageClosedFile from "./components/file/ManageClosedFile";
import ManageHoldRejectFile from "./components/file/ManageHoldRejectFile";
import ManageInprogressFile from "./components/file/ManageInprogessFile";
import ManagePendingFile from "./components/file/ManagePendingFile";
import ManageUnassignedFile from "./components/file/ManageUnassignedFile";
import ManageToBeVerifiedFile from "./components/file/ManageToBeVerifiedFile";
import ManageQueryRaisedFiles from "./components/file/ManageQueryRaisedFiles";
import ManageQueryReceivedFiles from "./components/file/ManageQueryReceivedFiles";
import SearchResults from "./components/file/SearchResults";
import BranchSearchResults from "./components/file/BranchSearchResults";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import ManageOriginalPresent from "./components/original-present/ManageOriginalPresent";
import AddQuery from "./components/query/AddQuery";
import ManageQuery from "./components/query/ManageQuery";
import AddUser from "./components/user/AddUser";
import ManageUser from "./components/user/ManageUser";
import WorkFile from "./components/work-file";
import ManageMeasurement from "./components/measurement/ManageMeasurement";
import ManageSro from "./components/sro/ManageSro";
import ManagePolicy from './components/policy/ManagePolicy';
import ManageBankPolicy from './components/bankPolicy/ManageBankPolicy';
import AddVideoCategory from './components/videoCategory/AddVideoCategory';
import ManageVideo from './components/videoCategory/ManageVideo';
import QaManage from "./components/qa-manage/qa-manage";
import RegisterComplaint from "./components/complaint-box/RegisterComplaint";
import ComplaintList from "./components/complaint-box/ComplaintList";
import ViewComplaint from "./components/complaint-box/ViewComplaint";
import ManageVettingBank from "./components/vetting-bank/ManageVettingBank";
import VettingQaManage from "./components/vetting-qa-manage/Vetting-qa-manage";
import AddNotification from "./components/notification/AddNotification";
import NotificationList from "./components/notification/NotificationList";
import PushNotification from "./components/notification/PushNotification";
import AddVisitor from "./components/visitors/AddVisitor";
import VisitorList from "./components/visitors/VisitorList";
import SaleDeed from "./components/sale-deed/SaleDeed";
import EodReport from "./components/eod-report/EodReport";
import VerificationDetails from "./components/verification-details/VerificationDetails";
import SubReport from "./components/dashboard/SubReport";
// import SubReportvetting from "./components/dashboard/subreport_vetting";
import EntryTrackingReport from "./components/entry-tracking-report/EntryTrackingReport";
import ProcessEod from "./components/process_eod/process_eod";
import liveTracking from "./components/notification/Livetrack";
import golmReport from "./components/bank-wise-report/glomReport";
import Deviationreport from "./components/bank-wise-report/Deviationreport"
import fileCompleted from "./components/bank-wise-report/filecompleted";
import UserVerifyFile from "./components/file/UserVerifyFile";
// import NewDashboard from "./components/new-dashboard/NewDashboard";
import Vettingtemplate from "./components/vetting_report/vetting_report_template"
import VettingLogin from "./components/vetting-file-management/login_file";
import VettingProcess from "./components/vetting-file-management/process_file";
import VettingComplete from "./components/vetting-file-management/complete_file";
import Vettingreport from "./components/vetting_report/report_of_vetting";
import ReportGenerate from "./components/ReportGenerate/ReportGenerate";
import VettingOverview from "./components/vetting-overview/vetting_overview";
import BlockDownload from "./components/block-download/block_download";
import VerifyHintAboutFile from "./components/workFileVerificationList/verification_hint_about";
import VettingTableTemplate from "./components/vetting-overview/vetting_table_template";
import SignatureFileSearch from "./components/file/SignatureFilesSearch";
import SignatureFile from "./components/file/Signature_file";
import vettingcompletereport from "./components/bank-wise-report/vettingcompletedReport";
import lawhandsMisreport from "./components/process_eod/LawhandsMis";
import SubReportvetting from "./components/dashboard/subreport_vetting";
import Productmanagement from "./components/productManagement/product_management";
import Bank_Productmanagement from "./components/bank_product_management/Bank_ProductManagement";
import BankWise from "./components/GradeManagement/bank_wise";
import EmployeeWise from "./components/GradeManagement/employee_wise";
import modeManagement from "./components/modeManagement/modeManagement";
import DynamicPage from "./components/Authenticating_pages/authenticate_page";
import FileLog from "./components/File_log/fileLogs";
import FileHistory from "./components/File_log/File_history";
import ManageQueryReason from "./components/query-reason/query_reason_manage";
import ManagedashInprogressFile from "./components/dashboard/Executive-Dashboard";
import ManagedeviationInprogressFile from "./components/dashboard/Deviation-Dashboard";
import emergentreport from "./components/bank-wise-report/EmergentReport";
import ReassignReport from "./components/bank-wise-report/ReassignReport";
import VideoReport from "./components/bank-wise-report/videoReport";
import HoldReport from "./components/file/verificationHoldFiles";
import ManageRole from "./components/role/role_management";
import DeviationQueue from "./components/file/DeviationQueue";
import DeviationInprogressFile from "./components/file/DeviationInprogressFile";
import DeviationReport from "./components/dashboard/DeviationReport";
import ManageRectificationInprogressFile from "./components/dashboard/Rectification-Dashboard";
import RectificationFiles from "./components/file/RectificationFiles";
import AddRectifyFile from "./components/file/AddRectifyFile";
import RectificationInprogressFile from "./components/file/RectificationInprogressFile";
import RectificationQueue from "./components/file/RectificationQueue";
import RectificationReport from "./components/dashboard/RectificationReport";
import ExpressReport from "./components/bank-wise-report/ExpressReport";
import BankProductBranchmanagement from "./components/bank_product_management/Bank_Product_Branch_Management";
declare var $: any;
class PrivateRoutes extends React.Component {
	render() {
		return (
			<div className="wrapper">
				<Sidebar />
				<div id="content">
					<Header />
					<Switch>
						<Route exact path="/dashboard" component={Dashboard} />
						<Route exact path="/executive-dashboard" component={ManagedashInprogressFile} />
						<Route exact path="/deviation-dashboard" component={ManagedeviationInprogressFile} />
						<Route exact path="/rectify-dashboard" component={ManageRectificationInprogressFile} />
						<Route exact path="/bank" component={ManageBank} />
						<Route exact path="/role" component={ManageRole} />
						<Route exact path="/query-reason" component={ManageQueryReason} />
						<Route exact path="/vetting-bank" component={ManageVettingBank} />
						<Route exact path="/pending-file" component={ManagePendingFile} />
						<Route
							exact
							path="/unassigned-file"
							component={ManageUnassignedFile}
						/>
						<Route exact path="/assigned-file" component={ManageAssignedFile} />
						<Route
							exact
							path="/hold-reject-file"
							component={ManageHoldRejectFile}
						/>
						<Route
							exact
							path="/hold-file"
							component={HoldReport}
						/>
						<Route exact path="/completed-file" render={(props) => <ManageClosedFile status="5" {...props} />} />
						<Route exact path="/typing-completed" render={(props) => <ManageClosedFile status="7" {...props} />} />
						<Route exact path="/to-be-verified-file" component={ManageToBeVerifiedFile} />
						<Route exact path="/to-user-verified-file" component={UserVerifyFile} />

						<Route exact path="/query-raised-files" component={ManageQueryRaisedFiles} />
						<Route exact path="/query-received-files" component={ManageQueryReceivedFiles} />
						<Route
							exact
							path="/inprogress-file"
							component={ManageInprogressFile}
						/>
						<Route exact path="/file/add/:id" component={AddFile} />
						<Route exact path="/rectifyfile/add/:id" component={AddRectifyFile} />
						<Route exact path="/rectify-files" component={RectificationFiles} />
						<Route exact path="/rectification-queue" component={RectificationQueue} />
						<Route exact path="/rectification-work-inprogress" component={RectificationInprogressFile} />
						<Route exact path="/rectification-report/:name/:id" component={RectificationReport} />
						<Route exact path="/entry-tracking-report" component={EntryTrackingReport} />
						<Route exact path="/process-eod" component={ProcessEod} />
						<Route exact path="/query" component={ManageQuery} />
						<Route exact path="/eod-report" component={EodReport} />
						<Route exact path="/golm-report" component={golmReport} />
						<Route exact path="/Deviationreport-report" component={Deviationreport} />
						<Route exact path="/filecompleted-report" component={fileCompleted} />
						<Route exact path="/verification-details/:id" component={VerificationDetails} />
						<Route
							exact
							path="/query/add/:queryid/:fileid"
							component={AddQuery}
						/>
						<Route exact path="/user" component={ManageUser} />
						<Route exact path="/user/add/:id" component={AddUser} />
						<Route exact path="/branch" component={ManageBranch} />
						<Route exact path="/branch/add/:id" component={AddBranch} />
						<Route exact path="/bank-branch" component={ManageBankBranch} />
						<Route
							exact
							path="/bank-branch/add/:id"
							component={AddBankBranch}
						/>
						<Route exact path="/file-dashboard/:id" component={FileDashboard} />
						<Route exact path="/work-file/:stepno/:id" component={WorkFile} />
						<Route
							exact
							path="/search-results/:name/:searchValue"
							component={SearchResults}
						/>
						<Route exact path="/deed" component={ManageDeed} />
						<Route exact path="/template" component={ManageDeedTemplate} />
						<Route exact path="/original-present" component={ManageOriginalPresent} />
						<Route exact path="/measure" component={ManageMeasurement} />
						<Route exact path="/sro" component={ManageSro} />
						<Route exact path="/branch-search" component={BranchSearchResults} />
						<Route exact path="/manage-policy" component={ManagePolicy} />
						<Route exact path="/manage-bank-policy" component={ManageBankPolicy} />
						<Route exact path="/video-category" component={AddVideoCategory} />
						<Route exact path="/manage-video" component={ManageVideo} />
						<Route exact path="/register-complaint" component={RegisterComplaint} />
						<Route exact path="/all-complaints" component={ComplaintList} />
						<Route exact path="/view-complaint/:id" component={ViewComplaint} />
						<Route exact path="/vetting-manage-qa" component={VettingQaManage} />
						<Route exact path="/manage-qa" component={QaManage} />
						<Route exact path="/all-complaints" component={ComplaintList} />
						<Route exact path="/view-complaint/:id" component={ViewComplaint} />
						{/* NOTIFICATIONS */}
						<Route exact path="/notification/add/" component={AddNotification} />
						<Route exact path="/all-notifications" component={NotificationList} />
						<Route exact path="/visitor/add/" component={AddVisitor} />
						<Route exact path="/visitor-list" component={VisitorList} />
						<Route exact path="/all-Pushnotifications" component={PushNotification} />
						<Route exact path="/livetracking" component={liveTracking} />

						{/* <Route exact path="/new-dashboard" component={NewDashboard} /> */}
						<Route exact path="/sale-deed/:id" component={SaleDeed} />
						<Route exact path="/sub-report/:name/:id" component={SubReport} />
						<Route exact path="/sub-report-vetting/:name/:id" component={SubReportvetting} />
						<Route exact path="/vetting-report-template" component={Vettingtemplate} />
						<Route exact path="/vetting-login" component={VettingLogin} />
						<Route exact path="/vetting-process" component={VettingProcess} />
						<Route exact path="/vetting-complete" component={VettingComplete} />
						<Route exact path="/vetting-report" component={Vettingreport} />

						<Route exact path="/generate-report" component={ReportGenerate} />
						<Route exact path="/vetting-overview/:id" component={VettingOverview} />
						<Route exact path="/vetting-table-template" component={VettingTableTemplate} />
						<Route exact path="/block-download" component={BlockDownload} />

						<Route exact path="/HintAboutFile/:id" component={VerifyHintAboutFile} />
						<Route exact path="/signature-file/search" component={SignatureFileSearch} />
						<Route exact path="/signature-file/:id" component={SignatureFile} />
						<Route exact path="/vettingcomplete" component={vettingcompletereport} />
						<Route exact path="/emergentreport" component={emergentreport} />
						<Route exact path="/reassign-report" component={ReassignReport} />
						<Route exact path="/express-report" component={ExpressReport} />
						<Route exact path="/videoreport" component={VideoReport} />
						<Route exact path="/lawhandsMisreport" component={lawhandsMisreport} />
						<Route exact path="/product" component={Productmanagement} />
						<Route exact path="/bank-product" component={Bank_Productmanagement} />
						<Route exact path="/bank-product-branch" component={BankProductBranchmanagement} />
						<Route exact path="/employee-wise" component={EmployeeWise} />
						<Route exact path="/mode-management" component={modeManagement} />
						<Route exact path="/bank-wise" component={BankWise} />
						<Route exact path="/file-log" component={FileLog} />
						<Route exact path="/file-history/:id" component={FileHistory} />
						<Route exact path="/authenticate-page" component={DynamicPage} />
						<Route exact path="/deviation-queue" component={DeviationQueue} />
						<Route exact path="/deviation-work-inprogress" component={DeviationInprogressFile} />
						<Route exact path="/deviation-report/:name/:id" component={DeviationReport} />
						<Route component={() => <div>Not Found</div>} />

					</Switch>
				</div>
			</div>
		);
	}
}

export default PrivateRoutes;
