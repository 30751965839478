import React, { Component } from "react";
import ReactFileReader from "react-file-reader";
import { callApi } from "../../utils/api";
import { cloneDeep } from 'lodash';
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import moment from "moment";
import { getDatabase, ref, onValue, set, push, child, get } from "firebase/database";

declare var $: any;
interface Query {
    title: "";
    information: "";
    created_date: String
}
interface AllProps {
    match: any;
    history: any;
}
export default class AddNotification extends Component<AllProps> {
    state = {
        notifyDetail: [],
        isLoading: false,
        base: [],
        notifyLoading: false
    };
    componentDidMount() {
        this.addItem();
    }
    addItem = (): void => {
        const notifyDetail: Query[] = this.state.notifyDetail
            ? this.state.notifyDetail
            : [];
        notifyDetail.push({ title: "", information: "", created_date: moment().format("DD-MM-YYYY HH:mm:ss") });
        this.setState({ notifyDetail });
    };
    removeItem = (index: number): void => {
        const notifyDetail: Query[] = this.state.notifyDetail;
        notifyDetail.splice(index, 1);
        this.setState({ notifyDetail });
    };
    handleChange = (e: any, index: number) => {
        const notifyDetail: Query[] = this.state.notifyDetail;
        notifyDetail.forEach((itm: any, i) => {
            if (i === index) {
                itm[e.target.name] = e.target.value;
            }
        });
        this.setState({ notifyDetail });
    };
    saveNotificationOld = () => {
        this.props.match.params.id = Number(this.props.match.params.id);
        const { notifyDetail } = this.state;
        const RequestBody = {
            notice: notifyDetail
        }
        if (notifyDetail.length > 0) {
            this.setState({ notifyLoading: true });
            callApi("POST", "notice_board_add.php", RequestBody)
                .then(res => res.data)
                .then(response => {
                    console.log("Response -> ", response)
                    let oldNotifyData: any = [];
                    const db = getDatabase();
                    const starCountRefGroup = ref(db, `notifications/all`);
                    onValue(starCountRefGroup, (snapshot) => {
                        const data = snapshot.val();
                        console.log("GROUP NOT", data)
                        if (data && data.type && data.notify && data.notify.length > 0) {
                            data.notify.filter((val) => {
                                oldNotifyData.push(val);
                            })
                        }
                    }, {
                        onlyOnce: true
                    });

                    console.log("firt oldNotifyData", oldNotifyData)
                    notifyDetail.reduce((newVal: any, oldVal: any) => {
                        oldVal.id = response.id
                        newVal.push(oldVal)
                        return newVal;
                    }, []).filter(val => {
                        oldNotifyData.push(val);
                    })
                    console.log("second oldNotifyData", oldNotifyData)
                    set(ref(db, `notifications/all`), {
                        notify: oldNotifyData,
                        "type": "modal",
                        "userType": "all"
                    })
                    this.setState({ notifyLoading: false, notifyDetail: [] }, () => {
                        this.addItem()
                    });
                    ToastsStore.success("Notification added Successully");
                    this.props.history.push("/dashboard");
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ notifyLoading: false });
                    ToastsStore.error("Something went Wrong, Please try again!");
                });
        }
    };

    saveNotification = () => {
        const { notifyDetail } = this.state;
        const RequestBody = {
            notice: notifyDetail
        };

        if (notifyDetail.length > 0) {
            this.setState({ notifyLoading: true });
            callApi("POST", "notice_board_add.php", RequestBody)
                .then(res => res.data)
                .then(response => {
                    console.log("Response -> ", response);
                    let oldNotifyData: any = [];
                    const db = getDatabase();
                    const starCountRefGroup = ref(db, `notifications/all`);

                    // Use get instead of onValue for a one-time read
                    get(starCountRefGroup).then((snapshot) => {
                        const data = snapshot.val();
                        console.log("GROUP NOT", data);
                        if (data && data.type && data.notify && data.notify.length > 0) {
                            oldNotifyData = data.notify; // Simplify data retrieval
                        }

                        console.log("first oldNotifyData", oldNotifyData);
                        const newNotifyData = notifyDetail.map((item: any, index) => ({
                            ...item,
                            id: response.id
                        }));
                        oldNotifyData.push(...newNotifyData); // Spread new notifications to old data

                        console.log("second oldNotifyData", oldNotifyData);
                        set(ref(db, `notifications/all`), {
                            notify: oldNotifyData,
                            type: "modal",
                            userType: "all"
                        }).then(() => {
                            this.setState({ notifyLoading: false, notifyDetail: [] }, () => {
                                this.addItem();
                            });
                            ToastsStore.success("Notification added successfully");
                            this.props.history.push("/dashboard");
                        }).catch(error => {
                            console.error("Firebase set error: ", error);
                            this.setState({ notifyLoading: false });
                            ToastsStore.error("Something went wrong while updating Firebase, please try again!");
                        });
                    }).catch(error => {
                        console.error("Firebase get error: ", error);
                        this.setState({ notifyLoading: false });
                        ToastsStore.error("Something went wrong while fetching notifications, please try again!");
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ notifyLoading: false });
                    ToastsStore.error("Something went wrong, please try again!");
                });
        }
    };


    render() {
        return (
            <div className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Add Notification</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={this.addItem}
                                                        >
                                                            <i className="feather icon-plus" />
                                                            ADD
                                                        </button>
                                                    </div>
                                                    {this.state.notifyDetail &&
                                                        this.state.notifyDetail.length
                                                        ? this.state.notifyDetail.map(
                                                            (notifyItem: Query, index: number) => {
                                                                return (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label>Title</label>{" "}
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="title"
                                                                                    placeholder=""
                                                                                    value={notifyItem.title}
                                                                                    onChange={e =>
                                                                                        this.handleChange(e, index)
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="form-group">
                                                                                <label>Notice</label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    name="information"
                                                                                    value={notifyItem.information}
                                                                                    onChange={e =>
                                                                                        this.handleChange(e, index)
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-icon btn-rounded btn-danger"
                                                                                style={{
                                                                                    marginTop: "35px"
                                                                                }}
                                                                                disabled={
                                                                                    this.state.notifyDetail.length === 1
                                                                                }
                                                                                onClick={() => this.removeItem(index)}
                                                                            >
                                                                                <i className="feather icon-minus" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                        : ""}
                                                    <div>
                                                        <div>
                                                            <div className="form-group">
                                                                <div
                                                                    className="btn btn-primary md-trigger"
                                                                    onClick={this.saveNotification}
                                                                >
                                                                    Submit
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
