import * as React from "react";
import { callApi } from "../../utils/api";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import '../process_eod/process_eod.css'
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
    id: any;
}

class ReassignReport extends React.Component<AllProps> {
    state = {
        backReason: "",
        userVAlue: false,
        banks: [],
        bank_name: "",
        selectVal: false,
        setResponse: true,
        myJSON: '',
        lookup: '',
        fileId: '',
        sampleId: "",
        id: "",
        fromDate: "",
        toDate: "",
        files: [],
        fileNo: '',
        lawhandsBranches: [],
        lawhands_branch: "",
        assigned_to: "",
        users: [],
        total: '',
        green: '',
        red: '',
        outreason: "",
        isLoading: false,
        isValid: false
    };
    obj: any;
    table: any;
    componentDidMount() {
        this.getLawHandsBranches()
        this.getUsers()
        this.getOutTime();
        this.ouTime();
        this.getBanks();
    }
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }

    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    this.setState({ isLoading: false });
                    console.log(err);
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };

    getUsers = () => {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        if (isAdmin) {
            callApi("GET", "user_get.php")
                .then(res => res.data)
                .then(response => { this.setState({ users: response.data }); })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            callApi("POST", "team_leader.php", { "user_id": user_id })
                .then(res => res.data)
                .then(response => {
                    console.log("210", response);
                    this.setState({ users: response.data });

                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    goToFileDashboard = fileName => {
        const url = "/reassign-report";
        localStorage.setItem("url", url);
        let obj = { "file_id": fileName }
        localStorage.setItem("thisPopup", JSON.stringify(obj))
        sessionStorage.setItem("currentfileId", JSON.stringify(fileName));
        this.props.history.push("/file-dashboard/" + fileName);
    };

    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getEodTableData = () => {
        if (this.state.fromDate && this.state.toDate) {
            this.setState({ isLoading: true });
            let obj: any = {
                id: this.state.id,
                from_date: this.state.fromDate,
                to_date: this.state.toDate,
                lawhands_branch: this.state.lawhands_branch,
                bank_private_name: this.state.bank_name
            };
            callApi("POST", "reassign_filter.php", obj)
                .then((res: any) => {
                    if (res.data.data && res.data.data.length > 0) {
                        let response = res.data.data;
                        this.setState({ isValid: false, isLoading: false, files: response });
                    } else {
                        ToastsStore.error("No data found");
                        this.setState({ isValid: false, isLoading: false, files: [] });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    this.setState({ files: [] });
                })
        } else {
            this.setState({ isValid: true });
        }
    }
    getBanks() {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ banks: result.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange1 = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName === "lawhands_branch") {
            const branch = fieldValue;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ [fieldName]: fieldValue });
            this.setState({ filteredUsers });
        }
        if (fieldName === "assigned_to") {
            const employee_id = fieldValue;
            this.setState({ [fieldName]: fieldValue });
        }
        if (fieldName === "bank_name") {
            const bank_name = fieldValue;
            console.log("267", bank_name);
            this.setState({ [fieldName]: fieldValue });
        }
    }
    handleChange = (e, stateName?, type?) => {
        if (e.target.name === "bank_name") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "toDate") {
            this.setState({ todate: e.target.value })
        }
        if (e.target.name === "fromDate") {
            this.setState({ fromdate: e.target.value })
        }
        if (e.target.name === "id") {
            this.setState({ id: e.target.value })
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    disabledDate = (current) => {
        // Disable dates after today and dates before the last 3 months
        return current && (current > moment().endOf('day') || current < moment().subtract(3, 'months').startOf('day'));
    };

    render() {

        const columns = [
            {
                header: 'S.No',
                key: 'serial',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.file_id)
                }>{file.serial}</td>
            },
            {
                header: 'File No',
                key: 'file_id',
                td: (file) =>
                    <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.file_id}</div>
            },
            {
                header: 'Login Date',
                key: 'login_dt',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.login_dt}</div>
            },
            {
                header: 'Re-Assign Date',
                key: 'reassign_dt',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.reassign_dt}</div>
            },

            {
                header: 'Previous Process Officer',
                key: 'previous_po',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.previous_po}</div>
            },
            {
                header: "Current Process Officer ",
                key: 'current_po',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.current_po}</div>

            },
            {
                header: 'Previous Verification Officer',
                key: 'previous_vo',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.previous_vo}</div>
            },
            {
                header: 'Current Verification Officer  ',
                key: 'current_vo',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.current_vo}</div>
            },
            {

                header: 'File Branch',
                key: 'lh_branch',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.lh_branch}</div>
            },
            {
                header: 'Bank Name',
                key: 'bank_name',
                td: (file) => <div onClick={() => this.goToFileDashboard(file.file_id)}>{file.bank_name}</div>
            }
        ];
        const data = this.state.files;
        let processedData;
        processedData = data.map((report: any, index) => ({
            serial: index + 1,
            file_id: report.file_id ? report.file_id : "-",
            login_dt: report.login_dt ? report.login_dt : "-",
            reassign_dt: report.reassign_dt ? report.reassign_dt : "-",
            previous_po: report.previous_po ? report.previous_po : "-",
            current_po: report.current_po ? report.current_po : "-",
            previous_vo: report.previous_vo ? report.previous_vo : "-",
            current_vo: report.current_vo ? report.current_vo : "-",
            lh_branch: report.lh_branch ? report.lh_branch : "-",
            bank_name: report.bank_name ? report.bank_name : "-",
        }));
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        const { selectVal } = this.state;

        const lawhandsBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.lawhandsBranches
        ];
        const banksoptions = [
            { value: "", label: "All" },
            ...this.state.banks
        ];
        const usersoptions = [
            { value: "", label: "All" },
            ...this.state.users
        ];
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        const downloadExcelProps = {
            type: 'filtered',
            title: `Reassign Report - ${formattedDate}`,
            showLabel: true
        }
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card" style={{ overflow: 'hidden' }}>
                                                <div className="card-header">
                                                    <h5 className="mt-1 mb-1">Reassign Report</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>From</h6>
                                                            <DatePicker format={'DD-MM-YYYY'} disabledDate={this.disabledDate}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
                                                            {this.state.isValid && !this.state.fromDate && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>To</h6>
                                                            <DatePicker format={'DD-MM-YYYY'} disabledDate={this.disabledDate}
                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
                                                            {this.state.isValid && !this.state.toDate && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>Bank Name</h6>
                                                            {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={banksoptions} name="bank_name"
                                                                    value={this.state.banks.find((o: any) => o.value === this.state.bank_name)}
                                                                    onChange={(e) => this.handleChange1(e, 'bank_name', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>LH Branch</h6>
                                                            {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={lawhandsBranchesoptions} name="lawhands_branch"
                                                                    value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                                                                    onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-xl-2 col-md-2">
                                                            <h6>File.No</h6>
                                                            <input type="id" name="id" className="form-control" id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={this.state.id}
                                                                onChange={e => this.handleChange(e)}
                                                            />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 mt-3">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                onClick={() => this.getEodTableData()}
                                                            >
                                                                {this.state.isLoading ? (
                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                ) : "Search"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <ReactFlexyTable
                                                        className='Flexy-table'
                                                        data={processedData}
                                                        columns={columns}
                                                        sortable
                                                        globalSearch
                                                        showExcelButton
                                                        downloadExcelProps={downloadExcelProps}
                                                        pageSize={20}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default ReassignReport;