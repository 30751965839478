import React from "react";
import { callApi } from "../../utils/api";
import { Deed } from "./ManageDeed";

declare var $: any;
interface AllProps {
  selectedDeed: Deed;
  listDeed: any;
}
class AddDeed extends React.Component<AllProps> {
  state = {
    deeddetail: {
      id: 0,
      deed: "",
    },
    users: []
  };
  componentWillReceiveProps(nextState) {
    const deeddetail = nextState.selectedDeed;
    deeddetail.deed = deeddetail.name;
    this.setState({ deeddetail });
  }
  handleChange = (e: any) => {
    const deeddetail: any = this.state.deeddetail;
    deeddetail[e.target.name] = e.target.value;
    this.setState({ deeddetail });
  };

  saveDeed = () => {
    if (this.state.deeddetail.id) {
      this.state.deeddetail.id = Number(this.state.deeddetail.id);
    }
    callApi("POST", "deed_master.php", this.state.deeddetail)
      .then(res => res.data)
      .then(response => {
        this.props.listDeed();
      })
      .catch(err => {
        console.log(err);
      });
  };
  render() {
    return (
      <div
        className="modal fade"
        id="deed-form"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Deed Form</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="col-form-label">Deed Name</label>
                  <input
                    type="text"
                    name="deed"
                    className="form-control"
                    placeholder="Deed Name"
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    value={
                      this.state.deeddetail.deed
                        ? this.state.deeddetail.deed
                        : ""
                    }
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.saveDeed}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddDeed;
