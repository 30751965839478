import * as React from "react";
import { callApi } from "../../utils/api";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import Select from "react-select";
import "../dashboard/Dashboard.css";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
}
class VideoReport extends React.Component<AllProps> {
    state = {
        lhBranchShow: false,
        bankBranch: false,
        branchName: false,
        lawBranchName: false,
        showBank: false,
        bankName: false,
        outreason: "",
        selectVal: false,
        users: [],
        branches: [],
        assigned_to: "",
        fromdate: "",
        eodate: "",
        eodFromDate: "",
        eodToDate: "",
        reportFromDate: "",
        reportToDate: "",
        todate: "",
        branch: "",
        category_id: "",
        CountData: [],
        branchEodData: [],
        eodReportType: "Branch",
        eodEmployeeList: [],
        eodEmployee: "",
        lawhandsBranches: [],
        bank_private_name: "",
        banks: [],
        filteredBranches: [],
        lawHandsBranch: "",
        isDateSearchClicked: false,
        products: [],
        filteredProduct: [],
        product_id: "",
        isLoading: false,
        isreportLoading: false,
        VideoReport: [],
        reportlawHandsBranch: "",
        report_bank_private_name: "",
        reportbranch: "",
        report_product_id: "",
        VideoCategoryList: [],
        isValid: false
    };
    tableDetails: any;
    componentDidMount() {
        $(".datepicker").on("change", this.handleChange);
        this.getOutTime();
        this.ouTime();
        this.listBankproduct();
        this.getUsers();
        this.getVideoCategoryList()
    }
    getVideoCategoryList = () => {
        callApi("POST", "video_category_getdata.php", {})
            .then(res => res.data)
            .then(response => {
                this.setState({ VideoCategoryList: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getUsers = () => {
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const user_id = user ? JSON.parse(user)["userId"] : null;
        const userDetails = user ? JSON.parse(user) : "";
        const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
        const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
        if (isAdmin) {
            callApi("GET", "user_get.php")
                .then(res => res.data)
                .then(response => { this.setState({ users: response.data }); })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            callApi("POST", "team_leader.php", { "user_id": user_id })
                .then(res => res.data)
                .then(response => {
                    console.log("210", response);
                    this.setState({ users: response.data });

                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    listBankproduct = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "bank_product_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                this.setState({ products: files, filteredProduct: files });
            })
            .catch(err => {
                console.log(err);
            });
    };
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");
        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    this.setState({ isLoading: false });
                    console.log(err);
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
            });
    }
    onchangeReason = (e: any) => {
        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    getBanks() {
        callApi("GET", "bank_get.php")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ banks: result.data });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then((res) => res.data)
            .then((response) => {
                const user: any = sessionStorage.getItem("user");
                const userDetails = user ? JSON.parse(user) : "";
                let lawhandsBranches = response.data;
                if (
                    lawhandsBranches &&
                    lawhandsBranches.length > 0 &&
                    userDetails.role_id !== "1" &&
                    userDetails.my_branchs
                ) {
                    const myBranchList = userDetails.my_branchs.split(",");
                    lawhandsBranches = lawhandsBranches.filter((value) =>
                        myBranchList.includes(value.id)
                    );
                }
                this.setState({ lawhandsBranches });
            })
            .catch((err) => {
                console.log({ err });
            });
    }

    getBankBranches() {
        callApi("GET", "bank_branch_get.php")
            .then((res) => res.data)
            .then((result) => {
                this.setState({ branches: result.data, filteredBranches: [] });
                if (this.state.bank_private_name) {
                    this.filterBranches(this.state.bank_private_name);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    filterBranches = (bankid) => {
        const filteredBranches = this.state.branches.filter(
            (branch: any) => branch.bank_id === bankid
        );
        const state = this.state;
        state.filteredBranches = filteredBranches;
        this.setState({ ...state });
    };

    getEodTableData = () => {
        const {
            reportFromDate,
            reportToDate,
            category_id,
            assigned_to
        } = this.state;

        let Filterobj: any = {
            user_idfk: assigned_to,
            from_date: reportFromDate,
            to_date: reportToDate,
            vedio_category_id: category_id,
        };
        if (reportFromDate && reportToDate) {
            this.setState({ isLoading: true });
            callApi("POST", "video_pause_get.php", Filterobj)
                .then((res) => res.data)
                .then((result) => {
                    if (result.data.length > 0) {
                        this.setState({ CountData: result.data, isDateSearchClicked: true, isValid: false, isLoading: false });
                    } else {
                        this.setState({ CountData: [], isDateSearchClicked: true, isValid: false, isLoading: false });
                        ToastsStore.error("No data found");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isValid: true });
        }

    };
    getBranchEodData = () => {
        const user: any = sessionStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        callApi("POST", "main_report_branch.php", {
            branch: userDetails.my_branchs,
            role_id: userDetails.role_id
        })
            .then((res) => res.data)
            .then((result) => {
                console.log("result", result);
                this.setState({ branchEodData: result.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleChange1 = (e: any, propName?, fieldType?) => {
        let fieldName = fieldType === "singleselect" ? propName : e.target.name;
        let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
        this.setState({ [fieldName]: fieldValue });
    };
    filterProduct = (bankid: any) => {
        console.log("product id--", bankid,);
        if (bankid) {
            const filteredProduct = this.state.products.filter(
                (branch: any) => branch.bank_id === bankid
            );
            const state = this.state;
            state.filteredProduct = filteredProduct;
            this.setState({ ...state });
        } else {
            this.setState({ product_id: "" })
        }
    };
    handleChange = (e, stateName?, type?) => {
        if (e.target.name === "eodToDate") {
            this.setState({ todate: e.target.value });
        }
        if (e.target.name === "eodfromDate") {
            this.setState({
                fromdate: e.target.value,
            });
        }
        if (stateName && type === "dropdown") {
            this.setState({
                [stateName]: e.value,
            });
        } else this.setState({ [e.target.name]: e.target.value });
        if (["eodFromDate", "eodToDate"].includes(e.target.name)) {
            this.setState({ isDateSearchClicked: false });
        }

        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };


    routeSubReport_vetting = (detail, type) => {
        const {
            eodFromDate,
            eodToDate,
            lawHandsBranch,
            bank_private_name,
            branch,
        } = this.state;
        const obj = {
            branch: lawHandsBranch,
            fromdate: eodFromDate,
            todate: eodToDate,
            bankname: bank_private_name,
            bankbranch: branch,
            isRaised: false,
            emergent_id: detail.user_id
        };
        this.props.history.push(`/sub-report/${type}/${detail.status}`, {
            filterData: { ...obj },
        });
    };
    handleDateChange = (date, dateStr, type) => {
        this.setState({ [type]: dateStr });
    };
    lawBranchName = (type: any) => {
        if (type == 2) {
            console.log("431", type)
            this.setState({ lhBranchShow: true })
            this.getLawHandsBranches();

        } else {
            console.log("436", type)
        }
    }
    bankName = (type: any) => {
        if (type == 2) {
            console.log("431", type)
            this.setState({ showBank: true })
            this.getBanks();
        } else {
            console.log("445", type)
        }
    }
    branchName = (type: any) => {
        if (type == 2) {
            console.log("450", type)
            this.setState({ bankBranch: true })
            this.getBankBranches();
        } else {
            console.log("454", type)
        }
    }
    disabledDate = (current) => {
        // Disable dates after today and dates before the last 3 months
        return current && (current > moment().endOf('day') || current < moment().subtract(3, 'months').startOf('day'));
    };
    render() {

        const usersoptions = [
            { value: "", label: "All" },
            ...this.state.users
        ];

        const VideoCategoryList = [
            { value: "", label: "All" },
            ...this.state.VideoCategoryList
        ];
        const user: any = sessionStorage.getItem("user");
        const {
            selectVal,
        } = this.state;
        const data = this.state.CountData;
        console.log("data", data);

        const processedData = Array.isArray(data)
            ? data.map((report: any, index: number) => ({
                serial: index + 1,
                Video_Category: report.Video_Category ? report.Video_Category : "",
                Video_Title: report.Video_Title ? report.Video_Title : "",
                User_Name: report.User_Name ? report.User_Name : "",
                Total_Video_Duration: report.Total_Video_Duration ? report.Total_Video_Duration : "",
                Watched_Start_Time: report.Watched_Start_Time ? report.Watched_Start_Time : "",
                Watched_End_Time: report.Watched_End_Time ? report.Watched_End_Time : "",
                Watched_Date: report.Watched_Date ? report.Watched_Date : "",
                Watched_Time: report.Watched_Time ? report.Watched_Time : "",
            }))
            : [];

        const columns = [
            {
                header: "S.No",
                key: "serial",
                td: (file) => (
                    <td>{file.serial}</td>
                ),
            },

            {
                header: "Video Category ",
                key: " Video_Category",
                td: (file) => (
                    <td>
                        {file.Video_Category}
                    </td>
                ),
            },
            {
                header: "Video Title",

                key: "Video_Title",
                td: (file) => (
                    <td>
                        {file.Video_Title}
                    </td>
                ),
            },
            {
                header: "User Name",

                key: "User_Name",
                td: (file) => (
                    <td>
                        {file.User_Name}
                    </td>
                ),
            },
            {
                header: "Total Video Duration in Min",
                key: "Total_Video_Duration",
                td: (file) => (
                    <td>
                        {file.Total_Video_Duration}
                    </td>
                ),
            },
            {
                header: "Watched Start Time",
                key: "Watched_Start_Time",
                td: (file) => (
                    <td>
                        {file.Watched_Start_Time}
                    </td>
                ),
            },
            {
                header: "Watched End Time",
                key: "Watched_End_Time",
                td: (file) => (
                    <td>
                        {file.Watched_End_Time}
                    </td>
                ),
            },
            {
                header: "Date",
                key: "Watched_Date",
                td: (file) => (
                    <td>
                        {file.Watched_Date}
                    </td>
                ),
            },
            {
                header: "Time",
                key: "Watched_Time ",
                td: (file) => (
                    <td>
                        {file.Watched_Time}
                    </td>
                ),
            },
        ];
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; 
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        const downloadExcelProps = {
            type: 'filtered',
            title: `Training Report - ${formattedDate}`,
            showLabel: true
        }
        return (
            <div className="pcoded-main-container" >
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row pr-3 pl-3">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="mt-1 mb-1">Video Report</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card-body report-container">
                                                        <div className="row mb-3">
                                                            <div className="col-xl-2 col-md-2">
                                                                <h6>From</h6>
                                                                <DatePicker
                                                                    format={"DD.MM.YYYY"}
                                                                    disabledDate={this.disabledDate}
                                                                    onChange={(date, dateStr) =>
                                                                        this.handleDateChange(
                                                                            date,
                                                                            dateStr,
                                                                            "reportFromDate"
                                                                        )
                                                                    }
                                                                    style={{ height: "39px" }}
                                                                />
                                                                {this.state.isValid && !this.state.reportFromDate && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <h6>To</h6>
                                                                <DatePicker
                                                                    format={"DD.MM.YYYY"}
                                                                    disabledDate={this.disabledDate}
                                                                    onChange={(date, dateStr) =>
                                                                        this.handleDateChange(
                                                                            date,
                                                                            dateStr,
                                                                            "reportToDate"
                                                                        )
                                                                    }
                                                                    style={{ height: "39px" }}
                                                                />
                                                                {this.state.isValid && !this.state.reportToDate && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <h6>Employee Name</h6>
                                                                {this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
                                                                    opt.label = opt.name; opt.value = opt.id

                                                                }) &&

                                                                    <Select options={usersoptions} name="assigned_to"
                                                                        value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
                                                                        onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
                                                                        required
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <h6>Video category</h6>
                                                                {this.state.VideoCategoryList && this.state.VideoCategoryList.length > 0 && this.state.VideoCategoryList.map((opt: any) => {
                                                                    opt.label = opt.category_name; opt.value = opt.id
                                                                }) &&
                                                                    <Select options={VideoCategoryList} name="category_id"
                                                                        value={this.state.VideoCategoryList.find((o: any) => o.value === this.state.category_id)}
                                                                        onChange={(e) => this.handleChange1(e, 'category_id', 'singleselect')}
                                                                        required
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-xl-2 col-md-12 p-3">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                    style={{ float: 'right' }}
                                                                    onClick={() => this.getEodTableData()}
                                                                >
                                                                    {this.state.isLoading ? (
                                                                        <i className="fa fa-spinner fa-spin"></i>
                                                                    ) : "Search"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <ReactFlexyTable
                                                            className='Flexy-table'
                                                            columns={columns}
                                                            data={processedData}
                                                            sortable
                                                            globalSearch
                                                            showExcelButton
                                                            downloadExcelProps={downloadExcelProps}
                                                            pageSize={20}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* THIS FOR OUT REASON MODAL */}
                                        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                                            <div className="modal-dialog ">
                                                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                                                    <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                                        <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                                                    </div>
                                                    <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                                        <select
                                                            className="custom-select"
                                                            name="outreason"
                                                            value={this.state.outreason}
                                                            onChange={(e) => this.onchangeReason(e)}
                                                            required
                                                            id="select1"
                                                            style={{ marginTop: '8px' }}
                                                        >
                                                            <option value="select">Select Your Reason</option>
                                                            <option value="Query Raised">Query Raised</option>
                                                            <option value=" Re-Query Raised"> Re-Query Raised</option>
                                                            <option value="Hold ">Hold </option>
                                                            <option value="Completed">Completed</option>
                                                            <option value="Other">Others</option>
                                                        </select>
                                                        {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                                    </div>
                                                    <div className="modal-footer" style={{ marginTop: '27px' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            disabled={this.state.isLoading}
                                                            onClick={this.outimeGet}
                                                            style={{ width: '76px', fontSize: '17px' }}
                                                        > {this.state.isLoading ? (
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                        ) : "OK"}</button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline"
                                                            onClick={this.BacktoFile}
                                                            style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                                        > Back To File</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* OTHER REASON MODAL */}
                                        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                                            <div className="modal-dialog ">
                                                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                                                    <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                                                    <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                                        <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Type Your Reason"
                                                                name="outreason" onChange={this.onchange} />
                                                            {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => this.outimeGet()}
                                                            style={{ width: '76px', fontSize: '17px' }}
                                                            disabled={this.state.isLoading}
                                                        > {this.state.isLoading ? (
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                        ) : "OK"}</button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline"

                                                            onClick={() => this.previousPop()}
                                                            // onClick={this.disableModalHandler}
                                                            style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                                        > Back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default VideoReport;
