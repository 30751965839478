import React, { Component } from "react";
import { callApi, API_ENDPOINT } from "../../utils/api";
import { Link } from "react-router-dom";
import _ from 'lodash';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { cloneDeep } from 'lodash';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { CircularProgress } from "@material-ui/core";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
import HintAboutFile from "./HintAboutFile";

interface AllProps {
  history: any,
  match: any
}

export default class WorkFileQA extends Component<AllProps> {
  state = {
    report: "",
    rep_id: "",
    remote_users: [],
    isapproved: 0,
    office_users: [],
    documents: [],
    selectedDoc: "",
    qaList: [],
    currentIndex: -1,
    hints: [],
  };
  document = {
    base: "",
    numPages: 0,
    pageNumber: 1
  };

  rendered: any;
  componentDidMount() {
    var docOverview = sessionStorage.getItem("overviewDocument");
    console.log("docOverview-------->>>", docOverview);
    if (docOverview != null) {
      this.setState({ report: docOverview, enableRefresh: true });
    } else {
      this.getFileOverview();
    }
    // this.getDocList();
    this.getFileQA();
    this.getHints();
  }
  getHints = () => {
    callApi("POST", "hint_get.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(result => {
        this.setState({ hints: result.data })
      })
      .catch(err => {
        console.log(err);
      });
  }
  getFileOverview = () => {
    const props: any = this.props;
    callApi("POST", "final_report_get.php", {
      file_idfk: props.match.params.id,
      check: true
    })
      .then(res => res.data)
      .then(response => {
        if (response && response.data && response.data.length) {
          if (response.data[0].rep_id) {
            const state = response.data[0];
            this.setState({ ...state });
          } else {
            const props: any = this.props;
            const user: any = sessionStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            callApi("POST", "template/tests/scrutiny_report.php", {
              file_idfk: props.match.params.id,
              check: true, userId
            })
              .then(res => res.data)
              .then(response => {
                this.setState({ report: "" });
                this.setState({ report: response.data });
              });
          }
          this.setState({ isapproved: response.data[0].isapproved });
        }
      });
  };
  getFileQA = () => {
    const props: any = this.props;
    const obj = {
      file_id: props.match.params.id
    }
    callApi("POST", "file_question_answer_get.php", obj)
      .then(res => res.data)
      .then(response => {
        let resData = response.responseJson.data;
        resData = _.sortBy(resData, (o) => {
          return Number(o.id);
        });
        this.setState({ qaList: resData });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getDocList = () => {
    const props: any = this.props;
    callApi("POST", "document_list.php", { id: props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          // const document = this.document;
          // document.base = API_ENDPOINT + response.data[0].base[0].base;
          // this.setState({ ...document });
          const documents = response.data;
          this.setState({
            documents,
            selectedDoc: documents[0].file_id + "~" + documents[0].type
          });
          this.getRevenueDocs(documents[0].file_id);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getRevenueDocs = id => {
    callApi("POST", "getbaseby_id.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = API_ENDPOINT + response.data[0].base[0].base;
          this.setState({ ...document });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  handleqaChange = (e, i) => {
    const { qaList } = this.state;
    const list: any = qaList;
    list[i].answer = e.target.value;
    this.setState({ qaList: list });
  }
  radioOnChange = (e, index, item) => {
    const { value } = e.target;
    const { qaList } = this.state;
    let baseArray = cloneDeep(qaList);
    if (item.check_box == true) {
      baseArray[index] = {
        ...baseArray[index],
        answer: value
      };
    }
    this.setState({ qaList: baseArray }, () => {
      console.log("radioOnChange____", item);
    });
  }
  saveQA = () => {
    const props: any = this.props;
    const obj = {
      file_id: props.match.params.id,
      answers: this.state.qaList
    }
    console.log(obj);

    callApi("POST", "answer_add.php", [obj])
      .then(res => res)
      .then(response => {
        sessionStorage.removeItem('overviewDocument');
        console.log(response);
        if (response.status === 200) {
          ToastsStore.success("work file Q & A are saved succesfully")
        } else {
          ToastsStore.error("Error in storing, Please try again!")
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { currentIndex } = this.state;
    return (
      <div className="pcoded-content" id="work-file-qa">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Work File Q &amp; A</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6" style={{
                          border: "1px solid rgb(222, 226, 230)",
                          borderRadius: "8px"
                        }}>
                          <Accordion className="mainAccordion">
                            <Tabs defaultActiveKey="documentKey"
                              style={{ padding: 0 }}>
                              <Tab eventKey="documentKey" title="Document">
                                <div
                                  className="row form-document"
                                  style={{ height: "70vh", overflow: 'hidden' }}
                                >
                                  {
                                    this.state.report ?
                                      <iframe width="99%" height="590" frameBorder="0" src={this.state.report} title="workfile"></iframe>
                                      :
                                      <CircularProgress style={{ marginLeft: '50%', marginTop: '30%' }} />
                                  }

                                </div>
                              </Tab>
                              <Tab eventKey="hintKey" title="Hint About">
                                <HintAboutFile id={this.props.match.params.id} path='workfileQA'/>
                              </Tab>
                            </Tabs>
                          </Accordion>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '8px',
                            height: '81vh'
                          }}
                        >
                          <h5>Question & Answers</h5>
                          <div className="container" style={{ width: "100%", height: '93%', overflow: 'auto' }}>
                            <table className="table table-striped">
                              <tbody>
                                {this.state.qaList.map((qa: any, index) =>
                                  <tr key={qa.id} className="row">
                                    <td style={{ borderRight: '1px solid #dee2e6', padding: '0' }} className="col-md-8">
                                      <SunEditor defaultValue={qa.question}
                                        height='100'
                                        name=" question"
                                        disable={true}
                                        hideToolbar={true}
                                        readOnly={true}
                                        setDefaultStyle="font-family: 'BookmanOldStyle'; font-size: 12px;"
                                        setOptions={{
                                          resizingBar: false,

                                        }}
                                      />
                                    </td>
                                    <td style={{ width: '30%', border: '2px solid #dee2e6' }} className="col-md-4">
                                      {qa.check_box == false ? <>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" value={qa.answer}
                                          onFocus={e => {
                                            this.setState({ currentIndex: index })
                                            e.target.select()
                                          }}
                                          onChange={(e) => this.handleqaChange(e, index)}
                                          rows={2}
                                        />
                                        {currentIndex === index ?
                                          <p id={`textarea_${index}`} style={{ fontSize: '10px', color: 'grey', marginTop: '3px' }}>Maximum 250 characters</p>
                                          : null}
                                      </> : <>
                                        <div style={{ display: "flex", justifyContent: "space-around", padding: "12% 0%" }}>
                                          <div style={{ display: "flex", gap: "5px" }}>
                                            <input
                                              type="radio"
                                              id={`queyes-${index}`}
                                              name={`questionRadio-${index}`}
                                              value="Yes"
                                              className="form-control f-small br-2 wth-90"
                                              style={{ fontSize: "2px" }}
                                              onChange={e => this.radioOnChange(e, index, qa)}
                                              checked={qa.answer === "Yes"}
                                            />
                                            <label htmlFor={`queyes-${index}`}>Yes</label>
                                          </div>
                                          <div style={{ display: "flex", gap: "5px" }}>
                                            <input
                                              type="radio"
                                              id={`queno-${index}`}
                                              name={`questionRadio-${index}`}
                                              value="No"
                                              className="form-control f-small br-2 wth-90"
                                              style={{ fontSize: "2px" }}
                                              onChange={e => this.radioOnChange(e, index, qa)}
                                              checked={qa.answer === "No"}
                                            />
                                            <label htmlFor={`queno-${index}`}>No</label>
                                          </div>
                                        </div>
                                      </>}
                                    </td>
                                  </tr>
                                )}
                                {this.state.qaList.length === 0 &&
                                  <tr>
                                    <td>There is no Q &amp; A for this file.</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                          <div style={{ marginTop: '20px' }}>
                            {this.state.qaList.length > 0 ?
                              <button
                                className="btn btn-success save-card"
                                onClick={this.saveQA}
                              >
                                Save
                              </button> : null}
                            <button
                              className="btn btn-primary save-card"
                              style={{ marginBottom: '10px' }}
                              onClick={() => this.props.history.replace(`/work-file/4/${this.props.match.params.id}`)}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
