import { Empty } from "antd";
import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import { getFileStatus } from '../../utils/fileStatus';
import VerifyFileModal from "./VerifyFileModal";
import { isEmpty, isNaN, isNumber } from 'lodash';
import app from "../../App";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import moment from 'moment';
import { FaBullseye } from "react-icons/fa";
interface AllProps {
  match: any;
  history: any;
}
declare var $: any;

export default class SearchResults extends Component<AllProps> {
  state = {
    filesbank: "",
    selectVal: false,
    setResponse: true,
    files: [],
    isFileRefresh: false,
    fileToBeVerified: 0,
    currentFileid: '',
    status: "",
    data: '',
    OutTime: 0,
    inTime: 0,
    inreason: "",
    outreason: "",
    autoAssign: [
      {
        "id": "1",
        "module_name": "Auto Assign",
        "status": true
      }
    ],
    isLoading: false,
    reassign_type: "",
    selectedFileId: "",
    holdVal: false,
    holdreason: "",
    unholdVal: false,
    unholdreason: "",
    showValid: false,
    reassignValidMessage: "",
    pageDynamic: [],
    deviationReason: "",
    deviationRequestFile: {}
  };
  appRef: app | null = null;

  componentDidMount() {
    this.getFiles();
    this.getOutTime();
    this.ouTime();
    // this.getAutoAssignStatus();
    this.getPageDynamicData();
  }
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  UNSAFE_componentWillReceiveProps() {
    this.getFiles();
  }
  GotoWorkFile = (id, process_office_id) => {
    console.log("process_office_id", process_office_id);
    let process_officer_id = JSON.stringify(process_office_id);
    sessionStorage.setItem("process_office_id", process_officer_id);
    const userid1: any = sessionStorage.getItem("user1");
    const url = `/search-results/file_name/${id}`;
    localStorage.setItem("url", url);
    let obj = { file_id: id };
    localStorage.setItem("thisPopup", JSON.stringify(obj));
    sessionStorage.setItem("currentfileId", JSON.stringify(id));
    const take_file: any = sessionStorage.getItem("takenfile_id");
    const user: any = sessionStorage.getItem("user");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = sessionStorage.getItem("currentfileId");
    if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
      this.timeUpdate(id, "workfile");
      console.log("process officer");
    } else {
      console.log("not process officer");
    }
    this.props.history.push("/work-file/7/" + id);
  };

  setFileId = (id: any) => {
    this.setState({ selectedFileId: id, reassignValidMessage: '', showValid: false, reassign_type: '' });
  }

  getPageDynamicData = () => {
    const user: any = sessionStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    let obj = {
      roleId: userDetails ? userDetails.role_id : null,
      userId: userDetails ? userDetails.userId : null,
      roleType: userDetails ? userDetails.roleType : null,
    };
    callApi("POST", "authenticating_pages_list_new.php", obj)
      .then((res) => res.data)
      .then((result) => {
        this.setState({ pageDynamic: result.responseJson.data, });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }

  disableReassignModalHandler = () => {
    $("#re-assign").modal("hide");
  }

  fileReassign = () => {
    const userid1: any = sessionStorage.getItem("user1");
    const { selectedFileId, reassign_type, files } = this.state;
    if (reassign_type == "") {
      this.setState({ selectVal: true });
    } else {
      if (files.length > 0) {
        // Iterate through the files array
        files.forEach((file: any) => {
          let fileStatus: any = file.file_status;
          let assigned_to: any = file.assigned_to;
          let verified_by: any = file.verified_by;
          if (assigned_to == '') {
            this.setState({ reassignValidMessage: "This file is already in queue", showValid: true });
            return;
          } else if (fileStatus == "0") {
            this.setState({ reassignValidMessage: "This file is already in queue", showValid: true });
            return;
          } else if (fileStatus == "5") {
            this.setState({ reassignValidMessage: "Completed file can't be reassigned", showValid: true });
            return;
          } else if (fileStatus == "7") {
            this.setState({ reassignValidMessage: "This file is still not query received. So can't be reassigned", showValid: true });
            return;
          } else if (fileStatus == "3") {
            this.setState({ reassignValidMessage: "This file is Hold. So can't be reassigned", showValid: true });
            return;
          } else if (fileStatus == "2" && assigned_to == '') {
            this.setState({ reassignValidMessage: "This file is already in Queue. So can't be reassigned", showValid: true });
            return;
          } else if (fileStatus == "6" && verified_by == '') {
            this.setState({ reassignValidMessage: "This file is in verification queue. So can't be reassigned for Process", showValid: true });
            return;
          } else if (fileStatus == "6" && reassign_type == 'process') {
            this.setState({ reassignValidMessage: "This file is to be Verified. So can't be reassigned for Process", showValid: true });
            return;
          } else if (fileStatus != "6" && reassign_type == "verification") {
            this.setState({ reassignValidMessage: "This file is Process. So can't be reassigned for Verification", showValid: true });
            return;
          } else {
            this.setState({ isLoading: true, showValid: false });
            let obj = {
              "file_no": selectedFileId,
              "reassign_type": reassign_type,
              "user_id": JSON.parse(userid1)
            }
            callApi("POST", "reassign.php", obj)
              .then(res => res.data)
              .then(response => {
                if (response.status == 200) {
                  this.setState({ isLoading: false, reassign_type: "", selectedFileId: "" });
                  $("#re-assign").modal("hide");
                  this.getFiles();
                  this.reassignupdatetime();
                  ToastsStore.success(response.message);
                }
              }).catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
              })
          }
          console.log("state", this.state);
        });
      }
    }
  }
  holdFile = () => {
    this.setState({ holdVal: true });
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const { holdreason, holdVal } = this.state;
    if (holdreason != "" && holdVal) {
      this.setState({ isLoading: true });
      const obj = {
        file_id: this.state.selectedFileId,
        hold_by: userId,
        reason: holdreason
      }
      callApi("POST", "hold_file.php", obj)
        .then(res => res.data)
        .then(response => {
          if (response.statuscode == 200) {
            this.setState({ holdVal: false, holdreason: '', isLoading: false });
            ToastsStore.success(response.message);
            $("#holdModal").modal("hide");
            this.getFiles();
            this.timeUpdate(this.state.selectedFileId, 'hold');
          } else {
            ToastsStore.error(response.message);
          }
        }).catch(err => {
          console.log(err);
          this.setState({ files: [] });
        });
    }
  }
  unholdFile = () => {
    this.setState({ unholdVal: true });
    const user: any = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const { unholdreason, unholdVal } = this.state;
    if (unholdreason != "" && unholdVal) {
      this.setState({ isLoading: true });
      const obj = {
        file_id: this.state.selectedFileId,
        unhold_by: userId,
        reason: unholdreason
      }
      callApi("POST", "unhold_file.php", obj)
        .then(res => res.data)
        .then(response => {
          if (response.statuscode == 200) {
            this.setState({ unholdVal: false, unholdreason: '', isLoading: false });
            ToastsStore.success(response.message);
            this.getFiles();
            $("#unholdModal").modal("hide");
            this.timeUpdate(this.state.selectedFileId, 'unhold');
          } else {
            ToastsStore.error(response.message);
          }
        }).catch(err => {
          console.log(err);
          this.setState({ files: [] });
        });
    }
  }


  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = sessionStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = sessionStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (sessionStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = sessionStorage.getItem("currentfileId");
    const userid1: any = sessionStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    let updatedRemainingTime: any = sessionStorage.getItem('updatedRemainingTime');
    let updatedtime = Math.round(updatedRemainingTime);
    const status: any = sessionStorage.getItem("dashboard_status");

    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent,
        "balance_min": updatedtime,
        "dashboard_status": status
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          this.setState({ isLoading: false });
          sessionStorage.removeItem('updatedRemainingTime');
          console.log("76", response);
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = sessionStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = sessionStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          let file_id = JSON.parse(fileid1);
          const take_file: any = sessionStorage.getItem("takenfile_id");
          const user: any = sessionStorage.getItem("user");
          // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
          const getfileId: any = sessionStorage.getItem("currentfileId");
          if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
            console.log(`${process_officer_id}---${user_id}`);

            this.timeUpdate(file_id, "fileout");
            console.log("process officer");
          } else {
            sessionStorage.removeItem('currentfileId');
          }
          if (this.appRef) {
            this.appRef.intervalClear();
            sessionStorage.removeItem('updatedRemainingTime');
            console.log("file out");
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }

  timeUpdate = (fileid, type) => {
    console.log(`${fileid}---${type}`);

    const loginuser: any = sessionStorage.getItem("user1");
    let req;
    this.state.autoAssign.find(
      (data: any) => {
        if (data.module_name === "Auto Assign" && data.status == true) {
          if (type == "workfile") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              taken_status: 1,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "filedash") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              reason: "Work Inprogress",
              taken_status: 1,
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == 'hold') {
            req = {
              id: fileid,
              verification_hold_by: JSON.parse(loginuser),
              reason: "Hold",
              created_by: JSON.parse(loginuser),
              color: "gray"
            };
          } else if (type == 'unhold') {
            req = {
              id: fileid,
              verification_unhold_by: JSON.parse(loginuser),
              reason: "Unhold",
              created_by: JSON.parse(loginuser),
              color: "gray"
            };
          }
          else {
            req = {
              id: fileid,
              reason: "File Out",
              created_by: JSON.parse(loginuser)
            }
            sessionStorage.removeItem("currentfileId");
            // sessionStorage.removeItem("takenfile_id");
          }
        }
      })
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  // openPoUp = () => {
  // }
  getFiles = () => {
    //console.log(this.props);
    callApi("POST", "search.php", {
      [this.props.match.params.name]: this.props.match.params.searchValue
    })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ filesbank: files })
        console.log("158", files);
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
          })
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  goToFileDashboard = (id, process_office_id) => {
    console.log("process_office_id", process_office_id);
    let process_officer_id = JSON.stringify(process_office_id);
    sessionStorage.setItem("process_office_id", process_officer_id);
    const userid1: any = sessionStorage.getItem("user1");
    const url = `/search-results/file_name/${id}`;
    localStorage.setItem("url", url);
    const fileBank: any = this.state.filesbank;
    fileBank.forEach(element => {
      if (element.id == id) {
        localStorage.setItem("bankName", element.bank_name);
      }
    });
    let obj = { "id": id }
    localStorage.setItem("thisPopup", JSON.stringify(obj))
    sessionStorage.setItem("currentfileId", JSON.stringify(id));
    const take_file: any = sessionStorage.getItem("takenfile_id");
    const user: any = sessionStorage.getItem("user");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = sessionStorage.getItem("currentfileId");
    if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
      this.timeUpdate(id, "filedash");
      console.log("process officer");
    } else {
      console.log("not process officer");
    }
    this.props.history.push("/file-dashboard/" + id);
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeVerified: modal.id });
  };
  getBillVettingComplete(file) {
    this.setState({ currentFileid: file.id });
    const obj = {
      id: file.id
    }
    callApi("POST", "bill_get_vetting_complete.php", obj)
      .then((res) => res.data)
      .then((result) => {
        const data = result.data
        if (data && data.length > 0) {
          this.setState({ status: data[0].vetting_status === "1" });
        }

        if (result.data) {
        } else {
        }
      });
  }
  updateBillVettingComplete(status) {
    callApi("POST", "bill_vetting_complete.php", {
      file_id: this.state.currentFileid,
      status,
    })
      .then((res) => res.data)
      .then((result) => {
        if (result.data) {
          this.vettingcompleteupdatetime();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  vettingcompleteupdatetime() {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      vetting_completed_time: currenttime,
      vetting_completed_date: currentdate,
      vetting_completed_by: JSON.parse(loginuser),
      id: this.props.match.params.id
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }
  reassignupdatetime() {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = sessionStorage.getItem("user1");
    let req = {
      reassign_time: currenttime,
      reassign_date: currentdate,
      reassign_by: JSON.parse(loginuser),
      id: this.props.match.params.id,
      reason: "Reassign",
      created_by: JSON.parse(loginuser),
      color: "#edada0"
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  handleDeviationReasonChange = (e) => {
    const { value } = e.target;
    this.setState({ deviationReason: value });
  }

  closeDeviationModal = () => {
    this.setState({ deviationReason: "", deviationRequestFile: {} });
  }

  submitRequeryDeviation = () => {
    const { deviationRequestFile, deviationReason }: any = this.state;
    const loginuser: any = sessionStorage.getItem("user1");
    const obj = {
      deviationRequest_by: JSON.parse(loginuser),
      deviationRequest_file: deviationRequestFile.id,
      deviation_reason: "Kindly validate the Querie."
    }
    // if (deviationReason != "") {
    callApi("POST", "manager_deviation_request.php", obj)
      .then(res => res.data)
      .then(response => {
        if (response.success == true) {
          $("#deviation-Modal").modal("hide");
          this.setState({ deviationReason: "" });
          ToastsStore.success(response.message);
        } else {
          ToastsStore.error(response.message);
        }
      });
    // } else {
    //   ToastsStore.error("Please type reason to proceed");
    // }
  }

  deviationRequest = (file) => {
    if (file.deviation_status != 1) {
      $("#deviation-Modal").modal("show");
    } else {
      ToastsStore.error("This file is already in deviation queue.");
    }
    this.setState({ deviationRequestFile: file })
  }

  render() {
    const { status: vettingStatus, setResponse } = this.state;
    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const roleType = user ? JSON.parse(user)["roleType"] : "";
    const columns = [
      {
        header: 'ID',
        key: 'index',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.index}</td>
      },
      {
        header: 'Borrower Name',
        key: 'borrower_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.borrower_name}</td>
      },
      {
        header: 'Property Owner Name',
        key: 'property_ownername',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.property_ownername}</td>
      },
      {
        header: 'File Name',
        key: 'file_id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.file_id}</td>
      },
      {
        header: 'Status',
        key: 'file_status',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }><div>{getFileStatus(file.file_status)}</div></td>
      },
      {
        header: 'Rectification Status',
        key: 'rectify_status',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }><div>{file.rectify_status}</div></td>
      },
      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.bank_name}</td>
      },
      {
        header: 'Process Officer',
        key: 'assigned_to',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.assigned_to}</td>
      },
      {
        header: 'Verified Officer',
        key: 'verified_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.verified_by}</td>
      },
      {
        header: 'Fronent Officer',
        key: 'login_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.login_by}</td>
      },
      {
        header: 'Estimate Time',
        key: 'estimate_time',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.estimate_time}</td>
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              <a
                className="dropdown-item"
                onClick={() => this.GotoWorkFile(file.id, file.process_office_id)}
                style={{
                  border: "none",
                  // background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Work File
              </a>
              {this.state.pageDynamic.map((page: any, index: any) => {
                if (page.page_name === "Reassign Button" && page.status == true) {
                  return (
                    <>
                      {['0', '1', '2'].includes(roleType) && <>
                        <a
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#re-assign"
                          onClick={() => this.setFileId(file.id)}
                          style={{
                            border: "none",
                            // background: "none",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          Reassign
                        </a>
                      </>
                      }
                    </>
                  );
                } else if (page.page_name === "Hold Button" && page.status == true) {
                  return (
                    <>
                      {file.file_status == "3" && ['0', '1', '2'].includes(roleType) && <>
                        <a
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#unholdModal"
                          onClick={() => this.setFileId(file.id)}
                          style={{
                            border: "none",
                            // background: "none",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          Unhold
                        </a>
                      </>}
                      {file.file_status == "6" && ['0', '1', '2'].includes(roleType) &&
                        <>
                          <a
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#holdModal"
                            onClick={() => this.setFileId(file.id)}
                            style={{
                              border: "none",
                              // background: "none",
                              display: "block",
                              margin: "auto",
                            }}
                          >
                            Hold
                          </a>
                        </>}
                    </>
                  );
                } else if (page.page_name === "Deviation Request Button" && page.status == true) {
                  return (
                    <>
                      {['0', '1', '2'].includes(roleType) && !["5", "6"].includes(file.file_status) && <>
                        <a
                          className="dropdown-item"
                          onClick={() => this.deviationRequest(file)}
                          style={{
                            border: "none",
                            // background: "none",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          Deviation Request
                        </a>
                      </>
                      }
                    </>
                  );
                }
                return null;
              })
              }


              <a
                className="dropdown-item"
                onClick={() => this.goToFileDashboard(file.id, file.process_office_id)}
                style={{
                  border: "none",
                  // background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Dashboard
              </a>
              <a
                className="dropdown-item"
                style={{ float: "right" }}
                data-toggle="modal"
                data-target="#delete-bank"
                onClick={() => this.getBillVettingComplete(file)}
              >
                Vetting Completed
              </a>
              {/* {file.file_status === '6' &&
                <a
                  className="dropdown-item"
                  onClick={() =>
                    this.assignFile(file)
                  }
                  data-toggle="modal"
                  data-target="#verify-modal"
                  data-whatever="@mdo"
                >
                  Verify
                </a>
              } */}
            </div>
          </div>
        }
      }
    ];
    const data = this.state.files;
    const { selectVal } = this.state;
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Search Results</h5>
                        </div>
                        <div className="card-body">
                          {this.state.isFileRefresh &&
                            <ReactFlexyTable data={data} columns={columns} sortable globalSearch className='Flexy-table' />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VerifyFileModal
          fileToBeVerified={this.state.fileToBeVerified}
          getFiles={this.getFiles}
          assignedFrom={"unassign"}
        /><div
          className="modal fade"
          id="delete-bank"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ textAlign: "center" }}>Vetting Completed</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {vettingStatus && (<h6>Your vetting report is completed please contact our support team</h6>)}

                {!vettingStatus && (
                  <>
                    <h6>If you want to complete the vetting report ?</h6>
                    <div className="form-group"></div>
                  </>
                )}
              </div>
              {!vettingStatus && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => this.updateBillVettingComplete(1)}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => this.updateBillVettingComplete(0)}
                  >
                    No
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deviation-Modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deviationLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5>
                  Are you sure you want to request for deviation?
                </h5>
              </div>
              {/* <div
                className="modal-body"
              >
                <p style={{ textAlign: 'center', color: 'black' }}>Please Type a Reason To deviation request for this query</p>
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    onChange={(e) => this.handleDeviationReasonChange(e)}
                    value={this.state.deviationReason}
                    name="deviationReason" />
                  {(isEmpty(this.state.deviationReason)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please type your reason </p> : null}
                </div>
              </div> */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.closeDeviationModal()}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.submitRequeryDeviation()}
                >
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* THIS FOR OUT REASON MODAL */}
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}
                  disabled={this.state.isLoading}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
        {/* {THIS FOR ASSIGN MODAL} */}
        <div className="modal assign-modal  hide in" id="re-assign" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-header">
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select Reassign Type for The File.No:{this.state.selectedFileId}</h3>
              </div>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <div style={{ color: 'black', marginLeft: '12%' }}>
                  <p>Note:</p>
                  <ul>
                    <li>File is in New File, Queue, Query Raised, Hold or Completed can't be Reassign the file.</li>
                  </ul>
                </div>
                <div className="form-group" style={{ width: '74%', margin: 'auto', marginTop: "10%" }}>
                  <select
                    className="custom-select"
                    name="reassign_type"
                    value={this.state.reassign_type}
                    onChange={(e) => this.onchangeReason(e)}
                    required
                    id="select1"
                    style={{ marginTop: '8px' }}
                  >
                    <option value="select">Select Your Type</option>
                    <option value="process">Process</option>
                    <option value="verification">Verification</option>
                  </select>
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Type </p> : null}
                  {this.state.showValid && <p style={{ color: "red", fontSize: "14px" }}>{this.state.reassignValidMessage}</p>}
                </div>
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={() => { this.fileReassign() }}
                  style={{ fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "Reassign"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => { this.disableReassignModalHandler() }}
                  data-bs-dismiss="re-assign" aria-label="Close"
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="unholdModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="holdModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5>
                  Are you sure you want to unhold {this.state.selectedFileId}?
                </h5>
              </div>
              {this.state.unholdVal && <>
                <div
                  className="modal-body"
                >
                  <p style={{ textAlign: 'center', color: 'black' }}>Please Type a Reason To Unhold The File</p>
                  <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                    <textarea
                      className="form-control"
                      placeholder="Type Your Reason"
                      name="unholdreason" onChange={this.onchange} />
                    {(isEmpty(this.state.unholdreason)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please type your reason </p> : null}
                  </div>
                </div>
              </>}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.unholdFile()}
                >
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="holdModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="holdModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5>
                  Are you sure you want to hold {this.state.selectedFileId}?
                </h5>
              </div>
              {this.state.holdVal && <>
                <div
                  className="modal-body"
                >
                  <p style={{ textAlign: 'center', color: 'black' }}>Please Type a Reason To hold The File</p>
                  <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                    <textarea
                      className="form-control"
                      placeholder="Type Your Reason"
                      name="holdreason" onChange={this.onchange} />
                    {(isEmpty(this.state.holdreason)) ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please type your reason </p> : null}
                  </div>
                </div>
              </>}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.holdFile()}
                >
                  {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}