import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { getFileStatus } from "../../utils/fileStatus";

interface ReportData {
    lh_branch_name: string;
    serial: number;
    id: number;
    file_name: string;
    prosses_officer: string;
    verified_by: string;
    status: string;
    pending: number;
    closed: number;
    total: number;
    sop_total: number;
    sop_notreleated: number;
    sop_pending: number;
    sop_complete: number;
    lod_total: number;
    lod_notreleated: number;
    lod_pending: number;
    lod_complete: number;
    updated_dt: string;
    login_date: string;
    borrower_name: string;
    owner_name: string;
    bank_name: string;
    product_name: string;
    bank_branch_name: string;
    sql: string;
    typing_completed_dt: string;
    verification_picked_dt: string;
    verification_completed_dt: string;
    query_raised_dt: string;
    query_received_dt: string;
    app_id: string;
    file_status: string;
    hold_by: string;
    hold_dt: string;
    hold_reason: string;
    unhold_by: string;
    unhold_dt: string;
    unhold_reason: string;
}

interface AllProps {
    reports: ReportData[];
    getBranch: any;
    redirectToDashboard?: any;
}



export default class ReportTable extends Component<AllProps> {
    render() {
        const { reports = [], redirectToDashboard = () => { } } = this.props;

        const columns = [
            {
                header: 'S.no',
                key: 'serial',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.serial}</span>,
            },
            {
                header: 'File Name',
                key: 'file_name',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.file_name}</span>,
            },
            {
                header: 'Process Officer',
                key: 'prosses_officer',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.prosses_officer}</span>,
            },
            {
                header: 'Verify Officer',
                key: 'verified_by',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.verified_by}</span>,
            },
            {
                header: 'File Status',
                key: 'file_status',
                td: (item: ReportData) => (
                    <div onClick={() => redirectToDashboard(item.id)}>
                        {item.file_status}<br />
                        <span className="text-c-red">Pending - {item.pending}</span><br />
                        <span className="text-c-green">Closed - {item.closed}</span><br />
                        <span className="text-c-info">Total - {item.total}</span>
                    </div>
                ),
            },
            {
                header: 'Update Date',
                key: 'updated_dt',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.updated_dt}</span>,
            },
            {
                header: 'Login Date',
                key: 'login_date',
                td: (item: ReportData) => <span onClick={() => redirectToDashboard(item.id)}>{item.login_date}</span>,
            },
        ];

        const processedData = reports.map((report, index) => ({
            serial: index + 1,
            id: report.id,
            file_name: report.file_name ? report.file_name : "",
            lh_branch_name: report.lh_branch_name ? report.lh_branch_name : "",
            bank_name: report.bank_name,
            product: report.product_name ? report.product_name : "",
            bank_branch_name: report.bank_branch_name ? report.bank_branch_name : "",
            borrower_name: report.borrower_name ? report.borrower_name : "",
            owner_name: report.owner_name ? report.owner_name : "",
            app_id: report.app_id ? report.app_id : "",
            file_status: report.file_status ? getFileStatus(report.file_status) : "",
            login_date: report.login_date ? report.login_date : "",
            updated_dt: report.updated_dt ? report.updated_dt : "",
            prosses_officer: report.prosses_officer ? report.prosses_officer : "",
            verified_by: report.verified_by ? report.verified_by : "",
            pending: report.pending ? report.pending : "",
            closed: report.closed ? report.closed : "",
            total: report.total ? report.total : "",
            sop_total: report.sop_total ? report.sop_total : "0",
            sop_notreleated: report.sop_notreleated ? report.sop_notreleated : "0",
            sop_pending: report.sop_pending ? report.sop_pending : "0",
            sop_complete: report.sop_complete ? report.sop_complete : "0",
            lod_total: report.lod_total ? report.lod_total : "0",
            lod_notreleated: report.lod_notreleated ? report.lod_notreleated : "0",
            lod_pending: report.lod_pending ? report.lod_pending : "0",
            lod_complete: report.lod_complete ? report.lod_complete : "0",
            query_raised_dt: report.query_raised_dt ? report.query_raised_dt : "",
            query_received_dt: report.query_received_dt ? report.query_received_dt : "",
            typing_completed_dt: report.typing_completed_dt ? report.typing_completed_dt : "",
            verification_picked_dt: report.verification_picked_dt ? report.verification_picked_dt : "",
            verification_completed_dt: report.verification_completed_dt ? report.verification_completed_dt : "",
            hold_by: report.hold_by ? report.hold_by : "",
            hold_dt: report.hold_dt ? report.hold_dt : "",
            hold_reason: report.hold_reason ? report.hold_reason : "",
            unhold_by: report.unhold_by ? report.unhold_by : "",
            unhold_dt: report.unhold_dt ? report.unhold_dt : "",
            unhold_reason: report.unhold_reason ? report.unhold_reason : "",
        }));
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        const downloadExcelProps = {
            type: 'filtered',
            title: `Subreport Report - ${formattedDate}`,
            showLabel: true
        }
        return (
            <div className="">
                <ReactFlexyTable
                    className="assigned-table"
                    data={processedData}
                    columns={columns}
                    sortable
                    pageSize={10}
                    globalSearch
                    showExcelButton
                    downloadExcelProps={downloadExcelProps}
                />
            </div>
        );
    }
}
