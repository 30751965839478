import React, { Component } from "react";
import { callApi } from "../../utils/api";
import ReactFlexyTable from "react-flexy-table";
import Select from 'react-select';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import { ADMIN_ROLE } from "../../utils/role";
import moment from "moment";
declare var $: any;
interface AllProps {
    history: any;
}
class VettingTableTemplate extends Component<AllProps> {
    state = {
        isFileRefresh: false,
        bank_templates: [],
        bank_id: 0,
        rows: [],
        columns: [],
        tableData: [],
        outreason: "",
        selectVal: false,
        isLoading: false
    };
    componentDidMount() {
        this.getTemplates();
        this.gettabledata();
        this.getOutTime();
        this.ouTime();
    }

    addtabledata = () => {
        const user: any = sessionStorage.getItem("user1");
        console.log("saved", this.state);
        if (this.state.bank_id) {
            if (this.state.columns.length > 0) {
                callApi("POST", "vetting_table_template_add.php", {
                    userid: JSON.parse(user),
                    bank_id: this.state.bank_id,
                    tableHeadersData: this.state.columns,
                })
                    .then(res => res.data)
                    .then(response => {
                        if (response.result == true) {
                            ToastsStore.success("Updated Successfully");
                            this.setState({ columns: [] })
                            this.gettabledata();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            else {
                ToastsStore.success("Add the table column and enter the data to proceed");
            }
        } else {
            ToastsStore.success("Please Select Bank");
        }
    }

    deletetabledata = (file: any) => {
        const user: any = sessionStorage.getItem("user1");
        callApi("POST", "", {
            userid: JSON.parse(user),
            id: file.id,
            bank_id: file.bank_id,
        })
            .then(res => res.data)
            .then(response => {
                console.log("response--->>>", response);
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchvettingtable(file: any): void {
        console.log(file);
        const user: any = sessionStorage.getItem("user1");
        callApi("POST", "vetting_table_template_get.php", {
            userid: JSON.parse(user),
            bank_id: file.bank_id,
        })
            .then(res => res.data)
            .then(response => {
                let res = response.data;
                if (res != "") {
                    for (let i = 0; i < res.length; i++) {
                        const element = res[i];
                        if (element.tableHeadersData != null) {
                            this.setState({ bank_id: element.bank_id });
                            this.setState({ columns: element.tableHeadersData });
                        }
                        else {
                            this.setState({ columns: [] });
                            this.setState({ bank_id: "" });
                        }
                    }
                } else {
                    this.setState({ columns: [] });
                    this.setState({ rows: [] });
                    this.setState({ bank_id: "" });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    addvettingtable() {
        console.log("this.state", this.state);
    }

    generatevettingtable() {
        console.log("this.state", this.state);
        // ToastsStore.success("Updated Successfully");
        // this.updatetabledata();
    }

    getTemplates = () => {
        callApi("POST", "bank_temp_get.php")
            .then(res => res.data)
            .then(response => {
                let banks = response.data;
                const vetting = banks.filter(item => item.name.split(" ").pop() === "Vetting");
                if (vetting && vetting.length > 0) {
                    vetting.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ bank_templates: vetting, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };

    gettabledata = () => {
        this.setState({ isFileRefresh: false })
        callApi("GET", "vetting_table_template_get_all.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ tableData: files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };


    handleChange = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        this.setState({ [fieldName]: fieldValue });
        this.setState({ bank_id: fieldValue })
    };

    handleChangeheader(event: React.ChangeEvent<HTMLInputElement>, columnIndex: number): void {
        const { columns } = this.state;
        const newColumns = [...columns] as string[];
        newColumns[columnIndex] = event.target.value;
        this.setState({ columns: newColumns });
    }

    handleChangetable(event: React.ChangeEvent<HTMLInputElement>, rowIndex: number, columnIndex: number): void {
        const { rows } = this.state;
        const newRows = [...rows] as string[][];
        // Check if rowIndex is a valid index in newRows
        if (newRows[rowIndex] || newRows[columnIndex]) {
            newRows[rowIndex][columnIndex] = event.target.value;
            this.setState({ rows: newRows });
        }
        console.log("table rows", this.state.rows);
    }
    handleAddRow = () => {
        const { rows, columns } = this.state as { rows: never[][]; columns: string[] };
        const newRow = [] as string[];
        for (let i = 0; i < columns.length; i++) {
            newRow.push("");
        }
        this.setState({ rows: [...rows, newRow] });
    };

    handleRemoveRow = (index) => {
        const { rows } = this.state;
        const newRows = [...rows];
        newRows.splice(index, 1);
        this.setState({ rows: newRows });
    };

    handleAddColumn = () => {
        const { rows, columns } = this.state;
        const newColumn = "";
        const newRows = rows.map((row) => [...row, newColumn]);
        this.setState({ rows: newRows, columns: [...columns, newColumn] });
    };

    handleRemoveColumn = (index) => {
        const { rows, columns } = this.state;
        const newColumns = [...columns];
        newColumns.splice(index, 1);
        const newRows = rows.map((row) => {
            const newRow = [...row];
            newRow.splice(index, 1);
            return newRow;
        });
        this.setState({ rows: newRows, columns: newColumns });
    };
    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    this.setState({ isLoading: false });
                    console.log("76", response);
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    }else {
                        sessionStorage.removeItem('currentfileId');
                      }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };

    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };


    render() {
        const { rows, columns }: any = this.state;
        const user: any = sessionStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const column = [
            {
                header: 'S.No',
                key: 'index',
            },
            {
                header: 'Bank Name',
                key: 'bank_name',
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <a
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#deed-form"
                                onClick={() =>
                                    this.fetchvettingtable(file)
                                }
                            >
                                Edit
                            </a>
                            {/* {role_id === ADMIN_ROLE &&
                                <a
                                    className="dropdown-item"
                                    style={{ float: "right" }}
                                    data-toggle="modal"
                                    data-target="#delete-template"
                                    onClick={() =>
                                        this.deletetabledata(file)
                                    }
                                >
                                    Delete
                                </a>
                            } */}
                        </div>
                    </div>
                }
            }
        ];
        const data = this.state.tableData;
        return (
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <ToastsContainer
                        store={ToastsStore}
                        position={ToastsContainerPosition.TOP_RIGHT}
                    />{" "}
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header row">
                                                    <div className="col-md-6">
                                                        <h5 className="file-status-header">Vetting Table Template</h5>
                                                    </div>
                                                    <div className="file-search-button col-md-4 d-flex">
                                                        <div style={{ marginTop: "10%", width: "50%", marginLeft: "50%" }}>
                                                            {this.state.bank_templates && this.state.bank_templates.length > 0 && this.state.bank_templates.map((opt: any) => {
                                                                opt.label = opt.name; opt.value = opt.id
                                                            }) &&
                                                                <Select options={this.state.bank_templates} name="bank_id"
                                                                    value={this.state.bank_templates.find((o: any) => o.value === this.state.bank_id)}
                                                                    onChange={(e) => this.handleChange(e, 'bank_id', 'singleselect')}
                                                                />
                                                            }
                                                        </div>
                                                        {/* <button
                                                            type="button" style={{ width: "30%", marginTop: "10%", marginLeft: "3%" }}
                                                            className="btn btn-icon btn-success float-right"
                                                            onClick={() => { this.generatevettingtable() }}
                                                        >
                                                            Generate
                                                        </button> */}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ float: "right" }}
                                                            onClick={() => this.props.history.goBack()}
                                                        >
                                                            Back
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className='table mt-4'>
                                                        <div className="card-body table">
                                                            <div className="dt-responsive table-responsive">
                                                                <label className='bold'>Table</label>
                                                                <table className="table table-bordered table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>S.No</th>
                                                                            {columns.map((column, columnIndex) => (
                                                                                <th key={columnIndex}>
                                                                                    <button
                                                                                        className="btn btn-danger btn-sm float-right"
                                                                                        onClick={() => this.handleRemoveColumn(columnIndex)}
                                                                                    >
                                                                                        x
                                                                                    </button>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={column}
                                                                                        onChange={(event) =>
                                                                                            this.handleChangeheader(event, columnIndex)
                                                                                        }
                                                                                    />
                                                                                </th>
                                                                            ))}
                                                                            <th>
                                                                                <button
                                                                                    onClick={this.handleAddColumn}
                                                                                    className="btn btn-primary float-right"
                                                                                >
                                                                                    Add Column
                                                                                </button>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {rows.map((row: [], rowIndex) => (
                                                                            <tr key={rowIndex}>
                                                                                <td>{rowIndex + 1}</td>
                                                                                {row.map((cell, columnIndex) => (
                                                                                    <td key={columnIndex}>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={cell}
                                                                                            onChange={(event) =>
                                                                                                this.handleChangetable(event, rowIndex, columnIndex)
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                ))}
                                                                                <td>
                                                                                    <button
                                                                                        className="btn btn-danger btn-sm float-right"
                                                                                        onClick={() => this.handleRemoveRow(rowIndex)}
                                                                                    >
                                                                                        x
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <button
                                                                    type="button" style={{ width: "10%" }}
                                                                    className="btn btn-icon btn-danger float-right"
                                                                    onClick={() => { this.addtabledata() }}
                                                                >
                                                                    Submit
                                                                </button>
                                                                {/* <button
                                                                    onClick={this.handleAddRow}
                                                                    className="btn btn-primary pull-left"
                                                                >
                                                                    Add Row
                                                                </button> */}
                                                                <div style={{ marginTop: "10%" }}>
                                                                    {this.state.isFileRefresh &&
                                                                        <ReactFlexyTable data={data} columns={column} sortable globalSearch />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}
export default VettingTableTemplate;