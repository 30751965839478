import * as React from "react";
import { callApi } from "../../utils/api";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import moment from "moment";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
import DashUploadDocument from "./DashUploadDocument";
import { cloneDeep } from 'lodash';
import { notifyToUser } from "../../utils/firebase";
import { pushnotificationApi } from "../../utils/pushNotify";
declare var $: any;

interface AllProps {
    match: any;
    history: any;
    router: any;
    route: any;
    id: any;
}

class AddRectifyFile extends React.Component<AllProps> {
    documentFormat = {
        base: "",
        base_url: "",
        docNoYear: "",
        particular: "xerox",
        type: "",
        isValid: true,
        doc_type: ""
    };
    state = {
        filestatus: "",
        selectVal: false,
        inreason: "",
        outreason: "",
        isLoading: false,
        file_id: this.props.match.params.id != "0" ? this.props.match.params.id : "",
        reason: "",
        isFileCompleted: false,
        isFileNotFound: false,
        isFileFound: false,
        files: [],
        isFileRefresh: false,
        base: [],
        deeds: [],
        previewDoc: '',
        type: "",
        categoriesList: [],
        isNewFile: false,
    };
    obj: any;
    componentDidMount() {
        this.getOutTime();
        this.ouTime();
        this.getCategories();
        this.getDeeds();
        if (this.props.match.params.id != "0") {
            this.getFiles(this.props.match.params.id);
        }
    }
    getCategories = () => {
        callApi("POST", "category.php")
            .then((res) => res.data)
            .then((response) => {
                this.setState({ categoriesList: response.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getDeeds = () => {
        callApi("POST", "deed_master_get.php", { category: this.state.type })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    const deeds = response.data;
                    this.setState({ deeds });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    getFiles = async (fileid) => {
        await this.getRectifyFiles(fileid);
        callApi("POST", "search.php", {
            file_name: fileid
        })
            .then(res => res.data)
            .then(response => {
                if (response['data'] && response['data'][0]) {
                    const filestatus = response['data'][0].file_status;
                    this.setState({ isFileCompleted: filestatus != 5 ? true : false, filestatus });
                    this.setState({ isFileNotFound: false });
                    this.setState({ isFileFound: true });
                } else {
                    if (this.state.file_id == "") {
                        this.setState({ isFileNotFound: false, isFileCompleted: false, isFileFound: false });
                    } else {
                        this.setState({ isFileNotFound: true, isFileCompleted: false, isFileFound: false });
                        ToastsStore.error("No data found.");
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    getRectifyFiles = (fileid) => {
        callApi("POST", "get_rectify_data.php", {
            file_id: fileid
        })
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                    this.setState({ files, isFileRefresh: true });
                } else {
                    this.setState({ files: [], isFileRefresh: false });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    validateDocuments = () => {
        let base: any = this.state.base;
        let isValid = true;
        const invalidDocuments = ['26', '27', '36', '35', '38', '103', '78', '5', 'Online Encumbrance Certificate'];
        for (let i = 0; i < base.length; i++) {
            const document = base[i];

            if (invalidDocuments.includes(document.doc_type)) {
                base[i].isValid = true;
            }
            else {
                if (document.type == "deed" || document.type == "mother_doc" || document.type == "title_deed" || document.type == "ec_doc") {
                    if (!document.docNoYear || !document.doc_type || !document.type || !document.particular || !document.base) {
                        isValid = false;
                        base[i].isValid = false;
                    } else {
                        base[i].isValid = true;
                    }
                } else if (document.type == "rev_rec_doc" || document.type == "sale_agreement") {
                    if (!document.doc_type || !document.type || !document.particular || !document.base) {
                        isValid = false;
                        base[i].isValid = false;
                    } else {
                        base[i].isValid = true;
                    }
                }
                else {
                    isValid = false;
                    base[i].isValid = false;
                }
            }
        }
        return isValid;
    };

    validateRequiredFileds = (e) => {
        let { file_id, reason } = this.state;
        if (this.state.base && this.state.base.length > 0) {
            const isValid = this.validateDocuments();
            if (!isValid) {
                ToastsStore.error("Please fill in all fields for this document");
                return;
            } else {
                if (file_id != "" && reason != "") {
                    this.saverectification("withdoc");
                    this.uploadDoc();
                } else {
                    if (file_id == "") {
                        ToastsStore.error("Enter the file no to proceed");
                    } else if (reason == "") {
                        ToastsStore.error("Enter the reason to proceed");
                    } else {
                        ToastsStore.error("Enter the field to proceed");
                    }
                }
            }
        } else {
            this.saverectification("withoutdoc");
        }
    };

    uploadDoc = () => {
        this.setState({ isLoading: true });
        let flag_status;
        if (this.state.filestatus == "7") {
            flag_status = 1;
        } else {
            flag_status = 0;
        }
        callApi("POST", "doc_file_upload.php", {
            id: this.state.file_id,
            uploadfile: this.state.base,
            flag_status: flag_status,
            type: "process"
        })
            .then(res => res)
            .then(response => {
                const responseData = response.data;
                if (responseData.success === true) {
                    this.setState({ isLoading: false });
                    this.setState({ base: [] });
                    const user: any = sessionStorage.getItem("user");
                    const userId = user ? JSON.parse(user)["userId"] : "";
                    const userName = user ? JSON.parse(user)["firstName"] : "";
                    const notifyData = {
                        fileId: this.state.file_id, title: `${this.state.file_id}Query Upload`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
                    };
                    let user_id = userId;
                    pushnotificationApi(notifyData, user_id);
                    const branchManger: any = sessionStorage.getItem("branch_manager");
                    const branch_Manager = JSON.parse(branchManger);
                    const queryOfficer: any = sessionStorage.getItem("Query_officer");
                    const query_ooficer = JSON.parse(queryOfficer);
                    const Scan_officer: any = sessionStorage.getItem("scan_officer");
                    const sacanofficer = JSON.parse(Scan_officer);
                    const process_mennager: any = sessionStorage.getItem("process_manager");
                    const processManager = JSON.parse(process_mennager);
                    if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
                        const branchManager = branch_Manager.join(",");
                        let obj = {
                            // notification_type:"queryupload",
                            user_id: branchManager,
                            ...notifyData
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, query_ooficer);
                        const Queryofficer = query_ooficer.join(",");
                        let obj = {
                            // notification_type:"queryupload",
                            user_id: Queryofficer,
                            ...notifyData
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
                        const ScanOfficer = sacanofficer.join(",");
                        let obj = {
                            // notification_type:"queryupload",
                            user_id: ScanOfficer,
                            ...notifyData
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    if (Array.isArray(processManager) && processManager.length > 0) {
                        notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
                        const ProcessManager = processManager.join(",");
                        let obj = {
                            // notification_type:"queryupload",
                            user_id: ProcessManager,
                            ...notifyData
                        }
                        callApi("POST", "pushnotification.php", obj)
                            .then((res) => res.data)
                            .then((response) => {
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    ToastsStore.success("File(s) Uploaded Successully");
                } else {
                    this.setState({ isLoading: false });
                    ToastsStore.success("File(s) Not Uploaded");
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }
    saverectification = (type) => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let { file_id, reason } = this.state;
        if (file_id != "" && reason != "") {
            if (type == "withoutdoc") {
                this.setState({ isLoading: true });
            }
            callApi("POST", "create_rectify.php", {
                file_id: file_id,
                reason: reason,
                created_by: userId
            })
                .then(res => res.data)
                .then(response => {
                    if (response['result'] == true) {
                        this.saveHint(reason, file_id);
                        ToastsStore.success(response["message"]);
                        if (type == "withoutdoc") {
                            this.setState({ isLoading: false });
                        }
                        const loginuser: any = sessionStorage.getItem("user1");
                        let req = {
                            id: file_id,
                            reason: "Rectification Received",
                            created_by: JSON.parse(loginuser),
                            color: "rgb(250 248 76 / 71%)"
                        }
                        callApi("POST", "time_update.php", req)
                            .then(res => res.data)
                            .then(response => { });
                        this.setState({ file_id: "", reason: "", isFileFound: false });
                        this.getRectifyFiles(file_id);
                    } else {
                        ToastsStore.error(response["message"]);
                        this.setState({ isLoading: false, isFileFound: false });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ isLoading: false, isFileFound: false });
                });
        } else {
            if (file_id == "") {
                ToastsStore.error("Enter the file no to proceed");
            } else if (reason == "") {
                ToastsStore.error("Enter the reason to proceed");
            } else {
                ToastsStore.error("Enter the field to proceed");
            }
        }
    }

    saveHint = (reason, file_id) => {
        const user: any = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi('POST', 'add_hints.php', { id: file_id, userid: userId, hints: reason })
            .then(res => res.data)
            .then(result => { })
            .catch(err => {
                console.log(err);
            });
    }

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = sessionStorage.getItem("currentfileId");
        if (sessionStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = sessionStorage.getItem("currentfileId");
        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = sessionStorage.getItem("currentfileId");
        const userid1: any = sessionStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // sessionStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = sessionStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = sessionStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = sessionStorage.getItem("takenfile_id");
                    const user: any = sessionStorage.getItem("user");
                    const getfileId: any = sessionStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        sessionStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = sessionStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                sessionStorage.removeItem('currentfileId');
                // sessionStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {

        this.setState({ [e.target.name]: e.target.value, selectVal: false });

    };
    handleChange = (e, propName?, fieldType?) => {
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName == "file_id") {
            const isValidInput = /^[0-9]*$/.test(fieldValue);
            if (!isValidInput) {
                // Display an error message or handle the invalid input as needed
                console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
                return;
            } else {
                this.setState({ [fieldName]: fieldValue });
                if (fieldValue == "") {
                    this.setState({ isFileCompleted: false, isFileNotFound: false, isFileFound: false });
                }
            }
        } else {
            const inputValue = fieldValue;
            if (/^[ -]/.test(inputValue)) {
                console.error("Invalid input. The first letter cannot be a space or hyphen.");
                return;
            }
            this.setState({ [fieldName]: inputValue });
        }
        console.log(`fieldName---${fieldName}---fieldValue---_${fieldValue}`);
    };
    addUploadDocument = () => {
        let baseArray = cloneDeep(this.state.base);
        baseArray.push(this.documentFormat);
        this.setState({ base: baseArray });
    };
    handleDocumentDetailsChange = (value, index, key) => {
        const invalidDocuments = ['26', '27', '36', '35', '38', '103', '78', '5', 'Online Encumbrance Certificate'];
        if (key == "docNoYear") {

            const isValidInput = /^[0-9/-]*$/.test(value);

            if (!isValidInput) {
                console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
                return;
            }
            let baseArray = cloneDeep(this.state.base);
            baseArray[index] = {
                ...baseArray[index],
                [key]: value,
            };
            this.setState({ base: baseArray });
        } else {
            let baseArray = cloneDeep(this.state.base);
            if (key == 'doc_type') {
                if (invalidDocuments.includes(value)) {
                    baseArray[index] = {
                        ...baseArray[index],
                        'docNoYear': '',
                    };
                    this.setState({ base: baseArray });
                }
            }

            baseArray[index] = {
                ...baseArray[index],
                [key]: value,
            };
            this.setState({ base: baseArray });
        }
    };
    handleDocumentFilesChange1 = (value, index, key) => {
        let baseArray = cloneDeep(this.state.base);
        baseArray[index] = {
            ...baseArray[index],
            base: value.fileList[0].name,
            base_url: value.base64,
        };
        this.setState({ base: baseArray });
    }
    deleteDocumentDetails = (index) => {
        const baseArray = cloneDeep(this.state.base);
        baseArray.splice(index, 1);
        this.setState({ base: baseArray });
    };
    deleteFile = (index) => {
        const baseArray = cloneDeep(this.state.base);
        baseArray[index] = {
            ...baseArray[index],
            details: {},
            base: "",
        };
        this.setState({ base: baseArray });
    };
    previewDoc = (modal) => {
        this.setState({ previewDoc: modal.file });
    };
    render() {
        const columns = [
            {
                header: 'S.No',
                key: 'id',
                td: (file) => <td >{file.index}</td>
            },
            {
                header: 'File ID',
                key: 'file_id',
                td: (file) => <td >{file.file_id}</td>
            },
            {
                header: 'Reason',
                key: 'reason',
                td: (file) => <td > {file.reason} </td>
            },
            {
                header: 'Status',
                key: 'status',
                td: (file) => <td >{file.status}</td>
            },
            {
                header: 'Created By',
                key: 'created_by',
                td: (file) => <td >{file.created_by}</td>
            },
            {
                header: 'Created Date',
                key: 'created_dt',
                td: (file) => <td >{file.created_dt}</td>
            },
            {
                header: 'Completed Date',
                key: 'completed_dt',
                td: (file) => <td >{file.completed_dt}</td>
            }
        ];
        const { selectVal, isFileCompleted, file_id, reason, isFileRefresh, files, isFileNotFound, isLoading, isFileFound } = this.state;
        const user: any = sessionStorage.getItem("user");
        const roleType = user ? JSON.parse(user)["roleType"] : "";
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2 className="file-status-header text-center font-weight-bold">Rectification</h2>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="file_id">File No<span style={{ color: "red" }}>*</span></label>
                                                                    <input
                                                                        className={file_id === '' ? 'form-control is-invalid' : 'form-control'}
                                                                        placeholder="Ex - 41674"
                                                                        name="file_id"
                                                                        type="text"
                                                                        value={file_id}
                                                                        onChange={e => this.handleChange(e)}
                                                                        onBlur={() => this.getFiles(file_id)}
                                                                    />
                                                                    {file_id === '' && (
                                                                        <div className="invalid-feedback">*Required Field</div>
                                                                    )}
                                                                    {isFileCompleted && (
                                                                        <div style={{ width: "100%", fontSize: "14px", color: "#dc3545" }}>This file is not completed, so rectification cannot proceed.</div>
                                                                    )}
                                                                    {isFileNotFound && (
                                                                        <div style={{ width: "100%", fontSize: "14px", color: "#dc3545" }}>To recover this file, Please contact the software team.(If the file has already been completed)</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="reason">Reason</label>
                                                                    <textarea
                                                                        placeholder=""
                                                                        name="reason"
                                                                        value={reason}
                                                                        onChange={e => this.handleChange(e)}
                                                                        className="form-control"
                                                                        style={{ height: '10vh' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    {isFileFound && !isFileCompleted && <>
                                                        <div className="row ml-1">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-sm upload-document-button"
                                                                onClick={() => this.addUploadDocument()}
                                                            >
                                                                Add Document +
                                                            </button>
                                                        </div>
                                                    </>}
                                                    <div className="row mt-4">
                                                        <div className="col-md-12">
                                                            {this.state.base.length > 0 && this.state.base.map((document, index) => (
                                                                <DashUploadDocument
                                                                    baseDetails={document}
                                                                    handleDocumentDetailsChange={
                                                                        this.handleDocumentDetailsChange
                                                                    }
                                                                    handleDocumentFilesChange={
                                                                        this.handleDocumentFilesChange1
                                                                    }
                                                                    deleteDocumentDetails={
                                                                        this.deleteDocumentDetails
                                                                    }
                                                                    deleteFile={this.deleteFile}
                                                                    deedList={this.state.deeds}
                                                                    previewDoc={this.previewDoc}
                                                                    categoriesList={this.state.categoriesList}
                                                                    index={index}
                                                                    newfile={this.state.isNewFile}
                                                                    path="filedashboard"
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <button onClick={(e) =>
                                                                this.validateRequiredFileds(e)
                                                            } type="button" className="btn  btn-success" style={{ height: "40px", marginTop: "5%", borderRadius: "10px" }} disabled={isLoading || isFileCompleted || isFileNotFound}
                                                            >  {this.state.isLoading ? (
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            ) : "Save"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isFileRefresh && files && files.length > 0 && <>
                                                    <div className="card-body">
                                                        <ReactFlexyTable data={files} columns={columns} sortable globalSearch className='Flexy-table' />
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`modal ${this.state.previewDoc ? 'open' : ''}`}
                    id="preview-document"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Document Preview</h5>
                                <button
                                    type="button"
                                    className="close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.setState({ previewDoc: '' })}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <iframe width="100%" height="400px" src={this.state.previewDoc}></iframe>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => this.setState({ previewDoc: '' })}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{sessionStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                >  {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {sessionStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                >  {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}
export default AddRectifyFile;
