import * as React from "react";
import RectificationInprogressFile from "../file/RectificationInprogressFile";

interface AllProps {
    history: any;
}

class ManageRectificationInprogressFile extends React.Component<AllProps> {
    render() {
        return (
            <RectificationInprogressFile history={this.props.history} />
        );
    }
}

export default ManageRectificationInprogressFile;
