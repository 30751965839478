import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import { callApi } from "../../utils/api";
import defaultStyle from "./defaultStyle";
import { Deed } from "./ManageDeedTemplate";
declare var $: any;
interface AllProps {
  selectedDeed: Deed;
  listDeed: any;
}
// interface AllState {
//   deeddetail: {
//     id: number;
//     deed_id: number;
//     deedid: string;
//     type: string;
//     scrutiny_table: string;
//     scrutiny_history: string;
//     template: string;
//     isScheduleChecked: boolean; 
//   };
//   dynamicFields: any[]; 
//   deeds: any[]; 
//   users: any[]; 
//   fields: any[];
// }
class AddDeedTemplate extends React.Component<AllProps> {
  state = {
    deeddetail: {
      id: 0,
      deed_id: 0,
      deedid: "",
      type: "",
      scrutiny_table: "",
      scrutiny_history: "",
      template: "",
      //isScheduleChecked: false,
    },
    dynamicFields: [],
    deeds: [],
    users: [],
    fields: []
  };
  componentDidMount() {
    this.getDeeds();
    this.getDynamicFields();
    this.getSuggestions();
    let s = $(".js-example-basic-multiple");
    s.select2({
        placeholder: "Select an option",
        allowClear: true
    });
    s.on("change", this.handleDynamicChange);
  }
  componentWillReceiveProps(nextState) {
    this.setState({ deeddetail: nextState.selectedDeed });
    if (!nextState.selectedDeed || typeof nextState.selectedDeed.fields !== 'string') {
      $(".js-example-basic-multiple").val([]);
      $(".js-example-basic-multiple").select2().trigger('change');
    } else {
      const fieldsArray = nextState.selectedDeed.fields.split(",");
      $(".js-example-basic-multiple").val(fieldsArray);
      $(".js-example-basic-multiple").select2().trigger("change");
    }
  }
  getDynamicFields = () => {
    callApi("POST", "input_master_get.php")
      .then(res => res.data)
      .then(response => {
        console.log(response);
        if (response.data && response.data.length) {
          this.setState({ dynamicFields: response.data });
          // $("select.multiselect").selectpicker();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getDeeds = () => {
    callApi("GET", "deed_master_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ deeds: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getSuggestions = () => {
    callApi("POST", "autocomplete_tag.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ users: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  handleChange = (e: any) => {
    const deeddetail: any = this.state.deeddetail;
    deeddetail[e.target.name] = e.target.value;
    this.setState({ deeddetail });
  };
  handleDynamicChange = (e: any) => {
    this.setState({ fields: $(".js-example-basic-multiple").val() });
  };
  handleScrTableChange = (e: any) => {
    const deeddetail: any = this.state.deeddetail;
    deeddetail["scrutiny_table"] = e.target.value;
    this.setState({ deeddetail });
  };
  handleHistoryChange = (e: any) => {
    const deeddetail: any = this.state.deeddetail;
    deeddetail["scrutiny_history"] = e.target.value;
    this.setState({ deeddetail });
  };
  saveDeed = () => {
    const deedInput: any = this.state.deeddetail;
    deedInput.fields = this.state.fields; 
    //deedInput.isScheduleChecked = this.state.deeddetail.isScheduleChecked;
    if (this.state.deeddetail.id) {
      deedInput.id = Number(this.state.deeddetail.id);
      callApi("POST", "deed_update.php", deedInput)
        .then(res => res.data)
        .then(response => {
          this.props.listDeed();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      callApi("POST", "deed_create.php", deedInput)
        .then(res => res.data)
        .then(response => {
          this.props.listDeed();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  renderUserSuggestion = e => {
    console.log("renderUserSuggestion");
  };
  // handleCheckboxChange = () => {
  //   this.setState(prevState => ({
  //     deeddetail: {
  //       ...prevState.deeddetail,
  //       isScheduleChecked: !prevState.deeddetail.isScheduleChecked,
  //     },
  //   }));
  // };
  render() {
    return (
      <div
        className="modal fade"
        id="deed-form"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Template Form</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Deed Category</label>
                  <select
                    className="custom-select"
                    name="type"
                    value={
                      this.state.deeddetail.type
                        ? this.state.deeddetail.type
                        : ""
                    }
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    required
                  >
                    <option>Select</option>
                    <option value="ec">EC</option>
                    <option value="revenue_record">Revenue Record</option>
                    <option value="deed">Deed</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Deed</label>
                  <select
                    className="custom-select"
                    name="deedid"
                    value={
                      this.state.deeddetail.deedid
                        ? this.state.deeddetail.deedid
                        : ""
                    }
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    required
                  >
                    <option>Select</option>
                    {this.state.deeds && this.state.deeds.length
                      ? this.state.deeds.map((deed: any, index) => {
                          return (
                            <option key={index} value={deed.id}>
                              {deed.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Template</label>
                  <input
                    type="text"
                    name="template"
                    className="form-control"
                    placeholder="Template Name"
                    onChange={e => {
                      this.handleChange(e);
                    }}
                    value={
                      this.state.deeddetail.template
                        ? this.state.deeddetail.template
                        : ""
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Dynamic Fields </label>
                  <select
                    className="js-example-basic-multiple"
                    name="fields"
                    value={this.state.fields}
                    onChange={e => this.handleDynamicChange(e)}
                    multiple
                  >
                    {this.state.dynamicFields.length
                      ? this.state.dynamicFields.map(
                          (template: any, index: number) => {
                            return (
                              <option key={index} value={template.id}>
                                {template.name}
                              </option>
                            );
                          }
                        )
                      : ""}
                  </select>
                </div>
                <div className="form-group">
                  {/* <input
                    type="checkbox"
                    className="form-control"
                    style={{ display: "inline", marginRight: "5px" }}
                    checked={this.state.deeddetail.isScheduleChecked}
                    onChange={this.handleCheckboxChange} 
                  /> 
                  <label>Schedule with Relationship</label> */}
                </div>
                <div className="form-group">
                  <label className="col-form-label">
                    Scrutiny Table Content
                  </label>
                  <MentionsInput
                    className="mentionTextarea"
                    style={defaultStyle}
                    value={this.state.deeddetail.scrutiny_table}
                    onChange={this.handleScrTableChange}
                  >
                    <Mention
                      trigger="@"
                      data={this.state.users}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => <div className="user">{highlightedDisplay}</div>}
                    />
                  </MentionsInput>
                </div>
                <div className="form-group">
                  <label className="col-form-label">
                    Scrutiny History Content
                  </label>
                  <MentionsInput
                    className="mentionTextarea"
                    style={defaultStyle}
                    value={this.state.deeddetail.scrutiny_history}
                    onChange={this.handleHistoryChange}
                  >
                    <Mention
                      trigger="@"
                      data={this.state.users}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => <div className="user">{highlightedDisplay}</div>}
                    />
                  </MentionsInput>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.saveDeed}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddDeedTemplate;
